import { userService } from '../services';

export const users = {
    namespaced: true,
    state: {
        all: {},
        selected_user: {},
        update: {}
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                userService.getAll()
                .then(
                    users => {
                        commit('getAllSuccess', users)
                        resolve(users)
                    }, 
                    error => {
                        commit('getAllFailure', error)
                        reject(error)
                    } 
                )
            });
        },
        getAllUsersWithRoles({ commit }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                userService.getAllUsersWithRoles()
                    .then(
                        users => {
                            commit('getAllSuccess', users)
                            resolve(users)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    )
            });
        },

        getUser({ commit }, id) {
            commit('getRequest');
            return new Promise((resolve, reject) => {
                userService.getUser(id)
                    .then(
                        user => { 
                            commit('getSuccess', user)
                            resolve(user)
                        },
                        error => {
                            commit('getFailure', error)
                            reject(error)
                        }
                    );
            });
            
        },


        changePassword({
            commit
        }, dto) {
            commit('updateRequest');

            return new Promise((resolve, reject) => {
                userService.updatePassword(dto)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })
        },

        changePasswordByProfile({
            commit
        }, dto) {
            commit('updateRequest');

            return new Promise((resolve, reject) => {
                userService.updatePasswordByProfile(dto)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })
        },

        updateUser({
            commit
        }, dto) {
            commit('updateRequest');

            return new Promise((resolve, reject) => {
                userService.updateUser(dto)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        

    },
    mutations: {

        updateRequest(state) {
            state.update = {
                loading: true
            }; 
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },

        getRequest(state) {
            state.selected_user = { loading: true };
        },
        getSuccess(state, user) {
            state.selected_user = { item: user };
        },
        getFailure(state, error) {
            state.selected_user = { error };
        },
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
}