<template>
  <main>
    <base-card>
      <v-row>
        <v-col cols="2" class="mr-4">
          <v-btn
              class="primary mt-3 mb-3"
              @click="ToggleTextAreas()"
              :disabled="disablebutton">
            Rechnungstext
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
              class="primary mt-3 mb-3"
              @click="TogglePositions()"
              :disabled="disablebutton"
          > Rechnungspositionen
          </v-btn>
        </v-col>
        <v-col>
          <template>
            <v-row justify="center">
              <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="primary mt-6 mb-3"
                      :disabled="!disableDownload || disablebutton || status == 'Storno'"
                      v-bind="attrs"
                      v-on="on">
                    Rechnung genehmigen
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    <h3>Rechnungsdatum</h3>
                  </v-card-title>
                  <v-card-text>
                    <v-radio-group
                        style="display: inline-block"
                        v-model="billdate"
                        column>
                      <v-row>
                        <v-col>
                          <v-radio
                              label="Standard:"
                              color="primary"
                              value="standard"
                              checked="checked"
                              @click="SetStandardBillingDate"
                          ></v-radio>
                        </v-col>
                        <v-col>
                          <p>{{ billingDate }}</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-radio
                              label="Neues Rechnungsdatum"
                              color="primary"
                              value="newDate"
                              @click="SetNewBillingDate"
                          ></v-radio>
                        </v-col>
                        <v-col cols="10">
                          <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="true"
                              :return-value="newbillingDateForInvoices"
                              transition="scale-transition"
                              offset-y
                              min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  :disabled="!newBillingDate"
                                  :value="computedDateFormattedMomentjs ?
                                  computedDateFormattedMomentjs.substr(8,2)+ '.'
                                  + computedDateFormattedMomentjs.substr(5,2)+'.'
                                  + computedDateFormattedMomentjs.substr(0,4): ''"
                                  label="Tag wählen"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                locale="de"
                                @change="setNewDateForBills">
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="menu = false">
                                Abbrechen
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu.save(date)">
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="secondary"
                          @click="dialog = false">
                        Abbrechen
                      </v-btn>
                      <v-btn
                          color="primary"
                          :disabled="!date && newBillingDate"
                          @click="ApproveOneInvoice()">
                        Genehmigen
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
        </v-col>
        <v-col>

        </v-col>
        <v-col cols="3">
          <v-btn
              style="margin-left: -70px"
              color="info"
              class="primary mt-3 mb-3"
              @click="DownloadInvoice()"
              :disabled="disableDownload || disablebutton || status == 'Storno'">
            <v-icon>
              mdi-cloud-download-outline
            </v-icon>
            Rechnung herunterladen
          </v-btn>
        </v-col>
      </v-row>
      <EditInvoiceTextAreas
          v-if="showtextareas"
          :form="this.form"
          :invoiceNumber="invoicenumber"
          :invoiceGOID="invoiceID"
          :readonly="!disableDownload"
          />
      <div v-for="(item) in invoicePositions" :key="item.ID">
        <EditInvoicePositions
            :InvoiceGoID="invoiceID"
            :key="tmp"
            class="mb-10"
            v-if="showpositions"
            :status="status"
            :invoicePosition="item"
            :approvedStatus="approvalString"/>
      </div>
      <v-row>
        <v-btn v-if="showRechnungsposition"
               @click="addPosition"
               class="ml-3 mb-3" color="secondary">
          <v-icon small>mdi-plus</v-icon>
          Rechnungsposition hinzufügen
        </v-btn>
        <v-col></v-col>
      </v-row>
    </base-card>
  </main>
</template>

<script>
import EditInvoiceTextAreas from './EditInvoiceTextAreas.vue'
import EditInvoicePositions from './EditInvoicePositions.vue'
import BaseCard from "../Base/BaseCard";
import moment from "moment";


export default {
  components: {
    BaseCard,
    EditInvoiceTextAreas,
    EditInvoicePositions
  },
  props: {
    invoiceID: Number,
    disablebutton: Boolean,
    approvalString: String,
    status: String,
    referenz: String,
    invoicenumber: Number,
  },
  async created() {
    if (this.invoiceID != undefined) {
      await this.$store.dispatch('invoices/getInvoiceInformationToEditDTOByInvoiceGoID', this.invoiceID)
      await this.$store.dispatch('invoices/getInvoicePositionToEditDTOByInvoiceGoID', this.invoiceID)
      this.FillInForm()
    }
  },
  mounted() {
    this.$root.$refs.EditInvoice = this;
  },
  data() {
    const defaultForm = Object.freeze({
      invoiceInformationToEditDTO: {
        ID: undefined,
        RecipientName: undefined,
      }
    })
    return {
      defaultForm,
      billdate: 'standard',
      form: Object.assign({}, defaultForm),
      showtextareas: false,
      showpositions: false,
      dialog: false,
      newbillingDateForInvoices: new Date(),
      billingDate: new Date().toLocaleDateString(),
      date: undefined,
      newBillingDate: false,
      positionsIncr: 0,
      tmp: 0,
      countPositions: 0,
      showRechnungsposition: false,
      NewInvoicePosition: {},
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      moment.locale("de");
      return this.date ? moment.utc(this.date).format() : '';
    },
    invoicePositions() {
      return this.$store.getters['invoices/getinvoicePositionToEditDTO']
    },
    disableDownload() {
      if (this.approvalString == "genehmigt" || this.status == "Storno") {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    SetInvoiceNumber(val) {
      alert(val)
    },
    setNewStatus(status) {
      this.status = status;
    },
    addPosition() {
      this.NewInvoicePosition = {}
      this.invoicePositions.push(this.NewInvoicePosition);
    },
    ShowAddPosition(show) {
      if (this.approvalString == 'nicht genehmigt') {
        this.showRechnungsposition = show
      } else {
        this.showRechnungsposition = false
      }
    },
    countINVP(val) {
      this.countPositions + val
    },
    incrPositions() {
      this.countPositions++;
    },
    refreshPositions() {
      this.tmp++;
    },
    SetStandardBillingDate() {
      this.newBillingDate = false
      this.newbillingDateForInvoices = new Date().toLocaleDateString()
    },
    SetNewBillingDate() {
      this.newBillingDate = true
    },
    setNewDateForBills() {
      this.newbillingDateForInvoices = new Date(this.computedDateFormattedMomentjs);
    },
    ToggleTextAreas() {
      this.showtextareas = !this.showtextareas
      if (this.showpositions == true) {
        this.showpositions = false
      }
    },
    TogglePositions() {
      this.showpositions = !this.showpositions

      if (this.showtextareas == true) {
        this.showtextareas = false;
      }
      this.ShowAddPosition(this.showpositions)
    },
    FillInForm() {
      this.form.invoiceInformationToEditDTO = this.$store.getters['invoices/getinvoiceInformationToEditDTO']
    },
    async DownloadInvoice() {
      console.log(this.invoiceID)
      let fileinformation = await this.$store.dispatch('invoicePdf/downloadInvoicePdfByID', this.invoiceID)
      //this.overlay = false
      var FileSaver = require('file-saver');
      FileSaver.saveAs(fileinformation.fileblob, fileinformation.filename)
    },
    async ApproveOneInvoice() {
      this.dialog = false
      let ApproveOneInvoiceDTO = {
        InvoiceGoID: this.invoiceID,
        Created: this.newbillingDateForInvoices,
      }
      await this.$store.dispatch('invoices/approveOneInvoiceByInvoiceGoID', ApproveOneInvoiceDTO)

      this.$emit('RenderInvoiceList')
      this.approvalString = "nicht genehmigt"
      this.$emit('RenderEditInvoiceApprovalString', this.approvalString)
    },
    // async DiapproveOneInvoice(){
    //   let ApproveOneInvoiceDTO = {
    //     InvoiceGoID: this.invoiceID
    //   }
    //   await this.$store.dispatch('invoices/disapproveOneInvoiceByInvoiceGoID', ApproveOneInvoiceDTO)
    //   this.$emit('RenderInvoiceList')
    //   this.approvalString = "nicht genehmigt"
    //   this.$emit('RenderEditInvoiceApprovalString', this.approvalString)
    // }
  }
};
</script>
