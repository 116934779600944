<template>
    <v-dialog
        ref="dialog"
        v-model="modalformonthpicker"
        :return-value.sync="date"
        persistent
        width="290px"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="daterangetext"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="dates"
            range
            locale="de"
            scrollable
            :selected-items-text="daterangetext">
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="SetBack()"
            >
            Abbrechen
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="SaveDateRange()"
            >
            OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>
<script>
export default {
    props: {
        label: String
    },
    data() {
        return{
            modalformonthpicker:false,
            dates: [],
            date: new Date().toISOString().substr(0, 7),
        }
    },
    computed: {
        daterangetext() {
            let dateZero = this.ConvertDate(this.dates[0])
            let dateOne = this.ConvertDate(this.dates[1])
            if(dateOne == "nicht gewählt" && dateZero == "nicht gewählt"){
                return ""
            }
            let text = "von " + dateZero + " bis " + dateOne
            return text
        },
    },
    methods: {
        SaveDateRange(){
            this.$refs.dialog.save(this.date)
            this.modalformonthpicker = false
            this.$emit('SendDates', this.dates)
        },
        SetBack(){
            this.modalformonthpicker = false
        },
        ConvertDate(date){
            console.log(date)
            if(date == undefined){
                return "nicht gewählt"
            }
            let array = date.split('-')
            let year = array[0]
            let month = array[1]
            let day = array[2]
            let germandate = day + "." + month + "." + year
            return germandate
        }
    }
}
</script>