<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h1>Leistungen und Preise</h1>
      </v-col>
      <v-col cols="12">
        <template v-if="serviceArticleData">
          <template v-for="a in serviceArticleData">
            <ServiceArticle
              :price="a.Price"
              :id="a.ID"
              :article-name="a.ArticleName"
              :recurring="a.Recurring"
              :key="a.ID"
              :timed="a.Timed"
              :by-calendar-entry="a.ByCalendarEntry"
              :numberOfPieces="a.NumberOfPieces"
              :taxPercentage="a.TaxPercentage"
            ></ServiceArticle>
          </template>
        </template>
        <template v-else>
          <v-alert>Noch keine Leistungen definiert</v-alert>
        </template>
        <v-btn @click="NewServiceArticle">Neuer Artikel hinzufügen</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceArticle from "../../components/servicearticles/ServiceArticle";

export default {
  components: {
    ServiceArticle,
  },
  mounted() {
    this.reload();
    this.$root.$on("reload_servicearticle_data", () => {
      this.reload();
    });
    this.$root.$refs.start.setMenuActive(true);
  },
  computed: {
    isLoading() {
      return this.$store.state.serviceArticles.all.loading;
    },
    serviceArticleData() {
      //let d = this.$store.state.serviceArticles.all;
      let d = this.$store.getters['serviceArticles/getServiceArticles']
      let x = null;
      if (d.items != undefined) {
        x = d.items.filter((a) => a.ID > 1);
      }
      return x;
    },
  },
  methods: {
    async reload() {
      await this.$store.dispatch("serviceArticles/getAll");
    },
    NewServiceArticle() {
      let serviceArticle = {
        ArticleName: "",
        Price: 0.0,
      };

      console.log(serviceArticle);

      this.$store
        .dispatch("serviceArticles/newServiceArticle", serviceArticle)
        .then((response) => {
          console.log(response);
          this.reload();
        });
    },
  },
};
</script>
