<template>
  <base-card>
  <v-col cols="12">
    <v-row>
      <v-col cols="3">
        <v-text-field
            label="Name"
            v-model="ArticleName"
            hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
            label="Preis"
            v-model="Price"
            hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
            label="Prozentsatz der Umsatzsteuer"
            v-model="TaxPercentage"
            hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-checkbox v-model="ByCalendarEntry"
                    label="Abrechnung nach Dienstplan Einträgen">
        </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="Timed"
                    label="Abrechnung nach Stunden">
        </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="Recurring"
                    label="Monatliche Abbrechnung">
        </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="NumberOfPieces"
                    label="Abrechnung nach Stückzahl">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-btn
            color="primary"
            elevation="2"
            class="mt-3 mr-2"
            @click="Save"
        >
          <i class="fas fa-save"></i>
        </v-btn>

        <v-btn
            color="primary"
            elevation="2"
            class="mt-3"
            @click="Delete"
        >
          <i class="fas fa-trash"></i>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
  </base-card>
</template>

<script>

import BaseCard from '../../components/Base/BaseCard.vue'
export default {
  components: {
    BaseCard
  },
  data() {
    return {
      ID: this.id,
      ArticleName: this.articleName,
      Price: (this.price === undefined ? 0 : this.price) + " €",
      Recurring: this.recurring,
      Timed: this.timed,
      ByCalendarEntry: this.byCalendarEntry,
      NumberOfPieces: this.numberOfPieces,
      TaxPercentage: this.taxPercentage
    };
  },
  props: {
    id: Number,
    articleName: String,
    price: Number,
    recurring: Boolean,
    timed: Boolean,
    byCalendarEntry: Boolean,
    numberOfPieces: Boolean,
    taxPercentage: Number(0),
  },
  methods: {
    async Save() {
      let price = this.Price;

      try {
        if (price.includes(",")) {
          price = price.replace(",", ".");
        }
      } catch {
        console.log("Price is not a string")
      }


      let serviceArticle = {
        ID: parseInt(this.ID),
        ArticleName: this.ArticleName,
        Price: parseFloat(price),
        Recurring: this.Recurring,
        Timed: this.Timed,
        ByCalendarEntry: this.ByCalendarEntry,
        NumberOfPieces: this.NumberOfPieces,
        TaxPercentage: Number(this.TaxPercentage),
      };

      console.log(serviceArticle);

      this.$store
          .dispatch("serviceArticles/updateServiceArticle", serviceArticle)
          .then(() => {
            this.$root.$emit("reload_servicearticle_data");
          });
    },
    async Delete() {
      this.$store
          .dispatch("serviceArticles/deleteServiceArticle", this.ID)
          .then(() => {
            this.$root.$emit("reload_servicearticle_data");
          });
    },
  },
};
</script>
