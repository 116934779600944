<template>
    <v-card>
        <v-card-title>
            Medikation
        </v-card-title>
        <v-card-text>
            <div v-for="item in medications" :key="item.ID">
            <MedicationInputMask
                :patientID="patientID"
                :medicationitem="item"
                :key="medicationmaskkey"
            />
            </div>
            <v-btn
                color="primary"
                @click="ShowAddMedication"
            >
                Medikation Hinzufügen
            </v-btn>
            <MedicationInputMask
                v-if="showaddmedication"
                :medicationitem="medicationform"
                :patientID="patientID"
                @Render="RenderInputMask"
            />
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="CloseDialog()"
            >
            Schließen 
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import MedicationInputMask from './MedicationInputMask.vue'
export default {
    created(){
        console.log("medicatindialog")
        console.log("patienID: " + this.patientID)
        this.$store.dispatch('patientMedication/getMedicationByPatientID', this.patientID)
    },
    components:{
        MedicationInputMask
    },
    props:{
        patientID: Number
    },
    data(){
        const defaultForm = Object.freeze({
      medicationitem: {
        Medication: undefined,
        Dosis: undefined,
        AtMorning: undefined,
        AtMidday: undefined,
        AtEvening: undefined,
        PatientID: undefined

      }
    })
        return {
            defaultForm,
            medicationform: Object.assign({}, defaultForm),
            showaddmedication: false,
            medicationmaskkey: 0
        }
    },
    computed:{
        medications(){
            return this.$store.getters['patientMedication/getMedication']
        }
    },
    methods:{
        CloseDialog(){
            this.$store.commit('eventdialog/changeMediacationDialogtoFalse')
        },
        ShowAddMedication(){
            this.showaddmedication = !this.showaddmedication
        },
        async RenderInputMask(){
            console.log("rendfer")
            await this.$store.dispatch('patientMedication/getMedicationByPatientID', this.patientID)
            this.medicationmaskkey++
        }
    }
}
</script>