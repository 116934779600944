<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-text-field
            v-model="form.invoiceInformationToEditDTO.RecipientName"
            label="Empfänger"
            placeholder=""
            hide-details="auto"
            :readonly="readonly"
            rows="1"
            auto-grow
        >
        </v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
            label="Debitoren-Nr."
            placeholder=""
            hide-details="auto"
            :readonly="readonly"
            v-model="form.invoiceInformationToEditDTO.DebitorenNumber"
            rows="1"
            auto-grow
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
            v-model="form.invoiceInformationToEditDTO.RecipientStreet"
            label="Strasse"
            placeholder=""
            hide-details="auto"
            :readonly="readonly"
            rows="1"
            auto-grow
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            v-model="form.invoiceInformationToEditDTO.RecipientCity"
            label="PLZ und Ort"
            placeholder=""
            hide-details="auto"
            :readonly="readonly"
            rows="1"
            auto-grow
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            v-model="form.invoiceInformationToEditDTO.RecipientCountry"
            label="Land"
            placeholder=""
            hide-details="auto"
            :readonly="readonly"
            rows="1"
            auto-grow
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
            label="Hauptbetreff"
            v-model="Subject"
            rows="1"
            readonly
            disbled
            auto-grow
        >
        </v-textarea>
      </v-col>
      <v-col>
        <v-textarea
            label="Nebenbetreff"
            v-model="form.invoiceInformationToEditDTO.SubjectBeside"
            rows="1"
            :readonly="readonly"
            auto-grow>
        </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
            label="Eingangtext"
            v-model="form.invoiceInformationToEditDTO.BegintextText"
            :readonly="readonly"
            rows="1"
            auto-grow
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
            label="Zahlungsbedingungen"
            v-model="form.invoiceInformationToEditDTO.EndtextTermsOfPayment"
            :readonly="readonly"
            rows="1"
            auto-grow
        >
        </v-textarea>
      </v-col>
      <v-col>
        <v-textarea
            label="Ausgangstext"
            v-model="form.invoiceInformationToEditDTO.EndtextText"
            :readonly="readonly"
            rows="1"
            auto-grow
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="SaveInvoiceData()"
        :disabled="readonly"
      >
        Änderungen Speichern
      </v-btn>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    form: {},
    invoiceNumber: Number,
    invoiceGoID: Number,
    readonly: Boolean
  },
  mounted() {
    this.$root.$refs.EditInvoiceTextAreas = this;
  },
  computed: {
    Subject() {
      return "Rechnung Nr.: " + this.invoiceNumber
    }
  },
  methods:{
    async SaveInvoiceData(){
      console.log(this.invoiceGoID)
      this.form.InvoiceGoID = this.invoiceGoID
      try{
        let response = await this.$store.dispatch('invoices/changeInvoiceInformationsByInvoiceID', this.form.invoiceInformationToEditDTO)
        console.log(response)
      } catch(error){
        alert(error)
      }
    }
  }
}
</script>