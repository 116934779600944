var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Rechnungsnummer","hide-details":"auto","disabled":""},model:{value:(_vm.Invoice.InvoiceNr),callback:function ($$v) {_vm.$set(_vm.Invoice, "InvoiceNr", $$v)},expression:"Invoice.InvoiceNr"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Unternehmen / Versicherung","hide-details":"auto","disabled":""},model:{value:(_vm.Invoice.ReceiptCompany),callback:function ($$v) {_vm.$set(_vm.Invoice, "ReceiptCompany", $$v)},expression:"Invoice.ReceiptCompany"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Rechnungsempfänger Vorname","hide-details":"auto","disabled":""},model:{value:(_vm.Invoice.ReceiptPersonFirstName),callback:function ($$v) {_vm.$set(_vm.Invoice, "ReceiptPersonFirstName", $$v)},expression:"Invoice.ReceiptPersonFirstName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Rechnungsempfänger Nachname","hide-details":"auto","disabled":""},model:{value:(_vm.Invoice.ReceiptPersonLastName),callback:function ($$v) {_vm.$set(_vm.Invoice, "ReceiptPersonLastName", $$v)},expression:"Invoice.ReceiptPersonLastName"}})],1),_c('v-col',{staticStyle:{"margin-top":"-20px"},attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mt-3 mr-2",attrs:{"color":"primary","elevation":"2","disabled":!_vm.Invoice.Generated},on:{"click":function($event){return _vm.downloadFile(_vm.Invoice.ID)}}},[_c('i',{staticClass:"fas fa-search-plus"})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 mr-2",attrs:{"color":"accent","elevation":"2","disabled":!_vm.Invoice.Generated || _vm.Invoice.Accepted},on:{"click":function($event){return _vm.Edit(_vm.Invoice.ID)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-pen"})])]}}])},[_c('span',[_vm._v("Rechnungsinfos anpassen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 mr-2",attrs:{"color":"accent","elevation":"2","disabled":!_vm.Invoice.Generated || _vm.Invoice.Accepted},on:{"click":function($event){return _vm.Recreate(_vm.Invoice.ID)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-redo"})])]}}])},[_c('span',[_vm._v("Neu erstellen")])]),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 mr-2",attrs:{"color":"info","elevation":"2","disabled":!_vm.Invoice.Generated || _vm.Invoice.Accepted},on:{"click":function($event){return _vm.Accept(_vm.Invoice.ID)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-check"})])]}}])},[_c('span',[_vm._v("Freigeben")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 mr-2",attrs:{"color":"accent","elevation":"2","disabled":!_vm.Invoice.Generated || !_vm.Invoice.Accepted || _vm.Invoice.Sended},on:{"click":function($event){return _vm.Send(_vm.Invoice.ID)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-envelope"})])]}}])},[_c('span',[_vm._v("Versenden")])]),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","elevation":"2","disabled":_vm.Invoice.Accepted || _vm.Invoice.Sended},on:{"click":function($event){return _vm.Delete(_vm.Invoice.ID)}}},[_c('i',{staticClass:"fas fa-trash"})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }