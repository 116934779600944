<template>
  <v-container fluid>
    <h4 class="mb-4">Dokumente</h4>
    <v-row v-if="showfiles">
      <KlientFileTable
          :patientid="patientid"
          :patientfiles="patientfiles"
          @renderdocs="Renderfiletable"/>
    </v-row>
    <div v-else> Keine Dokumente hinterlegt</div>
  </v-container>
</template>

<script>
import KlientFileTable from "../../components/patients/KlientFileTable";

export default {
  name: "PatientDocumentLayout",
  data() {
    return {
      showfiles: true,
    }
  },
  props: {
    filetablekey: Number,
    patientid: Number,

  },
  components: {
    KlientFileTable
  },
  methods: {
    Renderfiletable() {
      this.$emit("Renderfiletable")
    },
    showFiles() {
      this.showfiles = !this.showfiles
    },
  },
  created() {
    if (this.patientid !== undefined) {
      this.$store.dispatch("patients/getFiles", this.patientid);
    }
  },
  computed: {
    tablekeys() {
      return this.tmp;
    },
    patientfiles() {
      return this.$store.state.patients.files.items;
    },
  },
  mounted() {
    this.$root.$refs.PatientDocumentLayout = this
  }
}
</script>

<style scoped>

</style>