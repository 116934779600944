import {
    authHeader
} from '../helpers';

export const invoiceRunsService = {
    getAll,
    getInvoiceRun,
    create,
    deleteById
};

function getAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/runs`, requestOptions).then(handleResponse);
}

function getInvoiceRun(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/run/` + id, requestOptions).then(handleResponse);
}

function create(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/run`, requestOptions).then(handleResponse);
}

function deleteById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    console.log(id);
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/run/delete/id/` + id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}