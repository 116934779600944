<template>
    <v-container fluid>
        <v-row>
            <v-data-table
                :items="devices"
                :headers="headers"
                :search="search"
                no-data-text="keine Daten vorhanden"
                no-results-text="keine Suchergebnisse"
                single-select
                item-key="ID"
                isSelected="true"
                :items-per-page="5"
                @click:row="ClickRow"
                :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',
                }"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="Suchen"
                        class="mx-4"
                    >
                    </v-text-field>
                </template>

              <template v-slot:item.Active="{ item }">
                <v-simple-checkbox
                    v-model="item.Active"
                    disabled
                ></v-simple-checkbox>
              </template>


              <template v-slot:Active>
                <v-text-field
                    label="Suchen"
                    class="mx-4"
                >
                </v-text-field>
              </template>

            </v-data-table>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props:{
        devices: {},
    },
    data(){
        return{
            search: '',
            headers:[
                {text: 'Inventarnummer', value: 'Inventorynumber'},
                {text: 'Aktiv', value: 'Active'},
                {text: 'Beschreibung', value: 'Description'},
            ],
        }
    },
    methods:{
        ClickRow(item, row){
            row.select(true)
            console.log(item)
            this.$emit("sendDeviceID", item.ID)
        }
    }
}
</script>