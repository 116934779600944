import {authHeader} from '../helpers';

export const roleService = {
    GetAll,
    GetUserRole,
    Create,
    GetByID,
    DeleteByID,
    Change
};

function GetAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/usermanagement/roles`, requestOptions).then(handleResponse);
}

function GetUserRole(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/usermanagement/userrole/`+ data, requestOptions).then(handleResponse);
}

function GetByID(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/usermanagement/roles/` + id, requestOptions).then(handleResponse);
}

function Create(data) {
    // alert("serviceJS: " + JSON.stringify(data));
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/usermanagement/roles`, requestOptions).then(handleResponse);
}

function Change(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/usermanagement/change/` + data.ID, requestOptions).then(handleResponse);
}

function DeleteByID(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/usermanagement/delete/` + id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}