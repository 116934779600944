export const button = {
    namespaced: true,
    state: {
        deletebuttonmodaldialog: false
    },
    getters: {
        getdeletebuttonmodaldialog: state =>{
            return state.deletebuttonmodaldialog
        }
    },
    mutations: {
        changeDeleteButtonModalDialogToTrue(state){
            state.deletebuttonmodaldialog = true
        },
        changeDeleteButtonModalDialogToFalse(state){
            state.deletebuttonmodaldialog = false
        }
    }
}