<template>
  <v-container fluid>
    <v-alert
        v-model="alert"
        dismissible
        type="error"
        color="red">
      {{ alertmessage }}
    </v-alert>
    <template>
      <row :gutter="12">
        <column :lg="4" :md="12">
          <h2>
            <v-icon large style="color: #006ab5 !important;">mdi-calendar</v-icon>
            Dienstplan
          </h2>
          <h3 v-if="$cookies.get('role') !== 'Admin' && $cookies.get('role') !== 'Regular'"><i
              class="fas fa-users ml-4"></i>Mitarbeiter
            <v-btn class="ml-4" @click="ShowHideSidebar" small>Tabelle ein/aus</v-btn>
          </h3>
          <h3 v-else><i class="fas fa-users ml-4"></i>Alle Mitarbeiter
            <v-btn class="ml-4" @click="ShowHideSidebar" small>Tabelle ein/aus</v-btn>
          </h3>
          <EmployeeTable
              v-show="sidebar"
              :employees="employees"
              :key="employeetablekey"
              @SendEmployeeID="ShowEventsByEmployeeID">
          </EmployeeTable>
          <v-btn v-show="sidebar" v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular' || $cookies.get('role') === 'Limited'"
                 color="primary"
                 class="ml-4 mb-2"
                 @click="ShowEventModalDialog()">
            <i class="fas fa-plus"></i>
            Termin hinzufügen
          </v-btn>
          <v-btn v-show="sidebar"
                 v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular' "
                 color="primary"
                 class="ml-4 mb-2"
                 @click="ShowAllEvents()">
            Alle Termine anzeigen
          </v-btn>
          <v-btn v-show="sidebar"
                 color="primary"
                 class="ml-4 mb-2"
                 @click="Toggle24Hours()">
            24 Stunden an/aus
          </v-btn>
          <v-btn v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'"
                 v-show="sidebar"
                 color="primary"
                 class="ml-4 mb-2"
                 @click="ShowCopyCalendarDialog()">
            Dienstplan kopieren
          </v-btn>
        </column>
        <column :lg="8">
          <h4 v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'">Urlaub/Krankheit</h4>
          <EmployeeHolidays v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'"
                            :employees="employees"
                            :key="employeeholidaykey"
                            v-on:SetAlertMessage="HandleAlert"
                            @RenderCalendar="RenderCalendar()"
                            :disabled="disabled">
          </EmployeeHolidays>
          <EmployeeCalendar v-if="sidebar"
                            style="margin-top: -20px;"
                            v-on:SetAlertMessage="HandleAlert"
                            :type="type"
                            :initvalue="value"
                            @SendTypeAndValue="GetTypeAndValue"
                            :key="employeecalendarkey"
                            @RefreshCalendar="ShowAllEvents"
                            :dragorshowevent="dragorshowevent"
                            :employeeID="employeeID"
                            :daylength="daylength"
                            :firstinterval="firstinterval"
                            @DisableButtonHolidays="DisableButtonHolidays"
                            @sendDateToParent="ReceiveDateFromChild">
          </EmployeeCalendar>
        </column>
        <column>
          <EmployeeCalendar v-if="!sidebar"
                            style="margin-top: -20px;"
                            v-on:SetAlertMessage="HandleAlert"
                            :initetype="type"
                            :value="value"
                            @SendTypeAndValue="GetTypeAndValue"
                            :key="employeecalendarkey"
                            @RefreshCalendar="ShowAllEvents"
                            :dragorshowevent="dragorshowevent"
                            :employeeID="employeeID"
                            :daylength="daylength"
                            :firstinterval="firstinterval"
                            @DisableButtonHolidays="DisableButtonHolidays"
                            @sendDateToParent="ReceiveDateFromChild"
          >
          </EmployeeCalendar>
        </column>
      </row>
    </template>

    <v-dialog
        v-model="employeeCreateNewEventDialog"
        persistent max-width="1100px">
      <EmployeeCreateNewEventDialog
          :key="employeecreateneweventdialogkey"
          :date="value"
          @RefreshCalendar="ShowAllEvents"
          @SendDate="GetDate"/>
    </v-dialog>
    <v-dialog
        v-model="showCopyCalendarDialog"
        persistent max-width="550px">
      <CopyCalendarDialog
          :key="copycalendardialogkey"
          :datecopyfrom="datecopyfrom"
          :datecopyto="datecopyfrom"
          @RefreshCalendar="ShowAllEvents"/>
    </v-dialog>
  </v-container>
</template>

<script>
import EmployeeHolidays from '../../components/employees/roster/EmployeeHolidays.vue'
import EmployeeTable from '../../components/employees/roster/EmployeeTable.vue'
import EmployeeCalendar from '../../components/employees/roster/EmployeeCalendar.vue'
import EmployeeCreateNewEventDialog from '../../components/dialogs/EmployeeCreateNewEventDialog.vue'
import CopyCalendarDialog from '../../components/dialogs/CopyCalendarDialog.vue'

export default {
  components: {
    EmployeeTable,
    EmployeeCalendar,
    EmployeeHolidays,
    EmployeeCreateNewEventDialog,
    CopyCalendarDialog
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  data() {
    return {
      singleID: 0,
      role: "",
      currentID: 0,
      employeecalendarkey: 0,
      employeetablekey: 0,
      employeeholidaykey: 0,
      alert: false,
      alertmessage: "",
      sidebar: true,
      employeecreateneweventdialogkey: 0,
      type: 'month',
      value: new Date().toISOString().substr(0, 10),
      dragorshowevent: 'drag',
      employeeID: 0,
      daylength: 15,
      firstinterval: 5,
      disabled: false,
      datecopyfrom: new Date().toISOString().substr(0, 10),
      copycalendardialogkey: 0,
    }
  },
  computed: {
    employees() {
      return this.$store.state.employees.employeestable.items;
    },
    employeeCreateNewEventDialog() {
      return this.$store.getters['eventdialog/getEmployeeCreateNewEventDialog']
    },
    showCopyCalendarDialog() {
      return this.$store.getters['eventdialog/getCopyCalendarDialog']
    }
  },
  methods: {
    ReceiveDateFromChild(date) {
      this.datecopyfrom = date
      this.copycalendardialogkey++
    },
    ShowCopyCalendarDialog() {
      console.log("ShowCopyCalendarDialog")
      this.$store.commit('eventdialog/changeCopyCalendarDialogToTrue')
    },
    DisableButtonHolidays() {
      console.log("DisableButtonHolidays")
      this.disabled = true
    },
    HandleAlert(errormessage) {
      this.alertmessage = errormessage
      this.alert = true
    },
    routeTo(url) {
      this.$router.push(url);
    },
    RenderCalendar() {
      this.employeecalendarkey++
    }
    ,
    ShowHideSidebar() {
      this.sidebar = !this.sidebar;
    },
    async ShowEventModalDialog() {
      console.log("ShowEventModalDialog")
      await this.$store.commit("eventdialog/changeEmployeeCreateNewEventDialogToTrue")
      this.RenderEmployeeCreateNewEventDialog()
    },
    RenderEmployeeCreateNewEventDialog() {
      console.log("RenderEmployeeCreateNewEventDialog")
      this.employeecreateneweventdialogkey++
    },
    GetTypeAndValue(sendobject) {
      this.type = sendobject.Type
      this.value = sendobject.Value
      this.dragorshowevent = sendobject.DragOrShowEvent
      console.log("value: " + sendobject.Value)
    },
    GetDate(date) {
      this.value = date
    },
    ShowEventsByEmployeeID(employeeID) {
      this.employeeID = employeeID;
      this.RenderCalendar();
    },
    ShowAllEvents() {
      this.employeeID = 0
      this.$store.commit('employees/emptyEventsByEmployeeID')
      this.RenderCalendar()
      this.RenderEmployeeTable()
      this.$root.$refs.rosterEmployeeTable.setAllChipsToActive()
    },
    RenderEmployeeTable() {
      this.employeetablekey++
    },
    Toggle24Hours() {
      if (this.daylength == 15 && this.firstinterval == 5) {
        this.daylength = 24
        this.firstinterval = 0
      } else {
        this.daylength = 15
        this.firstinterval = 5
      }
    }
  },
  updated() {
  },
  created() {
    this.$store.dispatch('employees/getAllEmployeeTables');
    this.$store.dispatch('patients/getPatientsForTable');
  },
};
</script>
