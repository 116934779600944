import {
    invoiceNumberSettingService
} from '../services';

export const invoicenumbersetting = {
    namespaced: true,
    state: {
        current: {}
    },
    actions: {
        get({
            commit
        }) {
            commit('getRequest');
            return new Promise((resolve, reject) => {
                invoiceNumberSettingService.get()
                    .then(
                        data => {
                            commit('getSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        update({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                invoiceNumberSettingService.update(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },
    },
    mutations: {
        getRequest(state) {
            state.current = {
                loading: true
            };
        },
        getSuccess(state, data) {
            state.current = {
                item: data
            };
        },
        getFailure(state, error) {
            state.current = {
                error
            };
        },


        updateRequest(state) {
            state.current = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.current = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },

    }
}