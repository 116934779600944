<template>
  <v-container fluid>
    <h3 class="mb-4">Bankdaten und Abrechung</h3>
    <v-row>
      <v-col>
        <v-select
            label="Anstellungsart"
            v-model="data.EmploymentType"
            :items="employementtypes"
            dense
            required
            ref="EmploymentType"
            :disabled="disableinput"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
            label="Stunden pro Woche"
            v-model="data.HoursPerWeek"
            dense
            prepend-icon="mdi-calendar-clock"
            required
            ref="HoursPerWeek"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-select
            label="Firmenwagen"
            :items="companycaritems"
            item-text="text"
            item-value="value"
            prepend-icon="mdi-car"
            dense
            v-model="data.CompanyCar"
            ref="CompanyCar"
            :disabled="disableinput"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            label="Bruttogehalt pro Monat"
            v-model="data.SalaryMonth"
            dense
            prepend-icon="mdi-currency-eur"
            required
            ref="SalaryMonth"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Bruttogehalt pro Jahr"
            v-model="data.SalaryYear"
            dense
            required
            prepend-icon="mdi-currency-eur"
            ref="SalaryYear"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
            label="Familienstand"
            :items="dropdown.maritalstatuses"
            item-text="MaritalStatus"
            item-value="ID"
            dense
            v-model="data.MaritalStatusID"
            ref="MaritalStatusID"
            :disabled="disableinput"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
            label="Anzahl Kinder"
            v-model="data.NumberKids"
            dense
            required
            ref="NumberKids"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
            label="Steuerklasse"
            v-model="data.TaxClass"
            :items="taxes"
            placeholder="Steuerklasse"
            dense
            required
            ref="TaxClass"
            :disabled="disableinput"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-select
            label="Kirchensteuer"
            :items="churchtaxitems"
            item-text="text"
            item-value="value"
            dense
            v-model="data.ChurchTax"
            ref="ChurchTax"
            :disabled="disableinput"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            label="IBAN für Gehalt"
            v-model="data.IBAN"
            dense
            required
            ref="IBAN"
            :disabled="disableinput"
            placeholder="DE07 1234 1234 1234 1234 12"
            :rules="ibanRules"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Bonus"
            v-model="data.Bonus"
            dense
            required
            ref="Bonus"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    data: {},
    dropdown: {},
    disableinput: Boolean
  },
  data() {
    return {
      companycaritems: [{text: 'ja', value: true}, {text: 'nein', value: false}],
      churchtaxitems: [{text: 'ja', value: true}, {text: 'nein', value: false}],
      ibanRules: [
        (value) => (this.validateIBAN(value)) || "Keine gültige IBAN",
      ],
      taxes: ["1", "2", "3", "4",
        "5", "6",],
      employementtypes: ["Vollzeit", "Geringfügig", "beschäftigt", "Freiwillig",
        "Teilzeit", "Werkstudent", "Ausbildung", "Kurzfristige Beschäftigung", "Praktikum"],
    }
  },
  methods: {
    validateIBAN(value) {
      if (value == "" || value == undefined) {
        return true
      }
      var reg = /^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/;
      if (!value.match(reg)) {
        console.log("No valid IBAN");
        return false;
      }
      console.log("valid");
      return true;
    },
  },
}
</script>


<style scoped>

.error--text {
  color: red !important;
  font-size: 22px;
  text-transform: uppercase !important;
  font-weight: bold;
  font-style: italic;
}

</style>