var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-container',{attrs:{"fluid":""}},[_c('h2',[_c('v-icon',{staticStyle:{"color":"#006ab5 !important"},attrs:{"large":""}},[_vm._v("mdi-card-account-details-outline")]),_vm._v(" Rechnungen ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('base-card',[_c('InvoiceList',{key:_vm.invoicelistkey,attrs:{"invoiceRunID":_vm.invoiceRunID},on:{"SendInvoiceID":_vm.GetInvoiceID,"SendApproval":_vm.GetApprovalString,"SendStatus":_vm.GetStatus,"SendReferenz":_vm.GetReferenz,"SendInvoiceNumber":_vm.GetInvoiceNumber}})],1),_c('v-col',[[_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary"},'v-btn',attrs,false),on),[_vm._v(" Rechnungslauf genehmigen ")]),_c('v-col',{attrs:{"cols":"5"}},[((_vm.approvalString == 'genehmigt' && _vm.referenz == ''))?_c('v-btn',{staticStyle:{"margin-top":"-12px"},attrs:{"color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.storniereRE(_vm.invoicenumber)}}},[_vm._v("Stornieren R.-Nr.: "),_c('p',{staticStyle:{"margin-top":"16px","color":"yellow"}},[_vm._v(_vm._s(_vm.invoicenumber)+" ")])]):_vm._e(),_c('v-card-text',{staticClass:"text_center sub_btn"},[(_vm.status === 'Storno')?_c('v-menu',_vm._g(_vm._b({staticClass:"settingsBtn",attrs:{"color":"primary","light":"","nudge-width":_vm.auto,"offset-x":""},on:{"click":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn_style v-btn",staticStyle:{"margin-top":"-42px","margin-left":"-15px"},attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}],null,true)},'v-menu',attrs,false),on),[_c('v-card',[_c('v-list',{attrs:{"dense":""}}),_c('v-list',{attrs:{"dense":"","dark":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":_vm.DownloadStorno}},[_vm._v(" Storno R.-Nr. "),_c('p',{staticClass:"vs",staticStyle:{"margin-top":"16px","color":"yellow"}},[_vm._v(" "+_vm._s(_vm.invoicenumber)+" ")]),_vm._v(" Download ")]),_c('v-list-item',{attrs:{"dense":""},on:{"click":_vm.createNewInvoiceFromStorno}},[_vm._v(" Kopieren ")])],1)],1)],1):_vm._e()],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('h3',[_vm._v("Rechnungsdatum")])]),_c('v-card-text',[_c('v-radio-group',{staticStyle:{"display":"inline-block"},attrs:{"column":""},model:{value:(_vm.billdate),callback:function ($$v) {_vm.billdate=$$v},expression:"billdate"}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Standard:","color":"primary","value":"standard","checked":"checked"},on:{"click":_vm.SetStandardBillingDate}})],1),_c('v-col',[_c('p',[_vm._v(_vm._s(_vm.billingDate))])])],1),_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Neues Rechnungsdatum","color":"primary","value":"newDate"},on:{"click":_vm.SetNewBillingDate}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"return-value":_vm.newbillingDateForInvoices,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":!_vm.newBillingDate,"value":_vm.computedDateFormattedMomentjs ?
                                _vm.computedDateFormattedMomentjs.substr(8,2)+ '.'
                                + _vm.computedDateFormattedMomentjs.substr(5,2)+'.'
                                + _vm.computedDateFormattedMomentjs.substr(0,4): '',"label":"Tag wählen","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"de"},on:{"change":_vm.setNewDateForBills},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.date && _vm.newBillingDate},on:{"click":function($event){return _vm.ApproveInvoiceRun()}}},[_vm._v(" Genehmigen ")])],1)],1)],1)],1)],1)]],2)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('EditInvoice',{key:_vm.editinvoicekey,attrs:{"newPos":_vm.newpos,"invoiceID":_vm.invoiceID,"downloadinvoice":_vm.downloadinvoice,"disablebutton":_vm.disablebutton,"approvalString":_vm.approvalString,"status":_vm.status,"invoicenumber":_vm.invoicenumber},on:{"RenderInvoiceList":_vm.RenderInvoiceList,"RenderEditInvoiceApprovalString":_vm.RenderEditInvoiceApprovalString}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }