import Vue from "vue";
import Vuex from "vuex";

import {alert} from "./alert.module";
import {authentication} from "./authentication.module";
import {users} from "./users.module";
import {patients} from "./patients.module";
import {employees} from "./employees.module";
import {insurances} from "./insurances.module";
import {cars} from "./cars.module";
import {fuels} from "./fuels.module";
import {automotiveManufacturers} from "./automotiveManufacturers.module";
import {deviceAppointments} from "./deviceAppointments.module";
import {inventory} from "./inventory.module";
import {serviceArticles} from "./serviceArticles.module";
import {patientServiceArticles} from "./patientServiceArticles.module";
import {button} from "./button.module";
import {eventdialog} from "./eventdialog.module";
import {senderInfo} from "./senderinfo.module";
import {invoiceRuns} from "./invoiceRuns.module";
import {invoicenumbersetting} from "./invoicenumbersetting.module";
import {invoicedebitorsetting} from "./invoicedebitorsetting.module";
import {invoices} from "./invoices.module";
import {templates} from "./templates.module";
import {templatecategories} from "./templateCategories.module";
import {invoiceRunCategories} from "./invoiceRunCategories.module";
import {invoicePositions} from "./invoicePositions.module";
import {note} from "./note.module";
import {loading} from "./loading.module"
import {invoicePdf} from "./invoicePdf.module"
import {patientoverview} from "./patientOverview.module"
import {patientMedication} from "./patientmedication.module"
import {file} from "./file.module"
import {invoicePatient} from "./invoicePatient.module"
import {placeforklientkeys} from "./placeforklientkeys.module";
import {invoicekostentraegerdebitorsetting} from "./invoicekostentraegerdebitorsetting.module";
import {settings} from "./settings.module"
import {roles} from "./roles.module"

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
        users,
        patients,
        employees,
        insurances,
        senderInfo,
        cars,
        fuels,
        automotiveManufacturers,
        deviceAppointments,
        inventory,
        serviceArticles,
        patientServiceArticles,
        button,
        eventdialog,
        invoiceRuns,
        invoicenumbersetting,
        invoicedebitorsetting,
        invoicekostentraegerdebitorsetting,
        invoices,
        templates,
        templatecategories,
        invoiceRunCategories,
        invoicePositions,
        note,
        loading,
        invoicePdf,
        patientoverview,
        patientMedication,
        file,
        invoicePatient,
        placeforklientkeys,
        settings,
        roles,
    },
});
