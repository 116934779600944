import {
    authHeader
} from '../helpers';

export const inventoryService = {
    getAllInventoryDevicesTable,
    getInventoryDevicesForFormByID,
    createInventoryDevicesForForm,
    changeInventoryDeviceForFormByID,
    deleteInventoryDeviceByID,
    getAllInventorDevicesByPatientID,
    setInventoryDeviceOnPatient,
    deselectDeviceFromPatient
};

async function getAllInventoryDevicesTable(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/get/table`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getInventoryDevicesForFormByID(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/get/form/` + id, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function createInventoryDevicesForForm(inventorydevicesforform){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(inventorydevicesforform)
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/create/form`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function changeInventoryDeviceForFormByID(inventorydevicesforform, deviceid){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(inventorydevicesforform)
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/change/form/` + deviceid, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function deleteInventoryDeviceByID(deviceid){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/delete/` + deviceid, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllInventorDevicesByPatientID(patientid){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    console.log("patientid: " + patientid)
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/get/dialog/` + patientid, requestOptions)
    let data = await handleResponse(response)
    console.log(data)
    return data
}

async function setInventoryDeviceOnPatient(deviceid, patientid){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/change/dialog/` + deviceid + `/` + patientid, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function deselectDeviceFromPatient(deviceid){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/inventory/devices/change/deselect/` + deviceid, requestOptions)
    let data = await handleResponse(response)
    return data
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log(data)
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }
        return data;
    });
}