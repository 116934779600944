<template>
  <v-card>
    <v-card-title>
      <h3>Neuen Rechnungslauf anlegen</h3>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
              v-model="invoicerunname"
              label="Name des Rechnungslaufes"
              placeholder=""
              hide-details="auto"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-dialog
              ref="dialog"
              v-model="modalformonthpicker"
              :return-value.sync="date"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="daterangetext"
                  label="Monat des Rechungslaufes"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dates"
                range
                locale="de"
                scrollable
                :selected-items-text="daterangetext">
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="modalformonthpicker = false"
              >
                Abbrechen
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="SaveDateRange(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-select
              v-model="invoicemode"
              label="Rechungsart"
              :items="invoicemodes"
              placeholder="Pflegegrad"
              hide-details="auto"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
          class="mb-4 mr-2"
          color="info"
          @click="CreateInvoiceRun()"
      >
        Rechungslauf erzeugen
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          class="mb-4 mr-2"
          color="info"
          @click="CloseDialog()"
      >
        Schließen
      </v-btn>
    </v-card-actions>
  </v-card>

</template>
<script>
import {getUserFromJWT} from '../../helpers'

export default {
  data() {
    return {
      invoicerunname: undefined,
      modalformonthpicker: false,
      date: new Date().toISOString().substr(0, 7),
      monthname: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      dates: [],
      invoicemodes: ["AUS", "HN", "Private", "AUS wiederkehrend", "HN wiederkehrend", "Privat wiederkehrend"],
      invoicemode: undefined,
      timestampZero: undefined,
      timestampOne: undefined,
      timestampBeginPeriod: undefined,
      timestampEndPeriod: undefined
    }
  },
  computed: {
    daterangetext() {
      return this.dates.join(' - ')
    },
  },
  methods: {
    CloseDialog() {
      this.$store.commit('eventdialog/changeInvoiceRunDialogtoFalse')
    },
    async CreateInvoiceRun() {
      let timestampStart = new Date().getTime()
      let employeeID = getUserFromJWT().employeeID
      let CreateInvoiceRunDTO = {
        Name: this.invoicerunname,
        TimestampStart: timestampStart,
        TimestampBeginPeriod: this.timestampBeginPeriod,
        TimestampEndPeriod: this.timestampEndPeriod,
        EmployeeID: employeeID,
        InvoiceMode: this.invoicemode
      }
      console.log(JSON.stringify(CreateInvoiceRunDTO))
      await this.$store.dispatch('invoices/createInvoiceRun', CreateInvoiceRunDTO)
      this.$emit('RenderInvoiceRunList')
    },
    SaveDateRange(date) {
      this.$refs.dialog.save(date)
      this.SetTimestamps()
      this.SetTimestampStartAndEnd()
    },
    SetTimestamps() {
      console.log(this.dates)
      this.timestampZero = new Date(this.dates[0]).getTime()
      this.timestampOne = new Date(this.dates[1]).getTime()
    },
    SetTimestampStartAndEnd() {
      console.log("timestampZero: " + this.timestampZero)
      console.log("timestampOne: " + this.timestampOne)
      if (this.timestampZero <= this.timestampOne) {
        this.timestampBeginPeriod = this.timestampZero
        this.timestampEndPeriod = this.SetTimestampToEndofDay(this.timestampOne)
      } else {
        this.timestampBeginPeriod = this.timestampOne
        this.timestampEndPeriod = this.SetTimestampToEndofDay(this.timestampZero)
      }
    },
    SetTimestampToEndofDay(timestamp){
      let date = new Date(timestamp)
      let dateEndOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
      let timestampEndOfDay = dateEndOfDay.getTime()
      return timestampEndOfDay
    }
  }
}
</script>