<template>
  <main>
    <div class="denscol">
      <v-container fluid>
        <h3 class="mb-1">Hausnotruf</h3>
        <v-card class="mb-2" rounded style="background-color: snow ">
          <InvoiceKasseHeader
            ref="invoiceKasseHeaderHN"
            :patientID="patientID"
            :HN="true"
          ></InvoiceKasseHeader>
        </v-card>

        <v-card rounded style="background-color: snow ">
          <InvoicingTemplate
            ref="invoicingTemplateHN"
            :patientID="patientID"
            :HN="true"
          ></InvoicingTemplate>
        </v-card>

        <v-card class="mt-2" rounded style="background-color: snow ">
          <InvoiceAssignedBenefits
            :patientID="patientID"
            :HN="true"
          ></InvoiceAssignedBenefits>
        </v-card>

      </v-container>
    </div>
  </main>
</template>

<script>
import InvoicingTemplate from "../../components/invoices/InvoicingTemplate"
import InvoiceAssignedBenefits from "../../components/invoices/InvoiceAssignedBenefits"
import InvoiceKasseHeader from "../../components/invoices/InvoiceKasseHeader"

export default {
  components: {
    InvoicingTemplate,
    InvoiceAssignedBenefits,
    InvoiceKasseHeader
  },
  props:{
    patientID: Number
  },
  name: "InvoiceKasse"
}
</script>

<style scoped>

.centered-input input {
  text-align: right;
  font-size: 1.10em;
}

</style>