import { invoicePdfService } from '../services';

export const invoicePdf = {
    namespaced: true,
    state: {
        error: {},
    },
    actions:{
        getInvoicePdf({commit}, invoicePdfFileInfoDTO){
            return new Promise((resolve, reject) => {
                invoicePdfService.getInvoicePdf(invoicePdfFileInfoDTO).then(
                    response => {
                        resolve(response)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        downloadInvoicePdfByID({commit}, invoiceGoID){
            return new Promise((resolve, reject) => {
                invoicePdfService.downloadInvoicePdfByID(invoiceGoID).then(
                    response => {
                        resolve(response)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
    },
    mutations: {
        getFailure(state, error){
            state.error = {item: error}
        },
    }
}