<template>
  <div>
    <base-card>
    <template v-if="id == 0">
      <h3>Neues Auto anmelden</h3>
    </template>
    <template v-else>
      <h3> <v-icon  style="color: #006ab5 !important;">mdi-car</v-icon>
        Fahrzeug: {{ car.item.KfzNr }} -
        {{ car.item.AutomotiveManufacturer.Name }} {{ car.item.ModelName }}
      </h3>
    </template>

    <v-row>
      <v-col cols="8"></v-col>
      <v-col cols="2">
        <v-checkbox v-model="car.item.Available"
        label="Ist Verfügbar">

        </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="car.item.Operative"
        label="Ist Einsatzbereit">
        </v-checkbox>
      </v-col>
    </v-row>

    <v-col cols="12">
      <h4>Daten zum Auto</h4>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Nummer"
            hide-details="auto"
            tabindex="1"
            v-model="car.item.ID"
            disabled
          ></v-text-field>
        </v-col>
       <v-col cols="6">
          <v-text-field
            label="Fahrgestellnummer"
            hide-details="auto"
            tabindex="1"
            v-model="car.item.ChassisNumber"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Kennzeichen *"
            :rules="kfzRules"
            hide-details="auto"
            tabindex="1"
            v-model="car.item.KfzNr"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-select
            v-model="car.item.AutomotiveManufacturer"
            :items="manufacturers"
            item-text="Name"
            item-value="ID"
            tabindex="1"
            placeholder="Hersteller *"
            @change="HandleManufacturerChanged(car.item.AutomotiveManufacturer)"
          >
          </v-select>
        </v-col>
    
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-dialog
            ref="datedialog"
            v-model="datemodal"
            :return-value.sync="datehuau"
            persistent
            tabindex="4"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datehuau"
                label="HU / AU *"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="datehuau" scrollable locale="de-de">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datemodal = false">
                Abbrechen
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.datedialog.save(datehuau)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="car.item.Fuel"
            :items="fuels"
            item-text="Name"
            item-value="ID"
            tabindex="1"
            placeholder="Kraftstoff *"
            @change="HandleFuelChanged(car.item.Fuel)"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Modell *"
            :rules="rules"
            hide-details="auto"
            tabindex="1"
            v-model="car.item.ModelName"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3"
          ><v-row>
            <v-text-field
              label="Kilometerstand"
              :rules="rules"
              hide-details="auto"
              tabindex="1"
              v-model="car.item.KM"
            ></v-text-field> </v-row
        ></v-col>
        <v-col cols="3"
          ><v-row>
            <v-dialog
              ref="datekm"
              v-model="datekmmodal"
              :return-value.sync="datekm"
              persistent
              tabindex="4"
              width="290px"
              disabled
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datekm"
                  label="KM Datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  disabled
                ></v-text-field>
              </template>
              <v-date-picker v-model="datekm" scrollable locale="de-de">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="datekmmodal = false">
                  Abbrechen
                </v-btn>
                <v-btn text color="primary" @click="$refs.datekm.save(datekm)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-row></v-col
        >
        <v-col cols="3"
          ><v-row>
            <v-dialog
              ref="dateyear"
              v-model="dateyrmodal"
              :return-value.sync="dateyr"
              persistent
              tabindex="4"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateyr"
                  label="Baujahr *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateyr" scrollable locale="de-de">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateyrmodal = false">
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dateyear.save(dateyr)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-row></v-col
        >
        <v-col cols="3"
          ><v-row>
            <v-dialog
              ref="dateregister"
              v-model="dateregistermodal"
              :return-value.sync="datezlyr"
              persistent
              tabindex="4"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datezlyr"
                  label="Erstzulassung *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="datezlyr" scrollable locale="de-de">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateregistermodal = false">
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dateregister.save(datezlyr)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-row></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="selecteddrivers"
            :items="employeeNames"
            item-value="ID"
            item-text="Name"
            label="Fahrer"
            multiple
            @change="SelectedDriversChanged"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.Name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ selecteddrivers.length - 1 }} weitere)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Tankkarte"
            v-model="car.item.Card"
            hide-details="auto"
            tabindex="1"
          ></v-text-field>
          <v-btn @click="Save" color="primary" elevation="2" style="float: right" class="mt-3 mr-5" :disabled="!btnEnabled"><i class="fas fa-save"></i> Speichern </v-btn>
          <v-btn @click="Delete" elevator="2" style="float: right" class="mr-2 mt-3 deletecolor"
          ><i class="fas fa-trash"></i>Löschen</v-btn
        >
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12">
          </v-col>
      </v-row>
    </v-col>
    </base-card>
<!--    <v-divider></v-divider>-->
    <base-card>
    <v-col cols="12">
      <v-row>
        <SpecificCarAppointmentList></SpecificCarAppointmentList>
      </v-row>
    </v-col>
    </base-card>

  </div>
</template>

<script>
import moment from "moment";
import {validateKFZ }  from "../../../helpers/validator"
import SpecificCarAppointmentList from '../Cars/SpecificCarAppointmentList';
import BaseCard from "../../Base/BaseCard";

export default {
  components: {
    BaseCard,
    SpecificCarAppointmentList
  },
  mounted() {
    this.$root.$on("reload_car_details", () => {
      this.Reload();
    });
  },
  created() {
    this.Reload();
    this.GetAllManufacturer();
    this.GetAllEmployees();
    this.GetAllFuels();
  },
  data() {
    return {
      datekmmodal: false,
      datemodal: false,
      datekm: "",
      dateyrmodal: false,
      dateregistermodal: false,
      selectedfuel: 0,
      currentManufacturer: {},
      currentDrivers: {},
      
      rules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld."
      ],

      kfzRules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
          (value && validateKFZ(value)) ||
          "Keine gültiges Kennzeichen",
      ],
    };
  },
  computed: {
      btnEnabled() {
      return (
              (this.car.item.KfzNr != "") &&
              (this.selectedManufacturerID != 0) &&
              (this.datezlyr != "" || !!this.datezlyr) &&
              (this.dateyr != "" || !!this.dateyr) && 
              (this.datehuau != "" || !!this.datehuau) &&
              (this.car.item.ModelName != "" || !!this.car.item.ModelName)
              )
    },
    selecteddrivers: {
      get: function() {
        let automotiveDrivers = this.car.item.AutomotiveDrivers;
        console.log(automotiveDrivers);

        let employeesNew = [];
        if (automotiveDrivers != undefined) {
          automotiveDrivers.forEach((driver) => {
              let e = driver.Employee;
              employeesNew.push({ ID: e.ID, Name: e.Firstname + "," + e.Lastname });
          });
        }
        
        return employeesNew;
      },
      set: function(item) { 
        this.currentDrivers = item;
      }
    },
    datezlyr: {
      get: function() {
        return this.car.item.Registration ? moment(this.car.item.Registration).format("YYYY-MM-DD") : "";
      },
      set: function(newValue) {
        this.car.item.Registration = newValue;
      }
    },
    dateyr: {
      get: function() {
        return this.car.item.BuildYear ? moment(this.car.item.BuildYear).format("YYYY") : "";
      },
      set: function(newValue) {
        this.car.item.BuildYear = newValue;
      }
    },
    datehuau: {
      get: function () {
        return this.car.item.HUAU ? moment(this.car.item.HUAU).format("YYYY-MM") : "";
      },
      set: function (newValue) {
        this.car.item.HUAU = newValue;
      }
    },
    formatHUAU() {
      return this.datehuau ? moment(this.datehuau).format() : "";
    },
    formatBuildYear() {
      return this.dateyr ? moment(this.dateyr).format() : "";
    },
    formatRegistrationDate() {
      return this.datezlyr ? moment(this.datezlyr).format() : "";
    },
    selectedManufacturerID() {
      return this.currentManufacturer.ID;
    },
    selectedManufacturerWebsite() {
      return this.currentManufacturer.Website;
    },
    employeeNames() {
      let l = this.employees; let x = [];
      if (l != undefined) {
        l.forEach(element => { x.push({ ID: element.ID, Name: element.Firstname + "," + element.Lastname }); });
      }
      return x;
    },
    fuels(){
        let f = this.$store.state.fuels.all.items;
        let n = [{ ID: null, Name: "-"}];
        return (f) ? f : n;
    },
    manufacturers(){
        return this.$store.state.automotiveManufacturers.all.items;
    },
    employees(){
        return this.$store.state.employees.all.items;
    },
    userID() {
      return this.$store.state.authentication.userID;
    },
    car() {
      let c = this.$store.state.cars.single;      
      return c;
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    SelectedDriversChanged() {
      console.log(this.selecteddrivers);
    },
    HandleFuelChanged(id) {
      this.selectedfuel = this.fuels.filter(x => x.ID == id)[0];
      console.log(this.selectedfuel);
    },  
    HandleManufacturerChanged(id) {
      this.currentManufacturer = this.manufacturers.filter(x => x.ID == id)[0];
      console.log(this.currentManufacturer);
    },  
    async GetAllFuels() {
      this.$store.dispatch("fuels/getAll");
    },
    async GetAllManufacturer() {
      await this.$store.dispatch("automotiveManufacturers/getAll");
    },
    async GetAllEmployees() {
      this.$store.dispatch('employees/getAll');
    },
    Delete() {
      if (
        confirm(
          "Möchten Sie das Fahrzeug " +
            this.car.item.Model +
            " wirklich löschen?"
        )
      ) {
        this.$store
          .dispatch("cars/delete", this.car.item.ID)
          .then(() => {
            this.$root.$emit("reload_car_list");
            window.location.assign("/car/0");
          });
      }
    },
    Save() {
      let manuID = (this.currentManufacturer.ID == undefined) ? this.car.item.AutomotiveManufacturerID : this.currentManufacturer.ID;
      let fID = (this.selectedfuel.ID == undefined) ? this.car.item.FuelID : this.selectedfuel.ID;

      let car = {
        ID: parseInt(this.id),
        KfzNr: this.car.item.KfzNr,
        AutomotiveManufacturerID: parseInt(manuID),
        ModelName: this.car.item.ModelName,
        FuelID: parseInt(fID),
        HUAU: moment(this.car.item.HUAU).format(),
        Operative: this.car.item.Operative,
        Available: this.car.item.Available,
        KM: Number(this.car.item.KM),
        BuildYear: moment(this.dateyr).format(),
        Registration: moment(this.datezlyr).format(),
        Card: this.car.item.Card,
        ChassisNumber: this.car.item.ChassisNumber
      }


      console.log(this.currentDrivers);

      //Unlink all driver
      this.$store.dispatch("cars/unlinkAlldriver", this.id).then((unlink_response) => {
          console.log(unlink_response);
          this.$store.dispatch("cars/updateCar", car).then((response) => {
            console.log(response);

            //Link all
            this.currentDrivers.forEach((ele) => {
              let payload = {
                AutomotiveID: parseInt(this.id),
                EmployeeID: parseInt(ele)
              };
              console.log(payload);

              this.$store.dispatch("cars/linkdriver", payload).then((response_link) => {
                    console.log(response_link);
              });
            })
            this.$root.$emit("reload_car_list");
            this.$root.$emit("reload_car_details");
          });
        });

      

      console.log(car);
    },
    Reload() {
      if (this.id > 0) {
        this.$store.dispatch("cars/get", this.id).then((response) => {
          console.log(response);
        });
      }
    },
  },
};
</script>

<style>
tr:hover td {
  /*background-color: #951b81;*/
  background-color: #dadfec78;
  color: #951b81;;
}

i {
  margin-right: 10px;
}
svg{
  margin-right: 10px;
}
</style>
