<template>
  <v-container>
    <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              color="#cf5300" align="center" style="padding-bottom: 5px" dark>
        <v-card-title class="justify-center">
          <v-icon x-large>mdi-cloud-upload</v-icon>
          Import Logo
        </v-card-title>
        <v-card-subtitle>.png</v-card-subtitle>

        <v-file-input
            accept=".png"
            label="Datei auswählen"
            outlined
            class="mt-4 mr-2"
            v-model="chosenFile"
            @click:clear="clearChosenFile">
        </v-file-input>

        <v-row class="mt-4 ml-0 justify-center">
          <v-btn
              :disabled="chosenFile == undefined"
              @click="UploadLogo" class="ml-2 mb-6" style="background-color: white; color:#cf5300">
            Logo Hochladen
          </v-btn>
        </v-row>

        <ProgressCircleOverlay
            :absolute="true"
            :overlay="overlay"
            :errormessage="errormessage"
            :errorinfo="errorinfo"
            @CloseOverlayAndErrorinfo="CloseOverlayAndErrorinfo"
        />
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
import ProgressCircleOverlay from '../overlays/ProgressCircleOverlay.vue'

export default {
  components: {
    ProgressCircleOverlay,
  },
  data() {
    return {
      chosenFile: null,
      importDone: false,
      filename: '',
      overlay: false,
      loading: true,
      errorinfo: false,
      errormessage: "",
      showData: false,
    };
  },
  methods: {
    show() {
      this.showData = !this.showData;
    },
    clearChosenFile() {
      this.chosenFile = null;
      this.count = 0;
      this.showData = false;
    },
    CloseOverlayAndErrorinfo() {
      this.overlay = false;
      this.erroinfo = false;
      this.chosenFile = null
    },
    async UploadLogo() {
      this.overlay = true
      this.loading = true
      await this.TryUpload()
    },
    async TryUpload() {
      try {
        console.log("File: " + this.chosenFile)
        let fileresponse = await this.$store.dispatch('file/uploadLogo', this.chosenFile)
        this.overlay = false
        console.log(fileresponse)
        this.loading = false
        this.chosenFile = null
      } catch (error) {
        console.log(error)
        this.loading = false
        this.errorinfo = true
        this.errormessage = "Fehler beim Upload des Logos."
      }
    },
  }
};
</script>

<style scoped>

.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  margin: 2px auto;
}

.panel {
  border: 2px solid #dfdfdf;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0 0;
  margin: 10px;
}

.panel.panel-sm {
  max-width: 100%;
  margin: 10px auto;
}

.panel-heading {
  border-bottom: 2px solid #dfdfdf;
}

.panel-heading h1, .panel-heading h2, .panel-heading h3, .panel-heading h4, .panel-heading h5, .panel-heading h6 {
  margin: 0;
  padding: 0;
}

.panel-body .checkbox-inline {
  padding: 15px 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 100%;
}

tr:nth-child(even) {
  background-color: #dddddd;
}


</style>