import {placeforklientkeysService} from "../services/placeforklientkeys.service";

export const placeforklientkeys = {
    namespaced: true,
    state: {
        error: {},
        placeforklientkeys: {}
    },
    getters: {},
    actions: {
        getKeysByPatientID({commit}, patientID) {
            return new Promise((resolve, reject) => {
                placeforklientkeysService.getKeysByPatientID(patientID)
                    .then(
                        payload => {
                            commit('getAllSuccess', payload)
                            resolve(payload)
                        },
                        error => {
                            reject(error)
                        }
                    );
            })
        },
        addKeysByPatientID({commit}, payload) {
            return new Promise((resolve, reject) => {
                placeforklientkeysService.addKeysByPatientID(payload)
                    .then(
                        payload => {
                            commit('getAllSuccess', payload)
                            resolve(payload)
                        },
                        error => {
                            reject(error)
                        }
                    );
            })
        },
        delete({commit}, keyid) {
            return new Promise((resolve, reject) => {
                placeforklientkeysService.deleteById(keyid).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        deleteAll({commit}, keyid) {
            return new Promise((resolve, reject) => {
                placeforklientkeysService.deleteAll(keyid).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
    },
    mutations: {
        getAllSuccess(state, payload) {
            state.placeforklientkeys = {item: payload}
        },
        getAllFailure(state, error) {
            state.error = {
                error
            };
        },
    }
}