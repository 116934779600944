var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dataTables_wrapper"},[_c('v-data-table',{staticClass:"elevation-1 hdl",attrs:{"headers":_vm.headers,"item-key":"ID","items":_vm.devicestable,"loading":_vm.isLoading,"loading-text":"Wird geladen","search":_vm.search,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","locale":"de-de","footer-props":{
                  itemsPerPageAllText: 'Alle',
                  itemsPerPageText: 'Einträge pro Seite',                
              }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.Available",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.Available),callback:function ($$v) {_vm.$set(item, "Available", $$v)},expression:"item.Available"}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getData}},[_vm._v(" Neu laden ")])]},proxy:true},{key:"body",fn:function(ref){
              var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.ID,on:{"click":function($event){return _vm.GotoDeviceDetails()}}},[_c('td',[_vm._v(_vm._s(item.ID))]),_c('td',[_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.Active),callback:function ($$v) {_vm.$set(item, "Active", $$v)},expression:"item.Active"}})],1),_c('td',[_vm._v(_vm._s(item.Description))]),_c('td',[_vm._v(_vm._s(item.PatientID))]),_c('td',[_vm._v(_vm._s(item.Nextmaintainance))])])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }