<template>
  <main>
    <v-container fluid>
      <h2>
        <v-icon large style="color: #006ab5 !important;">mdi-card-account-details-outline</v-icon>
        Rechnungen
      </h2>
      <v-row>
        <v-col cols="5">
          <base-card>
            <InvoiceList
                :key="invoicelistkey"
                :invoiceRunID="invoiceRunID"
                @SendInvoiceID="GetInvoiceID"
                @SendApproval="GetApprovalString"
                @SendStatus="GetStatus"
                @SendReferenz="GetReferenz"
                @SendInvoiceNumber="GetInvoiceNumber"
            />
          </base-card>
          <v-col>
            <template>
              <v-row>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="primary"
                        v-bind="attrs"
                        v-on="on">
                      Rechnungslauf genehmigen
                    </v-btn>
                    <v-col cols="5">
                      <v-btn v-if="(approvalString == 'genehmigt' && referenz == '')"
                             @click.prevent="storniereRE(invoicenumber)"
                             style="margin-top: -12px;" color="secondary">Stornieren R.-Nr.: <p
                          style="margin-top: 16px; color:yellow">{{ invoicenumber }} </p></v-btn>

                      <v-card-text class="text_center sub_btn">
                        <v-menu v-if="status === 'Storno'" class="settingsBtn" color="primary" light v-bind="attrs"
                                v-on:click.prevent v-on="on" :nudge-width="auto" offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="btn_style v-btn" color="secondary"
                                   style="margin-top: -42px; margin-left: -15px" v-bind="attrs"
                                   v-on="on">
                              <v-icon>mdi-format-list-bulleted-square</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-list dense>
                            </v-list>
                            <v-list dense dark>
                              <v-list-item dense @click="DownloadStorno">
                                Storno R.-Nr. <p class="vs"
                                                 style="margin-top: 16px; color:yellow">
                                {{ invoicenumber }} </p> Download
                              </v-list-item>
                              <v-list-item dense @click="createNewInvoiceFromStorno">
                                Kopieren
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-card-text>
                    </v-col>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5">
                      <h3>Rechnungsdatum</h3>
                    </v-card-title>
                    <v-card-text>
                      <v-radio-group
                          style="display: inline-block"
                          v-model="billdate"
                          column>
                        <v-row>
                          <v-col>
                            <v-radio
                                label="Standard:"
                                color="primary"
                                value="standard"
                                checked="checked"
                                @click="SetStandardBillingDate"
                            ></v-radio>
                          </v-col>
                          <v-col>
                            <p>{{ billingDate }}</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-radio
                                label="Neues Rechnungsdatum"
                                color="primary"
                                value="newDate"
                                @click="SetNewBillingDate"
                            ></v-radio>
                          </v-col>
                          <v-col cols="10">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="true"
                                :return-value="newbillingDateForInvoices"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled="!newBillingDate"
                                    :value="computedDateFormattedMomentjs ?
                                  computedDateFormattedMomentjs.substr(8,2)+ '.'
                                  + computedDateFormattedMomentjs.substr(5,2)+'.'
                                  + computedDateFormattedMomentjs.substr(0,4): ''"
                                    label="Tag wählen"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="date"
                                  no-title
                                  scrollable
                                  locale="de"
                                  @change="setNewDateForBills">
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false">
                                  Abbrechen
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            @click="dialog = false">
                          Abbrechen
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="!date && newBillingDate"
                            @click="ApproveInvoiceRun()">
                          Genehmigen
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-col>
        </v-col>
        <v-col cols="7">
          <EditInvoice
              :key="editinvoicekey"
              :newPos="newpos"
              :invoiceID="invoiceID"
              :downloadinvoice="downloadinvoice"
              :disablebutton="disablebutton"
              :approvalString="approvalString"
              :status="status"
              :invoicenumber="invoicenumber"
              @RenderInvoiceList="RenderInvoiceList"
              @RenderEditInvoiceApprovalString="RenderEditInvoiceApprovalString"
          />
        </v-col>
      </v-row>

    </v-container>
  </main>
</template>

<script>
import EditInvoice from '../../components/invoices/EditInvoice.vue'
import InvoiceList from '../../components/invoices/InvoiceList.vue'
import BaseCard from "../../components/Base/BaseCard";
import moment from "moment";

export default {
  props: {
  },
  components: {
    BaseCard,
    EditInvoice,
    InvoiceList
  },
  data() {
    return {
      invoiceID: undefined,
      editinvoicekey: 0,
      newpos :false,
      invoicelistkey: 0,
      downloadinvoice: false,
      disablebutton: true,
      status: undefined,
      approvalString: undefined,
      billdate: 'standard',
      referenz: undefined,
      invoicenumber: undefined,
      dialog: false,
      newbillingDateForInvoices: new Date(),
      billingDate: new Date().toLocaleDateString(),
      date: undefined,
      newBillingDate: false,
      // showRechnungsposition: false,
    }
  },
  created() {
    this.init();
  },
  computed: {
    computedDateFormattedMomentjs() {
      moment.locale("de");
      return this.date ? moment.utc(this.date).format() : '';
    },
    invoiceRunID() {
      return this.$store.getters['invoices/getInvoiceRunID']
    }
  },
  methods: {
    async createNewInvoiceFromStorno(invoicenumber) {
      if (confirm("Möchten Sie eine neue Rechnung mit den vorhandenen Daten erstellen?")) {
        console.log("Storniere Rechnung " + invoicenumber)
        this.init();
        let ApproveOneInvoiceDTO = {
          InvoiceGoID: this.invoiceID,
        }
        this.$root.$refs.EditInvoice.setNewStatus("nicht genehmigt");
        await this.$store.dispatch('invoices/CreateNewInvoiceFromStorno', ApproveOneInvoiceDTO)
      } else {
        // do nothing
      }
      this.invoicelistkey++
    },
    DownloadStorno() {
      this.$root.$refs.EditInvoice.DownloadInvoice()
    },
    init() {
      this.referenz = "init"
      this.status = "init"
    },
    async storniereRE(invoicenumber) {
      if (confirm("Möchten Sie die Rechnung mit der R.-NR: " + invoicenumber + " wirklich stornieren?")) {
        console.log("Storniere Rechnung " + invoicenumber)
        this.init();
        let ApproveOneInvoiceDTO = {
          InvoiceGoID: this.invoiceID,
        }
        this.$root.$refs.InvoiceList.setNewStatus("Storno");
        await this.$store.dispatch('invoices/StornoInvoiceByInvoiceGoID', ApproveOneInvoiceDTO)
      } else {
        // do nothing
      }
      this.invoicelistkey++
    },
    RefreshPosition() {
      this.editinvoicekey++;
    },
    SelectBill(invoicenumber) {
      this.invoicenumber = invoicenumber;
    },
    SetStandardBillingDate() {
      this.newBillingDate = false
      this.newbillingDateForInvoices = new Date().toLocaleDateString()
    },
    SetNewBillingDate() {
      this.newBillingDate = true
    },
    setNewDateForBills() {
      this.newbillingDateForInvoices = new Date(this.computedDateFormattedMomentjs);
    },
    GetInvoiceID(invoiceID) {
      console.log("invoiceID: " + invoiceID)
      this.invoiceID = invoiceID
      this.editinvoicekey++
      this.disablebutton = false
    },
    async ApproveInvoiceRun() {
      this.dialog = false
      let approveInvoicesDTO = {
        InvoiceRunID: this.invoiceRunID,
        Created: this.newbillingDateForInvoices,
      }
      await this.$store.dispatch('invoices/approveAllInvoicesInInvoiceRunByInvoiceGoRunID', approveInvoicesDTO)
      this.RenderInvoiceList()
    },
    RenderInvoiceList() {
      console.log("emit")
      console.log("invoiceRunID: " + this.invoiceRunID)
      this.invoicelistkey++
    },
    GetApprovalString(approvalString) {
      console.log(approvalString)
      this.approvalString = approvalString
      this.editinvoicekey++
    },
    GetStatus(status) {
      console.log(status)
      this.status = status
      this.editinvoicekey++
    },
    GetReferenz(referenz) {
      console.log(referenz)
      this.referenz = referenz
      this.editinvoicekey++
    },
    GetInvoiceNumber(invoicenumber) {
      this.invoicenumber = invoicenumber
    },
    SetStatus(status) {
      console.log(status)
      this.status = status
      this.editinvoicekey++
    },
    RenderEditInvoiceApprovalString(approvalString) {
      console.log(approvalString)
      this.approvalString = approvalString
      this.editinvoicekey++
    }
  },
  mounted() {
    this.$root.$refs.InvoiceEditLayout = this;
    this.$root.$refs.start.setMenuActive(true);
  }
}
</script>

<style>
.vs {
  padding-right: 5px;
  padding-left: 5px;
}
</style>
<style scoped>


div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab.v-tab--active {
  background-color: #751265 !important;
  color: white !important;
}

div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab.v-tab--active
> a {
  background-color: #751265 !important;
  color: white !important;
}

div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab:hover {
  background-color: #951b81 !important;
  color: white !important;
}

div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab:hover
> a {
  background-color: #951b81 !important;
  color: white !important;
}
</style>
