<template>
  <main>
    <v-row style="margin-top: -18px">
      <v-col cols="6">
        <v-file-input
            v-model="files"
            color="deep-purple accent-4"
            counter
            label="Datei eingabe"
            multiple
            placeholder="Hier klicken um Dateien auszuwählen"
            prepend-icon="mdi-paperclip"
            outlined
            dense
            :show-size="1000"

        >
          <template v-slot:selection="{ index, text }">
            <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label

                small
            >
              {{ text }}
            </v-chip>

            <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
            >
                        +{{ files.length - 2 }} File(s)
                    </span>
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="3">
        <v-btn
            color="info"
            class="mt-1"
            @click="uploadFile()"
        >
          <v-icon small>mdi-content-save</v-icon>Speichern
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
            class="ml-4 mt-1"
            color="info"

            @click="CloseAddFile()"
        >
          <v-icon small>mdi-cancel</v-icon>abbrechen
        </v-btn>
      </v-col>
    </v-row>


    <!--      current -->

    <!--        <v-row>-->
    <!--            <template>-->
    <!--                <v-file-input-->
    <!--                    v-model="files"-->
    <!--                    color="deep-purple accent-4"-->
    <!--                    counter-->
    <!--                    label="Datei eingabe"-->
    <!--                    multiple-->
    <!--                    placeholder="Hier klicken um Dateien auszuwählen"-->
    <!--                    prepend-icon="mdi-paperclip"-->
    <!--                    outlined-->
    <!--                    :show-size="1000"-->
    <!--                >-->
    <!--                    <template v-slot:selection="{ index, text }">-->
    <!--                    <v-chip-->
    <!--                        v-if="index < 2"-->
    <!--                        color="deep-purple accent-4"-->
    <!--                        dark-->
    <!--                        label-->
    <!--                        small-->
    <!--                    >-->
    <!--                        {{ text }}-->
    <!--                    </v-chip>-->

    <!--                    <span-->
    <!--                        v-else-if="index === 2"-->
    <!--                        class="overline grey&#45;&#45;text text&#45;&#45;darken-3 mx-2"-->
    <!--                    >-->
    <!--                        +{{ files.length - 2 }} File(s)-->
    <!--                    </span>-->
    <!--                    </template>-->
    <!--                </v-file-input>-->
    <!--            </template>-->
    <!--        </v-row>-->
    <!--        <v-row>-->
    <!--            <v-col>-->
    <!--                <v-btn-->
    <!--                    color="primary"-->
    <!--                    @click="uploadFile()"-->
    <!--                >-->
    <!--                    Speichern-->
    <!--                </v-btn>-->
    <!--            </v-col>-->
    <!--                <v-col>-->
    <!--                <v-btn-->
    <!--                    color="primary"-->
    <!--                    @click="CloseAddFile()"-->
    <!--                >-->
    <!--                    abbrechen-->
    <!--                </v-btn>-->
    <!--            </v-col>-->
    <!--        </v-row>-->
    <!--        <v-overlay-->
    <!--            :absolute="true"-->
    <!--            :value="overlay"-->
    <!--        >-->
    <!--            <v-progress-circular v-if="loading"-->
    <!--                indeterminate-->
    <!--                size="64"-->
    <!--            >-->
    <!--            </v-progress-circular>-->
    <!--            <v-row v-if="errorinfo">-->
    <!--                <v-col>-->
    <!--                    <v-row>-->
    <!--                        <h3>{{errormessage}}</h3>-->
    <!--                    </v-row>-->
    <!--                    <v-row>-->
    <!--                        <v-btn-->
    <!--                            color="primary"-->
    <!--                            @click="CloseOverlayAndErrorinfo()"-->
    <!--                        >-->
    <!--                            Ok-->
    <!--                        </v-btn>-->
    <!--                    </v-row>-->
    <!--                </v-col>-->
    <!--            </v-row>-->
    <!--        </v-overlay>-->

  </main>
</template>
<script>
export default {
  data() {
    return {
      files: [],
      overlay: false,
      loading: true,
      errorinfo: false,
      errormessage: ""
    }
  },
  props: {
    employeeid: Number,
  },
  watch: {
    files: function () {
      this.$emit("sendFiles", this.files)
    }
  },
  methods: {
    async uploadFile() {
      let fileupload = {
        ID: this.employeeid,
        file: this.files
      }
      this.overlay = true
      this.loading = true
      await this.TryUpload(fileupload)
    },
    async TryUpload(fileupload) {
      try {
        let fileresponse = await this.$store.dispatch('employees/uploadFile', fileupload)
        this.overlay = false
        console.log(fileresponse)
        this.$emit("renderFileTable")
        this.loading = false
        this.files = []
      } catch (error) {
        console.log(error)
        this.loading = false
        this.errorinfo = true
        this.errormessage = "Fehler beim Upload der Datei/en"
      }
    },
    CloseAddFile() {
      this.$emit("CloseAddFile")
    },
    CloseOverlayAndErrorinfo() {
      this.overlay = false;
      this.erroinfo = false;
      this.files = []
    }
  }
}
</script>