<template>
  <div>
    <!--    <h4><i class="fas fa-car-alt"></i> Firmenwagen: {{ carCount }}</h4>-->
    <div class="dataTables_wrapper">
      <v-data-table
          :headers="headers"
          :items="cars.items"
          item-key="id"
          class="elevation-1 hdl"
          :loading="isLoading"
          loading-text="Wird geladen"
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          :search="search"
          locale="de-de"
          @click:row="rowClick"
          :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Suchen"
              class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.Available="{ item }">
          <v-simple-checkbox
              v-model="item.Available"
              disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.Operative="{ item }">
          <v-simple-checkbox
              v-model="item.Operative"
              disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="EditItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="DeleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="GetData"> Neu laden</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {text: "Vorort", value: "Available"},
        {text: "Nr.", value: "ID"},
        {text: "Kennzeichen", value: "KfzNr"},
        {text: "Marke", value: "AutomotiveManufacturer.Name"},
        {text: "Typ", value: "ModelName"},
        {text: "Kraftstoff", value: "Fuel.Name"},
        {text: "Kilometerstand", value: "KM"},
        {text: "HU/AU", value: "HUAU"},
        {text: "Fahrtüchtig", value: "Operative"},

      ],
      search: "",
    };
  },
  computed: {
    cars() {

      return this.$store.state.cars.all;

    },
    isLoading() {
      return this.$store.state.cars.loading;
    },

  },
  mounted() {
    this.$root.$on("reload_car_list", () => {
      this.GetData();
    });
  },
  created() {
    this.GetData();

  },
  destroyed() {
  },
  methods: {
    carCount() {
      alert(this.cars.items.length);

      // let count = (this.cars.items.length !== undefined ? this.cars.items.length : 0);
      // alert(this.cars.items.length);
      // this.$root.$refs.InventoryDefaultLayout.SetCarCount(count);
      // return count;
    },
    rowClick: function (item, row) {
      if (!this.clicked) {
        console.log(row);
        this.clicked = true;
        window.location.assign("/car/" + item.ID);
        this.$root.$emit("reload_car_details");
        this.clicked = false;
      }
    },
    DeleteItem(item) {
      if (
          confirm("Möchten Sie das Auto " + item.KfzNr + " wirklich löschen?")
      ) {
        this.$store.dispatch("cars/delete", item.ID).then(() => {
          this.$router.push("/cars");
        });
      }
    },
    EditItem(item) {
      this.$router.push("/car/" + item.ID);
      this.$root.$emit("reload_car_details");
    },
    async GetData() {

      this.$store.dispatch("cars/reset");
      this.$store.dispatch("cars/getAll");



    },
  },
};
</script>

<style>

</style>
