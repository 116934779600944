<template>
  <main>
    <div>
      <v-container fluid>

        <v-col cols="12">
          <v-card dark color="#951b81">
            <v-row>
              <v-col>
<!--                <v-card-title>-->
                  <v-icon class="ml-4" >mdi-layers-triple</v-icon>
                  INVENTAR
<!--                </v-card-title>-->
              </v-col>
              <v-col cols="10">
                <v-icon left>mdi-car</v-icon>
                Firmenwagen
                <v-icon class="mb-1 ml-3" left>mdi-devices</v-icon>
                Geräte
<!--                Firmenwagen : {{ carCount }}-->
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <base-card>
        <v-row class="mt-2">
          <v-col cols="2">
           <div align="center"><v-icon x-large >mdi-car</v-icon><br>
            FIRMENWAGEN

<!--            <v-card dark color="#951b81" class="justify-center" align="center" style="padding:10px">-->
              <v-btn
                  @click="NewCar"
                  small
                  color="info"
                  class="mt-2"
                  elevation="2"
              >
                 Neues Fahrzeug hinzufügen
              </v-btn>
<!--            </v-card>-->
           </div>
          </v-col>
          <v-col cols="10">
            <InventoryCarList></InventoryCarList>
          </v-col>
        </v-row>
        </base-card>

        <base-card>
        <v-row class="mt-2">
          <v-col cols="2">
            <div align="center">
<!--            <v-card dark color="#951b81 " class="justify-center" align="center" style="padding:10px">-->
              <v-icon  large>mdi-devices</v-icon><br>
              GERÄTE
              <v-btn
                  @click="NewDevice"
                  small
                  color="info"
                  class="mt-2 ml-1"
                  elevation="2"
              >
                Neues Gerät hinzufügen
              </v-btn>
<!--            </v-card>-->
            </div>
          </v-col>
          <v-col cols="10">
            <InventoryDeviceList></InventoryDeviceList>
          </v-col>
        </v-row>
          </base-card>



      </v-container>
    </div>
  </main>
</template>
<script>
import InventoryCarList from "../../components/inventory/Cars/InventoryCarList.vue";
import InventoryDeviceList from "../../components/inventory/InventoryDeviceList.vue";
import BaseCard from "../../components/Base/BaseCard";

export default {
  data() {
    return {
      count : 0
    }
  },
  mounted() {
    // this.$root.$emit("reload_nav");
    this.$root.$refs.InventoryDefaultLayout = this;
    this.$root.$refs.start.setMenuActive(true);
  },
  components: {
    BaseCard,
    InventoryCarList,
    InventoryDeviceList,
  },
  computed: {
    carCount() {
      return this.count;
    }
  },
  methods: {
    routeTo(url) {
      if (url.startsWith("http")) {
        window.open(url, "_blank")
      } else {
        this.$router.push(url);
      }

    },
    NewCar() {
      window.location.assign("/car/0");
    },
    NewDevice() {
      window.location.assign("/inventory/device/details")
    },
    SetCarCount(value) {
      this.count = value;
    }
  },
};
</script>

<style>

</style>