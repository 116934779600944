<template>
  <v-card color="primary" dark style="padding:10px">
    <v-card-title>
      Dienstplan kopieren
    </v-card-title>
    <v-card-text>
      <row>
        <v-radio-group
            v-model="copymode">
          <v-radio
              label="ausgewählter Tag übernehmen"
              value='day'
          ></v-radio>
          <v-radio
              label="ausgewählte Kalendarwoche übernehmen"
              value='week'
          ></v-radio>
        </v-radio-group>
      </row>
      <row>
        <v-date-picker
            :show-current="datecopyfrom"
            v-model="datecopyto"
            :min="datecopyfrom"
            locale="de"
            :first-day-of-week="1">
        </v-date-picker>
      </row>
      <row>
        <v-text-field
            label="Von"
            v-model="copyfromstring"
            disableds
        ></v-text-field>
      </row>
      <row>
        <v-text-field
            label="Nach"
            v-model="copytostring"
            disabled
        ></v-text-field>
      </row>
    </v-card-text>
    <v-card-actions>
      <row :gutter="12">
        <column :ld="1">
          <v-btn
              class="ml-2 mb-2"
              color="info"
              @click="CopyRoster()">
            Bestätigen
          </v-btn>
          <v-btn
              class="ml-2 mb-2"
              color="info"
              @click="CloseDialog()">
            Schließen
          </v-btn>
        </column>
      </row>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from "moment";

export default {
  props: {
    datecopyfrom: String,
    datecopyto: String
  },
  data() {
    return {
      copymode: 'day',
    }
  },
  computed: {
    copyfromstring() {
      moment.locale("de");
      if (this.copymode === 'day') {
        return moment(this.datecopyfrom).format("DD-MM-YYYY")
      } else if (this.copymode === 'week') {
        return "Kalenderwoche " + moment(this.datecopyfrom).week();
      } else {
        return ""
      }
    },
    copytostring() {
      moment.locale("de");
      if (this.copymode === 'day') {
        return moment(this.datecopyto).format("DD-MM-YYYY")
      } else if (this.copymode === 'week') {
        return "Kalenderwoche " + moment(this.datecopyto).week();
      } else {
        return ""
      }
    },
  },
  methods: {
    CloseDialog() {
      console.log("Close")
      this.$store.commit('eventdialog/changeCopyCalenarDialogToFalse')
    },
    async CopyRoster() {
      console.log("CopyRoster")
      let timestampcopyfrom = new Date(this.datecopyfrom).getTime()
      let timestampcopyto = new Date(this.datecopyto).getTime()
      let copyCalendarDTO = {
        TimestampCopyFrom: timestampcopyfrom,
        TimestampCopyTo: timestampcopyto,
        CopyMode: this.copymode,
      }
      console.log(JSON.stringify(copyCalendarDTO))
      let response = await this.$store.dispatch('employees/copyCalendar', copyCalendarDTO)
      console.log(response)
      this.$emit("RefreshCalendar")
    },
  }
}
</script>