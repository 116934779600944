<template>
  <main>
    <div class="ml-7 mt-4"><h2>Import / Export</h2>
      <h5>beta (0.2.6)</h5>
    </div>
    <v-row class="ma-0 pa-0">
      <v-col cols="3" class="ml-8">
        <ImportPatients></ImportPatients>
      </v-col>
      <v-col cols="3" style="margin-left: -30px">
        <ImportAutomotives></ImportAutomotives>
      </v-col>
      <v-col cols="3" style="margin-left: -30px">
        <ImportDevices></ImportDevices>
      </v-col>
      <v-col cols="3" style="margin-left: -30px">
        <ImportLogo></ImportLogo>
      </v-col>
    </v-row>
  </main>
</template>

<script>
import ImportPatients from "@/components/settings/ImportPatients";
import ImportAutomotives from "@/components/settings/ImportAutomotives";
import ImportDevices from "@/components/settings/ImportDevices";
import ImportLogo from "@/components/settings/ImportLogo";

export default {
  components: {
    ImportPatients,
    ImportAutomotives,
    ImportDevices,
    ImportLogo
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>