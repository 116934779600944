var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dataTables_wrapper"},[_c('v-data-table',{staticClass:"elevation-1 hdl",attrs:{"headers":_vm.headers,"items":_vm.cars.items,"item-key":"id","loading":_vm.isLoading,"loading-text":"Wird geladen","no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","search":_vm.search,"locale":"de-de","footer-props":{
                  itemsPerPageAllText: 'Alle',
                  itemsPerPageText: 'Einträge pro Seite',                
              }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.Available",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.Available),callback:function ($$v) {_vm.$set(item, "Available", $$v)},expression:"item.Available"}})]}},{key:"item.Operative",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.Operative),callback:function ($$v) {_vm.$set(item, "Operative", $$v)},expression:"item.Operative"}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.EditItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v(" mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.GetData}},[_vm._v(" Neu laden")])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }