<template>
  <div>
    <h4
        class="ml-2"
        style="color: white !important;padding: 10px">
      <v-icon>mdi-account-multiple</v-icon>
      PERSONAL (Übersicht)
    </h4>

    <div class="dataTables_wrapper mt-4" style="border:1px solid lightgrey">
      <v-data-table
          light
          ref="table"
          :headers="headers"
          :items="employees"
          :calculationdata="calculationdata"
          :employeeovertime="employeeovertime"
          item-key="id"
          class="elevation-1 hdl"
          :loading="isLoading"
          loading-text="Wird geladen"
          :search="search"
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          locale="de-de"
          items-per-page="5"
          :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',
                }"
      >

        <template v-slot:top>
          <v-row class="ml-4" style="width: 90%">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suchen"
                class="mx-4"
                width="50%"
            ></v-text-field>
            <v-btn class="mt-4" small @click="clearSearchFilter">Leeren</v-btn>
          </v-row>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="getData"> Neu laden</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>


<script>
export default {
  emits: ["GetPatientID"],
  data() {
    return {
      clicked: false,
      headers: [
        {text: "Name", value: "Lastname"},
        {text: "Vorname", value: "Firstname"},
        {text: "Urlaubstage", value: "Holidays"},
        {text: "Genommen", value: "HolidaysTaken"},
        {text: "Rest", value: "Overtime"},
        {text: "Krank / Fehltage", value: "SickDaysTaken"},
        {text: "Überstunden", value: "Overtime"},


      ],
      search: "",
    };
  },
  created() {
    this.$store.dispatch('employees/getAll');
    this.$store.dispatch('employees/getAllEmployeeActivities');
    this.$store.dispatch('employees/getAllEmploymenttypes');
    this.$store.dispatch('employees/getAllMaritalstatuses');
    this.$store.dispatch('employees/getAllTaxclasses');
  },
  destroyed() {
  },
  computed: {
    employees() {
      return this.$store.state.employees.all.items;
    },
    employeeovertime() {
      return this.$store.getters['employees/getEmployeeOvertime']
    },
    calculationdata() {
      return this.$store.getters['employees/getCalulationDataEmployeeID']
    },
  },
  methods: {
    clearSearchFilter() {
      this.search = "";
    },
    submit() {
      console.log(this.user);
    },
  },
}

</script>

<style>
#myShortTable_length,
#myShortTable_info {
  display: none;
}

#myShortTable > thead > tr > th {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable > thead > tr {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable thead {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody tr {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody td {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable_paginate {
  display: none;
}
</style>