<template>
  <div>
    <h4><i class="fas fa-users"></i> Alle Klienten</h4>
    <div class="dataTables_wrapper mt-4" style="border:1px solid lightgrey">
      <v-data-table
          ref="table"
          :headers="headers"
          :items="patients"
          item-key="ID"
          class="elevation-1 hdl"
          :loading="isLoading"
          loading-text="Wird geladen"
          :search="search"
          single-select
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          locale="de-de"
          @click:row="rowClick"
          :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
      >

        <template v-slot:top>
          <v-row style="width: 100%">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suchen"
                class="mx-4"
                width="50%"
                clearable
            ></v-text-field>
            <v-btn class="mt-4" small @click="clearSearchFilter">Leeren</v-btn>
          </v-row>
        </template>

        <template v-slot:item.Active="{ item }">
          <v-simple-checkbox
              v-model="item.Active"
              disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.ServiceEmergencyCall="{ item }">
          <v-simple-checkbox
              v-model="item.ServiceEmergencyCall"
              disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.ServiceHousehold="{ item }">
          <v-simple-checkbox
              v-model="item.ServiceHousehold"
              disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="getData"> Neu laden</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>


<script>
export default {
  emits: ["GetPatientID"],
  props: {debitoren : Number},
  data() {
    return {
      clicked: false,
      headers: [
        {text: "Aktiv", value: "Active", width: "15%"},
        {text: "Debitoren-Nr.", value: "DebitorenNumber", align: ' d-none'}, // ' d-none' hides the column but keeps the search ability
        {text: "Name", value: "LastName"},
        {text: "Vorname", value: "FirstName"},
        {text: "Notruf", value: "ServiceEmergencyCall", width: "20%"},
        {text: "HDL", value: "ServiceHousehold", width: "15%"},

      ],
      search: "",
    };
  },
  computed: {
    patients() {
      return this.$store.getters['patients/getPatientForTables']
    },
    isLoading() {
      return this.$store.getters['patients/getPatientLoading']
    },
  },
  async created() {
    this.$store.commit('patients/setLoadingTrue')
    await this.getPatientsForTable()
  },
  mounted() {
    this.$root.$refs.PatientShortList = this;
  },
  methods: {
    rowClick: function (item, row) {
       row.select(true)
      console.log(row);
      console.log("RowClick: ", item.ID);
      this.$emit("GetPatientID", item.ID)
    },

    clearSearchFilter() {
      this.search = "";
    },
    submit() {
      console.log(this.user);
    },
    getPatientsForTable() {
      this.$store.dispatch("patients/getPatientsForTable")
    }
  },
};

</script>

<style>
#myShortTable_length,
#myShortTable_info {
  display: none;
}

#myShortTable > thead > tr > th {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable > thead > tr {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable thead {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody tr {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody td {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable_paginate {
  display: none;
}
</style>