<template>
    <v-card>
        <v-toolbar
            :color="eventcolor"
        >
            <v-toolbar-title v-html="eventname"></v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            {{StartTime}}{{EndTime}}
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        event: null,
        eventcolor: String,
        eventname: String,
        endtime: Number,
        starttime: Number,
        eventtype: String
    },
    computed: {
        StartTime(){
            let value = ""
            if(this.eventtype == "appointment"){
                value = "Von " + this.GetTimeString(this.starttime)
            }
            return value
        },
        EndTime(){
            let value = ""
            if(this.eventtype == "appointment"){
                value = " bis " + this.GetTimeString(this.endtime)
            }
            return value
        }
    },
    methods: {
        GetStartDate(){
            let date = new Date(this.event.start)
            console.log(date)
        },
        GetZeroBeforeNumber(number){
            let value = number
            if(number < 10){
                value = "0" + number
            }
            return value
        },
        GetTimeString(timestamp){
            let date = new Date(timestamp)
            let hour = date.getHours()
            let minute = date.getMinutes()
            return this.GetZeroBeforeNumber(hour) + ":" + this.GetZeroBeforeNumber(minute) + " Uhr"
        }
    }
}
</script>