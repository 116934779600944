import {noteService} from '../services'

export const note = {
    namespaced: true,
    state: {
        notesByEmployee: {},
        error: {}
    },
    getters: {
        getNotesByEmployee: state => {
            return state.notesByEmployee.items
        }
    },
    actions:{
        deleteNoteByID({commit}, noteID){
            return new Promise((resolve, reject) => {
                noteService.deleteNoteByID(noteID).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        changeNote({commit}, note){
            return new Promise((resolve, reject) => {
                noteService.changeNote(note).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getNotesReferencedEmployeeByEmployeeID({commit}, data){
            return new Promise((resolve, reject) => {
                noteService.getNotesReferencedEmployeeByEmployeeID(data.referenceEmployeeID).then(
                    payload => {
                        commit('getNotesByEmployeeSuccess', payload);
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getNotesReferencedPatientByEmployeeID({commit}, data){
            return new Promise((resolve, reject) => {
                noteService.getNotesReferencedPatientByEmployeeID(data.referencePatientID).then(
                    payload => {
                        commit('getNotesByEmployeeSuccess', payload);
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        createNote({commit}, note){
            return new Promise((resolve, reject) => {
                console.log(JSON.stringify(note))
                noteService.createNote(note).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
    },
    mutations: {
        getFailure(state, error){
            state.error = {item: error}
        },
        getNotesByEmployeeSuccess(state, payload){
            state.notesByEmployee = {items: payload}
        },
        setNotesByEmployeeEmpty(state){
            state.notesByEmployee = {}
        }
    }
}