import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/LoginPage.vue'

//Mitarbeiter
import EmployeeRoster from '../views/employees/EmployeeRoster.vue'
import EmployeeOverview from '../views/employees/EmployeeOverview.vue'
import EmployeeTimeTable from '../views/employees/EmployeeTimeTable.vue'

//Patienten
import PatientsDetails from '../views/patients/PatientsOverview.vue'

import PatientDocumentLayout from '../views/patients/PatientDocumentLayout.vue'
import PatientAggreementLayout from '../views/patients/PatientAggreementLayout.vue'
import PatientNoteLayout from '../views/patients/PatientNoteLayout.vue'
import PatientTerminLayout from '../views/patients/PatientTerminLayout.vue'

//Insurances
import InsurancesDetails from '../views/insurances/InsurancesDetails.vue'

//Invoices
import InvoiceDefaultLayout from '../views/invoices/InvoiceDefaultLayout.vue'
import InvoiceEditLayout from '../views/invoices/InvoiceEditLayout.vue'
import InvoiceRunLayout from '../views/invoices/InvoiceRunLayout.vue'

//Inventory
import InventoryDefaultLayout from '../views/inventory/InventoryDefaultLayout.vue'
import InventoryCarDetails from '../views/inventory/cars/InventoryCarDetails.vue'
import InventoryDeviceDetails from '../views/inventory/device/InventoryDeviceDetails.vue'

//Settings
import SettingsDefaultLayout from '../views/settings/SettingsDefaultLayout.vue'
import SettingsServiceArticles from '../views/settings/SettingsServiceArticles.vue'
import SystemInfo from '../Systeminfo.vue'
import SettingsInvoiceSettings from '../views/settings/SettingsInvoiceSettings.vue'
import SettingsTemplateSettings from '../views/settings/SettingsTemplateSettings.vue'
import ImportExport from '../views/settings/ImportExport.vue'
import DashboardOverview from '../views/dashboard/DashboardOverview'
import CalendarSettings from '../views/settings/CalendarSettings.vue'
import UserSettings from  '../views/settings/UserSettings'

//Profile
import UserProfile from '../views/profile/UserProfileDefaultLayout.vue'


Vue.use(VueRouter)

const routes = [
    {path: '/', component: Home},
    {path: '/home', component: Home},
    {path: '/login', component: Login},
    {path: '/patient/documents/:id', component: PatientDocumentLayout},
    {path: '/patient/aggreements/:id', component: PatientAggreementLayout},
    {path: '/patient/notes/:id', component: PatientNoteLayout},
    {path: '/patient/termin/:id', component: PatientTerminLayout},
    {path: '/patient/', component: PatientsDetails},
    {path: '/insurances', component: InsurancesDetails},
    {path: '/insurance/:id', component: InsurancesDetails},
    {path: '/inventory', component: InventoryDefaultLayout},
    {path: '/invoices', component: InvoiceDefaultLayout},
    {path: '/invoice/edit', component: InvoiceEditLayout},
    {path: '/invoiceRun/:id', component: InvoiceRunLayout},
    {path: '/car/:id', component: InventoryCarDetails},
    {path: '/employeeroster', component: EmployeeRoster},
    {path: '/employeeoverview', component: EmployeeOverview},
    {path: '/employeetimetable', component: EmployeeTimeTable},
    {path: '/inventory/device/details', component: InventoryDeviceDetails},
    {path: '/settings', component: SettingsDefaultLayout},
    {path: '/settings/servicearticles', component: SettingsServiceArticles},
    {path: '/settings/invoices', component: SettingsInvoiceSettings},
    {path: '/settings/templates', component: SettingsTemplateSettings},
    {path: '/info', component: SystemInfo},
    {path: '/me', component: UserProfile},
    {path: '/settings/importexport', component: ImportExport},
    {path: '/dashboard/dashboardoverview', component: DashboardOverview},
    {path: '/settings/calendarsettings', component: CalendarSettings},
    {path: '/settings/benutzerverwaltung', component: UserSettings},
    {path: '*', redirect: '/'}
]

export const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/info'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }
    next();
})


