<template>
  <main>
    <v-container fluid>
      <h2><v-icon large  style="color: #006ab5 !important;">mdi-card-account-details-outline</v-icon> Personal im Überblick</h2>
      <v-row style="height: 60px">
        <div class="col-12">
          <v-btn
              color="primary"
              class="width300 ml-2 mt-2"
              @click="setBack()"
          >
            <i class="fas fa-plus"></i>
            Hinzufügen
          </v-btn>
          <v-btn
              color="primary"
              class="width300 ml-2 mt-2"
              @click="OpenNoteModalDialog()"
          >
            <i class="fas fa-pen-square"></i>
            Notizen
          </v-btn>
          <v-btn
              color="primary"
              class="width300 ml-2 mt-2"
              @click="selectFiles"
              :disabled="!enabledocumentbutton"
          >
            <i class="fas fa-file-alt"></i>
            Dokumente
          </v-btn>
          <div style="display: inline-block">
            <SelectFile v-if="selectfiles"
                        :employeeid="form.employee.ID"
                        @CloseAddFile="CloseAddFile"
                        @renderFileTable="renderFileTable"
            ></SelectFile>
          </div>
        </div>
      </v-row>
      <v-row>
<!--        <div-->
<!--            class="col-4 border border-right border-left-0 border-top-0 border-bottom-0"-->
<!--        >-->
          <EmployeeTable
              class="ml-4"
              :employees="employees"
              :key="employeetablekey"
              @sendEmployeeID="fillInForm"
          />
<!--        </div>-->

        <v-col style="margin-top:-25px">
          <EmployeeInputMask
              ref="mask"
              :data="form"
              :dropdown="dropdown"
              :employeeid="form.employee.ID"
              :filetablekey="filetablekey"
              :employeefiles="employeefiles"
              :disableinput="disableinput"
              :employeeovertime="employeeovertime"
              :calculationdata="calculationdata"
              @Renderfiletable="renderFileTable"
              :noDocs="!enabledocumentbutton"
          />
          <div class="" style="margin-right: 2%;">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  elevation="2"
                  @click="setBack()"
                  class="ml-2"
              >
                <i class="fas fa-ban"></i>
                abbrechen
              </v-btn>
              <v-btn
                  elevation="2"
                  @click="showSnackbarDelete()"
                  class="ml-2 deletecolor"
              >
                <i class="fas fa-trash-alt"></i>
                löschen
              </v-btn>
              <v-btn
                  color="primary"
                  elevation="2"
                  :disabled="!enablestorebutton"
                  @click="showSnackbarStore()"
                  class="ml-2"
              >
                <i class="fas fa-save"></i>
                speichern
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>

    </v-container>

    <v-snackbar
        v-model="showsnackbar"
        :vertical="true"
        :timeout="-1"
        :top="false"
    >
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="primary"
            class="mr-5"
            @click="closeSnackBar()"
        >
          <i class="fas fa-ban"></i>
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            v-bind="attrs"
            @click="storeOrDeleteEmployee()"
        >
          <i class="fas fa-save"></i>
          {{ snackbarbuttontext }}
        </v-btn>

      </template>
    </v-snackbar>
    <v-row>
      <v-dialog
          v-model="notemodaldialog"
          persistent max-width="1100px"
      >
        <NoteDialog
            :referenceEmployeeID="form.employee.ID"
            :key="notedialogkey"
        />
      </v-dialog>

    </v-row>
  </main>
</template>
<script>
import EmployeeTable from '../../components/employees/overview/EmployeeTable.vue'
import EmployeeInputMask from '../../components/employees/overview/EmployeeInputMask.vue'
import SelectFile from '../../components/employees/overview/SelectFile.vue'
import NoteDialog from '../../components/dialogs/NoteDialog.vue'
import {dialogmixin} from '../../mixins'

export default {
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  components: {
    EmployeeTable,
    EmployeeInputMask,
    SelectFile,
    NoteDialog
  },
  mixins: [dialogmixin],
  data() {
    const defaultForm = Object.freeze({
      employee: {
        ID: undefined,
        Personalnumber: undefined,
        EmployeeActivity: undefined,
        Firstname: undefined,
        Lastname: undefined,
        DateOfBirth: undefined,
        StartOfWork: undefined,
        Street: undefined,
        PLZ: undefined,
        City: undefined,
        EmploymentType: undefined,
        CompanyCar: undefined,
        SalaryYear: undefined,
        SalaryMonth: undefined,
        MaritalStatusID: undefined,
        NumberKids: undefined,
        TaxClass: undefined,
        ChurchTax: undefined,
        IBAN: undefined,
        Holidays: undefined,
        HolidaysTaken: undefined,
        SickDays: undefined,
        EmployeeColorHex: undefined,
        Overtime: undefined,
        HoursPerWeek: undefined,
        TimeRecordingSince: undefined,
        OvertimeTransfer: undefined,
        PhoneNumber: undefined,
        Email: undefined,
      }
    })
    return {
      form: Object.assign({}, defaultForm),
      defaultForm,
      selectfiles: false,
      employeetablekey: 0,
      changeemployee: false,
      addemployee: true,
      filetablekey: 0,
      showsnackbar: false,
      snackbartext: "",
      storeemployee: false,
      deleteemployee: false,
      snackbarbuttontext: "",
      enabledocumentbutton: false,
      disableinput: false,
    }
  },
  computed: {
    employees() {
      return this.$store.state.employees.all.items;
    },
    dropdown() {
      let items = {
        //activities: this.$store.state.employees.employeeactivities.items,
        //employmenttypes: this.$store.state.employees.employmenttypes.items,
        maritalstatuses: this.$store.state.employees.maritalstatuses.items,
        taxclasses: this.$store.state.employees.taxclasses.items
      }
      return items
    },
    enablestorebutton() {
      if (this.form.employee.Personalnumber == undefined) {
        return false
      } else if (this.form.employee.Personalnumber == "") {
        return false
      } else if (this.showsnackbar) {
        return false
      } else {
        return true
      }
    },
    employeefiles() {
      return this.$store.state.employees.files.items;
    },
    employeeovertime() {
      return this.$store.getters['employees/getEmployeeOvertime']
    },
    calculationdata() {
      return this.$store.getters['employees/getCalulationDataEmployeeID']
    },
  },
  created() {
    this.$store.dispatch('employees/getAll');
    this.$store.dispatch('employees/getAllEmployeeActivities');
    this.$store.dispatch('employees/getAllEmploymenttypes');
    this.$store.dispatch('employees/getAllMaritalstatuses');
    this.$store.dispatch('employees/getAllTaxclasses');
  },
  methods: {
    routeTo(url) {
      this.$router.push(url);
    },
    showSnackbarStore() {
      if (this.$refs.mask.$refs.form.validate()) {
        this.showsnackbar = true
        var personalnumber = this.form.employee.Personalnumber
        this.snackbartext = "Wollen Sie die Änderungen des Mitarbeiters mit der Personalnummer " + personalnumber + " speichern?"
        this.storeemployee = true
        this.snackbarbuttontext = "Speichern"
        this.disableinput = true
        this.enabledocumentbutton = false
      }
    },
    showSnackbarDelete() {
      this.showsnackbar = true
      var personalnumber = this.form.employee.Personalnumber
      this.snackbartext = "Wollen Sie den Mitarbeiter mit der Personalnummer " + personalnumber + " löschen?"
      this.deleteemployee = true
      this.snackbarbuttontext = "Löschen"
      this.disableinput = true
      this.enabledocumentbutton = false
    },
    async fillInForm(id) {
      console.log(id)
      await this.$store.dispatch('employees/getEmployeeOverview', id)
      await this.$store.dispatch('employees/calculateHolidaysByEmployeeID', id)
      //await this.$store.dispatch('employees/calculateOvertimeForEachEmployee', id)
      this.form.employee = this.$store.state.employees.employeeoverview.item
      this.renderFileTable()
      this.changeemployee = true
      this.addemployee = false
      this.enabledocumentbutton = true
      this.$root.$refs.EmployeeInputMask.EnableDocsBtn();
    },
    setBack() {
      this.$refs.mask.$refs.form.reset()
      this.form = Object.assign({}, this.defaultForm)
      this.changeemployee = false
      this.addemployee = true
      this.showfiles = false
      this.enabledocumentbutton = false
      this.disableinput = false
      this.$root.$refs.EmployeeDocuments.SetShowFiles(false);
    },
    selectFiles() {
      this.selectfiles = !this.selectfiles
    },
    async addEmployee() {
      let employee = {
        Firstname: this.form.employee.Firstname == undefined ? "" : this.form.employee.Firstname,
        Lastname: this.form.employee.Lastname == undefined ? "" : this.form.employee.Lastname,
        DateOfBirth: this.form.employee.DateOfBirth == undefined ? "" : this.form.employee.DateOfBirth,
        Personalnumber: this.form.employee.Personalnumber == undefined ? "" : Number(this.form.employee.Personalnumber),
        SalaryMonth: this.form.employee.SalaryMonth == undefined ? 0 : Number(this.form.employee.SalaryMonth),
        SalaryYear: this.form.employee.SalaryYear == undefined ? 0 : Number(this.form.employee.SalaryYear),
        MaritalStatusID: this.form.employee.MaritalStatusID == undefined ? 0 : Number(this.form.employee.MaritalStatusID),
        StartOfWork: this.form.employee.StartOfWork == undefined ? "" : this.form.employee.StartOfWork,
        CompanyCar: this.form.employee.CompanyCar == undefined ? false : Boolean(this.form.employee.CompanyCar),
        TaxClass: this.form.employee.TaxClass == undefined ? 1 : Number(this.form.employee.TaxClass),
        Holidays: this.form.employee.Holidays == undefined ? 0 : Number(this.form.employee.Holidays),
        HolidaysTaken: this.form.employee.HolidaysTaken == undefined ? 0 : Number(this.form.employee.HolidaysTaken),
        SickDays: this.form.employee.SickDays == undefined ? 0 : Number(this.form.employee.SickDays),
        NumberKids: this.form.employee.NumberKids == undefined ? 0 : Number(this.form.employee.NumberKids),
        ChurchTax: this.form.employee.ChurchTax == undefined ? false : Boolean(this.form.employee.ChurchTax),
        EmployeeActivity: this.form.employee.EmployeeActivity == undefined ? "" : this.form.employee.EmployeeActivity,
        EmploymentTypeID: this.form.employee.EmploymentType == undefined ? "" : this.form.employee.EmploymentTypeID,
        Bonus: this.form.employee.Bonus == undefined ? "" : this.form.employee.Bonus,
        Street: this.form.employee.Street == undefined ? "" : this.form.employee.Street,
        PLZ: this.form.employee.PLZ == undefined ? "" : this.form.employee.PLZ,
        City: this.form.employee.City == undefined ? "" : this.form.employee.City,
        IBAN: this.form.employee.IBAN == undefined ? "" : this.form.employee.IBAN,
        EmployeeColorHex: this.form.employee.EmployeeColorHex == undefined ? "" : this.form.employee.EmployeeColorHex,
        Overtime: this.form.employee.Overtime == undefined ? 0 : Number(this.form.employee.Overtime),
        HoursPerWeek: this.form.employee.HoursPerWeek == undefined ? 40 : Number(this.form.employee.HoursPerWeek),
        TimeRecordingSince: this.form.employee.TimeRecordingSince == undefined ? "0" : this.form.employee.TimeRecordingSince,
        OvertimeTransfer: this.form.employee.OvertimeTransfer = undefined ? 0 : this.form.employee.OvertimeTransfer,
        PhoneNumber: this.form.employee.PhoneNumber == undefined ? "" : this.form.employee.PhoneNumber,
        Email: this.form.employee.Email == undefined ? "" : this.form.employee.Email,
      }
      console.log(JSON.stringify(employee))
      let response = await this.$store.dispatch('employees/createEmployeeOverview', employee)
      this.renderEmployeeTable()
      console.log(response)

    },
    async renderEmployeeTable() {
      await this.$store.dispatch('employees/getAll');
      this.employeetablekey++
    },
    async changeEmployee() {
      let employee = this.form.employee
      employee.Holidays = Number(employee.Holidays)
      employee.HolidaysTaken = Number(employee.HolidaysTaken)
      employee.SalaryYear = Number(employee.SalaryYear)
      employee.SalaryMonth = Number(employee.SalaryMonth)
      employee.NumberKids = Number(employee.NumberKids)
      employee.Overtime = Number(employee.Overtime)
      employee.HoursPerWeek = Number(employee.HoursPerWeek)
      employee.OvertimeTransfer = Number(employee.OvertimeTransfer)
      employee.TaxClass = Number(employee.TaxClass)
      console.log(JSON.stringify(employee))
      let employeeResponse = await this.$store.dispatch('employees/changeEmployeeOverview', employee)
      console.log(employeeResponse)
      this.renderEmployeeTable()
    },
    storeEmployee() {
      if (this.addemployee) {
        console.log("addEmployee")
        this.addEmployee()
      }
      if (this.changeemployee) {
        console.log("changeemployee")
        this.changeEmployee()
      }
      this.setBack()
    },
    CloseAddFile() {
      this.selectfiles = false
    },
    async renderFileTable() {
      await this.$store.dispatch('employees/getFiles', this.form.employee.ID)
      this.filetablekey++
    },
    async deleteEmployee() {
      let id = this.form.employee.ID
      console.log(id)
      await this.$store.dispatch('employees/DeleteEmployeeByID', id);
      let response = await this.$store.dispatch('employees/getAll');
      console.log(response)
      this.renderEmployeeTable();
      this.setBack();
    },
    storeOrDeleteEmployee() {
      if (this.storeemployee) {
        this.storeEmployee()
        this.storeemployee = false
      }
      if (this.deleteemployee) {
        this.deleteEmployee()
        this.deleteemployee = false
      }
      this.showsnackbar = false
    },
    closeSnackBar() {
      this.showsnackbar = false
      this.disableinput = false
      if (this.changeemployee) {
        this.enabledocumentbutton = true
      }
    }
  },
  watch: {
    'form.employee.SalaryYear': function () {
      this.form.employee.SalaryMonth = this.form.employee.SalaryYear / 12
    },
    'form.employee.SalaryMonth': function () {
      this.form.employee.SalaryYear = this.form.employee.SalaryMonth * 12
    }
  }
}
</script>
<style scoped>
.custom_margin {
  margin-left: 2%;
  margin-right: 2%;
  max-width: 90%;
}

.width300 {
  width: 150px;
}

i {
  margin-right: 10px;
}

svg {
  margin-right: 10px;
}


</style>