import {authHeader} from '../helpers';

export const placeforklientkeysService = {
    addKeysByPatientID,
    getKeysByPatientID,
    deleteById,
    deleteAll
}

function deleteById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/placeforklientkeys/delete/` + id, requestOptions).then(handleResponse);
}

function deleteAll(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/placeforklientkeys/deleteAll/` + id, requestOptions).then(handleResponse);
}

function addKeysByPatientID(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/placeforklientkeys/post`, requestOptions).then(handleResponse);
}

function getKeysByPatientID(patientID) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/placeforklientkeys/get/` + patientID, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }
        return data;
    });
}