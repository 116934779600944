import {roleService} from "../services";

export const roles = {
    namespaced: true,
    state: {
        all: {},
        update: {},
        delete: {},
        loading: true,
    },
    actions: {

        GetAll({commit}) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                roleService.GetAll()
                    .then(
                        roles => {
                            commit('getAllSuccess', roles)
                            resolve(roles)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },
        GetUserRole({commit}, payload) {
            // commit('getAllRequest');
            return new Promise((resolve, reject) => {
                roleService.GetUserRole(payload)
                    .then(
                        roles => {
                            commit('getAllSuccess', payload)
                            resolve(roles)
                        },
                        error => {
                            commit('getAllFailure',  payload)
                            reject(error)
                        }
                    );
            })
        },
        Create({commit}, payload) {
            // alert("module: " + JSON.stringify(payload));
            commit('newRequest');
            return new Promise((resolve, reject) => {
                roleService.Create(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        GetByID({commit}, id) {
            return new Promise((resolve, reject) => {
                roleService.GetByID(id)
                    .then(
                        payload => {
                            commit('getRoleByEmployeeID', payload)
                            resolve(payload)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    )
            })
        },
        DeleteByID({commit}, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                roleService.DeleteByID(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        Change({commit}, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                roleService.Change(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })
        },
    },
    mutations: {
        getRoleByEmployeeID(state, payload){
            state.role = {item: payload}
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            };
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        },
        getAllSuccess(state, roles) {
            state.all = {
                items: roles
            };
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },
    }
}