<template>
    <v-card>
        <v-card-title>
            Wollen Sie {{itemname}} wirklich löschen?
        </v-card-title>
        <v-card-text>
            {{text}}
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="green darken-1"
                text
                @click="CloseDialog()"
            >
            Schließen 
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="DeleteItem()"
            >
            Löschen
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:{
        itemname: undefined,
        text: undefined
    },
    methods:{
        CloseDialog(){
            this.$store.commit('button/changeDeleteButtonModalDialogToFalse')
        },
        DeleteItem(){
            this.$store.commit('button/changeDeleteButtonModalDialogToFalse')
            this.$emit("CloseAndDelete")
        }
    }
}
</script>