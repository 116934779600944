import { employeeService } from '../services';


export const employees = {
    namespaced: true,
    state:{
        all: {},
        newemployee: {},
        newaddress: {},
        taxclasses: {},
        employee: {},
        maritalstatuses: {},
        employmenttypes: {},
        employeeactivities: {},
        employeedelete: {},
        employeebankaccount: {},
        error: {},
        employeechange: {},
        deletedaccount: {},
        deletedaddress: {},
        employeeoverview: {},
        files:{},
        events:{},
        employeestable:{},
        employeeholidaysforcalendar:{},
        employeecalendarsfortimetable:{},
        employeeovertime: 0,
        employeeCalendarsForTimetableByEmployeeID:{},
        employeeCalcutationData: {},
        employeeTimeRecords: {},
        eventsbyemployeeID: {},
        feiertage: {},
        newColorAndNameForEvent: {},
        employeesWithRole:{},
        singleEmployee:{}
    },
    getters:{
        getNewColorAndNameForEvent: state =>{
            return state.newColorAndNameForEvent.item
        },
        getAllFeiertage: state =>{
            return state.feiertage.holidaysDTO
        },
        getEmployeeTimeRecords: state =>{
            return state.employeeTimeRecords.items
        },
        getEmployeeOvertime: state => {
            let n = state.employeeovertime.toFixed(1)
            return n
        },
        getTimeTablesByEmployeeID: state =>{
            return state.employeeCalendarsForTimetableByEmployeeID.items
        },
        getCalulationDataEmployeeID: state => {
            return state.employeeCalcutationData.item
        },
        getAllEvents: state => {
            return state.events.items
        },
        getAllEventsByEmployeeID: state => {
            return state.eventsbyemployeeID
        }
    },
    actions:{
        increment(context){
            context.commit('increment')
        },
        getAll({commit}){
            return new Promise((resolve, reject) => {
                employeeService.getAll().then(
                    employees => {
                        commit('getAllSuccess', employees);
                        resolve(employees)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getAllWithRole({commit}){
            return new Promise((resolve, reject) => {
                employeeService.getAllEmployeesWithRole().then(
                    employees => {
                        commit('getAllSuccess', employees);
                        resolve(employees)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getAllTaxclasses({commit}){
            employeeService.getAllTaxclasses().then(
                taxclasses => {
                    commit('getAllTaxclassSuccess', taxclasses);
                },
                error => {
                    commit('getFailure', error);
                }
            )
        },
        getAllMaritalstatuses({commit}){
            employeeService.getAllMaritalstatuses().then(
                maritalstatuses => {
                    commit('getAllMaritalstatusesSuccess', maritalstatuses);
                },
                error => {
                    commit('getFailure', error);
                }
            )
        },
        getAllEmploymenttypes({commit}){
            employeeService.getAllEmploymenttypes().then(
                employmenttypes => {
                    commit('getAllEmploymenttypesSuccess', employmenttypes);
                },
                error => {
                    commit('getFailure', error);
                }
            )
        },
        getAllEmployeeActivities({commit}){
            employeeService.getAllEmployeeActivities().then(
                employeeactivities => {
                    commit('getAllEmployeeActivitiesSuccess', employeeactivities);
                },
                error => {
                    commit('getFailure', error)
                }
            )
        },
        getEmployeeOverview({commit}, id){
            return new Promise((resolve, reject) => {
                employeeService.getEmployeeOverview(id).then(
                    overview =>{
                        commit('employeeOverviewSuccess', overview),
                        resolve(overview)
                    },
                    error => {
                        commit("getFailure", error);
                        reject(error)
                    }
                )
            })
        },

        getEmployeeByID({commit}, id){
            return new Promise((resolve, reject) => {
                employeeService.getSingleEmployeeByID(id).then(
                    response => {
                        commit('getemployeeSingleSuccess', response),
                            resolve(response)
                    },
                    error => {
                        commit("getSingleEmployeeFailure", error);
                        reject(error)
                    },
                )
            })
        },

        changeEmployeeOverview({commit}, employeeoverview){
            return new Promise((resolve, reject) =>{
                employeeService.changeEmployeeOverview(employeeoverview.ID, employeeoverview).then(
                    overview =>{
                        commit('employeeOverviewSuccess', overview)
                        resolve(overview)
                    },
                    error => {
                        commit("getFailure", error);
                        reject(error);
                    }
                )
            })
        },
        uploadFile({commit}, fileupload){
            return new Promise((resolve, reject) => {
                employeeService.uploadFile(fileupload.ID, fileupload.file).then(
                    response =>{
                        //commit('employeeDataSuccess', response)
                        resolve(response)
                    },
                    error =>{
                        commit("getFailure", error);
                        reject(error)
                    }
                )
            })
        },
        downloadFile({commit}, fileID){
            return new Promise((resolve, reject) => {
                employeeService.downloadFile(fileID).then(
                    response => {
                        resolve(response)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        getFiles({commit}, id){
            return new Promise((resolve, reject) => {
                employeeService.getFiles(id).then(
                    response => {
                        commit('getFilesSuccess', response)
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createEmployeeOverview({commit}, employeeoverview){
            return new Promise((resolve, reject) =>{
                employeeService.createEmployeeOverview(employeeoverview).then(
                    response => {
                        commit('employeeOverviewSuccess', response)
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createEmployeeCalendar({commit}, event){
            return new Promise((resolve, reject) =>{
                employeeService.createEmployeeCalendar(event).then(
                    response =>{
                        commit('employeeCreateCalendarSuccess', response)
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllEmployeeCalendars({commit}){
            return new Promise((resolve, reject) =>{
                employeeService.getAllEmployeeCalendars().then(
                    response =>{
                        commit('employeeGetAllEmployeeCalendarsSuccess', response)
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        changeEmployeeCalender({commit}, event){
            return new Promise((resolve, reject) =>{
                employeeService.changeEmployeeCalender(event).then(
                    response =>{
                        commit('changeEmployeeCalendarSuccess', response)
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        deleteEmployeeCalendar({commit}, event){
            return new Promise((resolve, reject) =>{
                employeeService.deleteEmployeeCalendar(event).then(
                    response =>{
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllEmployeeTables({commit}){
            return new Promise((resolve, reject) => {
                employeeService.getAllEmployeesTable().then(
                    response => {
                        commit('getAllEmployeesTableSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createHolidayRange({commit}, employeeholidayrange){
            return new Promise((resolve, reject) =>{
                employeeService.createHolidayRange(employeeholidayrange).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getHolidayForOneEmployee({commit}, employeeid){
            return new Promise((resolve, reject) =>{
                employeeService.getHolidayForOneEmployee(employeeid).then(
                    response =>{
                        resolve(response)
                        commit('getHolidayForOneEmployeeSuccess', response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createHolidayOneDay({commit}, employeeholidayoneday){
            return new Promise((resolve, reject) =>{
                employeeService.createHolidayOneDay(employeeholidayoneday).then(
                    response =>{
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        deleteEmployeeHoliday({commit}, event){
            return new Promise((resolve, reject) =>{
                employeeService.deleteEmployeeHoliday(event).then(
                    response =>{
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllEmployeeTimeTables({commit}){
            return new Promise((resolve, reject) =>{
                employeeService.getAllEmployeeTimeTables().then(
                    response => {
                        commit('getAllEmployeeTimeTablesSuccess', response)
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createEmployeeTimeTable({commit}, employeetimetable){
            return new Promise((resolve, reject) =>{
                employeeService.createEmployeeTimeTable(employeetimetable).then(
                    response => {
                        resolve(response)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        calculateOvertimeForEachEmployee({commit},employeeid){
            return new Promise((resolve, reject) => {
                employeeService.calculateOvertimeForEachEmployee(employeeid).then(
                    response =>{
                        commit('calculateOvertimeForEachEmployee', response)
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        saveDeparture({commit},sendDeparture){
            return new Promise((resolve, reject) => {
                employeeService.saveDeparture(sendDeparture).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllEmployeeTimeTablesByEmployeeID({commit}, employeeID){
            return new Promise((resolve, reject) => {
                employeeService.getAllEmployeeTimeTablesByEmployeeID(employeeID).then(
                    response =>{
                        commit('getAllEmployeeTimeTablesByEmployeeIDSuccess', response)
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        calculateHolidaysByEmployeeID({commit}, employeeID){
            return new Promise((resolve, reject) => {
                employeeService.calculateHolidaysByEmployeeID(employeeID).then(
                    response =>{
                        console.log(response)
                        commit('calculateHolidaysByEmployeeIDSuccess', response)
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createEmployeeTimeRecord({commit}, employeeTimeRecord){
            return new Promise((resolve, reject) => {
                employeeService.createEmployeeTimeRecord(employeeTimeRecord).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getEmployeeTimeRecordsByEmployeeID({commit}, employeeID){
            return new Promise((resolve, reject) => {
                employeeService.getEmployeeTimeRecordsByEmployeeID(employeeID).then(
                    response =>{
                        commit('getEmployeeTimeRecordsByEmployeeIDSuccess', response)
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        DeleteEmployeeTimeRecordsByID({commit}, timerecordID){
            return new Promise((resolve, reject) => {
                employeeService.DeleteEmployeeTimeRecordsByID(timerecordID).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        DeleteEmployeeByID({commit}, employeeID){
            return new Promise((resolve, reject) => {
                employeeService.DeleteEmployeeByID(employeeID).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        DeleteFile({commit}, DeleteFile){
            return new Promise((resolve, reject) => {
                employeeService.DeleteFile(DeleteFile).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        saveTimeRecordComment({commit}, saveTimeRecordCommentDTO){
            return new Promise((resolve, reject) => {
                employeeService.saveTimeRecordComment(saveTimeRecordCommentDTO).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createEmployeeCalendarWithInputScreen({commit}, createCalendarWithInputScreenDTO){
            return new Promise((resolve, reject) => {
                employeeService.createEmployeeCalendarWithInputScreen(createCalendarWithInputScreenDTO).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllCalendarsByEmployeeID({commit}, employeeID){
            return new Promise((resolve, reject) => {
                employeeService.getAllCalendarsByEmployeeID(employeeID).then(
                    response =>{
                        resolve(response)
                        commit('getAllCalendarsByEmployeeIDSuccess', response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllFeiertageByYearByBundesland({commit}, year){
            return new Promise((resolve, reject) => {
                employeeService.getAllFeiertageByYearByBundesland(year).then(
                    response =>{
                        resolve(response)
                        commit('getAllFeiertageByYearByBundeslandSuccess', response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        changeEmployeeIDInCalendarTable({commit}, changeEmployeeIdInCalendarDTO){
            return new Promise((resolve, reject) => {
                employeeService.changeEmployeeIDInCalendarTable(changeEmployeeIdInCalendarDTO).then(
                    response =>{
                        resolve(response)
                        commit('changeEmployeeIDInCalendarTable', response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        copyCalendar({commit}, copyCalendarDTO){
            return new Promise((resolve, reject) => {
                employeeService.copyCalendar(copyCalendarDTO).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },

    },
    mutations:{
        changeEmployeeIDInCalendarTable(state, changeEmployeeIdInCalendarAnswerDTO){
            state.newColorAndNameForEvent = {item: changeEmployeeIdInCalendarAnswerDTO}
        },
        getAllFeiertageByYearByBundeslandSuccess(state, holidaysDTO){
            state.feiertage = {holidaysDTO}
        },
        popEventsByEmployeeID(state, employeeID){
            let index = -1
            let length = state.eventsbyemployeeID.length
            for(let counteventsbyemployeeID = 0; counteventsbyemployeeID < length; counteventsbyemployeeID++){
                for(let i = 0; i < state.eventsbyemployeeID.length; i++){
                    if(state.eventsbyemployeeID[i].EmployeeID == employeeID){
                        index = state.eventsbyemployeeID.indexOf(state.eventsbyemployeeID[i])
                        break
                    }
                }
                if(index == -1){
                    break;
                }
                state.eventsbyemployeeID.splice(index, 1)
                index = -1
            }
        },
        emptyEventsByEmployeeID(state){
            state.eventsbyemployeeID = {}
        },
        getAllCalendarsByEmployeeIDSuccess(state, payload){
            if(Object.keys(state.eventsbyemployeeID).length === 0){
                state.eventsbyemployeeID = payload
            } else{
                console.log(payload)
                console.log(state.eventsbyemployeeID)
                payload.forEach(item => {
                    state.eventsbyemployeeID.push(item)
                })
            }
        },
        getEmployeeTimeRecordsByEmployeeIDSuccess(state, payload){
            state.employeeTimeRecords = {items: payload}
        },
        calculateHolidaysByEmployeeIDSuccess(state, payload){
            state.employeeCalcutationData = {item: payload}
        },
        getAllEmployeeTimeTablesByEmployeeIDSuccess(state, payload){
            state.employeeCalendarsForTimetableByEmployeeID = {items: payload}
        },
        calculateOvertimeForEachEmployee(state, overtime){
            state.employeeovertime = Number(overtime)
        },
        getAllEmployeeTimeTablesSuccess(state, timetables){
            state.employeecalendarsfortimetable = {items: timetables}
        },
        getHolidayForOneEmployeeSuccess(state, holidays){
            state.employeeholidaysforcalendar = {items: holidays}
        },
        changeEmployeeCalendarSuccess(state, event){
            let index = state.events.items.findIndex(element => {
                return event.ID == element.ID && event.eventtype == element.eventtype
            })
            state.events.items.splice(index, 1)
            state.events.items.push(event)
        },
        getAllEmployeesTableSuccess(state, employeestable){
            state.employeestable = {items: employeestable}
        },
        employeeGetAllEmployeeCalendarsSuccess(state, events){
            state.events = {items: events}
        },
        employeeCreateCalendarSuccess(state, event){
            state.events.items.push(event)
        },
        getFilesSuccess(state, file){
            state.files = {items: file}
        },
        employeeOverviewSuccess(state, overview){
            state.employeeoverview = {item: overview}
        },
        getemployeeSingleSuccess(state, singleEmployee){
            state.singleEmployee = {items: singleEmployee}
        },
        getFailure(state, error){
            state.error = {item: error}
        },
        getSingleEmployeeFailure(state, error){
            state.error = {item: error}
        },
        getDeletedEmployeeSuccess(state, deleted){
            state.employeedelete = {item: deleted}
        },
        getAllSuccess(state, employees) {
            state.all = {items: employees };
        },
        getAllTaxclassSuccess(state, taxclasses){
            state.taxclasses = {items: taxclasses}
        },
        getAllMaritalstatusesSuccess(state, maritalstatuses){
            state.maritalstatuses = {items: maritalstatuses}
            let maritalstatus = {
                ID: 100,
                MaritalStatus: "keine Auswahl"
            }
            state.maritalstatuses.items.unshift(maritalstatus)
        },
        getAllEmploymenttypesSuccess(state, employmenttypes){
            state.employmenttypes = {items: employmenttypes}
        },
        getAllEmployeeActivitiesSuccess(state, employeeactivities){
            state.employeeactivities = {items: employeeactivities}
        },
    },
}