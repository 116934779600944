<template>
  <v-card color="primary" dark style="padding:10px">
    <v-alert
        v-model="alert"
        dismissible
        type="error"
        color="red"
    >
      {{ alertmessage }}
    </v-alert>
    <v-chip class="mt-4 ml-4"
            :style="{backgroundColor: event.color}"
    >
      <v-icon left>
        mdi-account-circle
      </v-icon>
      <v-btn
          :color="event.color"
          @click="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular' ? OpenEmployeeTableModalDialog() : ''"
      >
        {{ event.EmployeeInformation === "" ? event.name : event.EmployeeInformation }}
      </v-btn>
    </v-chip>
    <v-card-text>
      <a class="titleStyle"> <b>Uhrzeit</b></a>
      <row :gutter="12">
        <column :md="3">
          <DatePicker
              :date="date"
              :modal="modaldatepicker"
              @SendDate="GetDate"
          />
        </column>
        <column :md="3">
          <TimePicker
              label="Start"
              :time="timestart"
              :modal="modaltimestart"
              @SendTime="GetTimeStart"
          />
        </column>
        <column :md="3">
          <TimePicker
              label="Ende"
              :time="timeend"
              :modal="modaltimeend"
              @SendTime="GetTimeEnd"
          />
        </column>
      </row>
      <a class="titleStyle"> <b>Klient</b></a>
      <PatientInfo
          :key="patientinfokey"
          :patientid="event.PatientID"
      />
      <a class="titleStyle"> <b>Leistung</b></a>
      <PatientServiceArticleInfo
          :calendarEntryPresent="true"
          class="mb-2"
          :key="articleinfokey"
          :calendarID="event.ID"
          @RenderComponent="RenderPatientArticleInfo()"
      />
      <row :gutter="12">
        <column>
          <v-btn
              color="secondary"
              elevation="2"
              class="mt-2"
              @click="ShowPatientList()"
          >
            Klient hinzufügen
          </v-btn>
        </column>
        <column :xs="12" v-if="showpatientlist">
          <PatientList
              @sendPatientID="GetPatient"
          />
        </column>
      </row>
      <row :gutter="12">
        <column>
          <v-btn
              color="secondary"
              elevation="2"
              @click="ShowServices()"
          >
            Artikel hinzufügen
          </v-btn>
        </column>
        <column :xs="12" v-if="showservices">
          <ServiceList
              @sendPatientServiceArticleID="GetPatientServiceArticleID"
              :patientID="event.PatientID"
          />
        </column>
      </row>

    </v-card-text>

    <v-card-actions>
      <row :gutter="12">
        <column :ld="1">
          <v-btn
              color="info"
              class="ml-2 mb-2"
              @click="CloseAndSaveDialog()">
            Speichern und Schließen
          </v-btn>

          <v-btn
              color="info"
              class="ml-2 mb-2"
              @click="DeleteEvent()">
            Löschen
          </v-btn>
          <v-btn
              outlined
              dark
              class="ml-2 mb-2"
              @click="Cancel()">
            Abbrechen
          </v-btn>
        </column>
      </row>
    </v-card-actions>
    <v-dialog
        v-model="employeetablemodaldialog"
        persistent max-width="1100px">
      <EmployeeTableDialog
          @sendEmployeeID="ReceiveEmployeeID"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import PatientList from "./PatientList"
import PatientInfo from "./PatientInfo"
import ServiceList from "./ServiceList"
import PatientServiceArticleInfo from "./PatientServiceArticleInfo"
import DatePicker from "../utils/DatePicker.vue"
import TimePicker from "../utils/TimePicker.vue"
import EmployeeTableDialog from './EmployeeTableDialog.vue'
import moment from "moment";

export default {
  components: {
    PatientList,
    PatientInfo,
    ServiceList,
    PatientServiceArticleInfo,
    DatePicker,
    TimePicker,
    EmployeeTableDialog
  },
  props: {
    event: {},
  },
  created() {
    moment.locale("de")
  },
  mounted() {
    this.$root.$refs.EmployeeRosterEventDialog = this;
  },
  data() {
    return {
      showpatientlist: false,
      showservices: false,
      patientinfokey: 0,
      articleinfokey: 0,
      datetosend: undefined,
      modaldatepicker: false,
      timestarttosend: undefined,
      timeendtosend: undefined,
      modaltimestart: false,
      modaltimeend: false,
      alert: false,
      alertmessage: "",
      newDate: undefined,
      newStartTime: undefined,
      newEndTime: undefined,
      tmpDate: this.date,
    }
  },
  computed: {
    from() {
      let date = new Date(this.event.start)
      return moment(date).format("HH:mm")
    },
    to() {
      let date = new Date(this.event.end)
      return moment(date).format("HH:mm")
    },
    timestart() {
      let date = new Date(this.event.start);
      if (this.timestarttosend !== undefined) {
        return this.timestarttosend;
      } else {
        return moment(date).format("HH:mm")
      }
    },
    timeend() {
      let date = new Date(this.event.end);
      if (this.timeendtosend !== undefined) {
        return this.timeendtosend;
      } else {
        return moment(date).format("HH:mm")
      }
    },
    date() {
      if (this.tmpDate !== undefined) {
        return new Date(this.tmpDate)
      } else {
        return new Date(this.event.start).toISOString().substr(0, 10);
      }
    },
    employeetablemodaldialog() {
      return this.$store.getters['eventdialog/getemployeetablemodaldialog']
    },
    employees() {
      return this.$store.state.employees.all.items;
    },
  },
  methods: {
    setNewDate(date) {
      this.tmpDate = moment(date).format("DD.MM.YYYY");
    },
    Cancel() {
      this.$store.commit('eventdialog/changeEventModalDialogToFalse')
    },
    async ReceiveEmployeeID(employeeID) {
      console.log(employeeID)
      let changeEmployeeIdInCalendarDTO = {
        CalendarID: this.event.ID,
        EmployeeID: employeeID
      }
      let response = await this.$store.dispatch('employees/changeEmployeeIDInCalendarTable', changeEmployeeIdInCalendarDTO)
      console.log(response)

      //this.event.EmployeeInformation = this.$store.getters['employees/getNewColorAndNameForEvent'].Fullname
      this.event.EmployeeInformation = this.$store.getters['employees/getNewColorAndNameForEvent'].Fullname
      this.event.color = this.$store.getters['employees/getNewColorAndNameForEvent'].Color
      console.log(this.event.EmployeeInformation)
      //hier weitermachen
    },
    OpenEmployeeTableModalDialog() {
      this.$store.commit('eventdialog/changeEmployeeTableModalDialogToTrue')
    },
    async CloseAndSaveDialog() {
      //console.log(this.event.TraveledKilometers)
      let timestampstart = this.GetTimestampStart()
      let timestandend = this.GetTimestampEnd()
      if (timestampstart > timestandend) {
        this.SetAlertMessage("Die Startzeit muss vor der Endzeit des Termins liegen")
        return
      }
      let event = {
        ID: this.event.ID,
        start: timestampstart,
        end: timestandend,
        EmployeeID: this.event.EmployeeID,
        Personalnumber: this.event.Personalnumber,
        color: this.event.color,
        timed: this.event.timed,
        name: this.event.name,
        eventtype: this.event.eventtype,
        PatientID: this.event.PatientID,
      }
      await this.$store.dispatch('employees/changeEmployeeCalender', event)
      this.$store.commit('eventdialog/changeEventModalDialogToFalse')
      this.$emit("RefreshCalendar")
    },
    DeleteEvent() {
      if (confirm("Möchten Sie den Eintrag wirklich löschen?")) {
        this.$store.commit('eventdialog/changeEventModalDialogToFalse');
        this.$emit("CloseAndDelete");
      } else {
        //doNothing
      }
    },
    async ShowPatientList() {
      if (this.showpatientlist == false) {
        await this.$store.dispatch("patients/getAll")
        this.showpatientlist = true
      } else {
        this.showpatientlist = false
      }
      this.date = this.tmpDate;
    },
    async GetPatient(patientid) {
      this.event.PatientID = patientid
      this.RenderPatientInfo()
      this.DeleteServiceArticlesForCalendarEntry()
    },
    async DeleteServiceArticlesForCalendarEntry() {
      await this.$store.dispatch('serviceArticles/deleteCalendarToPatientServiceArticleCalendarID', this.event.ID)
      this.RenderPatientArticleInfo()
    },
    RenderPatientInfo() {
      this.patientinfokey = this.patientinfokey + 1
    },
    async ShowServices() {
      console.log("test")
      if (this.showservices == false) {
        //let response = await this.$store.dispatch("patientServiceArticles/getArticleByPatientID", this.event.PatientID)
        //console.log(response)
        this.showservices = true
      } else {
        this.showservices = false
      }
    },
    async GetPatientServiceArticleID(articleID) {
      console.log(articleID)
      this.event.PatientServiceArticleID = articleID
      await this.SetArticleToCalendarEntry(articleID)
      this.RenderPatientArticleInfo()
    },
    async SetArticleToCalendarEntry(articleID) {
      let articleToCalendar = {
        CalendarID: this.event.ID,
        PatientServiceArticleID: articleID
      }
      await this.$store.dispatch("serviceArticles/setArticleToCalendarEntry", articleToCalendar)
    },
    RenderPatientArticleInfo() {
      this.articleinfokey = this.articleinfokey + 1
    },
    GetTimestamp(time) {
      let year = this.GetYear()
      let month = this.GetMonth()
      let day = this.GetDay()
      let hour = this.GetHour(time)
      let minute = this.GetMinute(time)
      let date = new Date(year, month - 1, day, hour, minute)
      console.log(date)
      return date.getTime()
    },
    GetDate(date) {
      console.log("date: " + date)
      this.datetosend = date
    },
    GetTimeStart(time) {
      this.timestarttosend = time
    },
    GetTimeEnd(time) {
      this.timeendtosend = time
    },
    GetHour(time) {
      let split = time.split(':')
      let hour = split[0]
      return this.GetNumberWithoutZero(hour)
    },
    GetMinute(time) {
      let split = time.split(':')
      let minute = split[1]
      return this.GetNumberWithoutZero(minute)
    },
    GetYear() {
      let split = this.datetosend.split('-')
      let year = Number(split[0])
      return year
    },
    GetMonth() {
      let split = this.datetosend.split('-')
      let month = split[1]
      return this.GetNumberWithoutZero(month)
    },
    GetDay() {
      let split = this.datetosend.split('-')
      let day = split[2]
      return this.GetNumberWithoutZero(day)
    },
    GetNumberWithoutZero(number) {
      if (number[0] == '0') {
        let numberwithoutzero = Number(number[1])
        return numberwithoutzero
      } else {
        let numberwithoutzero = Number(number)
        return numberwithoutzero
      }
    },
    GetTimestampStart() {
      if (this.timestarttosend == undefined) {
        console.log("undefined: " + this.timestarttosend)
        console.log("this.datetosend: " + this.datetosend)
        this.timestarttosend = this.from
      }
      if (this.datetosend == undefined) {
        this.datetosend = this.date
      }
      console.log(this.timestarttosend)
      let timestampstart = this.GetTimestamp(this.timestarttosend)
      console.log("timestampstart: " + timestampstart)
      return timestampstart
    },
    GetTimestampEnd() {
      if (this.timeendtosend == undefined) {
        console.log("undefined: " + this.timeendtosend)
        console.log("this.datetosend: " + this.datetosend)
        this.timeendtosend = this.to
      }
      if (this.datetosend == undefined) {
        this.datetosend = this.date
      }
      console.log(this.timeendtosend)
      let timestampend = this.GetTimestamp(this.timeendtosend)
      console.log("timestampend: " + timestampend)
      return timestampend
    },
    SetAlertMessage(message) {
      this.alert = true
      this.alertmessage = message
    },
  }
}
</script>

<style scoped>

.titleStyle {
  color: white;
  font-weight: bold;
}
</style>