<template>
  <v-container fluid>
    <v-card dark color="primary" style="padding:18px; height:auto;">
      <v-form ref="holidayform">
        <row :gutter="12">
          <column :lg="4">
            <v-select
                class="mb-6"
                label="Mitarbeiter"
                :items="employees"
                item-value="ID"
                dense
                v-model="employeeID"
                ref="selectemployees"
                hint="Wählen sie einen Mitarbeiter"
                persistent-hint
                @change="SetBackFormularAndGetHoliday()"
            >
              <template v-slot:selection="{item}">
                {{ item.Personalnumber }} {{ item.Firstname }} {{ item.Lastname }}
              </template>
              <template v-slot:item="{item}">
                {{ item.Personalnumber }} {{ item.Firstname }} {{ item.Lastname }}
              </template>
            </v-select>
          </column>
          <column :lg="3">
            <v-dialog
                ref="dialogpickholidayrange"
                v-model="modalforpickholidayrange"
                :return-value.sync="daterange"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :disabled="!employeeID"
                              v-model="daterangetext"
                              label="Urlaubszeitraum oder -tag wählen"
                              prepend-icon="mdi-calendar"
                              readonly
                              dense
                              v-bind="attrs"
                              v-on="on"
                              ref="start"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="daterange"
                  scrollable
                  :first-day-of-week="1"
                  locale="de"
                  range
                  :events="ShowHolidaysTaken"
                  :allowed-dates="AllowedDates"
                  :selected-items-text="daterangetext"
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="SetBackDateRange()"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="ShowHolidaysRangeInTextField(daterange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </column>
          <column :lg="3" v-if="daterange.length == 1">
            <v-select
                v-model="fullorhalfday"
                :items="fullorhalf"
                label="ganzer oder halber Tag"
                dense
            ></v-select>
          </column>
          <column :lg="3">
            <v-select
                v-model="timetype"
                :items="timetypes"
                label="Zeitart"
                dense
            ></v-select>
          </column>
        </row>
        <row :gutter="12">
          <column>
            <v-btn
                small
                color="info"
                elevation="2"
                @click="SaveHoliday()"
                :disabled="disabled"
            >
              <i class="fas fa-save"></i>
              speichern
            </v-btn>
            <v-btn
                class="ml-2"
                small
                color="info"
                elevation="2"
                @click="SetBackHolidayFormular()"
            >
              <i class="fas fa-ban"></i>
              abbrechen
            </v-btn>
          </column>
        </row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    employees: {},
    data: {},
    disabled: Boolean
  },
  data() {
    return {
      modalforpickholidayrange: false,
      // daterange: ["", ""],
      daterange: [],
      holidaystartasdate: "",
      holidayendasdate: "",
      timestampzero: undefined,
      timestampone: undefined,
      employeeID: undefined,
      holidayrange: {
        employeeID: undefined,
        startasunixtimestampmilli: undefined,
        endasunixtimestampmilli: undefined,
        halfday: false,
        holidayorflextimereduction: false,  //false means holiday true means flextimereduction
        sick: false,
        freeday: false
      },
      timetypes: ["Urlaub", "Gleitzeitabbau", "Krank", "freier Tag"],
      timetype: undefined,
      fullorhalf: ["halber Tag", "ganzer Tag"],
      fullorhalfday: "ganzer Tag"
    }
  },
  computed: {
    daterangetext() {
      // if (this.daterange[0] == "") {
      //   return
      // }
      // if (this.daterange.length == 1) {
      //   return this.daterange[0]
      // }
      // return this.holidaystartasdate + " - " + this.holidayendasdate
      return this.daterange.join(' - ');
    },
    holidaysforcalendar() {
      return this.$store.state.employees.employeeholidaysforcalendar;
    }
  },
  methods: {
    ShowHolidaysRangeInTextField(daterange) {
      this.$refs.dialogpickholidayrange.save(daterange)
      this.SetTimestamps()
      this.SetDataHolidayRangeStartEndAsTimeStampMilli()
      this.SetHolidayStartAndEndAsDate()
      this.modalforpickholidayrange = false
    },
    SetBackDateRange() {
      this.modalforpickholidayrange = false
    },
    ShowHolidaysTaken(date) {
      let timestamp = new Date(date).getTime()
      let holiday = this.$store.state.employees.employeeholidaysforcalendar.items.find(element => element.Date == timestamp && !element.Holidayorflextimereduction)
      let flextimereduction = this.$store.state.employees.employeeholidaysforcalendar.items.find(element => element.Date == timestamp && element.Holidayorflextimereduction)
      if (holiday != undefined) {
        return 'red'
      }
      if (flextimereduction != undefined) {
        return 'blue'
      }
    },
    AllowedDates(date) {
      /*let now = new Date()
      const datearray = date.split('-')
      let calendardate = new Date(datearray[0], datearray[1]-1,datearray[2])
      if(now <= calendardate){
          return true
      }else{
          return false
      }*/
      console.log(date)
      return true
    },
    SetTimestamps() {
      this.timestampzero = new Date(this.daterange[0]).getTime()
      this.timestampone = new Date(this.daterange[1]).getTime()
      console.log("timestampzero: " + this.timestampzero)
      console.log("timestampone: " + this.timestampone)
    },
    SetDataHolidayRangeStartEndAsTimeStampMilli() {
      if (this.timestampzero < this.timestampone) {
        this.holidayrange.startasunixtimestampmilli = this.timestampzero
        this.holidayrange.endasunixtimestampmilli = this.timestampone
      } else if (this.timestampzero > this.timestampone) {
        this.holidayrange.startasunixtimestampmilli = this.timestampone
        this.holidayrange.endasunixtimestampmilli = this.timestampzero
      } else {
        this.holidayrange.startasunixtimestampmilli = this.timestampzero
        this.holidayrange.endasunixtimestampmilli = this.timestampzero
      }
    },
    SetHolidayStartAndEndAsDate() {
      if (this.timestampzero < this.timestampone) {
        this.holidaystartasdate = this.daterange[0]
        this.holidayendasdate = this.daterange[1]
      } else {
        this.holidaystartasdate = this.daterange[1]
        this.holidayendasdate = this.daterange[0]
      }
    },
    async SaveHoliday() {
      if (this.daterange.length == 1) {
        console.log("Ein Tag")
        await this.SaveHolidayOneDay()
      }
      if (this.daterange.length == 2) {
        console.log("Mehrere Tage")
        await this.SaveHolidayRange()
      }
      this.RenderCalendar()
    },
    async SaveHolidayRange() {
      this.holidayrange.employeeID = this.employeeID
      this.holidayrange.halfday = false
      if (this.timetype == "Gleitzeitabbau" || this.timetype == "Urlaub") {
        if (this.timetype == "Gleitzeitabbau") {
          this.holidayrange.holidayorflextimereduction = true
        }
        console.log(JSON.stringify(this.holidayrange))
      }
      if (this.timetype == "Krank") {
        this.holidayrange.sick = true
        console.log(JSON.stringify(this.holidayrange))
      }
      if (this.timetype == "freier Tag") {
        this.holidayrange.freeday = true
      }
      try {
        let response = await this.$store.dispatch('employees/createHolidayRange', this.holidayrange)
        console.log(response)
      } catch (err) {
        console.log('fetch failed', err)
        this.SetAlertMessage(err)
      }

    },
    async SaveHolidayOneDay() {
      console.log("Ausgabe: " + this.employeeID)
      let holidayoneday = {
        employeeID: this.employeeID,
        startasunixtimestampmilli: this.timestampzero,
        halfday: false,
        holidayorflextimereduction: false,
        sick: false,
        freeday: false
      }
      if (this.fullorhalfday == "halber Tag") {
        holidayoneday.halfday = true
      }
      if (this.timetype == "Gleitzeitabbau" || this.timetype == "Urlaub") {
        if (this.timetype == "Gleitzeitabbau") {
          holidayoneday.holidayorflextimereduction = true
        }

      }
      if (this.timetype == "Krank") {
        holidayoneday.sick = true
      }
      if (this.timetype == "freier Tag") {
        holidayoneday.freeday = true
      }
      console.log(JSON.stringify(holidayoneday))
      try {
        //let response = await this.$store.dispatch('employees/createHolidayOneDay', holidayoneday)
        await this.$store.dispatch('employees/createHolidayOneDay', holidayoneday)
      } catch (err) {
        console.log('fetch failed', err)
        this.SetAlertMessage(err)
      }
    },
    GetHolidayForOneEmployee() {
      this.$store.dispatch('employees/getHolidayForOneEmployee', this.employeeID)
    },
    SetBackHolidayFormular() {
      this.daterange = ["", ""]
      //this.employeeID = undefined
      this.timetype = undefined
      //this.$refs.holidayform.reset()
    },
    SetAlertMessage(errormessage) {
      this.$emit('SetAlertMessage', errormessage)
    },
    SetBackFormularAndGetHoliday() {
      this.GetHolidayForOneEmployee()
      this.SetBackHolidayFormular()
    },
    RenderCalendar() {
      this.$emit('RenderCalendar')
    }
  }
}
</script>

<style scoped>
i {
  margin-right: 10px;
}

svg {
  margin-right: 10px;

}
</style>