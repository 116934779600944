<template>
 <v-row>
   <v-col cols="12">
  <v-row>
    <v-col cols="12">
      <h4><i class="fas fa-calendar-alt"></i>Für dieses Fahrzeug anstehende Termine</h4>
      <div class="dataTables_wrapper">
      <v-data-table
        :headers="headers"
        :items="appointments"
        item-key="id"
        class="elevation-1 hdl"
        :loading="isLoading"
        no-data-text="Keine Termine"
        loading-text="Wird geladen"
        locale="de-de"
        @click:row="rowClick"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="DeleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
<!--          Keine Termine-->
<!--          <v-btn color="primary" @click="getData"> Neu laden </v-btn>-->
        </template>
      </v-data-table>
    </div>
    <v-btn
            @click="dialog = true"
            color="primary"
            elevation="2"
            class="mt-3"
          >
            <i class="fas fa-plus"></i> Neuer Termin
    </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <v-dialog v-model="dialog" persistent max-width="960px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Neuer Termin</span
              >
            </v-card-title>
            <v-card-text>
              <template>
                <v-container>
                  <v-row
                  >
                    <v-col cols="3">
<v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Datum"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            abbrechen
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
                    </v-col>
                    <v-col cols="3">
                       <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            label="Uhrzeit"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time"
          full-width
          @click:minute="$refs.menu2.save(time)"
        ></v-time-picker>
      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Termin"
                        v-model="termin"
                        hint=""
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="NewAppointment()"
                :disabled='(date == null || time == null || termin == "")'
                v-if="!isChangeAppointment"
              >
                Termin hinzufügen
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="ChangeAppointment()"
                :disabled='(date == null || time == null || termin == "")'
                v-else
              >
                Termin speichern
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-col>
  </v-row>
  </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      headers: [
        { text: "Datum & Uhrzeit", value: "Date" },
        { text: "Termin", value: "Name" },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialog: false,
      date: null,
      time: null,
      menu2: false,
      menu: false,
      termin: "",
      isChangeAppointment: false,
      selectedAppointment: {}
    };
  },
  computed: {
    appointments() {
      let appointments = this.$store.state.deviceAppointments.selected.items;
      if (appointments != undefined) {
        appointments.forEach(a => {
          a.Date = moment(a.Date).utc().format("DD.MM.YYYY - hh:mm");
        });
      }
      return appointments;
    },
    id() {
      let tmpID = this.$route.params.id;
      return tmpID;
    },
  },
  mounted() {
    this.$root.$on("reload_specific_car_appointments_list", () => {
      this.GetAll();
    });
  },
  created() {
    this.GetAll();
  },
  methods: {
    rowClick(item) {
      this.isChangeAppointment = true;
      this.selectedAppointment = item.ID;
      this.termin = item.Name

      let dString = item.Date.split('T')[0];
      let valueString = dString.split('-');
      let dateString = valueString[0]
      let tString = valueString[1]
      console.log(dateString);

      let parts = dateString.split('.');
      let dateConvert = new Date(
          parseInt(parts[2]),
          parseInt(parts[1])-1,
          parseInt(parts[0])
      );

      this.date = moment(dateConvert).format("YYYY-MM-DD");
      this.time = tString;
      this.dialog = true;
    },
    async DeleteItem(item) {
        if (
          confirm("Möchten Sie den Termin " + item.Name + " wirklich löschen?")
        ) {
          this.$store.dispatch("deviceAppointments/delete", item.ID).then(() => {
              this.$root.$emit("reload_car_appointments_list");
              this.$root.$emit("reload_specific_car_appointments_list");
          });
        }
    },
    async GetAll() {
      this.$store.dispatch("deviceAppointments/getAllAutomotivesByID", this.id);
      this.dialog = false;
      this.date = null;
      this.time = null;
      this.termin = "";
      this.isChangeAppointment = false;
      this.selectedAppointment = null;
    },
    async NewAppointment() {
        let d = moment(this.date).format("YYYY-MM-DD");
        let dString = d + "T" + this.time + ":00Z";
        console.log(dString);

        let app = {
          AutomotiveID: parseInt(this.id),
          Name: this.termin,
          Date: dString
        };

        this.$store.dispatch("deviceAppointments/newAppointment", app).then((response) => {
          console.log(response);
          
          this.$root.$emit("reload_car_appointments_list");
          this.$root.$emit("reload_specific_car_appointments_list");
        });
    },
    async ChangeAppointment() {
        let d = moment(this.date).format("YYYY-MM-DD");
        let dString = d + "T" + this.time + ":00Z";
        console.log(dString);

        let selectedID = parseInt(this.selectedAppointment);
        let app = {
          ID: selectedID,
          AutomotiveID: parseInt(this.id),
          Name: this.termin,
          Date: dString
        };

        this.$store.dispatch("deviceAppointments/change", {app, selectedID} ).then((response) => {
          console.log(response);
          this.$root.$emit("reload_car_appointments_list");
          this.$root.$emit("reload_specific_car_appointments_list");
        });
    }
  },
};
</script>

<style></style>
