<template>
  <v-col style="position: relative">

    <v-simple-table v-if="patientfiles.length" ref="docstable">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Dateiname
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in patientfiles"
            :key="item.ID">
          <td>
            {{ item.Filename }}
            <v-btn
                elevation="2"
                small
                style="float: right; color: #951b81"
                class="ml-1"
                @click="DeleteFile(item.Filename,item.ID)">
              <i class="fas fa-trash-alt"></i>
            </v-btn>
            <v-btn
                style="float: right; color: #951b81 "
                elevation="2"
                class="ml-3"
                small
                @click="DownloadFile(item.Filename, item.ID)">
              <i class="fas fa-download"></i>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>{{ noDocs }}</div>
    <v-overlay
        :absolute="true"
        :value="overlay">
      <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="15"
          :value="percentage"
          color="primary">
        {{ percentage.toFixed(1) }}
      </v-progress-circular>
    </v-overlay>
  </v-col>
</template>


<script>
export default {
  props: {
    patientid: Number,
    patientfiles: {}
  },
  data() {
    return {
      overlay: false,
      noDocs: "Keine Dokumente hinterlegt.",
    }
  },
  computed: {
    loadedbytes() {
      return this.$store.getters["loading/getloadedbytes"]
    },
    totalbytes() {
      return this.$store.getters['loading/gettotalbytes']
    },
    percentage() {
      return this.loadedbytes / this.totalbytes
    }
  },
  mounted() {
    this.$root.$refs.KlientTable = this;
  },
  methods: {
    interval() {
      this.$emit('renderdocs')
      setTimeout(() => this.$root.$refs.PatientInputMask.renderFileTable(), 300);
    },

    async DownloadFile(filename, fileID) {
      if (confirm('Möchten Sie das Dokument : ' + filename + ' herunterladen?')) {
        console.log("fileID: " + fileID);
        this.overlay = true
        let fileinformation = await this.$store.dispatch('patients/downloadFile', fileID)
        // alert(JSON.stringify(fileinformation));
        this.overlay = false
        var FileSaver = require('file-saver');
       FileSaver.saveAs(fileinformation.fileblob, filename); // OK
       //   FileSaver.saveAs(fileinformation.fileblob, fileinformation.filename); // Not working! Shows different charset in browser download option
      } else {
        // do nothing
      }
    },

    async DeleteFile(filename, id) {
      if (confirm('Möchten Sie das Dokument: ' + filename + ' wirklich löschen?')) {
        let DeleteFile = {
          ID: id,
          PatientID: this.patientid,
          Filename: filename
        };
        let response = await this.$store.dispatch('patients/DeleteFile', DeleteFile);
        console.log("Deleting File " + response);
        this.interval();
      } else {
        // do nothing
      }
    },
  },
}
</script>