<template>
  <v-container fluid>
    <h2>Benutzerverwaltung</h2>
    <v-row>
      <v-col cols="6">
        <EmployeeTable
            class="ml-4"
            :employees="employees"
            :users="users"
            @SendRole="roleExist"
        />
      </v-col>
      <v-col cols="4">
        <div style="position: sticky; top: 75px">
          <h3>Rechte</h3>
          <base-card>
            <h3>
              PNR: {{ user.id }}
              <v-chip class="ml-3" large label v-if="user.id != undefined" :color="user.color">
                {{ user.firstname }} {{ user.lastname }}
              </v-chip>
            </h3>
            <div>
              <v-col cols="8">
                Rechte:
                <v-select :disabled="user.id == undefined"
                          :value="user.role"
                          :items="roles"
                          outlined
                          placeholder="Wählen Sie ein Recht aus:"
                          @change="SetRole"
                ></v-select>
                <v-btn color="primary" @click="saveRole" :disabled="user.employeeID == undefined">Speichern</v-btn>
                <v-btn color="secondary" @click="deleteRole(user.employeeID)" :disabled="user.id == undefined"
                       class="ml-1">Rechte
                  löschen
                </v-btn>
              </v-col>
            </div>
          </base-card>
        </div>
      </v-col>
    </v-row>
    <template>
      <div class="text-center ma-2">
        <v-snackbar
            color="success"
            v-model="snackbar"
            :timeout="timeout">
          <v-icon>mdi-check-circle</v-icon>
          {{ snackbartext }}
        </v-snackbar>
      </div>
<!--      BOOM : {{users}}-->
    </template>
  </v-container>
</template>

<script>
import BaseCard from "../../components/Base/BaseCard";
import EmployeeTable from "../../components/settings/EmployeeSettingsTable";

export default {
  name: "UserSettings",
  components: {EmployeeTable, BaseCard},
  data() {
    return {
      roles: ['Admin', 'Regular', 'Limited', 'ReadOnly'],
      user: {
        username: undefined,
        id: undefined,
        employeeID: undefined,
        firstname: undefined,
        lastname: undefined,
        color: undefined,
        role: undefined
      },
      // role: undefined,
      snackbar: false,
      snackbartext: '',
      timeout: 1500,
      roleExisting: false,

    }
  },
  methods: {

    roleExist(role) {
      this.roleExisting = role !== undefined;
    },
    SetRole(role) {
      this.user.role = role
    },
    ShowSnackbar(msg) {
      this.snackbartext = msg
      this.snackbar = true
    },
    updateRole() {
      let updateRole = {
        EmployeeID: this.user.employeeID,
        Role: this.user.role,
      }
      this.$store.dispatch("roles/Change", updateRole)
      this.ShowSnackbar("Nutzerrechte für " + this.user.firstname + " " + this.user.lastname + " gespeichert.");
      location.reload();
    },
    saveRole() {
      if (confirm("Möchten Sie die Nutzerrechte für " + this.user.firstname + " " + this.user.lastname + " ändern/speichern?")) {
        let newRole = {
         EmployeeID: this.user.employeeID,
          role: this.user.role,
        }
        if (this.roleExisting) {
          this.updateRole()
          return
        }
        this.$store.dispatch("roles/Create", newRole)
        this.roleExisting = false
        this.ShowSnackbar("Nutzerrechte für " + this.user.firstname + " " + this.user.lastname + " gespeichert.");
        location.reload();
      } else {
        // do nothing
      }
    },
    deleteRole(id) {
      if (confirm("Möchten Sie die Nutzerrechte für " + this.user.firstname + " " + this.user.lastname + " wirklich löschen?")) {
        this.$store.dispatch("roles/DeleteByID", id)
        this.ShowSnackbar("Nutzerrechte von " + this.user.firstname + " " + this.user.lastname + " gelöscht.");
        location.reload();
      } else {
        // do nothing
      }
    },
    SetUser(id, employeeid, name, vorname, color, role) {
      this.user.id = id;
      this.user.employeeID = employeeid;
      this.user.firstname = name;
      this.user.lastname = vorname;
      this.user.color = color;
      this.user.role = role;
    }
  },
  computed: {
    employees() {
      return this.$store.state.employees.all.items;
    },
    users() {
      return this.$store.state.users.all.items;
    }
  },
  created() {
    this.$store.dispatch('employees/getAllWithRole');
    this.$store.dispatch('users/getAllUsersWithRoles');
  },
  mounted() {
    this.$root.$refs.UserSettings = this;
    this.$root.$refs.start.setMenuActive(true);
  }
}
</script>
