import { fileService } from '../services';

export const file = {
    namespaced: true,
    state: {
        error: {},
    },
    getters:{},
    actions: {
        uploadLogo({commit}, file){
            return new Promise((resolve, reject) =>{
                fileService.uploadLogo(file).then(
                    response =>{
                        resolve(response)
                    },
                    error =>{
                        reject(error)
                        commit('getFailure', error)
                    }
                )
            })
        },
        downloadExportPerformance({commit}, data){
            return new Promise((resolve, reject) =>{
                fileService.downloadExportPerformance(data).then(
                    response =>{
                        resolve(response)
                    },
                    error =>{
                        reject(error)
                        commit('getFailure', error)
                    }
                )
            })
        },
        downloadExportTimeRecording({commit}, data){
            return new Promise((resolve, reject) =>{
                fileService.downloadExportTimeRecording(data).then(
                    response =>{
                        resolve(response)
                    },
                    error =>{
                        reject(error)
                        commit('getFailure', error)
                    }
                )
            })
        },
    },
    mutations: {
        getFailure(state, error){
            state.error = {item: error}
        },
    },
}