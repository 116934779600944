<template>
  <div>
<!--    <base-card>-->


      <!--      <div class="dataTables_wrapper mt-4" >-->
      <row :gutter="12">
        <column :xs="12" :lg="12">

      <v-data-table
          style="padding:5px"
          light
          :items="employees"
          :headers="headers"
          :search="search"
          :header-props="headerProps"
          item-key="ID"
          class="elevation-1 "
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          single-select
          isSelected="true"
          :items-per-page="10"
          @click:row="ClickRow"
          :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
      >

        <template v-slot:top>
          <v-row style="width: 100%">
            <column class="mt-4 ml-2">
            <h3>
              <v-icon style="color: #006ab5">mdi-account-multiple</v-icon>
              Alle Mitarbeiter
            </h3>
            </column>
            <v-text-field
                clearable
                v-model="search"
                label="Suchen"
                append-icon="mdi-magnify"
                class="mx-4"
                width="50%"
            >
            </v-text-field>
            <!--              <v-btn class="mt-4" small @click="clearSearchFilter">Leeren</v-btn>-->
          </v-row>
        </template>



        <template v-slot:item.Personalnumber="{item}">
          <v-chip
              label
              :color="getColor(item)"
          >
            {{ item.Personalnumber }}
          </v-chip>
        </template>


      </v-data-table>
      <!--      </div>-->
        </column>
      </row>
<!--    </base-card>-->
  </div>
</template>

<script>
// import BaseCard from "../../Base/BaseCard";

export default {
  // components: {BaseCard},
  props: {
    employees: {},
  },
  data() {
    return {
      search: '',
      headers: [
        {text: 'PNR', value: 'Personalnumber'},
        {text: 'Vorname', value: 'Firstname'},
        {text: 'Nachname', value: 'Lastname'},
      ],
      headerProps: {
        sortByText: 'Sortieren nach'
      },
    }
  },
  methods: {
    ClickRow(item, row) {
      row.select(true)
      this.$emit("sendEmployeeID", item.ID)
    },
    getColor(item) {
      console.log(item)
      if (item.EmployeeColorHex == "") {
        return "#FFFFFFFF"
      } else {
        return item.EmployeeColorHex
      }
    },
    clearSearchFilter() {
      this.search = "";
    },
  }
}
</script>