import {
    authHeader
} from '../helpers';

export const patientMedicationService = {
    newMedication,
    updateMedication,
    deleteMedication,
    getMedicationByPatientID
}


function getMedicationByPatientID(patientID) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/medications/` + patientID, requestOptions).then(handleResponse);
}

function newMedication(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/medication`, requestOptions).then(handleResponse);
}


function updateMedication(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/medication/change/` + payload.ID, requestOptions).then(handleResponse);
}

function deleteMedication(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/medication/delete/` + payload.ID, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}