<template>
    <v-card color="rgb(241, 241, 241)" light>
        <v-card-title>
          <v-icon style="color:#006ab5">mdi-clipboard-text-outline</v-icon>
           <h3>Notizen</h3>
        </v-card-title>
        <v-card-text>
            <v-row  class="mt-2 ml-1 mr-1">
                <v-textarea light
                    solo
                    name="input-7-4"
                    label="Hier Notiz eintragen...."
                    v-model="notetext"
                ></v-textarea>
            </v-row>
            <v-row  style="margin-top:-20px">
                <v-col cols="2" >
                    <v-btn
                        color="primary"
                        elevation="2"
                        class="ml-2"
                        @click="SaveNote()"
                    >
                        <i style="margin-right: 10px;" class="fas fa-save"></i>
                        speichern
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        color="primary"
                        elevation="2"
                        class="ml-2"
                        @click="SetBackTextfield()"
                    >   <v-icon small>mdi-clipboard-text-outline</v-icon>
                        Neu Notiz
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <NoteTable
                        @SendNote="GetExistingNote"
                        @SendNoteID="DeleteNote"
                        :key="notetablekey"
                        :referenceEmployeeID="referenceEmployeeID"
                        :referencePatientID="referencePatientID"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="mb-4 mr-2"
                color="info"
                @click="CloseDialog()"
            >
            Schließen 
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
//import {getUserFromJWT} from '../../helpers'
import NoteTable from './NoteTable'
export default {
    components:{
        NoteTable
    },
    props:{
        referenceEmployeeID: Number,
        referencePatientID: Number,
    },
    data(){
        return {
            notetext: "",
            note: {},
            newNote: true,
            notetablekey: 0
        }
    },
    methods:{
        CloseDialog(){
            this.$store.commit('eventdialog/changeNoteModalDialogtoFalse')
        },
        GetExistingNote(note){
            this.note = note
            this.notetext = this.note.Text
            this.newNote = false
        },
        async SaveNote(){
            console.log("referencePatientID: " + this.referencePatientID)
            console.log("referenceEmployeeID: " + this.referenceEmployeeID)
            if(this.referencePatientID == undefined && this.referenceEmployeeID == undefined){
                console.log("geht nicht")
                return 
            }
            if(this.newNote){
                await this.CreateNote()
            }else{
                await this.UpdateNote()
            }
            this.RenderNoteTable()
            this.SetBackTextfield()
        },
        async CreateNote(){
            let noteDTO ={
                    EmployeeID: 0,
                    Text: this.notetext,
                    ReferencedEmployeeID: 0,
                    ReferencedPatientID: 0
                }
            if(this.referencePatientID == undefined){
                noteDTO.ReferencedEmployeeID = this.referenceEmployeeID
                
            }
            if(this.referenceEmployeeID == undefined){
                noteDTO.ReferencedPatientID = this.referencePatientID
            }
            await this.$store.dispatch('note/createNote', noteDTO)
            
        },
        SetBackTextfield(){
            this.notetext = ""
            this.newNote = true
            this.note = {}
        },
        async DeleteNote(noteID){
            let response = await this.$store.dispatch('note/deleteNoteByID', noteID)
            this.RenderNoteTable()
            console.log(response)
        },
        RenderNoteTable(){
            this.notetablekey++
        },
        async UpdateNote(){
            this.note.Text = this.notetext
            let response = await this.$store.dispatch('note/changeNote', this.note)
            console.log(response)
        }
    }
}
</script>