<template>
<v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Absender Informationen</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Logo URL"
                    v-model="Info.LogoURL"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Firma"
                    v-model="Info.SenderCompany"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Geschäftsführer"
                    v-model="Info.SenderCEO"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Unternehmensinformation"
                    v-model="Info.SenderAddress"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Stadt"
                    v-model="Info.SenderCity"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Straße"
                    v-model="Info.SenderStreet"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="PLZ"
                    v-model="Info.SenderPLZ"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Land"
                    v-model="Info.SenderCountry"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Bankname"
                    v-model="Info.SenderBankName"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="BIC"
                    v-model="Info.SenderBIC"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="BLZ"
                    v-model="Info.SenderBLZ"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="FAX"
                    v-model="Info.SenderFAX"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="IBAN"
                    v-model="Info.SenderIBAN"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="KTN"
                    v-model="Info.SenderKTN"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="E-Mail"
                    v-model="Info.SenderMail"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Telefon"
                    v-model="Info.SenderTel"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Web-Addresse"
                    v-model="Info.SenderWeb"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Umsatzsteuer-Identifikationsnummer"
                    v-model="Info.SenderUmst"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="IK-Nummer"
                    v-model="Info.IkNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-btn color="primary" @click="Save">
                  Speichern
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  data() {
    return {
      dismissClicked: false,
      show1: false,
      Info: this.info,
    };
  },
  props: {
    info: {},
  },
  computed: {

  },
  methods: {
    Save() {
      console.log(JSON.stringify(this.info))
      this.$store.dispatch("senderInfo/update", this.Info);
    },
  },
};
</script>
