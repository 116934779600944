export const titleMixin = {
    created() {
        this.logMessage();
    },
    data() {
        return {
            titlePool: ["Frau", "Herr", "Divers"],
        };
    },
    methods: {
        logMessage() {
            console.log('TitlePool loaded');
        },
    }
}