<template>
  <div>
    <div class="card-body">
      <div class="dataTables_wrapper">
        <v-data-table
          :headers="headers"
          :items="invoiceRunsDTO"
          item-key="id"
          :loading="isLoading"
          loading-text="Wird geladen"
          :search="search"
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          locale="de-de"
          class="elevation-1 hdl"
          :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Suchen"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> Neu laden </v-btn>
          </template>
          <template
            v-slot:body="{items}"
          >
            <tbody>
              <tr
                v-for="item in items"
                :key="item.ID"
              >
                <td>{{item.ID}}</td>
                <td>{{item.Created}}</td>
                <td>{{item.Name}}</td>
                <td>{{item.InvoiceMode}}</td>
                <td>
                  <v-btn
                    @click="DeleteInvoiceRun(item.ID)"
                  >löschen</v-btn>
                </td>
                <td>
                  <v-btn
                    @click="changeViewToEdit(item.ID)"
                  >ansehen</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created(){
    await this.$store.dispatch('invoices/getInvoiceRunsForTable')
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "ID" },
        { text: "Gestartet am", value: "Created" },
        { text: "Name", value: "Name" },
        { text: "Rechnungsart", value: "InvoiceMode" },
        {text: "Löschen"},
        {text: "Rechnugslauf ansehen"}
      ],
      finishedExport: false,
      blobUrl: "",
      search: "",
    };
  },
  computed: {
    invoiceRunsDTO() {
      return this.$store.getters['invoices/getInvoiceRunForListDTO'];
    },
  },
  methods:{
    async DeleteInvoiceRun(invoiceRunID){
      try{
        await this.$store.dispatch('invoices/deleteInvoiceRun', invoiceRunID)
        this.$emit('RenderInvoiceRunList')
    
      } catch(error){
        console.log(error)
      }
    },
    changeViewToEdit(invoiceRunID) {
      console.log(invoiceRunID)
      this.$store.commit('invoices/setInvoiceRunID', invoiceRunID)
      this.$router.push("/invoice/edit");
    },
  },
};
</script>
