<template>
  <main>
    <div class="ml-7 mt-4"><h2>Kalendereinstellungen</h2></div>
    <v-row class="ml-7">
        <v-col class="ml-1">
            <Feiertage
              @RefreshSettings="RenderFeiertagSettings()"
            />
        </v-col>
        <v-col class="ml-7 mr-7">
            <FeiertageSettings
              :key="feiertagsettingskey"
            />
        </v-col>
    </v-row>
  </main>
</template>

<script>

import Feiertage from "@/components/settings/Feiertage.vue";
import FeiertageSettings from "@/components/settings/FeiertageSettings.vue";
export default {
    components: {
      Feiertage,
      FeiertageSettings
    },
    data(){
      return {
        feiertagsettingskey: 0
      }
    },
    methods: {
      RenderFeiertagSettings(){
        console.log("Render")
        this.feiertagsettingskey++
      }
    }
    
}
</script>

<style scoped>

</style>