var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"dataTables_wrapper"},[_c('v-data-table',{staticClass:"elevation-1 hdl",attrs:{"headers":_vm.headers,"items":_vm.insurances.items,"item-key":"id","loading":_vm.isLoading,"loading-text":"Wird geladen","search":_vm.search,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","custom-filter":_vm.filterOnlyCapsText,"locale":"de-de","footer-props":{
                  itemsPerPageAllText: 'Alle',
                  itemsPerPageText: 'Einträge pro Seite',
      }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"clearable":"","label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.isCareHealthInsurance",fn:function(ref){
      var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.isCareHealthInsurance),callback:function ($$v) {_vm.$set(item, "isCareHealthInsurance", $$v)},expression:"item.isCareHealthInsurance"}})]}},{key:"item.isNormalInsurance",fn:function(ref){
      var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.isNormalInsurance),callback:function ($$v) {_vm.$set(item, "isNormalInsurance", $$v)},expression:"item.isNormalInsurance"}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getData}},[_vm._v(" Neu laden")])]},proxy:true}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',[_c('i',{staticClass:"fas fa-building"}),_vm._v(" Alle Kassen")])}]

export { render, staticRenderFns }