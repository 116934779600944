<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="time"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            :label="label"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="modal"
          v-model="time"
          full-width
          format="24hr"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Schließen
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="SaveTime"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
</template>
<script>
export default {
    props:{
        label: String,
        modal: Boolean(false),
        time: null
    },
    methods: {
      SaveTime(){
        this.$refs.dialog.save(this.time)
        this.$emit('SendTime', this.time)
      }
    }
}
</script>