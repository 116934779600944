import {
    authHeader
} from '../helpers';

export const serviceArticleService = {
    getAll,
    newServiceArticle,
    updateServiceArticle,
    deleteServiceArticle,
    setArticleToCalendarEntry,
    getAllPatientServiceArticlesByCalendarID,
    deleteCalendarToPatientServiceArticleByID,
    deleteCalendarToPatientServiceArticleCalendarID,
    saveAmountToPatientServiceArticleForCalendars
};

function getAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/servicearticles`, requestOptions).then(handleResponse);
}

function newServiceArticle(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/servicearticle`, requestOptions).then(handleResponse);
}

function updateServiceArticle(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/servicearticle/change/` + data.ID, requestOptions).then(handleResponse);
}

function deleteServiceArticle(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/servicearticle/delete/id/` + data, requestOptions).then(handleResponse);
}

async function setArticleToCalendarEntry(articleToCalendar){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(articleToCalendar)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/servicearticles/calendar/set`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllPatientServiceArticlesByCalendarID(calendarID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/servicearticles/calendar/get/` + calendarID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function deleteCalendarToPatientServiceArticleByID(calendarToArticleID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/servicearticles/calendar/delete/` + calendarToArticleID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function deleteCalendarToPatientServiceArticleCalendarID(calendarID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/servicearticles/calendar/all/delete/` + calendarID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function saveAmountToPatientServiceArticleForCalendars(saveTimeInMinutes){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(saveTimeInMinutes)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/servicearticles/calendar/amount/save`, requestOptions)
    let data = await handleResponse(response)
    return data
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log(text)
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }
        return data;
    });
}