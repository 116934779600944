import {
    serviceArticleService
} from '../services';

export const serviceArticles = {
    namespaced: true,
    state: {
        all: {},
        patientServiceArticles: {}
    },
    getters: {
        settings: state => {
          return state.all.filter(article => article.ID > 1)
        },
        getPatientServiceArticles: state =>{
            return state.patientServiceArticles.items
        },
        getServiceArticles: state => {
            return state.all
        },
    },
    actions: {
        reset({
            commit
        }) {
            commit('serviceArticlesReset');
        },

        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                serviceArticleService.getAll()
                    .then(
                        data => {
                            commit('getAllSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        newServiceArticle({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                serviceArticleService.newServiceArticle(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updateServiceArticle({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                serviceArticleService.updateServiceArticle(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deleteServiceArticle({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                serviceArticleService.deleteServiceArticle(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        setArticleToCalendarEntry({commit}, articleToCalendar){
            return new Promise((resolve, reject)=>{
                serviceArticleService.setArticleToCalendarEntry(articleToCalendar).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllPatientServiceArticlesByCalendarID({commit}, calendarID){
            return new Promise((resolve, reject)=>{
                serviceArticleService.getAllPatientServiceArticlesByCalendarID(calendarID).then(
                    payload =>{
                        resolve(payload)
                        commit('getAllPatientServiceArticlesByCalendarIDSuccess', payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        deleteCalendarToPatientServiceArticleByID({commit}, calendarToArticleID){
            return new Promise((resolve, reject)=>{
                serviceArticleService.deleteCalendarToPatientServiceArticleByID(calendarToArticleID).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        deleteCalendarToPatientServiceArticleCalendarID({commit}, calendarID){
            return new Promise((resolve, reject)=>{
                serviceArticleService.deleteCalendarToPatientServiceArticleCalendarID(calendarID).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        saveAmountToPatientServiceArticleForCalendars({commit}, saveTimeInMinutes){
            return new Promise((resolve, reject)=>{
                serviceArticleService.saveAmountToPatientServiceArticleForCalendars(saveTimeInMinutes).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        }
    },
    mutations: {
        getAllPatientServiceArticlesByCalendarIDSuccess(state, payload){
            state.patientServiceArticles = {items: payload}
        },
        serviceArticlesReset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },
        deleteRequest(state) {
            state.delete = {
                loading: true
            }; 
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; 
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; 
        },
        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, data) {
            state.all = {
                items: data
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        },
        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },
        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        }
    }
}