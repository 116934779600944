import {
    invoiceRunCategoryService
} from '../services';

export const invoiceRunCategories = {
    namespaced: true,
    state: {
        all: {}
    },
    actions: {
        reset({
            commit
        }) {
            commit('Reset');
        },

        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                invoiceRunCategoryService.getAll()
                    .then(
                        data => {
                            commit('getAllSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },
    },
    mutations: {
        Reset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },
        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, data) {
            state.all = {
                items: data
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        }
    }
}