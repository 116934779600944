<template>
  <v-form ref="form" :disabled="formdisabled">
    <v-col
        class="densecol mb-0 pb-0">
      <!--    style="border: 1px solid;border-color:#e6d1d1; margin-bottom:10px !important" -->
      <h2>Adresse</h2>

      <v-row>
        <v-col cols=6>
          <v-text-field
              v-model="form.personaladdress.Street"
              label="Straße:"
              placeholder="Straße"
              hide-details="auto"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row style="padding-bottom: 20px">
        <v-col cols="3">
          <v-text-field
              v-model="form.personaladdress.Plz"
              label="PLZ:"
              placeholder="PLZ"
              hide-details="auto"
              :error-messages="PLZErrors"
              @input="$v.form.personaladdress.Plz.$touch()"
              @blur="$v.form.personaladdress.Plz.$touch()"
          >
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
              v-model="form.personaladdress.City"
              label="Ort:"
              placeholder="Ort"
              hide-details="auto"
              :error-messages="CityErrors"
              @input="$v.form.personaladdress.City.$touch()"
              @blur="$v.form.personaladdress.City.$touch()"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-form>
</template>

<script>

import {helpers, numeric, minLength, maxLength} from 'vuelidate/lib/validators';

const alphaNumAndDotAndWhiteSpaceValidator = helpers.regex('alphaNumAndDot', /^[a-zA-Z.\s-,+äüöÄÖÜß]*$/);

export default {
  props: {
    patientID: undefined,
    formdisabled: Boolean
  },
  async created() {
    await this.FillInForm()
  },
  data() {
    const defaultForm = Object.freeze({
      personaladdress: {
        PatientID: undefined,
        Street: undefined,
        Plz: undefined,
        City: undefined,
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
    };
  },
  validations: {
    form: {
      personaladdress: {
        Plz: {
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        City: {alphaNumAndDotAndWhiteSpaceValidator}
      }
    }
  },
  methods: {
    async SavePersonalAddressObject() {
      //console.log('### calling SavePersonalAddressObject ###');
      this.form.personaladdress.PatientID = this.patientID
      //console.log('SavePersonalAddressObject: ' + JSON.stringify(this.form.personaladdress));
      await this.$store.dispatch('patientoverview/createPatientPersonalAddress', this.form.personaladdress)
    },
    ResetForm() {
      console.log("# calling ResetFrom() of PatientAddressData #");
      this.$refs.form.reset()
      //console.log('PatientAddressData: ' + JSON.stringify(this.form.personaladdress));
    },
    async FillInForm() {
      console.log("PatientID: " + this.patientID)
      await this.$store.dispatch('patientoverview/getPatientPersonalAddressByPatientId', this.patientID)
      this.form.personaladdress = this.$store.getters['patientoverview/getAddress']
    }
  },
  computed: {
    PLZErrors() {
      const errors = []
      if (!this.$v.form.personaladdress.Plz.$dirty) return errors
      !this.$v.form.personaladdress.Plz.numeric && errors.push('Nur Zahlen erlaubt')
      !this.$v.form.personaladdress.Plz.minLength && errors.push('Maximal 5 Zeichen')
      !this.$v.form.personaladdress.Plz.maxLength && errors.push('Anzahl der erlaubten Zeichen überschritten')
      return errors
    },
    CityErrors() {
      const errors = []
      if (!this.$v.form.personaladdress.City.$dirty) return errors
      !this.$v.form.personaladdress.City.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Keine Zahlen erlaubt')
      return errors
    }
  },
};
</script>


<style scoped>

.error--text {
  color: red !important;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
}
</style>
