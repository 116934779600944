<template>
  <v-container>
    <!--      <img :src="image"/>-->
    <!--    <v-row class="loginframe pt-4 pl-4 pr-4 pb-4 mt-8">-->

    <v-form>
      <row :gutter="12">
        <column :xs="12" :md="4" :lg="3"></column>
        <column :xs="12" :md="4" :lg="3" align="center">
          <i class="fas fa-lock-open fa-4x mt-8 "></i>
          <h4 class="mt-4">Anmeldung</h4>
          Sie können das DRK-Portal nur nach Anmeldung mit Ihren Login-Daten
          nutzen.
        </column>
        <column :xs="12" :md="4" :lg="3" align="center">
          <v-text-field
              class="mt-4"
              label="Name"
              v-model="username"
              :class="{ 'is-invalid': submitted && !username }"
          >
          </v-text-field>
          <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Passwort"
              hint="Mindestens 8 Zeichen"
              counter
              @click:append="show1 = !show1"
              @keydown="OnSubmitEvent"
              class="mb-4"
          ></v-text-field>
          <v-btn v-on:click="handleSubmit" style="float:left;" color="primary" elevation="2">
            Anmelden
          </v-btn>
        </column>
        <column :xs="12" :md="4" :lg="3"></column>
      </row>
    </v-form>

    <!--    <v-row style="margin-left: 2%; margin-right: 2%" class="mt-1 pt-3">-->
    <v-row align="center" justify="center" class="mt-2">
      <div class="col-md-1"></div>
      <div class="col-md-3 mainbox_wrapper">
        <div class="card mb-4 shadow-sm">
          <div class="mainbox mainbox-homeservice card-img-top text-center">
            <img src="icon_haushalt.svg" height="100px" style="color: white; margin-top: 25px; margin-bottom: -15px;"/>
            <h3>AUS & HNR</h3>
          </div>
        </div>
      </div>
      <div class="col-md-3 mainbox_wrapper">
        <div class="card mb-4 shadow-sm">
          <div class="mainbox mainbox-shoppingService card-img-top text-center">
            <i class="fas fas fa-cart-arrow-down fa-7x pt-5 mt-1"></i>
            <h3>Einkaufsservice</h3>
          </div>
        </div>
      </div>
      <div class="col-md-3 mainbox_wrapper">
        <div class="card mb-4 shadow-sm">
          <div class="mainbox mainbox-firstAid card-img-top text-center">
            <i class="fas fa-first-aid fa-7x pt-5 mt-1"></i>
            <h3>Erste Hilfe</h3>
          </div>
        </div>
      </div>
    </v-row>
    <v-row align="center" justify="center">
      <div class="col-md-1"></div>
      <div class="col-md-3 mainbox_wrapper">
        <div class="card mb-4 shadow-sm">
          <div class="mainbox mainbox-inclusion card-img-top text-center">
            <i class="fas fa-universal-access fa-7x pt-5 mt-1"></i>
            <h3>Inklusion</h3>
          </div>
        </div>
      </div>
      <div class="col-md-3 mainbox_wrapper">
        <div class="card mb-4 shadow-sm">
          <div class="mainbox mainbox-youth card-img-top text-center">
            <img src="icon_jugendhilfe.svg" height="100px"
                 style="color: white; margin-top: 25px; margin-bottom: -15px;"/>
            <h3>Jugendhilfe</h3>
          </div>
        </div>
      </div>
      <div class="col-md-3 mainbox_wrapper">
        <div class="card mb-4 shadow-sm">
          <div class="mainbox mainbox-homelessAssistance card-img-top text-center">
            <i class="fas fa-hands-helping fa-7x pt-5 mt-1"></i>
            <h3>Obdachlosenhilfe</h3>
          </div>
        </div>
      </div>
    </v-row>

  </v-container>
</template>
<script>
// import image from "../c"
export default {
  name: "LoginPage",
  data() {
    return {
      // image: image,
      username: "",
      password: "",
      submitted: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    loginError() {
      let e = this.$store.state.authentication.status.error;
      console.log(e);
      return e;
    }
  },
  created() {
    // reset login status
    this.$store.dispatch("authentication/logout");
  },
  methods: {
    OnSubmitEvent(key) {
      if (key.keyCode == 13) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.submitted = true;
      const {username, password} = this;
      const {dispatch} = this.$store;
      if (username && password) {
        dispatch("authentication/login", {username, password});
      }
    },
    handlePWReset() {
      console.log("Implementented");
    },
  },
};
</script>

<style scoped>

.svgStyle {
  color: white !important;
  font-size: 80px;
}
</style>