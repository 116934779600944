import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#951b81',
                secondary: '#0e4194',
                accent: '#006ab5',
                error: '#ababab',
            }
        }
    }
});
