<template>
  <div>
    <div>
      <v-text-field
        label="IBAN für Einzug:"
        placeholder="DE07 1234 1234 1234 1234 12"
        hide-details="auto"
        v-model="Bankaccount.IBAN"
      ></v-text-field>
    </div>
    <v-row>
      <v-col cols="12">
      <v-btn class="mt-4" @click="Save()" color="primary" style="float: right;"><i class="fas fa-save"></i>Speichern</v-btn>
    </v-col >
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="Bankaccount.UseAlternatePaymentAddress"
          :disabled="locked"
          :label="`Alternative Rechnungsadresse`"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="Bankaccount.UseAlternatePaymentAddress">
      <v-col cols="12" class="densecol ml-0 pl-0"> 
        <InsurancePaymentAddress
          :insurance-payment-address="Insurance.InsurancePaymentAddress"
          @insurancePaymentAddress="getFromInsurancePaymentAddress"
          :disabled="locked"
        ></InsurancePaymentAddress>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          @click="Save()"
          color="primary"
          style="float: right;"
          :disabled="submitted || locked"
          elevation="2"
          ><i class="fas fa-save"></i>Speichern</v-btn
        >
        <v-btn v-if="Bankaccount.UseAlternatePaymentAddress"
          @click="DeletePaymentAddress()"
          color="error"
          style="float: right;"
          class="mr-1"
          elevation="2"
          :disabled="locked"
          ><i class="fas fa-trash"></i>Löschen</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {validateIBAN} from  "../../helpers/validator"

import InsurancePaymentAddress from "../insurances/InsurancePaymentAddress"

export default {
  data() {
    return {
      Bankaccount: this.bankaccount,
      Insurance: this.insuranceObject,

      ibanRules: [
        (value) => (value && validateIBAN(value)) || "Keine gültige IBAN",
      ],
    };
  },
  components: {
    InsurancePaymentAddress
  },
  props: {
    bankaccount: {},
    insuranceObject: {},
  },
  methods: {
    getFromInsurancePaymentAddress(data) {
      this.Insurance.InsurancePaymentAddress = data;
    },
    Save() {
      if (
        confirm(
          "Möchten Sie die Bankdaten wirklich speichern? Die Änderungen haben auswirkungen auf die nächste Abrechnung."
        )
      ) {
        console.log(this.Bankaccount);
        this.submitted = true;

      this.$store
              .dispatch(
                "insurances/updateInsurancePaymentAddress",
                this.Insurance.InsurancePaymentAddress
              )
              .then((response) => {
                  this.Insurance.InsurancePaymentAddressID = response.ID
              });

              this.$store
        .dispatch("insurances/updateInsurance", this.Insurance)
        .then((response) => {
          console.log(response.ID);
          this.$root.$emit("reload_insurance_list");
        });

        this.$store
        .dispatch("insurances/updateBankaccount", this.Bankaccount)
        .then(() => {
          this.$root.$emit("reload_insurance_details");
           this.submitted = false;
        });
      }
    },
    Delete() {
      this.$store
        .dispatch("insurances/deleteAddress", this.bankaccount.ID)
        .then((address_response) => {
          console.log(address_response.ID);
          this.submitted = false;
          this.$root.$emit("reload_insurance_details");
        });
    },
  },
};
</script>

<style scoped>
i {
  margin-right: 10px;
}
svg {
  margin-right: 10px;
}
</style>