import {authHeader} from '../helpers';
import {userService} from '../services';

export const patientOverviewService = {
    getPatientPersonalDataById,
    changePatientPersonalData,
    createPatientPersonalData,
    getPatientPersonalAddressByPatientId,
    createPatientPersonalAddress,
    getPatientBankAccountByPatientID,
    createPatientBankAccount,
    getPatientRelatedContactByPatientID,
    createPatientRelatedContact,
    getPaymentAddressByPatientID,
    createPaymentAddress,
    deletePatientRelatedContactByID
}

async function deletePatientRelatedContactByID(contactID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/contactdata/delete/` + contactID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPaymentAddressByPatientID(patientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/paymentaddress/get/` + patientID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function createPaymentAddress(addressDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(addressDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/paymentaddress/post`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPatientRelatedContactByPatientID(patientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/contactdata/get/` + patientID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function createPatientRelatedContact(contactDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(contactDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/contactdata/post`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPatientBankAccountByPatientID(patientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/accountdata/get/` + patientID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function createPatientBankAccount(accountDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(accountDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/accountdata/post`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPatientPersonalAddressByPatientId(patientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/addressdata/get/` + patientID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function createPatientPersonalAddress(addressDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(addressDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/addressdata/post`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function createPatientPersonalData(personalDataDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(personalDataDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/personaldata/post`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function changePatientPersonalData(personalDataDTO){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(personalDataDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/personaldata/put`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPatientPersonalDataById(patientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientoverview/personaldata/get/` + patientID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //console.log(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}