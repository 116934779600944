import {
    insuranceService
} from '../services';

export const insurances = {
    namespaced: true,
    state: {
        all: {},
        selected_insurance: {},
        new: {},
        update: {},
        delete: {}
    },
    actions: {
        reset({
            commit
        }) {
            commit('insurancesReset');
        },
        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                insuranceService.getAll()
                    .then(
                        insurances => {
                            commit('getAllSuccess', insurances)
                            resolve(insurances)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        create({
            commit
        }, data) {
            commit('newInsuranceRequest');
            return new Promise((resolve, reject) => {
                insuranceService.create(data)
                    .then(
                        data => {
                            commit('newInsuranceSuccess', data);
                            resolve(data)
                        },
                        error => {
                            commit('newInsuranceFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updateInsurancePaymentAddress({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                insuranceService.updateInsurancePaymentAddress(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        getInsurance({
            commit
        }, id) {
            commit('getInsuranceRequest');

            insuranceService.getInsurance(id)
                .then(
                    insurance => commit('getInsuranceSuccess', insurance),
                    error => commit('getInsuranceFailure', error)
                );
        },

        updateInsurance({
            commit
        }, insurance) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                insuranceService.updateInsurance(insurance)
                    .then(
                        insurance => {
                            commit('updateSuccess', insurance);
                            resolve(insurance)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deleteInsuranceById({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                insuranceService.deleteInsuranceById(id)
                    .then(
                        insurance => {
                            commit('deleteSuccess', insurance);
                            resolve(insurance)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        newAddress({
            commit
        }, address) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                insuranceService.newAddress(address)
                    .then(
                        address => {
                            commit('newSuccess', address);
                            resolve(address)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        updateAddress({
            commit
        }, address) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                insuranceService.updateAddress(address)
                    .then(
                        address => {
                            commit('updateSuccess', address);
                            resolve(address)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        newContact({
            commit
        }, contact) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                insuranceService.newContact(contact)
                    .then(
                        contact => {
                            commit('newSuccess', contact);
                            resolve(contact)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })
        },

        updateContact({
            commit
        }, contact) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                insuranceService.updateContact(contact)
                    .then(
                        contact => {
                            commit('updateSuccess', contact);
                            resolve(contact)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deleteContact({
            commit
        }, contact) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                insuranceService.deleteContact(contact)
                    .then(
                        contact => {
                            commit('deleteSuccess', contact);
                            resolve(contact)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deleteAddress({
            commit
        }, address) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                insuranceService.deleteAddress(address)
                    .then(
                        address => {
                            commit('deleteSuccess', address);
                            resolve(address)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },




        newBankaccount({
            commit
        }, bankaccount) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                insuranceService.newBankaccount(bankaccount)
                    .then(
                        bankaccount => {
                            commit('newSuccess', bankaccount);
                            resolve(bankaccount)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        updateBankaccount({
            commit
        }, bankaccount) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                insuranceService.updateBankaccount(bankaccount)
                    .then(
                        bankaccount => {
                            commit('updateSuccess', bankaccount);
                            resolve(bankaccount)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        deleteBankaccount({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                insuranceService.deleteBankaccountById(id)
                    .then(
                        bankaccount => {
                            commit('deleteSuccess', bankaccount);
                            resolve(bankaccount)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },
    },
    mutations: {

        deleteRequest(state) {
            state.delete = {
                loading: true
            }; //Wird geladen status
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Objekt das vom Server zurückgegeben wird
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },

        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Objekt das vom Server zurückgegeben wird
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },


        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Address Objekt das vom Server zurückgegeben wird
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },

        insurancesReset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },


        getInsuranceRequest(state) {
            state.selected_insurance = {
                loading: true
            };
        },
        getInsuranceSuccess(state, insurance) {
            state.selected_insurance = {
                item: insurance,
                loading: false
            };
        },
        getInsuranceFailure(state, error) {
            state.selected_insurance = {
                error,
                loading: false
            };
        },


        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, insurances) {
            state.all = {
                items: insurances
            };
            //state.all.items.shift();
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        }
    }
}