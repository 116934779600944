import {
    authHeader
} from '../helpers';

export const patientServiceArticleService = {
    newServiceArticle,
    updateServiceArticle,
    deleteById,
    getArticleByPatientID,
    getPatientServiceArticleForBookingByCalendarID,
    getPatientServiceArticleForCreateCalendarByID
};

function newServiceArticle(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/servicearticle`, requestOptions).then(handleResponse);
}

function updateServiceArticle(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/servicearticle/change/` + payload.ID, requestOptions).then(handleResponse);
}

function deleteById(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/servicearticle/delete/` + payload.ID, requestOptions).then(handleResponse);
}

async function getArticleByPatientID(patientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patient/servicearticles/roster/get/` + patientID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getPatientServiceArticleForBookingByCalendarID(calendarID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientservicearticles/booking/get/` + calendarID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getPatientServiceArticleForCreateCalendarByID(patientServiceArticleID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patientservicearticles/calendar/create/get/` + patientServiceArticleID, requestOptions)
    let data = await handleResponse(response)
    return data
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}