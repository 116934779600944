import { userService } from '../services';
import { jwt_decode } from '../helpers';
import {router} from '../router'

const user = JSON.parse(localStorage.getItem('user'));
const publicUsername = jwt_decode(user).username;
const employeeID = jwt_decode(user).employeeID;
const userID = jwt_decode(user).userID;

const initialState = user
    ? { status: { loggedIn: true, error: false }, user, publicUsername, employeeID, userID }
    : { status: {}, user: null, publicUsername: null, employeeID: null, userID: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { username, password }) {
            commit('loginRequest', { username });
            userService.login(username, password)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        router.push('/');
                    },
                    error => {
                        commit('loginFailure', error);
                        dispatch('alert/error', { msg: error, title: "Authentifizierung fehlgeschlagen" }, { root: true });
                    }
                );
        },
        logout({ commit }) {
            userService.logout();
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true, error: false };
            state.user = user;
            state.publicUsername = jwt_decode(user).username;
            state.userID = jwt_decode(user).userID;
            state.employeeID = jwt_decode(user).employeeID;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true, error: false };
            state.user = user;
            state.publicUsername = jwt_decode(user).username;
            state.userID = jwt_decode(user).userID;
            state.employeeID = jwt_decode(user).employeeID;
            console.log(state.employeeID);
        },
        loginFailure(state) {
            state.status = { loggedIn: false, error: true };
            state.user = null;
            state.publicUsername = null;
            state.userID = null;
            state.employeeID = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.publicUsername = null;
            state.userID = null;
            state.employeeID = null;
            localStorage.removeItem('username');
        }
    }
}