<template>
  <v-container>
    <v-row>
      <v-col cols="4" v-if="$cookies.get('role') ==='Admin'">
        <v-card
            color="primary"
            dark>
          <v-card-title class="headline">Benutzerverwaltung</v-card-title>
          <v-card-subtitle>Benutzer verwalten und Gruppenzugehörigkeiten festlegen</v-card-subtitle>
          <v-card-actions>
            <v-btn text @click="RouteUserSettings">Benutzer verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'">
        <v-card
            color="secondary"
            dark
        >
          <v-card-title class="headline">Leistungen / Preise</v-card-title>

          <v-card-subtitle>Leistungen und Artikelpreise für die Abrechung in HN und AUS</v-card-subtitle>

          <v-card-actions>
            <v-btn text @click="RouteServiceArticleSettings">Artikel verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'">
        <v-card
            color="info"
            dark
        >
          <v-card-title class="headline">Rechnungs Einstellungen</v-card-title>

          <v-card-subtitle>Einstellungen zum Rechnungslauf, Kopfzeilen, Briefkopf sowie Logo</v-card-subtitle>

          <v-card-actions>
            <v-btn text @click="RouteInvoiceSettings">Einstellungen verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="4" v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'">
        <v-card
            color="info"
            dark>
          <v-card-title class="headline">Textvorlagen</v-card-title>

          <v-card-subtitle>Dynamische Textvorlagen inkl. Rechnungslauf Textvorlagen</v-card-subtitle>

          <v-card-actions>
            <v-btn text @click="RouteTemplateSettings">Textvorlagen verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="4" v-if="$cookies.get('role') === 'Admin'">
        <v-card
            color="grey"
            dark>
          <v-card-title class="headline">Import/Export/Upload (beta)</v-card-title>

          <v-card-subtitle>Importieren oder Exportieren von Datensätzen sowie Upload eines Logos. Vorerst nur für
            Testzwecke!
          </v-card-subtitle>
          <v-card-actions>
            <v-btn text @click="RouteImportExport">Import / Upload</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="4" v-if="$cookies.get('role') === 'Admin'">
        <v-card
            color="#455A64"
            dark>
          <v-card-title class="headline">Dashboard (preview)</v-card-title>

          <v-card-subtitle>Überblick über alle Informationen aus den Bereichen Umsätze, Rechnungslauf, Mitarbeiter und
            Klienten
          </v-card-subtitle>

          <v-card-actions>
            <v-btn text @click="RouteDashboard">zum Dashboard</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="4" v-if="$cookies.get('role') === 'Admin'">
        <v-card
            color="#455A64"
            dark>
          <v-card-title class="headline">Kalender Einstellungen</v-card-title>

          <v-card-subtitle>Einstellungen der Feiertage</v-card-subtitle>

          <v-card-actions>
            <v-btn text @click="RouteCalendarSettings">Kalender verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
  </v-container>

</template>

<script>

export default {
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  inject: ['role', 'currentID'],
  computed: {
    Role() {
      return this.role
    },
    currentID() {
      return this.ID
    },
  },
  methods: {
    RouteDashboard() {
      this.$router.push("/dashboard/dashboardoverview");
    },
    RouteServiceArticleSettings() {
      this.$router.push("/settings/servicearticles");
    },
    RouteUserSettings() {
      this.$router.push("/settings/benutzerverwaltung");
    },
    RouteInvoiceSettings() {
      this.$router.push("/settings/invoices");
    },
    RouteTemplateSettings() {
      this.$router.push("/settings/templates");
    },
    RouteImportExport() {
      this.$router.push("/settings/importexport");
    },
    RouteCalendarSettings() {
      this.$router.push("/settings/calendarsettings")
    }
  },
}
</script>