<template>
  <div>
    <base-card>
      <h4><i class="fas fa-calendar-alt"></i>Alle anstehende Termine</h4>
      <div class="dataTables_wrapper">
        <v-card
            max-width="450"
        >
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-if="appointments ==''">Keine Termine</v-list-item>
              <v-list-item v-for="item in appointments" :key="item.ID">

                <v-list-item-content>
                  <v-list-item-title
                      v-text="item.Date + ' - '+ item.Name + ' - ' + item.Automotive.KfzNr"
                  >
                  </v-list-item-title>
                  <!--                <v-divider></v-divider>-->
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
    </base-card>
  </div>
</template>

<script>
import moment from "moment";
import BaseCard from "../../Base/BaseCard";

export default {
  components: {BaseCard},
  computed: {
    cars() {
      return this.$store.state.cars.all;
    },
    appointments() {
      let appointments = this.$store.state.deviceAppointments.automotives.items;
      let cars = this.cars.items;
      if (appointments != undefined && cars != undefined) {
        appointments.forEach(a => {
          cars.forEach(c => {
            if (c.ID != undefined) {
              if (c.ID == a.AutomotiveID) {
                a.Automotive = c;
                a.Date = moment(a.Date).format("DD.MM.YYYY - hh:mm");
              }
            }
          })
        });
      }
      let v = {}
      if (appointments != undefined) {
        v = appointments.filter(a => a.Automotive != undefined);
      }

      return v;
    },
  },
  mounted() {
    this.$root.$on("reload_car_appointments_list", () => {
      this.GetAll();
    });
  },
  created() {
    this.GetAll();
  },
  methods: {
    async GetAll() {
      this.$store.dispatch("deviceAppointments/getAllAutomotives");
    },
  },
};
</script>


