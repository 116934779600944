<template>
    <div>
        <v-row v-if="!patientExist">
            <v-col>
                <p>kein Klient hinterlegt</p>
            </v-col>
        </v-row>
        <row :gutter="12" v-if="patientExist">
          <column :xs="12" :md="4" :lg="3">
                <v-text-field
                        :value="patient.ID"
                        label="PATNR"
                        disabled
                ></v-text-field>
            </column>
          <column :xs="12" :md="4" :lg="3">
                <v-text-field
                        :value="patient.LastName"
                        label="Name"
                        disabled
                ></v-text-field>
           </column>
          <column :xs="12" :md="4" :lg="3">
                <v-text-field
                        :value="patient.FirstName"
                        label="Vorname"
                        disabled
                ></v-text-field>
          </column>
          <column :xs="12" :md="4" :lg="3">
            <v-text-field
                :value="patient.PhoneNumber"
                label="Telefonnummer"
                disabled
            ></v-text-field>
         </column>
        </row>
        <row :gutter="12" v-if="patientExist">
          <column :xs="12" :md="4" :lg="3">
                <v-text-field
                        :value="patient.Street"
                        label="Straße"
                        disabled
                ></v-text-field>
            </column>
          <column :xs="12" :md="4" :lg="3">
                <v-text-field
                        :value="patient.PLZ"
                        label="PLZ"
                        disabled
                ></v-text-field>
          </column>
          <column :xs="12" :md="4" :lg="3">
                <v-text-field
                        :value="patient.City"
                        label="Ort"
                        disabled
                ></v-text-field>
            </column>
        </row>
    </div>
</template>
<script>
export default {
    props:{
        patientid: Number
    },
    data(){
        return {
            patientExist: true
        }
    },
    created(){
        console.log("patienid: " + this.patientid)
        if(this.patientid != 0){
            console.log("if")
            this.$store.dispatch('patients/getPatientForEventDialogByID', this.patientid)
        }else{
            console.log("else")
            this.patientExist = false
        }
    },
    computed:{
        patient(){
            if(this.patientid != 0){
                return this.$store.getters['patients/getPatientForEvent']
            }else{
                return ""
            }
        }
    }
}
</script>