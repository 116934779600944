import { patientOverviewService } from '../services';


export const patientoverview = {
    namespaced: true,
    state:{
        error: {},
        personaldata: {},
        addressdata: {},
        account: {},
        contact: {},
        paymentaddress: {}
    },
    getters:{
        getPaymentAddress: state => {
            return state.paymentaddress.item
        },
        getContactData: state =>{
            return state.contact.item
        },
        getAccountData: state =>{
            return state.account.item
        },
        getPersonalData: state =>{
            return state.personaldata.item
        },
        getAddress: state =>{
            return state.addressdata.item
        }
    },
    actions:{
        deletePatientRelatedContactByID({commit}, contactID){
            return new Promise((resolve, reject) => {
                patientOverviewService.deletePatientRelatedContactByID(contactID).then(
                    response => {
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        createPaymentAddress({commit}, addressDTO){
            return new Promise((resolve, reject) => {
                patientOverviewService.createPaymentAddress(addressDTO).then(
                    response => {
                        commit('createPaymentAddressSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getPaymentAddressByPatientID({commit}, patientID){
            return new Promise((resolve, reject) => {
                patientOverviewService.getPaymentAddressByPatientID(patientID).then(
                    response => {
                        commit('getPaymentAddressByPatientIDSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        createPatientRelatedContact({commit}, contactDTO){
            return new Promise((resolve, reject) => {
                patientOverviewService.createPatientRelatedContact(contactDTO).then(
                    response => {
                        commit('createPatientRelatedContactSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getPatientRelatedContactByPatientID({commit}, patientID){
            return new Promise((resolve, reject) => {
                patientOverviewService.getPatientRelatedContactByPatientID(patientID).then(
                    response => {
                        commit('getPatientRelatedContactByPatientIDSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        createPatientBankAccount({commit}, accountDTO){
            return new Promise((resolve, reject) => {
                patientOverviewService.createPatientBankAccount(accountDTO).then(
                    response => {
                        commit('createPatientBankAccountSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getPatientBankAccountByPatientID({commit}, patientID){
            return new Promise((resolve, reject) => {
                patientOverviewService.getPatientBankAccountByPatientID(patientID).then(
                    response => {
                        commit('getPatientBankAccountByPatientIDSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        createPatientPersonalAddress({commit}, addressDTO){
            return new Promise((resolve, reject) => {
                patientOverviewService.createPatientPersonalAddress(addressDTO).then(
                    response => {
                        commit('createPatientPersonalAddressSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getPatientPersonalAddressByPatientId({commit}, patientID){
            return new Promise((resolve, reject) => {
                patientOverviewService.getPatientPersonalAddressByPatientId(patientID).then(
                    response => {
                        commit('getPatientPersonalAddressByPatientIdSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getPatientPersonalDataById({commit}, patientID){
            return new Promise((resolve, reject) => {
                patientOverviewService.getPatientPersonalDataById(patientID).then(
                    response => {
                        commit('getPatientPersonalDataByIdSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        changePatientPersonalData({commit}, personalDataDTO){
            return new Promise((resolve, reject) => {
                patientOverviewService.changePatientPersonalData(personalDataDTO).then(
                    response => {
                        commit('changePatientPersonalDataSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        createPatientPersonalData({commit}, personalDataDTO){
            return new Promise((resolve, reject) => {
                patientOverviewService.createPatientPersonalData(personalDataDTO).then(
                    response => {
                        commit('createPatientPersonalDataSuccess', response);
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
    },
    mutations:{ 
        getFailure(state, error){
            state.error = {item: error}
        },
        getPatientPersonalDataByIdSuccess(state, payload){
            state.personaldata = {item: payload}
        },
        changePatientPersonalDataSuccess(state, payload){
            state.personaldata = {item: payload}
        },
        createPatientPersonalDataSuccess(state, payload){
            state.personaldata = {item: payload}
        },
        getPatientPersonalAddressByPatientIdSuccess(state, payload){
            state.addressdata = {item: payload}
        },
        createPatientPersonalAddressSuccess(state, payload){
            state.addressdata = {item: payload}
        },
        createPatientBankAccountSuccess(state, payload){
            state.account = {item: payload}
        },
        getPatientBankAccountByPatientIDSuccess(state, payload){
            state.account = {item: payload}
        },
        createPatientRelatedContactSuccess(state, payload){
            state.contact = {item: payload}
        },
        getPatientRelatedContactByPatientIDSuccess(state, payload){
            state.contact = {item: payload}
        },
        createPaymentAddressSuccess(state, payload){
            state.paymentaddress = {item: payload}
        },
        getPaymentAddressByPatientIDSuccess(state, payload){
            state.paymentaddress = {item: payload}
        }
    },
}