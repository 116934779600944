<template>
    <main>
        <h3>Gewählte Konfiguration</h3>
        <v-row class="mt-1">
            <v-col v-if="show" class="mt-1">
                Keine Konfiguration vorhanden
            </v-col>
            <v-col>
                <v-row v-if="!show">
                    <v-select
                        label="Bundesland gewählt"
                        v-model="bundesland"
                        :items="bundeslaender"
                        item-text="land"
                        item-value="abbr"
                        disabled
                    >
                    </v-select>
                </v-row>
                <FeiertagChoice
                    v-for="item in specialHolidayConfigSettings"
                    :key="item.ID"
                    :feiertag="item"
                    :disabled="Boolean(true)"
                />
            </v-col>
        </v-row>
    </main>
</template>
<script>
import FeiertagChoice from './FeiertagChoice.vue'
export default {
    components: {
        FeiertagChoice
    },
    async created() {
        let response = await this.GetSpecialHolidayConfigSettings()
        console.log(response.length)
        if(response.length == 0){
            this.show = true
        } else {
            this.show = false
            this.bundesland = this.specialHolidayConfigSettings[0].Bundesland
        }
        
    },
    data(){
        return {
            bundeslaender: [
                {land: 'Baden Württemberg', abbr: 'BW'}, {land: 'Bayern', abbr: 'BY'}, {land: 'Berlin', abbr: 'BE'}, 
                {land: 'Brandenburg', abbr: 'BB'}, {land: 'Bremen', abbr: 'HB'}, {land: 'Hamburg', abbr: 'HH'},
                {land: 'Hessen', abbr: 'HE'},{land: 'Mecklenburg Vorpommern', abbr: 'MV'},{land: 'Niedersachsen', abbr:'NI'}, 
                {land: 'Nordrhein Westfalen', abbr: 'NW'}, {land: 'Rheinland Pfalz', abbr: 'RP'}, {land: 'Saarland', abbr: 'SL'},
                {land: 'Sachsen', abbr: 'SN'},{land: 'Sachsen Anhalt', abbr: 'ST'},{land: 'Schleswig Holstein', abbr: 'SH'},
                {land: 'Thüringen', abbr: 'TH'}],
            bundesland: "",
            show: Boolean
        }
    },
    computed:{
        specialHolidayConfigSettings(){
            return this.$store.getters['settings/getHolidayConfigSettings']
        }
    },
    methods: {
        async GetSpecialHolidayConfigSettings(){
            let response = await this.$store.dispatch('settings/getSpecialHolidayConfigSettings')
            return response
        }
    },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
}
</script>