<template>
  <main>
    <v-container fluid>
      <h2>
        <v-icon large style="color: #006ab5 !important;">mdi-account-multiple</v-icon>
        Klienten im Detail
      </h2>
      <v-row style="height: 60px">
        <div class="col-12">
          <v-btn
              @click="ResetAllForms"
              class="width300 ml-2 mt-2"
              color="primary"
              elevator="2">
            <i class="fas fa-plus"></i>Hinzufügen
          </v-btn>

          <v-btn
              color="primary"
              class="width300 mt-2 ml-2"
              @click="OpenNoteModalDialog()"
              :disabled="buttondisabled">
            <v-icon small> mdi-clipboard-text-outline</v-icon>
            Notizen
          </v-btn>

          <v-btn
              color="primary"
              class="width300 ml-2 mt-2"
              @click="OpenDeviceDialog()"
              :disabled="buttondisabled">
            <v-icon small>mdi-devices</v-icon>
            Gerätedetails
          </v-btn>

          <v-btn
              color="primary"
              class="width300 ml-2 mt-2"
              :disabled="buttondisabled"
              @click="selectFiles()">
            <i class="fas fa-file-alt"></i>
            Dokumente
          </v-btn>
          <v-btn v-if="disabled"
                 color="primary"
                 class="width300 ml-2 mt-2"
                 @click="OpenMedicationModalDialog()"
                 :disabled="true"
          ><i class="fas fa-notes-medical"></i>
            Medikation
          </v-btn>
          <div style="display: inline-block">
            <SelectKlientFile
                style="display: inline-block"
                v-if="selectfiles"
                :patientID="PatientID"
                key="filetablekey"
                @CloseAddFile="CloseAddFile"
                @renderFileTable="renderFileTable">
            </SelectKlientFile>
          </div>
        </div>
      </v-row>
      <v-row>

        <div class="col-4 border border-right border-left-0 border-top-0 border-bottom-0">
          <base-card>
            <PatientShortList
                @GetPatientID="GetPatientID"
                :key="updateShortList">
            </PatientShortList>
            <v-btn
                color="info"
                class="width300 mt-2"
                @click="deletePatient()"
                :disabled="buttondisabled">
              <v-icon>mdi-delete-empty-outline</v-icon>
              Klient löschen
            </v-btn>
          </base-card>
        </div>
        <v-col style="margin-top: -12px">
          <PatientsInputMask
              ref="patientinputmask"
              :patientID="PatientID"
              :key="patientKey"
              :formdisabled="formdisabled"
              :tabdisabled="tabdisabled"
              :filetablekey="filetablekey"
              @Renderfiletable="renderFileTable"
              @invoiceChoice="invoiceChoice"
          ></PatientsInputMask>
          <div class="container col-12 ml-0 mt-0">
            <v-btn ref="saveBtn"
                   color="primary"
                   elevation="2"
                   style="float:right; margin-top: -30px"
                   @click="SaveData()">
              <i class="fas fa-save"></i>Speichern
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-dialog
          v-model="inventorydevicedialog"
          persistent
          max-width="960px">
        <InventoryDeviceModalDialog
            :patientid="PatientID"
            :key="inventorydevicemodaldialogkey"
        />
      </v-dialog>
      <v-dialog
          v-model="notemodaldialog"
          persistent max-width="1100px">
        <NoteDialog
            :referencePatientID="PatientID"
            :key="notedialogkey"/>
      </v-dialog>
      <v-dialog
          v-model="medicationmodaldialog"
          persistent max-width="1100px"
      >
        <MedicatonDialog
            :key="medicationdialogkey"
            :patientID="PatientID"
        />
      </v-dialog>
    </v-container>
  </main>
</template>

<script>
import PatientShortList from "../../components/patients/PatientsShortList";
import PatientsInputMask from "@/components/patients/PatientsInputMask";
import InventoryDeviceModalDialog from "../../components/inventory/Devices/InventoryDeviceModalDialog"
import NoteDialog from '../../components/dialogs/NoteDialog.vue'
import MedicatonDialog from '../../components/dialogs/MedicatonDialog.vue'
import {dialogmixin} from '../../mixins'
import BaseCard from "../../components/Base/BaseCard";
import SelectKlientFile from '../../components/patients/SelectKlientFile.vue'

export default {
  components: {
    BaseCard,
    PatientsInputMask,
    PatientShortList,
    InventoryDeviceModalDialog,
    NoteDialog,
    MedicatonDialog,
    SelectKlientFile
  },
  mixins: [dialogmixin],
  data() {
    return {
      selectfiles: false,
      patientKey: 0,
      PatientID: undefined,
      filetablekey: 0,
      inventorydevicemodaldialogkey: 0,
      save: true,
      change: false,
      formdisabled: true,
      buttondisabled: true,
      patientShortListKey: 0,
      invoiceHN: true,
      invoiceAUS: false,
      invoicePrivate: false,
      tabdisabled: true,
    };
  },
  computed: {
    inventorydevicedialog() {
      return this.$store.getters['inventory/getInventorydevicedialog']
    },
    updateShortList() {
      return this.patientShortListKey;
    },
  },
  async created() {
    this.renderFileTable()
    await this.GetAllInvoiceTemplateDTO()
  },
  methods: {
    async GetAllInvoiceTemplateDTO(){
      let result = await this.$store.dispatch('invoicePatient/getAllInvoiceTemplatesDTO')
      console.log(result)
    },
    CloseAddFile() {
      this.selectfiles = false
    },
    async renderFileTable() {
      if (this.patientID !== undefined) {
        // alert("SHOW patientID: " + this.patientID)
        await this.$store.dispatch('patients/getFiles', this.PatientID);
        this.filetablekey++
      }
    },
    selectFiles() {
      this.selectfiles = !this.selectfiles
      this.filetablekey++
    },
    deletePatient() {
      if (confirm('Möchten Sie den ausgewählten Klienten mit der \nKlientennummer: ' + this.PatientID + " wirklich löschen?\n" +
          "Alle gespeicherten Dokumente zu diesem Klienten werden ebenfalls gelöscht.")) {
        this.$store.dispatch('patients/deletePatientById', parseInt(this.PatientID));
        this.patientShortListKey++
        this.ResetAllForms();
      } else {
        // doNothing
      }
    },
    GetPatientID(ID) {
      this.PatientID = ID
      this.RenderPatientInputMask()
      this.formdisabled = false
      this.change = true
      this.save = false
      this.buttondisabled = false
      this.tabdisabled = false
      this.renderFileTable()
      this.$root.$refs.KlientTable.interval();
    },
    RenderPatientInputMask() {
      this.patientKey++;
    },
    async SaveData() {
      console.log("save: " + this.save)
      console.log("change: " + this.change)
      if (this.change) {
        await this.$refs.patientinputmask.$refs.patientpersonaldata.ChangePersonalData();
        await this.$refs.patientinputmask.$refs.patientaddressdata.SavePersonalAddressObject();
        await this.$refs.patientinputmask.$refs.patientbankaccountdata.SavePersonalBankAccountObject();
        await this.$refs.patientinputmask.$refs.patientrelatedcontacts.SaveRelatedContactObject();
        if (this.invoiceHN == true) {
          await this.$refs.patientinputmask.$refs.invoicedata.$refs.invoiceHN.$refs.invoiceKasseHeaderHN.SavePatientDataForInvoice();
          await this.$refs.patientinputmask.$refs.invoicedata.$refs.invoiceHN.$refs.invoicingTemplateHN.SavePatientTemplateConfigDTO();
        }
        if (this.invoiceAUS == true) {
          await this.$refs.patientinputmask.$refs.invoicedata.$refs.invoiceAUS.$refs.invoiceKasseHeaderAUS.SavePatientDataForInvoice();
          await this.$refs.patientinputmask.$refs.invoicedata.$refs.invoiceAUS.$refs.invoicingTemplateAUS.SavePatientTemplateConfigDTO();
        }
        if (this.invoicePrivate == true) {
          await this.$refs.patientinputmask.$refs.invoicedata.$refs.invoicePrivate.$refs.invoicingTemplatePrivate.SavePatientTemplateConfigDTO();
          await this.$refs.patientinputmask.$refs.invoicedata.$refs.invoicePrivate.SavePrivatePrepaidDTO();
          await this.$refs.patientinputmask.$refs.invoicedata.$refs.invoicePrivate.$refs.alternatepaymentaddress.SaveAlternatePaymentAddressObject();
        }
      }
      if (this.save) {
        await this.$refs.patientinputmask.$refs.patientpersonaldata.SavePersonalData();
        this.ResetAllForms()
      }

    },
    ResetAllForms() {
      this.patientKey++
      this.patientShortListKey++
      console.log("### calling ResetAllForms ###");
      this.PatientID = undefined
      this.save = true
      this.change = false
      this.formdisabled = true
      this.buttondisabled = true
      this.tabdisabled = true
      this.selectfiles = false
      this.$refs.patientinputmask.$refs.patientpersonaldata.ResetForm();
      this.$refs.patientinputmask.$refs.patientchips.ResetForm();
      this.$refs.patientinputmask.$refs.patientaddressdata.ResetForm();
      this.$refs.patientinputmask.$refs.patientrelatedcontacts.ResetForm();
      this.$refs.patientinputmask.$refs.patientbankaccountdata.ResetForm();
      this.$refs.patientinputmask.$refs.invoicedata.ResetForm();
      this.$refs.patientinputmask.$refs.keyplacement.ResetForm();
      this.$refs.patientinputmask.$refs.invoicedata.$refs.invoicePrivate.$refs.alternatepaymentaddress.ResetForm();
    },
    OpenDeviceDialog() {
      this.inventorydevicemodaldialogkey++
      this.$store.commit('inventory/changeInventoryDeviceDialogToTrue')
    },
    invoiceChoice(invoiceChoice) {
      if (invoiceChoice == "HN") {
        this.invoiceHN = true
        this.invoiceAUS = false
        this.invoicePrivate = false
      }
      if (invoiceChoice == "AUS") {
        this.invoiceHN = false
        this.invoiceAUS = true
        this.invoicePrivate = false
      }
      if (invoiceChoice == "Private") {
        this.invoiceHN = false
        this.invoiceAUS = false
        this.invoicePrivate = true
      }
      console.log("incoiceChoice: " + invoiceChoice)

    }
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
    this.$root.$refs.PatientsOverview = this;
  },
};
</script>

<style scoped>
div.subNav.v-sheet.theme
light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab.v-tab
active {
  background-color: #751265 !important;
  color: white !important;
}

div.subNav.v-sheet.theme
light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab.v-tab
active
> a {
  background-color: #751265 !important;
  color: white !important;
}

div.subNav.v-sheet.theme
light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab:hover {
  background-color: #951b81 !important;
  color: white !important;
}

div.subNav.v-sheet.theme
light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab:hover
> a {
  background-color: #951b81 !important;
  color: white !important;
}

i {
  margin-right: 10px;
}

svg {
  margin-right: 10px;
}

label.disabled {
  opacity: 0.5;
}

input[type="text"],
text-area {
  border: 1px solid #ab7cab;
  background: white;
  margin: 2px;
}

.header {
  position: fixed;
  /* fixing the position takes it out of html flow - knows
                    nothing about where to locate itself except by browser
                    coordinates */
  left: 0; /* top left corner should start at leftmost spot */
  top: 0; /* top left corner should start at topmost spot */
  width: 100vw; /* take up the full browser width */
  z-index: 200; /* high z index so other content scrolls underneath */
  height: 100px; /* define height for content */
}
</style>
