import {
    authHeader
} from '../helpers';

export const deviceAppointmentService = {
    getAllAutomotives,
    getAllAutomotivesByID,
    newAppointment,
    deleteById,
    update
};

function getAllAutomotives() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/deviceappointments/automotives`, requestOptions).then(handleResponse);
}

function getAllAutomotivesByID(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/deviceappointments/automotives/` + id, requestOptions).then(handleResponse);
}

function newAppointment(patient) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(patient)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/deviceappointment`, requestOptions).then(handleResponse);
}

function update(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/deviceappointment/change/` + data.ID, requestOptions).then(handleResponse);
}


function deleteById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/deviceappointment/delete/` + id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}