<template>
  <main>
    <v-container fluid>
      <h2><v-icon style="color: #006ab5 !important;">mdi-contacts</v-icon>Alle Kostenträger im Überblick</h2>
      <v-row>
        <div
            class="col-3 border border-right border-left-0 border-top-0 border-bottom-0"
        >
          <base-card>
            <InsuranceShortList></InsuranceShortList>
          </base-card>
          <v-btn @click="New" color="primary" elevation="2"
          ><i class="fas fa-plus"></i>Hinzufügen
          </v-btn
          >
        </div>
        <v-col cols="9">
          <base-card>
            <InsuranceEmpty v-if="id == 0 || id == undefined"></InsuranceEmpty>
            <InsuranceDetailed v-else></InsuranceDetailed>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import InsuranceShortList from "../../components/insurances/InsuranceShortList";
import InsuranceDetailed from "../../components/insurances/InsuranceDetailed";
import InsuranceEmpty from "../../components/insurances/InsuranceEmpty";
import BaseCard from "../../components/Base/BaseCard";

export default {
  components: {
    BaseCard,
    InsuranceDetailed,
    InsuranceEmpty,
    InsuranceShortList,
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  computed: {
    id() {
      let x = this.$route.params.id;
      console.log("ID: " + x);
      return this.$route.params.id;
    },
  },
  methods: {
    routeTo(url) {
      if (url.startsWith("http")) {
        window.open(url, "_blank")
      } else {
        this.$router.push(url);
      }
    },
    New() {
      window.location.assign("/insurances");
      this.id = 0;
      this.$root.$emit("reload_insurance_details");
    },
    Delete() {

    }
  },
};
</script>

<style scoped>
i {
  margin-right: 10px;
}
svg {
  margin-right: 10px;
}
</style>