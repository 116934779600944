<template>
<div>
  <v-card
  elevation="1"
  class="card">
  <slot></slot>
  </v-card>
</div>
</template>

<script>
export default {
  name: "BaseCard"
}
</script>

<style scoped>

.card {
  margin: 20px 0 20px 0;
  padding: 20px;
  background-color: rgb(253, 248, 248);
}
</style>