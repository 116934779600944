<template>
  <v-container fluid>
    <h3> Zugewiesene Leistungen </h3>
    <div  v-for="item in patientServiceArticles" :key="item.PatientServiceArticleID">
      <PatientServiceArticle
        :key="patientServiceArticleKey"
        :patientID="patientID"
        :AUS="AUS"
        :HN="HN"
        :serviceArticleID="item.ServiceArticleID"
        :patientServiceArticleID="item.PatientServiceArticleID"
        :showDeleteButton="true"
        :fillFields="true"
        :showAmount="true"
        :Amount="Math.round(item.Amount * 100) / 100"
        @RenderPatientServiceArticle="RenderPatientServiceArticle()"
      />
    </div>
    <v-row class="d-flex justify-end mt-0">
      <v-col cols="3">
        <v-text-field
            v-model="SumFinal"
            class="centered-input"
            append-icon="mdi-currency-eur"
            label="Summe:"
            hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn
      class="primary mt-3 mb-3"
      @click="ShowPatientServiceArticleForm()"
    >
    Neue Leistung hinzufügen
    </v-btn>
    <PatientServiceArticle
      v-if="showservicearticle"
      :showSafeButton="true"
      :showDeleteButton="false"
      :patientID="patientID"
      :AUS="AUS"
      :HN="HN"
      :Private="Private"
      :fillFields="false"
      :showAmount="false"
      @RenderPatientServiceArticle="RenderPatientServiceArticle()"
    />

  </v-container>
</template>

<script>
import PatientServiceArticle from './PatientServiceArticle.vue'
export default {
  name: "InvoiceAssignedBenefits",
  components:{
    PatientServiceArticle
  },
  props:{
    patientID: Number,
    HN: Boolean(false),
    AUS: Boolean(false),
    Private: Boolean(false)
  },
  async created(){
    await this.GetPatientServiceArticles()
    // console.log('****************')
    // console.log('patientServiceArticles:')
    // console.log(this.patientServiceArticles)
  },
  data() {
    return {
      amount: 0,
      price: 0.00,
      sumFinal: 0.00,
      showservicearticle: false,
      patientServiceArticleKey: 0
    };
  },
  computed: {
    SumFinal() {
      let roundedNumber = Math.round(this.CalculateSumm() * 100) / 100 
      roundedNumber = roundedNumber.toFixed(2).replace(".", ",")
      return roundedNumber
    },
    patientServiceArticles(){
        return this.$store.getters['invoicePatient/getServiceArticleToShowDTO']
    }
  },
  methods: {
    incrementAmount() {
      this.amount++;
    },
    decrementAmount() {
      if (this.amount <= 0) {
        return
      }
      this.amount--;
    },
    ShowPatientServiceArticleForm(){
      this.showservicearticle=!this.showservicearticle
    },
    async GetPatientServiceArticles(){
      let mode
      if(this.HN == true){
          mode="HN"
      }
      if(this.AUS == true){
        mode="AUS"
      }
      if(this.Private == true){
        mode="Private"
      }
      let data = {
          PatientID: this.patientID,
          Mode: mode
      }
      await this.$store.dispatch('invoicePatient/getPatientServiceArticlesByPatientIDByMode', data)
    },
    async RenderPatientServiceArticle(){
      await this.GetPatientServiceArticles()
      this.form = this.defaultForm
      this.showservicearticle = false
      this.patientServiceArticleKey++
    },
    CalculateSumm(){
      console.log("****************************")
      let sum = 0
      this.patientServiceArticles.forEach(element => {
        sum += element.Price * element.Amount
      })
      return sum
    }
  },
}
</script>

<style scoped>

.centered-input input {
  text-align: right;
  font-size: 1.10em;
}

</style>