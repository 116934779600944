<template>
  <v-container fluid>
    <v-row class="mt-2">
      <v-col cols="8">
        <TemplateInvoice/>
      </v-col>
      <v-col cols="4">
        <TemplatePlaceholders/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import Template from "../../components/templates/Template";
import TemplateInvoice from "../../components/templates/TemplateInvoice";
import TemplatePlaceholders from "../../components/settings/TemplatePlaceholders.vue"

export default {
  components: {
    TemplateInvoice,
    TemplatePlaceholders
    //Template,
  },
  data() {
    return {
      textVorlage: true,
    };
  },
  mounted() {
    this.reload();
    this.$root.$on("reload_template_data", () => {
      this.reload();
    });
    this.$root.$refs.start.setMenuActive(true);
  },
  computed: {
    isLoading() {
      return this.$store.state.templates.all.loading;
    },
    templateData() {
      let d = this.$store.state.templates.all;
      let x = null;
      if (d.items != undefined) {
        x = d.items;
      }
      return x;
    },
  },
  methods: {
    async reload() {
      this.$store.dispatch("templates/reset");
      await this.$store.dispatch("templates/getAll");
      await this.$store.dispatch("templatecategories/getAll");
    },
    NewTemplate() {
      let template = {
        Content: "Dies ist meine neue Vorlage",
        TemplateCategoryID: 1,
      };

      console.log(template);

      this.$store
          .dispatch("templates/newTemplate", template)
          .then((response) => {
            console.log(response);
            this.reload();
          });
    },
    showTextvorlage() {
      this.textVorlage = !this.textVorlage;
      this.templateAbrechnung = false;
    },
  },
};
</script>
