export function validateEmail(value) {
    if (!value.includes("@")) {
      return false;
    }
    if (
      !(
        value.includes(".de") ||
        value.includes(".com") ||
        value.includes(".eu") ||
        value.includes(".ch") ||
        value.includes(".cc") ||
        value.includes(".org") ||
        value.includes(".net") ||
        value.includes(".us") ||
        value.includes(".biz") ||
        value.includes(".co")
      )
    ) {
      return false;
    }
    return true;
  }

  export function  validatePLZ(value) {
    var pattern = /\d\d\d\d\d/;
    if (!value.match(pattern)) {
      return false;
    }
    return true;
  }

  export function  validateKFZ(value) {
    var pattern = /[A-ZÖÜÄ]{1,3}-[A-ZÖÜÄ]{1,2}-[1-9]{1}[0-9]{1,3}/g;
    if (!value.match(pattern)) {
      return false;
    }
    return true;
  }
  export function validatePhoneNumber(value) {
    var phoneno = /^\+?([0-9]{2})\)?[ ]?([0-9]{4})[ ]?([0-9]+)$/;
    if (!value.match(phoneno)) {
      console.log("No valid phone");
      return false;
    }
    console.log("valid");
    return true;
  }

  export function validateIBAN(value) {
    var reg = /^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/;
    if (!value.match(reg)) {
      console.log("No valid IBAN");
      return false;
    }
    console.log("valid");
    return true;
  }