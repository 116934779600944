<template>
  <div>
    <v-row v-if="!employeeExist">
      <v-col>
        <p>kein Mitarbeiter hinterlegt</p>
      </v-col>
    </v-row>
    <row :gutter="12" v-if="employeeExist">
      <column :xs="1">
        <v-chip
            class="ml-4 mt-2"
            :color="getColor(employee)">
          {{ employee.Personalnumber }}
        </v-chip>
      </column>
      <column :lg="5">
        <v-text-field
            class="mr-2"
            :value="employee.Firstname"
            label="Vorname"
            disabled
        ></v-text-field>
      </column>
      <column :lg="6">
        <v-text-field
            :value="employee.Lastname"
            label="Nachname"
            disabled
        ></v-text-field>
      </column>
    </row>

  </div>
</template>
<script>
export default {
  props: {
    employeeID: Number
  },
  data() {
    return {
      employeeExist: true
    }
  },
  async created() {
    console.log("employeeID: " + this.employeeID)
    if (this.employeeID != 0) {
      console.log("if")
      await this.$store.dispatch('employees/getEmployeeOverview', this.employeeID)
    } else {
      console.log("else")
      this.employeeExist = false
    }
  },
  computed: {
    employee() {
      if (this.patientid != 0) {
        return this.$store.state.employees.employeeoverview.item
      } else {
        return ""
      }
    }
  },
  methods: {
    getColor(item) {
      console.log(item)
      if (item.EmployeeColorHex == "") {
        return "#FFFFFFFF"
      } else {
        return item.EmployeeColorHex
      }
    },
  }
}
</script>