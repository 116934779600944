<template>
  <div>
    <h3>Neuen Kostenträger anlegen</h3>
    <div class="row">
      <v-col cols="12">
        <base-card>

        <v-row>
          <v-col cols="6">
            <v-text-field
                label="Name"
                placeholder="-"
                hide-details="auto"
                tabindex="1"
                v-model="insuranceName"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
                label="Debitoren-Nr."
                hide-details="auto"
                readonly
                disabled
                tabindex="1"
                v-model="DebitorenNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-checkbox
                :label="`Pflegekasse`"
                tabindex="11"
                v-model="isCareHealthInsurance"
            ></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-checkbox
                class="ml-8"
                :label="`Krankenkasse`"
                tabindex="12"
                v-model="isNormalInsurance"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-btn @click="New" color="primary" elevation="2" style="float: right" class="mt-3 mr-5"><i
                class="fas fa-save"></i> Speichern
            </v-btn>
          </v-col>
        </v-row>
        </base-card>

        <v-alert
            border="left"
            colored-border
            type="info"
            elevation="2"
        >
          Bitte speichern Sie erst die obrigen Daten um die unten stehenden Felder bearbeiten zu können.
        </v-alert>
        <span></span>
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
            <h3>Adresse</h3>
            <v-divider inset></v-divider>
            <v-btn disabled color="primary" class="mt-3"><i class="fas fa-plus"></i> Neue Adresse hinzufügen</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
            <h3>Bankdaten und Abrechnung</h3>
            <template>
              <v-divider inset></v-divider>
              <v-btn disabeld color="primary" class="mb-3" disabled>
                <i class="fas fa-plus"></i> Neue Bankverbindung hinzufügen
              </v-btn>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
            <h3>Kontakte</h3>
            <v-divider inset></v-divider>
            <v-btn color="primary" class="mb-3 mt-3" disabled><i class="fas fa-plus"></i> Neuer Kontakt</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>


<script>
import BaseCard from "../Base/BaseCard";
export default {
  components: {BaseCard},
  data() {
    return {
      insuranceName: "",
      isCareHealthInsurance: false,
      isNormalInsurance: false,
      ik: "",
      DebitorenNumber: undefined
    };
  },
  computed: {
    userID() {
      let usr = this.$store.state.authentication.userID;
      return usr;
    },
  },
  methods: {
    New() {
      let insurance = {
        InsuranceName: this.insuranceName,
        IsCareHealthInsurance: this.isCareHealthInsurance,
        IsNormalInsurance: this.isNormalInsurance,
        IK: this.ik,
        DebitorenNumber: parseInt(this.DebitorenNumber)
      };

      this.$store.dispatch("insurances/create", insurance).then((response) => {
        this.$root.$emit("reload_insurance_list");
        this.$root.$emit("reload_insurance_details");
        this.$router.push("/insurance/" + response.ID);
      });
    }
  }
}
</script>

<style scoped>
i {
  margin-right: 10px;
}
svg {
  margin-right: 10px;
}
</style>