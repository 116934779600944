import {patientMedicationService} from '../services';

export const patientMedication = {
    namespaced: true,
    state:{
        medication: {}
    },
    getters:{
        getMedication: state => {
            return state.medication.items
        }
    },
    actions:{
        newMedication({commit},medicationitem){
            return new Promise((resolve, reject) => {
                patientMedicationService.newMedication(medicationitem).then(
                    payload => {
                        commit('getMedicationByPatientIDSuccess', payload);
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getMedicationByPatientID({commit},patientID){
            return new Promise((resolve, reject) => {
                patientMedicationService.getMedicationByPatientID(patientID).then(
                    payload => {
                        commit('getMedicationByPatientIDSuccess', payload);
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
    },
    mutations:{
        getMedicationByPatientIDSuccess(state, payload){
            state.medication = {items: payload}
        }
    },
}