import {userService} from '../services'

export const networkUtils = {
    httpRequest,
};

async function httpRequest(ressource, requestOptions){
    let response = await fetch(process.env.VUE_APP_API_ROUTE + ressource, requestOptions);
    let data = await handleResponse(response);
    return data;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //console.log(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

/*function handleResponseFile(response){
    return response.blob().then(blob => {
        if(!response.ok){
            if(response.status === 401){
                userService.logout();
                location.reload(true)
            }
            const error = (blob && blob.message) || response.statusText;
            return Promise.reject(error);
        }
        const objectURL = window.URL.createObjectURL(blob);
        return objectURL
    })
}*/