import {authHeader} from '../helpers';
import {store} from '../store'

export const invoicePdfService = {
    getInvoicePdf,
    downloadInvoicePdfByID
}
async function getInvoicePdf(invoicePdfFileInfoDTO){
    console.log(JSON.stringify(invoicePdfFileInfoDTO))
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(invoicePdfFileInfoDTO),
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/pdf/invoice/get`, requestOptions)
    console.log("test")
    let data = await downloadFileStream(response)
    return data
}

async function downloadInvoicePdfByID(invoiceGoID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/pdf/invoice/download/id/` + invoiceGoID, requestOptions)
    console.log("test")
    let data = await downloadFileStream(response)
    return data
}


async function downloadFileStream(response){
    console.log("downloadFileStream")
    const contenttype = response.headers.get('Content-Type')
    let splitcontenttypearray = contenttype.split(';')
    console.log("contenttype:" + contenttype)
    let filename = getValueFromSplitContenttypeArray(splitcontenttypearray[0])
    let filesize = getValueFromSplitContenttypeArray(splitcontenttypearray[1])
    store.commit('loading/setTotalBytes', Number(filesize))
    console.log("filename: " + filename)
    const reader = response.body.getReader()
    //let receivedLength = 0
    let chunks = []
    let finished = false
    store.commit('loading/setLoadedBytestoZero')
    while(!finished){
        const {done, value} = await reader.read()
        //console.log("done: " + done)
        if(done){
            break
        }
        chunks.push(value)
        store.commit('loading/setLoadedBytes', value.length)
        //receivedLength += value.length
        //console.log(`Received ${receivedLength}`)
        //console.log(`Content-Disposition ${contentdisposition}`)
        finished = done
    }
    let blob = new Blob(chunks)
    let fileInformation = {
        fileblob: blob,
        filename: filename,
        filesize: filesize
    }
    return fileInformation
}

function getValueFromSplitContenttypeArray(splitcontenttype){
    let array = splitcontenttype.split('=')
    let value = array[1]
    return value
}