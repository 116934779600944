<template>
    <main>
        <h3>Feiertage</h3>
        <v-row class="mt-1">
            <v-col>
                <v-row>
                    <v-select
                        label="Bundesland wählen"
                        v-model="bundesland"
                        :items="bundeslaender"
                        item-text="land"
                        item-value="abbr"
                        @change="GetSpecialHolidays(bundesland)"
                    >
                    </v-select>
                </v-row>
                <FeiertagChoice
                    v-for="item in specialHolidays"
                    :key="item.ID"
                    :feiertag="item"
                />
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="SaveSpecialHolidayConfig()"
                    >
                        Speichern
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
    </main>
</template>
<script>
import FeiertagChoice from './FeiertagChoice.vue'
export default {
    components: {
        FeiertagChoice
    },
    async created() {
        await this.GetSpecialHolidayConfig()
        this.bundesland = this.specialHolidays[0].Bundesland
    },
    data(){
        return {
            bundeslaender: [
                {land: 'Baden Württemberg', abbr: 'BW'}, {land: 'Bayern', abbr: 'BY'}, {land: 'Berlin', abbr: 'BE'}, 
                {land: 'Brandenburg', abbr: 'BB'}, {land: 'Bremen', abbr: 'HB'}, {land: 'Hamburg', abbr: 'HH'},
                {land: 'Hessen', abbr: 'HE'},{land: 'Mecklenburg Vorpommern', abbr: 'MV'},{land: 'Niedersachsen', abbr:'NI'}, 
                {land: 'Nordrhein Westfalen', abbr: 'NW'}, {land: 'Rheinland Pfalz', abbr: 'RP'}, {land: 'Saarland', abbr: 'SL'},
                {land: 'Sachsen', abbr: 'SN'},{land: 'Sachsen Anhalt', abbr: 'ST'},{land: 'Schleswig Holstein', abbr: 'SH'},
                {land: 'Thüringen', abbr: 'TH'}],
            bundesland: "",
        }
    },
    computed: {
        specialHolidays(){
            return this.$store.getters['settings/getAllHolidayConfig']
        },
    },
    methods: {
        async GetSpecialHolidayConfig(){
            await this.$store.dispatch('settings/getSpecialHolidayConfig')
        },
        async GetSpecialHolidays(bundesland){
            this.$store.commit('settings/emptyHolidayConfig')
            await this.$store.dispatch("settings/getAllSpecialHolidaysByBundesland", bundesland)
        },
        async SaveSpecialHolidayConfig(){
            let specialHolidayConfigDTO = []
            for (let i = 0; i < this.specialHolidays.length; i++){
                let config = {
                    Name:   this.specialHolidays[i].Name,
                    Bundesland: this.bundesland,
                    Activ: this.specialHolidays[i].Activ
                }
                console.log(config)
                specialHolidayConfigDTO.push(config)
            }
            console.log(JSON.stringify(specialHolidayConfigDTO))
            await this.$store.dispatch("settings/saveSpecialHolidayConfig", specialHolidayConfigDTO)
            console.log("vor emit")
            this.$emit('RefreshSettings')
        }
    }
}
</script>