import {
    deviceAppointmentService
} from '../services';

export const deviceAppointments = {
    namespaced: true,
    state: {
        automotives: {},
        selected: {}
    },
    actions: {
        reset({
            commit
        }) {
            commit('automotiveReset');
        },

        getAllAutomotives({
            commit
        }) {
            commit('getAllAutomotiveRequest');
            return new Promise((resolve, reject) => {
                deviceAppointmentService.getAllAutomotives()
                    .then(
                        data => {
                            commit('getAllAutomotiveSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getAllAutomotiveFailure', error)
                            reject(error)
                        }
                    );
            })
        },
        getAllAutomotivesByID({
            commit
        }, payload) {
            commit('getSpecificAutomotiveRequest');
            return new Promise((resolve, reject) => {
                deviceAppointmentService.getAllAutomotivesByID(payload)
                    .then(
                        data => {
                            commit('getSpecificAutomotiveSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getSpecificAutomotiveFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        newAppointment({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                deviceAppointmentService.newAppointment(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        change({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                deviceAppointmentService.update(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        delete({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                deviceAppointmentService.deleteById(id)
                    .then(
                        insurance => {
                            commit('deleteSuccess', insurance);
                            resolve(insurance)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },
    },
    mutations: {
        reset(state) {
            state.automotives = {
                items: null
            };
            state.automotives = {
                loading: false
            };
        },
        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },
        deleteRequest(state) {
            state.delete = {
                loading: true
            }; //Wird geladen status
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Objekt das vom Server zurückgegeben wird
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },
        getSpecificAutomotiveRequest(state) {
            state.selected = {
                loading: true
            };
        },
        getSpecificAutomotiveSuccess(state, data) {
            state.selected = {
                items: data
            };
        },
        getSpecificAutomotiveFailure(state, error) {
            state.selected = {
                error
            };
        },
        getAllAutomotiveRequest(state) {
            state.automotives = {
                loading: true
            };
        },
        getAllAutomotiveSuccess(state, data) {
            state.automotives = {
                items: data
            };
        },
        getAllAutomotiveFailure(state, error) {
            state.automotives = {
                error
            };
        }
    }
}