import {authHeader} from '../helpers';
import {userService} from '../services';

export const invoicePatientService = {
    getInsuranceForInvoiceChoiceDTO,
    getPatientDataForInvoiceDTOByPatientID,
    savePatientDataForInvoiceDTO,
    getAllInvoiceTemplatesDTO,
    savePatientTemplateConfigDTO,
    getPatientTemplateConfigDTOByPatientID,
    getServiceArticlesDTO,
    safePatientServiceArticle,
    getPatientServiceArticlesByPatientIDByMode,
    savePrivatePrepaidDTO,
    deletePatientServiceArticleByID
}

async function deletePatientServiceArticleByID(patientServiceArticleID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/patientservicearticle/delete/` + patientServiceArticleID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function savePrivatePrepaidDTO(privatePrepaidDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(privatePrepaidDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/privateprepaid/save`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPatientServiceArticlesByPatientIDByMode(patientID, mode){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/patientservicearticle/get/` + patientID +`/` + mode, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function safePatientServiceArticle(patientServiceArticleForSafeDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(patientServiceArticleForSafeDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/patientservicearticle/save`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getServiceArticlesDTO(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/servicearticles/get`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPatientTemplateConfigDTOByPatientID(patientID, invoiceChoice){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/templateconfig/get/` + patientID + `/` + invoiceChoice, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function savePatientTemplateConfigDTO(patientTemplateConfigDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(patientTemplateConfigDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/templateconfig/save`, requestOptions);
    let data = await handleResponse(response);
    return data;
}


async function getAllInvoiceTemplatesDTO(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/templates/get`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function savePatientDataForInvoiceDTO(insurancePatientDTO){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(insurancePatientDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/data/save`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getPatientDataForInvoiceDTOByPatientID(patientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/data/get/` + patientID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getInsuranceForInvoiceChoiceDTO(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/patient/insurances/get`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //console.log(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}