<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="InsuranceContact.Title"
          :items="titles"
          tabindex="1"
          placeholder="Anrede"
        >
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Vorname"
          placeholder="Vorname"
          hide-details="auto"
          :rules="rules"
          tabindex="3"
          v-model="InsuranceContact.FirstName"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Nachname"
          placeholder="Nachname"
          :rules="rules"
          hide-details="auto"
          tabindex="3"
          v-model="InsuranceContact.LastName"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Straße"
          placeholder="Straße"
          hide-details="auto"
          :rules="rules"
          tabindex="3"
          v-model="InsuranceContact.Street"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="PLZ"
          placeholder="12345"
          hide-details="auto"
          tabindex="3"
          :rules="plzRules"
          v-model="InsuranceContact.PLZ"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Ort"
          placeholder="Ort"
          :rules="rules"
          hide-details="auto"
          tabindex="3"
          v-model="InsuranceContact.City"
        ></v-text-field>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
            <v-text-field
                label="Telefon"
                placeholder="+49 1234 5678"
                hide-details="auto"
                tabindex="3"
                v-model="InsuranceContact.Phone"></v-text-field>
        </v-col>
        <v-col cols="4">
            <v-text-field
                label="Position"
                placeholder="Position"
                hide-details="auto"
                tabindex="3"
                v-model="InsuranceContact.Position"></v-text-field>
        </v-col>  
        <v-col cols="4">
            <v-text-field
                label="Abteilung"
                placeholder="Abteilung"
                hide-details="auto"
                tabindex="3"
                v-model="InsuranceContact.Department"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          
      <v-btn @click="Save" color="primary" elevation="2" class="mb-3 ml-1" style="float: right"><i class="fas fa-save"></i> Speichern </v-btn>
      <v-btn @click="Delete" color="error" class="ml-3" style="float: right"><i class="fas fa-trash"></i> Löschen </v-btn>
        </v-col>
      </v-row>
  </v-col>
</template>

<script>
import {validateEmail, validatePhoneNumber, validatePLZ } from '../../helpers/validator';

export default {
  data() {
    return {
      InsuranceContact: {
        Title: this.contactTitle,
        FirstName: this.contactFirstName,
        LastName: this.contactLastName,
        EMail: this.contactEmail,
        Phone: this.contactPhone,
        ID: this.contactId,
        Position: this.contactPosition,
        Department: this.contactDepartment,
        Note: this.contactNote,
        Street: this.contactStreet,
        PLZ: this.contactPlz,
        City: this.contactCity,
        InsuranceID: this.patientId,
      },

      titles: ["Frau", "Herr", "Divers"],

      rules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
          (value && value.length >= 2) || "Mindestens 2 Zeichen eingeben",
      ],

      phoneRules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) => (value && validatePhoneNumber(value)) || "Keine gültige Telefonnummer",
      ],

      emailRules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) => (value && validateEmail(value)) || "Keine gültige E-Mail Adresse",
      ],

      plzRules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) => (value && validatePLZ(value)) || "Keine gültige Postleitzahl",
      ],
    };
  },
  props: {
    patientId: Number,
    contactTitle: String,
    contactFirstName: String,
    contactLastName: String,
    contactEmail: String,
    contactPhone: String,
    contactId: Number,
    contactPosition: String,
    contactDepartment: String,
    contactNote: String,
    contactStreet: String,
    contactPlz: String,
    contactCity: String
  },
  methods: {
    Save() {
      this.submitted = true;
      console.log(this.InsuranceContact);

      this.$store
        .dispatch("insurances/updateContact", this.InsuranceContact)
        .then((address_response) => {
          console.log(address_response.ID);
        });
    },
    Delete() {
      this.$store
            .dispatch("insurances/deleteContact", this.InsuranceContact)
            .then((address_response) => {
              console.log(address_response.ID);
              this.submitted = false;
              this.$root.$emit("reload_insurance_details");
            });
    },
  },
};
</script>
<style scoped>
i {
  margin-right: 10px;
}
svg {
  margin-right: 10px;
}
</style>