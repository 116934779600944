<template>
    <v-dialog
        style="color: white"
        ref="dialog"
        v-model="modaldatepicker"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datetoshow"
            label="Wählen Sie ein Datum"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          scrollable
          locale="de"
          :first-day-of-week="1"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modaldatepicker = false"
          >
            Schließen
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="SaveDate()"
          >
            OK
          </v-btn>
        </v-date-picker>
    </v-dialog>
</template>
<script>
export default {
    props: {
        modal: Boolean(false),
        date: String
    },
    data() {
      return{
        modaldatepicker: false
      }
    },
    computed:{
      datetoshow(){
        let array = this.date.split('-')
        let newdate = array[2] + "." + array[1] + "." + array[0]
        return newdate
      }
    },
    methods: {
      SaveDate(){
        //this.$root.$refs.EmployeeRosterEventDialog.setNewDate(this.date);
        this.$refs.dialog.save(this.date)
        this.modaldatepicker = false
        console.log("vor emit")
        this.$emit('SendDate', this.date)
      }
    }
}
</script>