var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',[(_vm.id == 0)?[_c('h3',[_vm._v("Neues Auto anmelden")])]:[_c('h3',[_c('v-icon',{staticStyle:{"color":"#006ab5 !important"}},[_vm._v("mdi-car")]),_vm._v(" Neues Auto anmelden")],1)],_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Daten zum Auto")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Nummer","rules":_vm.rules,"hide-details":"auto","tabindex":"1","disabled":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Fahrgestellnummer","rules":_vm.rules,"hide-details":"auto","tabindex":"1"},model:{value:(_vm.chassisNumber),callback:function ($$v) {_vm.chassisNumber=$$v},expression:"chassisNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Kennzeichen *","rules":_vm.kfzRules,"hide-details":"auto","tabindex":"1"},model:{value:(_vm.kfzNr),callback:function ($$v) {_vm.kfzNr=$$v},expression:"kfzNr"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.manufacturers,"item-text":"Name","item-value":"ID","tabindex":"1","placeholder":"Hersteller *"},on:{"change":function($event){return _vm.HandleManufacturerChanged(_vm.selectedManufacturer)}},model:{value:(_vm.selectedManufacturer),callback:function ($$v) {_vm.selectedManufacturer=$$v},expression:"selectedManufacturer"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"datedialog",attrs:{"return-value":_vm.datehuau,"persistent":"","tabindex":"4","width":"290px"},on:{"update:returnValue":function($event){_vm.datehuau=$event},"update:return-value":function($event){_vm.datehuau=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"HU / AU *","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.datehuau),callback:function ($$v) {_vm.datehuau=$$v},expression:"datehuau"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datemodal),callback:function ($$v) {_vm.datemodal=$$v},expression:"datemodal"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"de-de"},model:{value:(_vm.datehuau),callback:function ($$v) {_vm.datehuau=$$v},expression:"datehuau"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datemodal = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.datedialog.save(_vm.datehuau)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.fuels,"item-text":"Name","item-value":"ID","tabindex":"1","placeholder":"Kraftstoff *"},model:{value:(_vm.selectedfuel),callback:function ($$v) {_vm.selectedfuel=$$v},expression:"selectedfuel"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Modell *","rules":_vm.rules,"hide-details":"auto","tabindex":"1"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-text-field',{attrs:{"label":"Kilometerstand","rules":_vm.rules,"hide-details":"auto","tabindex":"1"},model:{value:(_vm.KM),callback:function ($$v) {_vm.KM=$$v},expression:"KM"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-dialog',{ref:"datekm",attrs:{"return-value":_vm.datekm,"persistent":"","tabindex":"4","width":"290px","disabled":""},on:{"update:returnValue":function($event){_vm.datekm=$event},"update:return-value":function($event){_vm.datekm=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"KM Datum","prepend-icon":"mdi-calendar","readonly":"","disabled":""},model:{value:(_vm.datekm),callback:function ($$v) {_vm.datekm=$$v},expression:"datekm"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datekmmodal),callback:function ($$v) {_vm.datekmmodal=$$v},expression:"datekmmodal"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"de-de"},model:{value:(_vm.datekm),callback:function ($$v) {_vm.datekm=$$v},expression:"datekm"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datekmmodal = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.datekm.save(_vm.datekm)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-dialog',{ref:"dateyear",attrs:{"return-value":_vm.dateyr,"persistent":"","tabindex":"4","width":"290px"},on:{"update:returnValue":function($event){_vm.dateyr=$event},"update:return-value":function($event){_vm.dateyr=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Baujahr *","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateyr),callback:function ($$v) {_vm.dateyr=$$v},expression:"dateyr"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateyrmodal),callback:function ($$v) {_vm.dateyrmodal=$$v},expression:"dateyrmodal"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"de-de"},model:{value:(_vm.dateyr),callback:function ($$v) {_vm.dateyr=$$v},expression:"dateyr"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateyrmodal = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateyear.save(_vm.dateyr)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-dialog',{ref:"dateregister",attrs:{"return-value":_vm.datezlyr,"persistent":"","tabindex":"4","width":"290px"},on:{"update:returnValue":function($event){_vm.datezlyr=$event},"update:return-value":function($event){_vm.datezlyr=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Erstzulassung *","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.datezlyr),callback:function ($$v) {_vm.datezlyr=$$v},expression:"datezlyr"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateregistermodal),callback:function ($$v) {_vm.dateregistermodal=$$v},expression:"dateregistermodal"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"de-de"},model:{value:(_vm.datezlyr),callback:function ($$v) {_vm.datezlyr=$$v},expression:"datezlyr"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateregistermodal = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateregister.save(_vm.datezlyr)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.employeeNames,"item-value":"id","item-text":"name","label":"Fahrer","multiple":""},on:{"change":_vm.SelectedDriversChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.selecteddrivers.length - 1)+" weitere) ")]):_vm._e()]}}]),model:{value:(_vm.selecteddrivers),callback:function ($$v) {_vm.selecteddrivers=$$v},expression:"selecteddrivers"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Tankkarte","hide-details":"auto","tabindex":"1"},model:{value:(_vm.card),callback:function ($$v) {_vm.card=$$v},expression:"card"}}),_c('v-btn',{staticClass:"mt-3 mr-5",staticStyle:{"float":"right"},attrs:{"color":"primary","elevation":"2","disabled":!_vm.btnEnabled},on:{"click":_vm.NewCar}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Speichern ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" Bitte speichern Sie erst die obrigen Daten um die unten stehenden Felder bearbeiten zu können. ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('h3',[_vm._v("Anstehende Termine")])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }