<template>
  <v-card color="primary" dark style="padding:10px">
    <v-alert
        v-model="alert"
        dismissible
        type="error"
        color="red"
    >
      {{ alertmessage }}
    </v-alert>
    <v-card-text>
      <a class="titleStyle"> <b>Zeit</b></a>
      <row>
        <column :xs="12" :lg="4">
          <DatePicker
              :date="date"
              :modal="modaldatepicker"
              @SendDate="GetDate"
          />
        </column>
          <column :xs="12" :lg="4">
            <TimePicker
                label="Start"
                :time="timestart"
                :modal="modaltimestart"
                @SendTime="GetTimeStart"
            />
          </column>
        <column :xs="12" :lg="4">
          <TimePicker
              label="Ende"
              :time="timeend"
              :modal="modaltimeend"
              @SendTime="GetTimeEnd"
          />
        </column>
      </row>

      <a class="titleStyle"> <b>Mitarbeiter</b></a>
      <EmployeeInfo
          :key="employeeinfokey"
          :employeeID="employeeID"
      />
      <a class="titleStyle"> <b>Klient</b></a>
      <PatientInfo
          :key="patientinfokey"
          :patientid="patientID"
      />
      <a class="titleStyle"> <b>Leistung</b></a>
      <PatientServiceArticleInfo
          :key="patientservicearticleinfokey"
          :calendarEntryPresent="false"
      />
      <row :gutter="12">
        <column>
          <v-btn
              class="mt-2"
              color="secondary"
              elevation="2"
              @click="ShowEmployee()">
            Mitarbeiter hinzufügen
          </v-btn>
        </column>

        <column :xs="12" v-if="showemployees">
          <EmployeeTable
              :employees="employees"
              @sendEmployeeID="GetEmployeeID"
          />
        </column>
      </row>
      <row :gutter="12">
        <column>
          <v-btn
              color="secondary"
              elevation="2"
              @click="ShowPatientList()"
          >
            Klient hinzufügen
          </v-btn>
        </column>
        <column :xs="12" v-if="showpatientlist">
          <PatientList
              @sendPatientID="GetPatient"
          />
        </column>

      </row>
      <row :gutter="12">
        <column>
          <v-btn
              color="secondary"
              elevation="2"
              @click="ShowServices()"
          >
            Artikel hinzufügen
          </v-btn>
        </column>
        <column :xs="12" v-if="showservices">
          <ServiceList
              @sendPatientServiceArticleID="GetPatientServiceArticleID"
              :patientID="patientID"
          />
        </column>
      </row>
    </v-card-text>

    <v-card-actions>
    <row :gutter="12">
      <column :ld="1">
          <v-btn
              class="ml-2 mb-2"
              color="info"
              @click="SaveNewEvent()">
            Speichern und Schließen
          </v-btn>
        <v-btn
            class="ml-2 mb-2"
            color="info"
            @click="CloseDialog()">
          Abbrechen
        </v-btn>
      </column>
    </row>
    </v-card-actions>

  </v-card>
</template>
<script>
import PatientInfo from "./PatientInfo"
import PatientList from "./PatientList"
import DatePicker from "../utils/DatePicker.vue"
import TimePicker from "../utils/TimePicker.vue"
import ServiceList from "../dialogs/ServiceList.vue"
import PatientServiceArticleInfo from "../dialogs/PatientServiceArticleInfo.vue"
import EmployeeTable from '../employees/overview/EmployeeTable.vue'
import EmployeeInfo from "./EmployeeInfo.vue"

export default {
  components: {
    PatientInfo,
    PatientList,
    DatePicker,
    TimePicker,
    ServiceList,
    PatientServiceArticleInfo,
    EmployeeTable,
    EmployeeInfo
  },
  props: {
    date: String
  },
  created() {
    this.$store.dispatch('employees/getAll')
  },
  computed: {
    employees() {
      return this.$store.state.employees.all.items;
    }
  },
  data() {
    return {
      showpatientlist: false,
      patientID: 0,
      patientinfokey: 0,
      timestart: undefined,
      timeend: undefined,
      modaltimestart: false,
      modaltimeend: false,
      modaldatepicker: false,
      showservices: false,
      patientServiceArticleID: 0,
      patientservicearticleinfokey: 100,
      showemployees: false,
      employeeID: 0,
      employeeinfokey: 0,
      alert: false,
      alertmessage: "",
    }
  },
  methods: {
    CloseDialog() {
      this.$store.commit('eventdialog/changeEmployeeCreateNewEventDialogToFalse')
      this.$store.commit('patientServiceArticles/deletePatientServiceArticlesForCreateCalendar')
    },
    async ShowPatientList() {
      if (this.showpatientlist == false) {
        await this.$store.dispatch("patients/getAll")
        this.showpatientlist = true
      } else {
        this.showpatientlist = false
      }
    },
    ShowEmployee() {
      if (this.showemployees == false) {
        this.showemployees = true
      } else {
        this.showemployees = false
      }
    },
    GetEmployeeID(id) {
      this.employeeID = id
      this.employeeinfokey++
    },
    GetPatient(patientid) {
      console.log("patientID: " + patientid)
      this.patientID = patientid
      this.RenderPatientInfo()
    },
    RenderPatientInfo() {
      this.patientinfokey++
    },
    ShowServices() {
      console.log("test")
      if (this.showservices == false) {
        this.showservices = true
      } else {
        this.showservices = false
      }
    },
    async GetPatientServiceArticleID(ID) {
      console.log("PatientServiceArticleID: " + ID)
      this.patientServiceArticleID = ID
      await this.$store.dispatch('patientServiceArticles/getPatientServiceArticleForCreateCalendarByID', this.patientServiceArticleID)
      this.patientservicearticleinfokey++

    },
    async SaveNewEvent() {
      if (this.timestart == undefined || this.timeend == undefined) {
        this.SetAlertMessage("Bitte Anfangs- und Endzeit setzen")
        return
      }
      if (this.employeeID == 0) {
        this.SetAlertMessage("Bitte hinterlegen Sie für den Termin einen Mitarbeiter")
        return
      }
      let timestampstart = this.GetTimestamp(this.timestart)
      let timestampend = this.GetTimestamp(this.timeend)
      if (timestampstart >= timestampend) {
        this.SetAlertMessage("Die Startzeit muss vor der Endzeit des Termins liegen")
        return
      }
      let patientID = this.patientID
      let ids = this.$store.getters['patientServiceArticles/getServiceArticleIDs']
      let employeeID = this.employeeID
      let createCalendarWithInputScreenDTO = {
        Start: timestampstart,
        End: timestampend,
        PatientID: patientID,
        PatientServiceArticleID: ids,
        EmployeeID: employeeID
      }
      await this.$store.dispatch('employees/createEmployeeCalendarWithInputScreen', createCalendarWithInputScreenDTO)
      this.$emit('SendDate', this.date)
      this.$emit('RefreshCalendar')
      this.CloseDialog()
    },
    GetTimestamp(time) {
      let year = this.GetYear()
      let month = this.GetMonth()
      let day = this.GetDay()
      let hour = this.GetHour(time)
      let minute = this.GetMinute(time)
      let date = new Date(year, month - 1, day, hour, minute)
      console.log(date)
      return date.getTime()
    },
    GetHour(time) {
      let split = time.split(':')
      let hour = split[0]
      return this.GetNumberWithoutZero(hour)
    },
    GetMinute(time) {
      let split = time.split(':')
      let minute = split[1]
      return this.GetNumberWithoutZero(minute)
    },
    GetYear() {
      let split = this.date.split('-')
      let year = Number(split[0])
      return year
    },
    GetMonth() {
      let split = this.date.split('-')
      let month = split[1]
      return this.GetNumberWithoutZero(month)
    },
    GetDay() {
      let split = this.date.split('-')
      let day = split[2]
      return this.GetNumberWithoutZero(day)
    },
    GetNumberWithoutZero(number) {
      if (number[0] == '0') {
        let numberwithoutzero = Number(number[1])
        return numberwithoutzero
      } else {
        let numberwithoutzero = Number(number)
        return numberwithoutzero
      }
    },
    GetDate(date) {
      console.log("date: " + date)
      this.date = date
    },
    GetTimeStart(time) {
      this.timestart = time
    },
    GetTimeEnd(time) {
      this.timeend = time
    },
    SetAlertMessage(message) {
      this.alert = true
      this.alertmessage = message
    }
  }
}
</script>

<style scoped>
.titleStyle {
  color: white;
  font-weight: bold;
}
</style>