<template>
    <v-overlay
        :absolute="true"
        :value="overlay"
    >
    <v-progress-circular v-if="loading"
        indeterminate
        size="64"
    >
    </v-progress-circular>
        <v-row v-if="errorinfo">
            <v-col>
                <v-row>
                    <h3 style="color: white !important;">{{errormessage}}</h3>
                </v-row>
                <v-row>
                    <v-btn
                        color="info"
                        @click="CloseOverlayAndErrorinfo()"
                    >
                        Ok
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-overlay>
</template>
<script>
export default {
    props: {
        absolute: Boolean,
        overlay: Boolean,
        errormessage: String,
        errorinfo: Boolean
    },
    methods: {
        CloseOverlayAndErrorinfo(){
            this.$emit("CloseOverlayAndErrorinfo")
        }
    }
}
</script>