<template>
  <div class="denscol">
    <v-container fluid>
      <v-row>
        <v-select
            label="Kostenträger"
            class="ml-3 mt-0 mr-3"
            hide-details="auto"
            placeholder="Unbekannt"
            :items="insurances"
            item-text="InsuranceName"
            item-value="InsuranceID"
            v-model="form.invoicepatientdata.InsuranceID"
            @change="FillCheckBox()"
        >
        </v-select>
        <v-checkbox
            disabled
            readonly
            v-model="form.invoicepatientdata.isNormalInsurance"
        >
          <template v-slot:label>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a class="mr-2" v-on="on"> KK </a>
                </template>
                Krankenkasse
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>

        <v-checkbox
            disabled
            readonly
            v-model="form.invoicepatientdata.isCareHealthInsurance"
        >
          <template v-slot:label>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a class="mr-3" v-on="on"> PK </a>
                </template>
                Pflegekasse
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
              label="Versicherungsnummer:"
              placeholder="Versicherungsnummer"
              hide-details="auto"
              disabled
              v-model="form.invoicepatientdata.HealthInsuranceNumber"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field v-if="HN"
                        label="Klient-ID:"
                        placeholder="Klient-ID"
                        hide-details="auto"
                        v-model="form.invoicepatientdata.KlientID"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-row
          class="d-flex justify-end">
        <v-col cols="3">
          <v-checkbox
            label="monatlich wiederkehrende Artikel"
            v-model="form.invoicepatientdata.TaxCreditRecurring"
            @change="ToggleOtherCheckbox()"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            label="manuell gebuchte Artikel"
            v-model="form.invoicepatientdata.TaxCreditManual"
            @change="ToggleOtherCheckbox()"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-text-field
              class="centered-input"
              append-icon="mdi-currency-eur"
              label="Entlastungsbetrag:"
              hide-details="auto"
              v-model="form.invoicepatientdata.TaxCredit"
          ></v-text-field>
        </v-col>

        <v-col cols="3"
               class="mb-4">
          <v-text-field
              class="centered-input"
              append-icon="mdi-currency-eur"
              label="Guthaben:"
              hide-details="auto"
              v-model="form.invoicepatientdata.Prepaid"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "InvoiceInputMask",
  async created() {
    await this.$store.dispatch('invoicePatient/getInsuranceForInvoiceChoiceDTO')
    if (this.patientID != undefined) {
      console.log("***************")
      console.log("patientID:" + this.patientID)
      await this.FillForm()
    }
  },
  props: {
    patientID: Number,
    AUS: Boolean(false),
    HN: Boolean(false),
  },
  data() {
    const defaultForm = Object.freeze({
      invoicepatientdata: {
        isCareHealthInsurance: undefined,
        isNormalInsurance: undefined,
        InsuranceID: undefined,
        Prepaid: undefined,
        TaxCredit: undefined,
        HealthInsuranceNumber: undefined,
        IKNumber: undefined,
        KlientID: undefined,
        TaxCreditRecurring: undefined,
        TaxCreditManual: undefined
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
      insuranceID: undefined,
    }
  },
  computed: {
    insurances() {
      return this.$store.getters['invoicePatient/getInsurancesForInvoiceChoiseDTO']
    },
    patientDataForInvoices() {
      return this.$store.getters['invoicePatient/getPatientDataForInvoiceDTO']
    }
  },
  methods: {
    FillCheckBox() {
      let insurance = this.insurances.find(element => element.InsuranceID == this.form.invoicepatientdata.InsuranceID)
      console.log(insurance)
      if(insurance === undefined){
        return
      }
      this.form.invoicepatientdata.isCareHealthInsurance = insurance.isCareHealthInsurance
      this.form.invoicepatientdata.isNormalInsurance = insurance.isNormalInsurance
    },
    async FillForm() {
      await this.$store.dispatch('invoicePatient/getPatientDataForInvoiceDTOByPatientID', this.patientID)
      this.form.invoicepatientdata.HealthInsuranceNumber = this.patientDataForInvoices.HealthInsuranceNumber
      this.form.invoicepatientdata.IKNumber = this.patientDataForInvoices.IkNumber
      if (this.HN === true) {
        this.form.invoicepatientdata.Prepaid = this.patientDataForInvoices.HnPrepaid
        this.form.invoicepatientdata.InsuranceID = this.patientDataForInvoices.InsuranceHNID
        this.form.invoicepatientdata.TaxCredit = this.patientDataForInvoices.HNTaxCredit
        this.form.invoicepatientdata.KlientID = this.patientDataForInvoices.KlientID
        this.form.invoicepatientdata.TaxCreditManual = this.patientDataForInvoices.HNTaxCreditManual
        this.form.invoicepatientdata.TaxCreditRecurring = this.patientDataForInvoices.HNTaxCreditRecurring
      }
      if (this.AUS === true) {
        this.form.invoicepatientdata.Prepaid = this.patientDataForInvoices.AusPrepaid
        this.form.invoicepatientdata.InsuranceID = this.patientDataForInvoices.InsuranceAUSID
        this.form.invoicepatientdata.TaxCredit = this.patientDataForInvoices.AUSTaxCredit
        this.form.invoicepatientdata.TaxCreditManual = this.patientDataForInvoices.AUSTaxCreditManual
        this.form.invoicepatientdata.TaxCreditRecurring = this.patientDataForInvoices.AUSTaxCreditRecurring
      }
      this.FillCheckBox()
    },
    async SavePatientDataForInvoice() {
      this.Test()
      let insurancePatientDTO = {
        PatientID: this.patientID,
        InsuranceHNID: undefined,
        HNTaxCredit: undefined,
        AUSTaxCredit: undefined,
        PrivatePrepaid: undefined,
        InsuranceAUSID: undefined,
        HnPrepaid: undefined,
        AusPrepaid: undefined,
        KlientID: undefined,
        HNTaxCreditManual: undefined,
        HNTaxCreditRecurring: undefined,
        AUSTaxCreditManual: undefined,
        AUSTaxCreditRecurring: undefined,
        AUS: false,
        HN: false,
      }
      if (this.HN == true) {
        insurancePatientDTO.HNTaxCredit = Number(this.form.invoicepatientdata.TaxCredit)
        insurancePatientDTO.InsuranceHNID = this.form.invoicepatientdata.InsuranceID
        insurancePatientDTO.HnPrepaid = Number(this.form.invoicepatientdata.Prepaid)
        insurancePatientDTO.KlientID = this.form.invoicepatientdata.KlientID
        insurancePatientDTO.HNTaxCreditManual = this.form.invoicepatientdata.TaxCreditManual
        insurancePatientDTO.HNTaxCreditRecurring = this.form.invoicepatientdata.TaxCreditRecurring
        insurancePatientDTO.HN  = true
      }
      if (this.AUS == true) {
        insurancePatientDTO.AUSTaxCredit = Number(this.form.invoicepatientdata.TaxCredit)
        insurancePatientDTO.InsuranceAUSID = this.form.invoicepatientdata.InsuranceID
        insurancePatientDTO.AusPrepaid = Number(this.form.invoicepatientdata.Prepaid)
        insurancePatientDTO.AUSTaxCreditManual = this.form.invoicepatientdata.TaxCreditManual
        insurancePatientDTO.AUSTaxCreditRecurring = this.form.invoicepatientdata.TaxCreditRecurring
        insurancePatientDTO.AUS  = true
      }
      console.log("### >>> insurancePatientDTO <<< ###")
      console.log(JSON.stringify(insurancePatientDTO))
      await this.$store.dispatch('invoicePatient/savePatientDataForInvoiceDTO', insurancePatientDTO)
    },
    Test() {
      console.log("Dies ist ein Test")
    }
  }
}
</script>

<style scoped>

</style>