<template>
    <v-container>
        <h1>Systeminfo</h1>
        <v-row>
            <v-col cols="12">
                <b>Aktuell verwendete Version:</b> <i>{{ drk_name }}</i>
            </v-col>
        </v-row>
        <v-row>
            <v-list>
                <v-list-item>
                    <v-checkbox v-model="backend_online" :label="backend_label" :color="backend_color" readonly></v-checkbox><v-btn class="ml-3" @click="DownloadLogfile()" color="info">Download Logfile</v-btn>
                </v-list-item>
            </v-list>
        </v-row>
    </v-container>
</template>

<script>
export default {
   mounted() {
       this.reload();
   },
   data() {
    return {
      drk_name: process.env.VUE_APP_DRK,
      api_ok: false
    };
  },
  computed: {
      backend_online() {
          return  this.api_ok;
      },
      backend_color() {
          return (this.api_ok) ? 'green' : 'red'
      },
      backend_label() {
          return "API v1 erreichbar: " + process.env.VUE_APP_API_ROUTE
      }
  },
  methods: {
      reload() {
        const requestOptions = {
            method: 'GET'
        };

        return fetch(process.env.VUE_APP_API_ROUTE + `/ping`, requestOptions).then((response) => {
            let ok = response.ok;
            console.log(ok);
            this.api_ok = ok;
        });
      },
      DownloadLogfile() {
          window.open(process.env.VUE_APP_API_ROUTE + `/log`);
      }
  },
}
</script>