<template>
  <div>
    <h3>Rechnungsposition:</h3>
    <v-row>
      <v-col cols="4" v-if="!approvedStatusForButton && status!='Storno'">
        <v-text-field
            v-model="invoicePosition.Description"
            label="Beschreibung"
            placeholder=""
            hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col v-else cols="5">
        <v-text-field
            v-model="invoicePosition.Description"
            readonly="true"
            label="Beschreibung"
            placeholder=""
            hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            v-model="invoicePosition.PositionUnitNumber"
            :readonly="approvedStatusForButton || status =='Storno' "
            label="Anzahl der Einheiten"
            placeholder=""
            type="number"
            hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            v-model="invoicePosition.PricePerUnit"
            :readonly="approvedStatusForButton || status =='Storno' "
            label="Preis pro Einheit"
            placeholder=""
            type="number"
            hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            v-model="PositionNetPrice"
            :readonly="true"
            label="Preis der Position"
            placeholder=""
            type="number"
            hide-details="auto">
        </v-text-field>
      </v-col>
      <v-row class="mt-6 ml-2" v-if="!approvedStatusForButton && status!='Storno'">
        <v-btn
            @click="DeletePosition(invoicePosition.ID)"
            color="info">
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn color="primary"
               class="ml-2"
               @click.prevent="SaveUpdatePositions(invoicePosition.ID)">
          <v-icon small>mdi-content-save</v-icon>
        </v-btn>
      </v-row>
    </v-row>
    <v-row>
      <!--                  <v-col>-->
      <!--                    <v-text-field-->
      <!--                        v-model="invoicePosition.TaxPercentage"-->
      <!--                        :readonly = "approvedStatusForButton"-->
      <!--                        label="Prozentsatz Ust"-->
      <!--                        placeholder=""-->
      <!--                        hide-details="auto">-->
      <!--                    </v-text-field>-->
      <!--                  </v-col>-->
      <!--                  <v-col>-->
      <!--                    <v-text-field-->
      <!--                        v-model="PositionTaxPrice"-->
      <!--                        :readonly = "approvedStatusForButton"-->
      <!--                        label="Umstatzsteuerbetrag"-->
      <!--                        placeholder=""-->
      <!--                        hide-details="auto">-->
      <!--                    </v-text-field>-->
      <!--                  </v-col>-->
      <!--                  <v-col>-->
      <!--                    <v-text-field-->
      <!--                        v-model="GrossPrice"-->
      <!--                        :readonly = "approvedStatusForButton"-->
      <!--                        label="Bruttopreis der Position"-->
      <!--                        placeholder=""-->
      <!--                        hide-details="auto">-->
      <!--                    </v-text-field>-->
      <!--                  </v-col>-->

    </v-row>
    <template>
      <div class="text-center ma-2">
        <v-snackbar
            color="success"
            v-model="snackbar"
            :timeout="timeout">
          <v-icon>mdi-check-circle</v-icon>
          {{ invoicePosition.Description }} gespeichert.
          <!--          <template v-slot:action="{ attrs }">-->
          <!--            <v-btn-->
          <!--                color="pink"-->
          <!--                text-->
          <!--                v-bind="attrs"-->
          <!--                @click="snackbar = false">-->
          <!--              OK-->
          <!--            </v-btn>-->
          <!--          </template>-->
        </v-snackbar>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    invoicePosition: {},
    approvedStatus: Boolean,
    countPositions: Number,
    status: String,
    InvoiceGoID: Number,
  },
  data() {
    return {
      snackbar: false,
      timeout: 1500,
    }
  },
  computed: {
    GrossPrice() {
      // return (this.PositionTaxPrice * this.invoicePosition.PositionEuroAmount).toFixed(2)
      return (this.PositionNetPrice + this.PositionTaxPrice).toFixed(2)
    },
    PositionTaxPrice() {
      return (((this.invoicePosition.PricePerUnit / 100) * this.invoicePosition.TaxPercentage) * this.invoicePosition.PositionUnitNumber).toFixed(2);
    },
    PositionNetPrice() {
      return (this.invoicePosition.PositionUnitNumber * this.invoicePosition.PricePerUnit).toFixed(2)
    },
    approvedStatusForButton() {
      if (this.approvedStatus === "genehmigt") {
        return true
      }
      return false
    }
  },
  mounted() {
    this.$root.$refs.EditInvoicePositions = this;
  },
  methods: {
    SetInvoiceGoId(id) {
      this.InvoiceGoID = id;
    },
    async createNewPosition() {
      if (this.invoicePosition.Description === undefined
          || this.invoicePosition.PositionUnitNumber === undefined
          || this.invoicePosition.PricePerUnit === undefined) {
        alert("Es sind nicht alle Felder ausgefüllt. Speichern nicht möglich.")
        return
      }
      if (confirm("Möchten Sie die Rechnungsposition " + this.invoicePosition.Description + " wirklich ändern/speichern?")) {
        let newPositionDataDTO = {
          InvoiceGoID: parseInt(this.InvoiceGoID),
          Description: this.invoicePosition.Description,
          PositionUnitNumber: parseFloat(this.invoicePosition.PositionUnitNumber),
          PricePerUnit: parseFloat(this.invoicePosition.PricePerUnit),
          PositionEuroAmount: parseFloat(this.invoicePosition.PositionUnitNumber) * parseFloat(this.invoicePosition.PricePerUnit),
          TaxPercentage: parseFloat(this.invoicePosition.TaxPercentage),
          PositionTaxEuroAmount: parseFloat(this.invoicePosition.PositionEuroAmount / 100 * this.invoicePosition.TaxPercentage),
          PositionGrossEuroAmount: parseFloat(this.invoicePosition.PositionEuroAmount + (this.invoicePosition.PositionEuroAmount / 100) * this.invoicePosition.TaxPercentage),
        };
        await this.$store.dispatch('invoicePositions/createNewPosition', newPositionDataDTO).then(this.ShowSnackbar())
        setTimeout(() => this.$store.dispatch('invoices/getInvoicePositionToEditDTOByInvoiceGoID', this.InvoiceGoID), 1500);
      } else {
        // do nothing
      }
    },
    DeletePosition(id) {
      if (id == undefined) {
        if (confirm("Diese Position wurde noch nicht gespeichert. Löschvorgang fortsetzen?")) {
          this.$destroy();
          this.$el.parentNode.removeChild(this.$el);
        } else {
          // do nothing
        }
        return;
      }
      if (confirm("Möchten Sie die Rechnungsposition " + this.invoicePosition.Description + " wirklich löschen?")) {
        this.$store.dispatch('invoicePositions/DeletePositionByID', id);
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
        // this.tmpCount--;
      } else {
        // do nothing
      }
    },
    SaveUpdatePositions(id) {
      if (id == undefined) {
        this.createNewPosition();
        return;
      }
      if (confirm("Möchten Sie die " + this.invoicePosition.Description + " wirklich ändern?")) {
        let updatedPositionDataDTO = {
          InvoiceID: parseInt(id),
          PositionUnitNumber: parseFloat(this.invoicePosition.PositionUnitNumber),
          PricePerUnit: parseFloat(this.invoicePosition.PricePerUnit),
          PositionEuroAmount: parseFloat(this.invoicePosition.PositionUnitNumber) * parseFloat(this.invoicePosition.PricePerUnit),
          TaxPercentage: parseFloat(this.invoicePosition.TaxPercentage),
          PositionTaxEuroAmount: parseFloat(this.invoicePosition.PositionEuroAmount / 100 * this.invoicePosition.TaxPercentage),
          PositionGrossEuroAmount: parseFloat(this.invoicePosition.PositionEuroAmount + (this.invoicePosition.PositionEuroAmount / 100) * this.invoicePosition.TaxPercentage),
        };
        console.log(JSON.stringify(updatedPositionDataDTO));
        this.$store.dispatch('invoicePositions/updatePositionByID', updatedPositionDataDTO);
        this.ShowSnackbar();
      } else {
        // do nothing
      }
    },
    ShowSnackbar() {
      this.snackbar = true
    }
  }

}
</script>

<style scoped>
.mat-simple-snackbar {
  text-align: center;
}
</style>