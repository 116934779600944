<template>
  <v-form ref="form" :disabled="formdisabled">
    <v-checkbox
        v-model="UseAlternatePaymentAddress"
        :label="`Rechnungsempfänger entspricht nicht Leistungsempfänger`"
        @click="setAlternatePaymentAddress"
    ></v-checkbox>
    <v-col v-if="form.alternatepaymentaddressdata.UseAlternatePaymentAddress" cols="12" class="densecol ml-0 pl-0 mb-4">
      <h4>Rechnungsempfänger</h4>
      <v-col cols="6">
        <v-text-field
            label="Debitoren-Nr"
            v-model="form.alternatepaymentaddressdata.DebitorenNumber"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="3">
            <v-select
                v-model="form.alternatepaymentaddressdata.Title"
                label="Anrede:"
                :items="titlePool.titlePool"
                placeholder="Anrede"
                hide-details="auto">
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.FirstName"
                label="Vorname:"
                placeholder="Vorname"
                hide-details="auto"
                :error-messages="firstNameErrors"
                @input="$v.form.alternatepaymentaddressdata.FirstName.$touch()"
                @blur="$v.form.alternatepaymentaddressdata.FirstName.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.LastName"
                label="Nachname:"
                placeholder="Nachname"
                hide-details="auto"
                :error-messages="lastNameErrors"
                @input="$v.form.alternatepaymentaddressdata.LastName.$touch()"
                @blur="$v.form.alternatepaymentaddressdata.LastName.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.Street"
                label="Straße:"
                placeholder="Straße"
                hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.PLZ"
                label="PLZ:"
                placeholder="PLZ"
                hide-details="auto"
                :error-messages="PLZErrors"
                @input="$v.form.alternatepaymentaddressdata.PLZ.$touch()"
                @blur="$v.form.alternatepaymentaddressdata.PLZ.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.City"
                label="Ort:"
                placeholder="Ort"
                hide-details="auto"
                :error-messages="CityErrors"
                @input="$v.form.alternatepaymentaddressdata.City.$touch()"
                @blur="$v.form.alternatepaymentaddressdata.City.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.PhoneNumber"
                label="Telefon:"
                placeholder="+49 1234 123456"
                hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                v-model="form.alternatepaymentaddressdata.EMail"
                label="E-Mail:"
                placeholder="E-Mail"
                hide-details="auto"
                :error-messages="emailErrors"
                @input="$v.form.alternatepaymentaddressdata.EMail.$touch()"
                @blur="$v.form.alternatepaymentaddressdata.EMail.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.IBAN"
                label="IBAN:"
                placeholder="DE07 1234 1234 1234 1234 12"
                hide-details="auto"
                :error-messages="IBANErrors"
                @input="$v.form.alternatepaymentaddressdata.IBAN.$touch()"
                @blur="$v.form.alternatepaymentaddressdata.IBAN.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="form.alternatepaymentaddressdata.BIC"
                label="BIC:"
                placeholder="ABCDEFG"
                hide-details="auto"
                :error-messages="BICErrors"
                @input="$v.form.alternatepaymentaddressdata.BIC.$touch()"
                @blur="$v.form.alternatepaymentaddressdata.BIC.$touch()"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
                v-model="form.alternatepaymentaddressdata.SEPA"
                label="Einzugsermächtigung vorhanden"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <v-btn
          style="float: left;"
          class="mr-1"
          outlined
          elevation="2"
          @click="felderLeeren"
      ><i class="fas fa-trash"></i>Löschen
      </v-btn
      >
    </v-col>

  </v-form>
</template>

<script>

import {helpers, numeric, minLength, maxLength, email, required} from 'vuelidate/lib/validators';

const ibanValidator = helpers.regex('alphaNumAndDot', /^DE(?:\s*[0-9a-zA-Z]\s*){20}$/);
const bicValidator = helpers.regex('alphaNumAndDot', /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/);
const alphaNumAndDotAndWhiteSpaceValidator = helpers.regex('alphaNumAndDot', /^[a-zA-Z.\s-,+äüöÄÖÜß]*$/);


export default {
  inject: ['titlePool'],
  props: {
    patientID: undefined,
    formdisabled: Boolean
  },

  data() {
    const defaultForm = Object.freeze({
      alternatepaymentaddressdata: {
        Title: undefined,
        FirstName: undefined,
        LastName: undefined,
        Street: undefined,
        PLZ: undefined,
        City: undefined,
        EMail: undefined,
        PhoneNumber: undefined,
        IBAN: undefined,
        BIC: undefined,
        SEPA: undefined,
        PatientID: undefined,
        DebitorenNumber: undefined,
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
      UseAlternatePaymentAddress: false
    };
  },
  validations: {
    form: {
      alternatepaymentaddressdata: {
        IBAN: {ibanValidator},
        BIC: {bicValidator},
        PLZ: {
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        City: {alphaNumAndDotAndWhiteSpaceValidator},
        EMail: {email},
        LastName: {required, alphaNumAndDotAndWhiteSpaceValidator},
        FirstName: {alphaNumAndDotAndWhiteSpaceValidator},
      }
    }
  },
  methods: {
    felderLeeren() {
      if (confirm("Möchten Sie den Rechnungsempfänger wirklich löschen? Änderungen werden erst nach dem speichern übernommen.")) {
        this.ResetForm();
      }
    },
    setAlternatePaymentAddress() {
      this.form.alternatepaymentaddressdata.UseAlternatePaymentAddress = !this.form.alternatepaymentaddressdata.UseAlternatePaymentAddress;
    },
    async SaveAlternatePaymentAddressObject() {
      // alert("PatientPaymentAddress -> SaveAlternatePaymentAddressObject")
      console.log("### calling SaveAlternatePaymentAddressObject() #");
      this.form.alternatepaymentaddressdata.PatientID = this.patientID
      this.form.alternatepaymentaddressdata.DebitorenNumber = parseInt(this.form.alternatepaymentaddressdata.DebitorenNumber);
      await this.$store.dispatch('patientoverview/createPaymentAddress', this.form.alternatepaymentaddressdata)
    },
    ResetForm() {
      console.log("# calling ResetFrom() of AlternatePaymentAddressObject #");
      this.$refs.form.reset()
      this.form.alternatepaymentaddressdata.UseAlternatePaymentAddress = false;
      console.log('AlternatePaymentAddressObject: ' + JSON.stringify(this.form.alternatepaymentaddressdata));
    },
    async FillInForm() {
      //console.log("PatientID: " + this.patientID)
      await this.$store.dispatch('patientoverview/getPaymentAddressByPatientID', this.patientID)
      this.form.alternatepaymentaddressdata = this.$store.getters['patientoverview/getPaymentAddress']
      this.UseAlternatePaymentAddress = this.form.alternatepaymentaddressdata.UseAlternatePaymentAddress

      // if (this.form.alternatepaymentaddressdata.UseAlternatePaymentAddress === true) {
      //   alert("BOO");
      // } else {
      //   alert("YA")
      // }
    },
  },
  computed: {
    IBANErrors() {
      const errors = []
      if (!this.$v.form.alternatepaymentaddressdata.IBAN.$dirty) return errors
      !this.$v.form.alternatepaymentaddressdata.IBAN.ibanValidator && errors.push('Ungültiges IBAN Format')
      return errors
    },
    BICErrors() {
      const errors = []
      if (!this.$v.form.alternatepaymentaddressdata.BIC.$dirty) return errors
      !this.$v.form.alternatepaymentaddressdata.BIC.bicValidator && errors.push('Mindestens 8 Zeichen / Großbuchstaben')
      return errors
    },
    PLZErrors() {
      const errors = []
      if (!this.$v.form.alternatepaymentaddressdata.PLZ.$dirty) return errors
      !this.$v.form.alternatepaymentaddressdata.PLZ.numeric && errors.push('Nur Zahlen erlaubt')
      !this.$v.form.alternatepaymentaddressdata.PLZ.minLength && errors.push('Maximal 5 Zeichen')
      !this.$v.form.alternatepaymentaddressdata.PLZ.maxLength && errors.push('Anzahl der erlaubten Zeichen überschritten')
      return errors
    },
    CityErrors() {
      const errors = []
      if (!this.$v.form.alternatepaymentaddressdata.City.$dirty) return errors
      !this.$v.form.alternatepaymentaddressdata.City.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Keine Zahlen erlaubt')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.alternatepaymentaddressdata.EMail.$dirty) return errors
      !this.$v.form.alternatepaymentaddressdata.EMail.email && errors.push('Ungültige E-Mail Adresse')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.alternatepaymentaddressdata.LastName.$dirty) return errors
      // !this.$v.form.alternatepaymentaddressdata.LastName.required && errors.push('Nachname ist ein Pflichtfeld')
      // !this.$v.form.alternatepaymentaddressdata.LastName.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Nachname darf keine Zahlen enthalten')
      return errors
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.alternatepaymentaddressdata.FirstName.$dirty) return errors
      !this.$v.form.alternatepaymentaddressdata.FirstName.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Vorname darf keine Zahlen enthalten')
      return errors
    },
  },
  async created() {
    await this.FillInForm()
    if (Object.keys(this.patientObject).length === 0) {
      return this.alternatepaymentaddressdata;
    } else {
      this.form.alternatepaymentaddressdata.FirstName = this.patientObject.item.PatientPaymentAddress.FirstName;
      this.form.alternatepaymentaddressdata.LastName = this.patientObject.item.PatientPaymentAddress.LastName;
      this.form.alternatepaymentaddressdata.Street = this.patientObject.item.PatientPaymentAddress.Street;
      this.form.alternatepaymentaddressdata.PLZ = this.patientObject.item.PatientPaymentAddress.PLZ;
      this.form.alternatepaymentaddressdata.City = this.patientObject.item.PatientPaymentAddress.City;
      this.form.alternatepaymentaddressdata.EMail = this.patientObject.item.PatientPaymentAddress.EMail;
      this.form.alternatepaymentaddressdata.PhoneNumber = this.patientObject.item.PatientPaymentAddress.PhoneNumber;
      this.form.alternatepaymentaddressdata.IBAN = this.patientObject.item.PatientPaymentAddress.IBAN;
      this.form.alternatepaymentaddressdata.BIC = this.patientObject.item.PatientPaymentAddress.BIC;
      this.form.alternatepaymentaddressdata.SEPA = this.patientObject.item.PatientPaymentAddress.SEPA;
      this.UseAlternatePaymentAddress = this.patientObject.item.PatientPaymentAddress.UseAlternatePaymentAddress;
      this.form.alternatepaymentaddressdata.DebitorenNumber = this.DebitorenNumber;
    }
  },
}
</script>


<style scoped>

.error--text {
  color: red !important;
  font-size: 22px;
  text-transform: uppercase !important;
  font-weight: bold;
  font-style: italic;
}

</style>