<template>
  <main>
    <!-- RESPONSIVE NAVBAR  -->

    <!--   -->
    <v-container class="mt-5" align="center" justify="center">
      <row :gutter="12" align="center">
        <column :xs="12" :md="4" :lg="1"></column>
        <column :xs="12" :md="4" :lg="3">
          <v-card color="#951B80" class="equaleight text_center mainbox-homeservice" style="height:250px">
            <img src="icon_haushalt.svg" height="100px" style="color: white; margin-top:40px"/>
            <div class="title_txt">AUS & HNR</div>
          </v-card>
          <v-card>
            <v-card-text class="text_center sub_btn"
                         v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')">
              <v-btn outlined to="patient" class="btn_style v-btn">
                <v-icon class="iconStyle">mdi-account-multiple</v-icon>
                Klienten
              </v-btn>
            </v-card-text>
            <v-card-text class="text_center sub_btn"
                         v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')">
              <v-btn to="insurances" outlined class="btn_style v-btn">
                <v-icon class="iconStyle">mdi-contacts</v-icon>
                Kostenträger
              </v-btn>
            </v-card-text>
            <v-card-text class="text_center sub_btn"
                         v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')">
              <v-btn to="invoices" outlined class="btn_style v-btn">
                <v-icon class="iconStyle">mdi-cash-register</v-icon>
                Abrechnung
              </v-btn>
            </v-card-text>
            <v-card-text class="text_center sub_btn" v-if="loggedIn &&($cookies.get('role') === 'Admin')">
              <v-btn to="employeeoverview" outlined class="btn_style v-btn">
                <v-icon class="iconStyle">mdi-card-account-details-outline</v-icon>
                Personal
              </v-btn>
            </v-card-text>
            <v-card-text class="text_center sub_btn" v-if="loggedIn &&($cookies.get('role') === 'Admin')">
              <v-btn to="inventory" outlined class="btn_style v-btn">
                <v-icon class="iconStyle">mdi-layers-triple</v-icon>
                Inventar
              </v-btn>
            </v-card-text>
            <v-card-text class="text_center sub_btn">
              <v-btn to="employeeroster" outlined class="btn_style v-btn">
                <v-icon class="iconStyle">mdi-calendar</v-icon>
                Dienstplan
              </v-btn>
            </v-card-text>
            <v-card-text class="text_center sub_btn">
              <v-btn to="employeetimetable" outlined class="btn_style v-btn">
                <v-icon class="iconStyle">mdi-calendar-check</v-icon>
                Zeiterfassung
              </v-btn>
            </v-card-text>
            <v-card-text class="text_center sub_btn">
              <v-menu class="settingsBtn" color="primary" dark v-bind="attrs" v-on:click.prevent v-on="on"
                      :nudge-width="200" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined class="btn_style v-btn" v-bind="attrs" v-on="on">
                    <v-icon class="iconStyle">mdi-menu</v-icon>
                    Einstellungen
                  </v-btn>
                </template>
                <v-card>
                  <v-list dense>
                    <v-list-item v-if="loggedIn">
                      <v-list-item-content>
                        <v-list-item-title>{{ username }}</v-list-item-title>
                        <v-list-item-subtitle>{{ getRole }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="RouteLogout()">
                          <v-icon class="iconStyle">mdi-lock</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider v-if="loggedIn"></v-divider>
                  <v-list dense>
                    <v-list-item dense v-if="loggedIn" @click="RouteUserProfile()">
                      Mein Profil
                    </v-list-item>
                    <v-list-item dense @click="RouteSettings()"
                                 v-if="loggedIn && ($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')">
                      CuraCAT Einstellungen
                    </v-list-item>
                    <v-list-item dense @click="RouteAbout()">
                      Über CuraCAT
                    </v-list-item>
                    <v-list-item dense @click="RouteInformation()">
                      Systeminformationen
                    </v-list-item>
                    <v-list-item dense @click="RouteHelp()">
                      Hilfe
                    </v-list-item>
                    <v-list-item dense v-if="loggedIn">
                      <v-divider></v-divider>
                    </v-list-item>
                    <v-list-item dense @click="RouteLogout()" v-if="loggedIn">
                      Abmelden
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-card-text>
          </v-card>
        </column>
        <column :xs="12" :md="4" :lg="3">
          <v-card color="#751165" class="equalHeight text_center">
            <i class="fas fa-cart-arrow-down fa-7x pt-5 mt-8"></i>
            <div class="title_txt">Einkaufsservice</div>
          </v-card>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-shopping">
              <v-icon class="iconStyle">mdi-account-multiple</v-icon>
              Klienten
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-shopping">
              <v-icon class="iconStyle">mdi-home-group</v-icon>
              Institutionen
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-shopping">
              <v-icon class="iconStyle">mdi-card-account-details-outline</v-icon>
              Personal
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-shopping">
              <v-icon class="iconStyle">mdi-clipboard-check-multiple-outline</v-icon>
              Organisation
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-shopping">
              <v-icon class="iconStyle">mdi-cash-register</v-icon>
              Abrechnung
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-shopping">
              <v-icon class="iconStyle">mdi-calendar</v-icon>
              Kalender
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inv-btn-shoppingclusion">
              <v-icon class="iconStyle">mdi-format-list-checks</v-icon>
              ToDo
            </v-btn>
          </v-card-text>
        </column>
        <column :xs="12" :md="4" :lg="3">
          <v-card color="#520544" class="equalHeight text_center">
            <i class="fas fas fa-medkit fa-7x pt-5 mt-8"></i>
            <div class="title_txt">Erste Hilfe</div>
          </v-card>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn-firstAid">
              <v-icon class="iconStyle">mdi-account-multiple</v-icon>
              Teilnehmer
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-firstAid">
              <v-icon class="iconStyle">mdi-school</v-icon>
              Kurse
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-firstAid">
              <v-icon class="iconStyle">mdi-cash-register</v-icon>
              Abrechnung
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-firstAid">
              <v-icon class="iconStyle">mdi-calendar</v-icon>
              Kalender
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-firstAid">
              <v-icon class="iconStyle">mdi-format-list-checks</v-icon>
              ToDo
            </v-btn>
          </v-card-text>
        </column>
        <column :xs="12" :md="4" :lg="1"></column>
      </row>
      <row :gutter="12" align="center" justify="center" class="mt-4">
        <column :xs="12" :md="4" :lg="1"></column>
        <column :xs="12" :md="4" :lg="3">
          <v-card color="#009FE2" class="equalHeight text_center">
            <i class="fas fa-universal-access fa-7x pt-5 mt-5"></i>
            <div class="title_txt">Inklusion</div>
          </v-card>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inclusion">
              <v-icon class="iconStyle">mdi-account-multiple</v-icon>
              Klienten
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inclusion">
              <v-icon class="iconStyle">mdi-home-group</v-icon>
              Institution
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inclusion">
              <v-icon class="iconStyle">mdi-card-account-details-outline</v-icon>
              Personal
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inclusion">
              <v-icon class="iconStyle">mdi-cash-register</v-icon>
              Abrechnung
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inclusion">
              <v-icon class="iconStyle">mdi-clipboard-check-multiple-outline</v-icon>
              Organisation
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inclusion">
              <v-icon class="iconStyle">mdi-calendar</v-icon>
              Kalender
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-inclusion">
              <v-icon class="iconStyle">mdi-format-list-checks</v-icon>
              ToDo
            </v-btn>
          </v-card-text>
        </column>
        <column :xs="12" :md="4" :lg="3">
          <v-card color="#0069B4" class="equalHeight text_center">
            <img src="icon_jugendhilfe.svg" height="100px" style="color: white; margin-top: 25px;"/>
            <div class="title_txt">Jugendhilfe</div>
          </v-card>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-youth">
              <v-icon class="iconStyle">mdi-account-multiple</v-icon>
              Bewohner
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-youth">
              <v-icon class="iconStyle">mdi-card-account-details-outline</v-icon>
              Personal
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-youth">
              <v-icon class="iconStyle">mdi-clipboard-check-multiple-outline</v-icon>
              Organisation
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-youth">
              <v-icon class="iconStyle">mdi-cash-register</v-icon>
              Abrechnung
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-youth">
              <v-icon class="iconStyle">mdi-timer-sand</v-icon>
              Warteliste
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-youth">
              <v-icon class="iconStyle">mdi-calendar</v-icon>
              Kalender
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-youth">
              <v-icon class="iconStyle">mdi-format-list-checks</v-icon>
              ToDo
            </v-btn>
          </v-card-text>
        </column>
        <column :xs="12" :md="4" :lg="3">
          <v-card color="#0E4194" class="equalHeight text_center">
            <i class="fas fa-hands-helping fa-7x pt-5 mt-6"></i>
            <div class="title_txt">Obdachlosenhilfe</div>
          </v-card>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-homeless">
              <v-icon class="iconStyle">mdi-account-multiple</v-icon>
              Bewohner
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-homeless">
              <v-icon class="iconStyle">mdi-card-account-details-outline</v-icon>
              Personal
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-homeless">
              <v-icon class="iconStyle">mdi-clipboard-check-multiple-outline</v-icon>
              Organisation
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-homeless">
              <v-icon class="iconStyle">mdi-cash-register</v-icon>
              Abrechnung
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-homeless">
              <v-icon class="iconStyle">mdi-timer-sand</v-icon>
              Warteliste
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-homeless">
              <v-icon class="iconStyle">mdi-calendar</v-icon>
              Kalender
            </v-btn>
          </v-card-text>
          <v-card-text class="text_center sub_btn">
            <v-btn outlined class="btn_style v-btn v-btn-homeless">
              <v-icon class="iconStyle">mdi-format-list-checks</v-icon>
              ToDo
            </v-btn>
          </v-card-text>
        </column>
        <column :xs="12" :md="4" :lg="1"></column>
      </row>
    </v-container>
  </main>
</template>

<script>

export default {
  data() {
    return {
      role: undefined
    };
  },
  methods: {
    SetRole(role) {
      this.role = role;
    },
    encode_utf8(s) {
      return unescape(encodeURIComponent(s));
    },
    decode_utf8(s) {
      return decodeURIComponent(escape(s));
    },
    routeTo(url) {
      if (url.startsWith("http")) {
        window.open(url, "_blank");
      } else {
        this.$router.push(url);
      }
    },
    clear() {
      this.$store.dispatch("alert/clear");
    },
    RouteLogout() {
      this.$cookies.remove("role", null)
      this.$cookies.remove("currentUserID", null)
      this.$cookies.set("default_unit_second", "input_value", 0);
      this.$router.push("/login");
    },
    RouteSettings() {
      this.$router.push("/settings");
    },
    RouteAbout() {
      window.open("https://www.brandmauer.de/curacat", "_blank");
    },
    RouteInformation() {
      this.$router.push("/info");
    },
    RouteHelp() {
      window.open("https://support.brandmauer.de", "_blank");
    },
    RouteUserProfile() {
      this.$router.push("/me");
    },
  },
  mounted() {
    this.$root.$refs.home = this;
    this.$root.$refs.start.setMenuActive(false);
  },
  computed: {
    getRole() {
      return this.role;
    },
    user() {
      let usr = this.$store.state.authentication.user;
      return usr;
    },
    username() {
      let usr = this.$store.state.authentication.publicUsername;
      return this.decode_utf8(usr);
    },
    dialog() {
      let v =
          this.$store.state.alert.message != "" &&
          this.$store.state.alert.message != undefined &&
          this.$store.state.alert.message != null;
      return v;
    },
    dialogMsg() {
      return this.$store.state.alert.message;
    },
    dialogTitle() {
      return this.$store.state.alert.title;
    },
    loggedIn() {
      let state = this.$store.state.authentication.status.loggedIn;
      return state;
    },
  },
};
</script>

<style scoped>

div.card-body.mainbox-body > div > ul > li {
  margin-left: -24px;
  padding: 0px !important;
}

div.card-body.mainbox-body > div > ul > li > div {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

div.card-body.mainbox-body > div > ul > li > div > div:nth-child(1) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

div.card-body.mainbox-body > div > ul > li > div > div:nth-child(2) {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

div.card-body.mainbox-body > div > ul > li > div > div:nth-child(2) > a {
  text-decoration: none;
  color: black;
  font-weight: 500;
}

/* reDESIGN*/
a {
  font-size: 1.0em !important;
  text-transform: uppercase !important;
}


.equalHeight {
  min-height: 250px;
  max-height: 250px;
}

.text_center {
  text-align: center;
  color: white;
}

.title_txt {
  margin-top: 20px;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: xx-large;
  font-weight: bold;
}

.btn_style {
  /*width: 250px;*/
  /*height: 100px;*/
  width: 100%;
  margin-left: 0px;
  font-size: 1.0em;
  margin-top: -5px;
}

.customStyle {
  background-color: red;
}

.customStyle:hover {
  background-color: yellow;
  color: white;
}

.v-btn:hover {
  color: #951B80;
  background-color: white;
}

.v-btn-firstAid:hover {
  color: #520544;
  background-color: white;
}

.v-btn-inclusion:hover {
  color: #0e4194;
  background-color: white;
}

.v-btn-youth:hover {
  color: #006ab5;
  background-color: white;
}

.v-btn-homeless:hover {
  color: #0E4194;
  background-color: white;
}

.v-btn-shopping:hover {
  color: #751165;
  background-color: white;
}


i:hover {
  color: white;
}

.link-style {
  text-decoration: none;
  color: black;
}

.iconStyle {
  margin-right: 10px;
}

.sub_btn {
  height: 55px;
  background-color: #dadfec78;
}

.v-btn.v-btn--outlined {
  border: thin solid #bbbbbb;
  justify-content: inherit;
}
</style>
