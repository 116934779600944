<template>
  <div>
    <row :gutter="12">
      <column :xs="12" :lg="12">
        <v-data-table
            style="padding:5px"
            light
            :items="users"
            :headers="headers"
            :search="search"
            :header-props="headerProps"
            item-key="ID"
            class="elevation-1 "
            no-data-text="keine Daten vorhanden"
            no-results-text="keine Suchergebnisse"
            single-select
            isSelected="true"
            :items-per-page="10"
            @click:row="ClickRow"
            :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }">

          <template v-slot:top>
            <v-row style="width: 100%">
              <column class="mt-4 ml-2">
                <h3>
                  <v-icon style="color: #006ab5">mdi-account-multiple</v-icon>
                  Alle User
                </h3>
              </column>
              <v-text-field
                  clearable
                  v-model="search"
                  label="Suchen"
                  append-icon="mdi-magnify"
                  class="mx-4"
                  width="50%">
              </v-text-field>
            </v-row>
          </template>
          <template v-slot:item.ID="{item}">
            <v-chip
                label
                :color="getColor(item)">
              {{ item.ID }}
            </v-chip>
          </template>
        </v-data-table>
      </column>
    </row>
  </div>
</template>

<script>
export default {
  props: {
    employees: {},
    users: {}
  },
  data() {
    return {
      search: '',
      // employees
      // headers: [
      //   {text: 'PNR', value: 'ID'},
      //   {text: 'Vorname', value: 'Firstname'},
      //   {text: 'Nachname', value: 'Lastname'},
      //   {text: 'Rechte', value: 'Role'},
      // ],
      // users
      headers: [
        {text: 'PNR', value: 'ID'},
        {text: 'Username', value: 'Username'},
        {text: 'Vorname', value: 'Firstname'},
        {text: 'Nachname', value: 'Lastname'},
        {text: 'Rechte', value: 'Role'},
      ],
      headerProps: {
        sortByText: 'Sortieren nach'
      },
    }
  },
  methods: {
    ClickRow(item, row) {
      row.select(true)
      this.$emit("SendRole", item.Role)
      this.$root.$refs.UserSettings.SetUser(item.ID, item.EmployeeID, item.Firstname, item.Lastname, item.EmployeeColorHex, item.Role)
    },
    getColor(item) {
      console.log(item)
      if (item.EmployeeColorHex == "") {
        return "#FFFFFFFF"
      } else {
        return item.EmployeeColorHex
      }
    },
    clearSearchFilter() {
      this.search = "";
    },
  }
}
</script>