<template>
  <v-col style="position: relative">
    <v-simple-table v-if="employeefiles.length">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Dateiname
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in employeefiles"
            :key="item.ID"
        >
          <td>
            {{ item.Filename }}

            <v-btn
                style="float: right; color: #951b81 "
                elevation="2"
                class="ml-3"
                small
                @click="DownloadFile(item.ID)"
            >
              <i class="fas fa-download"></i>
            </v-btn>

            <v-btn
                elevation="2"
                small
                style="float: right; color: #951b81"
                class="ml-1"

                @click="DeleteFile(item.Filename,item.ID)"
            >
              <i class="fas fa-trash-alt"></i>
            </v-btn>


          </td>

        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>{{ noDocs }}</div>
    <v-overlay
        :absolute="true"
        :value="overlay"
    >
      <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="15"
          :value="percentage"
          color="primary"
      >
        {{ percentage.toFixed(1) }}
      </v-progress-circular>
    </v-overlay>
  </v-col>
</template>

<script>
export default {
  props: {
    employeeid: Number,
    employeefiles: {}
  },
  data() {
    return {
      overlay: false,
      noDocs: "Keine Dokumente hinterlegt."
    }
  },
  computed: {
    loadedbytes() {
      return this.$store.getters["loading/getloadedbytes"]
    },
    totalbytes() {
      return this.$store.getters['loading/gettotalbytes']
    },
    percentage() {
      return this.loadedbytes / this.totalbytes
    }
  },
  methods: {

    async DownloadFile(fileID) {

      if (confirm("Möchten Sie das Dokument herunterladen?")) {
        console.log("fileID: " + fileID);
        this.overlay = true
        let fileinformation = await this.$store.dispatch('employees/downloadFile', fileID)
        this.overlay = false
        var FileSaver = require('file-saver');
        FileSaver.saveAs(fileinformation.fileblob, fileinformation.filename);
      } else {
        // do nothing
      }
    },

    async DeleteFile(filename, id) {

      if (confirm('Möchten Sie dieses Dokument wirklich löschen?')) {

        console.log(filename);
        console.log(this.employeeid);
        console.log(id);
        let DeleteFile = {
          ID: id,
          EmployeeID: this.employeeid,
          Filename: filename
        };
        let response = await this.$store.dispatch('employees/DeleteFile', DeleteFile);
        console.log(response);
        this.$emit("Renderfiletable");
      } else {
        // do nothing
      }
    }
  }
}

</script>