import { invoicePatientService } from '../services';


export const invoicePatient = {
    namespaced: true,
    state:{
        error: {},
        incurancesForInvoiceChoiceDTO: {},
        patientDataForInvoiceDTO: {},
        invoiceTemplateDTO: {},
        patientTemplateConfigDTO: {},
        serviceArticlesDTO: {},
        serviceArticleToShowDTO: {}
    },
    getters:{
        getServiceArticleToShowDTO: state => {
            return state.serviceArticleToShowDTO.items
        },
        getServiceArticlesDTO: state => {
            return state.serviceArticlesDTO.items
        },
        getPatientTemplateConfigDTO: state => {
            return state.patientTemplateConfigDTO.item
        },
        getInsurancesForInvoiceChoiseDTO: state => {
            return state.incurancesForInvoiceChoiceDTO.items
        },
        getPatientDataForInvoiceDTO: state => {
            return state.patientDataForInvoiceDTO.item
        },
        // getInvoiceTemplateDTOSubjectMain: state =>{
        //     console.log(JSON.stringify(state.invoiceTemplateDTO.items))
        //     return state.invoiceTemplateDTO.items.filter(element => element.SubjectMain == true)
        // },
        getInvoiceTemplateDTOSubjectBeside: state =>{
            console.log(state.invoiceTemplateDTO.items)
            return state.invoiceTemplateDTO.items.filter(element => element.SubjectBeside == true)
        },
        getInvoiceTemplateDTOBegintextText: state =>{
            console.log(state.invoiceTemplateDTO.items)
            return state.invoiceTemplateDTO.items.filter(element => element.BegintextText == true)
        },
        getInvoiceTemplateDTOEndtextText: state =>{
            console.log(state.invoiceTemplateDTO.items)
            return state.invoiceTemplateDTO.items.filter(element => element.EndtextText == true)
        },
        getInvoiceTemplateDTOEndtextTermsOfPayment: state =>{
            console.log(state.invoiceTemplateDTO.items)
            return state.invoiceTemplateDTO.items.filter(element => element.EndtextTermsOfPayment == true)
        },
        // getInvoiceTemplateDTOBegintextSalutation: state =>{
        //     return state.invoiceTemplateDTO.items.filter(element => element.BegintextSalutation == true)
        // },
        // getInvoiceTemplateDTOEndtextGreetings: state =>{
        //     return state.invoiceTemplateDTO.items.filter(element => element.EndtextGreetings == true)
        // },
    },
    actions: {
        deletePatientServiceArticleByID({commit}, patientServiceArticleID){
            return new Promise((resolve, reject) =>{
                invoicePatientService.deletePatientServiceArticleByID(patientServiceArticleID).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        savePrivatePrepaidDTO({commit}, privatePrepaidDTO){
            return new Promise((resolve, reject) =>{
                invoicePatientService.savePrivatePrepaidDTO(privatePrepaidDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getPatientServiceArticlesByPatientIDByMode({commit}, data){
            return new Promise((resolve, reject) =>{
                invoicePatientService.getPatientServiceArticlesByPatientIDByMode(data.PatientID, data.Mode).then(
                    payload => {
                        commit('getPatientServiceArticlesByPatientIDByModeSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        safePatientServiceArticle({commit}, patientServiceArticleForSafeDTO){
            return new Promise((resolve, reject) =>{
                invoicePatientService.safePatientServiceArticle(patientServiceArticleForSafeDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getServiceArticlesDTO({commit}){
            return new Promise((resolve, reject) =>{
                invoicePatientService.getServiceArticlesDTO().then(
                    payload => {
                        commit('getServiceArticlesDTOSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getPatientTemplateConfigDTOByPatientID({commit}, data){
            return new Promise((resolve, reject) =>{
                invoicePatientService.getPatientTemplateConfigDTOByPatientID(data.PatientID, data.InvoiceChoice).then(
                    payload => {
                        commit('getPatientTemplateConfigDTOByPatientIDSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        savePatientTemplateConfigDTO({commit}, patientTemplateConfigDTO){
            return new Promise((resolve, reject) =>{
                invoicePatientService.savePatientTemplateConfigDTO(patientTemplateConfigDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getAllInvoiceTemplatesDTO({commit}){
            console.log("getAllInvoiceTemplatesDTO({commit})")
            return new Promise((resolve, reject) =>{
                invoicePatientService.getAllInvoiceTemplatesDTO().then(
                    payload => {
                        console.log(payload)
                        commit('getAllInvoiceTemplatesDTOSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        console.log(error)
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        savePatientDataForInvoiceDTO({commit}, insurancePatientDTO){
            return new Promise((resolve, reject) =>{
                invoicePatientService.savePatientDataForInvoiceDTO(insurancePatientDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getPatientDataForInvoiceDTOByPatientID({commit}, patientID){
            return new Promise((resolve, reject) =>{
                invoicePatientService.getPatientDataForInvoiceDTOByPatientID(patientID).then(
                    payload => {
                        commit('getPatientDataForInvoiceDTOByPatientIDSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getInsuranceForInvoiceChoiceDTO({commit}){
            return new Promise((resolve, reject) =>{
                invoicePatientService.getInsuranceForInvoiceChoiceDTO().then(
                    payload => {
                        commit('getInsuranceForInvoiceChoiceDTOSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
    },
    mutations:{ 
        getFailure(state, error){
            state.error = {item: error}
        },
        getInsuranceForInvoiceChoiceDTOSuccess(state, payload){
            state.incurancesForInvoiceChoiceDTO = {items: payload}
        },
        getPatientDataForInvoiceDTOByPatientIDSuccess(state, payload){
            state.patientDataForInvoiceDTO = {item: payload}
        },
        getAllInvoiceTemplatesDTOSuccess(state, payload){
            console.log(payload)
            state.invoiceTemplateDTO = {items: payload}
            let defaultElement = {
                ID: 0,
                TemplateName: "keines Verwenden",
                SubjectMain: true,
                SubjectBeside: true,
                BegintextText: true,
                EndtextText: true,
                EndtextTermsOfPayment: true,
                BegintextSalutation: true,
                EndtextGreetings: true
            }
            state.invoiceTemplateDTO.items.push(defaultElement)
        },
        getPatientTemplateConfigDTOByPatientIDSuccess(state, payload){
            state.patientTemplateConfigDTO = {item:  payload}
        },
        getServiceArticlesDTOSuccess(state, payload){
            state.serviceArticlesDTO = {items:  payload}
        },
        getPatientServiceArticlesByPatientIDByModeSuccess(state, payload){
            state.serviceArticleToShowDTO = {items: payload}
        },
        emptyServiceArticleToShowDTO(state){
            state.serviceArticleToShowDTO = {}
        }
    },
}