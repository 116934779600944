<template>
  <div>
    <v-card dark style="padding:10px; background-color:#f1f1f1">
      <v-row>
        <h3 class="mt-4 ml-8"> Arbeitszeiten von</h3>
        <v-chip class="mt-4 ml-2"
                :style="{backgroundColor: employee.EmployeeColorHex}"
        >
          <v-icon left>
            mdi-account-circle
          </v-icon>
          {{ employee.Firstname }} {{ employee.Lastname }}
        </v-chip>
      </v-row>

      <v-toolbar light color="white" class="mt-8">
        <v-text-field v-model="search" clearable append-icon="mdi-text-box-search-outline" label="Suchen" single-line
                      hide-details></v-text-field>
        <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
        </v-menu>
      </v-toolbar>
      <v-layout v-resize="onResize" column style="padding-top:26px;">
        <v-data-table
            light
            :headers="headers"
            :items="timerecords"
            :search="search"
            :pagination.sync="pagination"
            :header-props="headerProps"
            :hide-headers="isMobile"
            :class="{mobile: isMobile}"
            no-data-text="keine Daten vorhanden"
            no-results-text="keine Suchergebnisse"
            single-select
            item-key="ID"
            isSelected="true"
            :items-per-page="5"
            class="elevation-1 "
            :footer-props="{
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Einträge pro Seite',}">

          <template v-slot:item.Comment="{ item }">
            <v-text-field
                label="Kommentar"
                v-model="item.Comment"
                :key="item.ID"
            >
            </v-text-field>
          </template>


          <template v-slot:item.saveComment="{ item }">
            <div align="center">
            <v-btn
                color="primary"
                small
                @click="SaveComment(item.ID, item.Comment)"
            >
              <i class="fas fa-save mr-2"></i>
            </v-btn>
            </div>
          </template>
          <template v-slot:item.deleteComment="{ item }">
            <v-btn small align="center"      color="primary"
                   @click="DeleteTimeRecord(item.ID)"
            >
              <i class="fas fa-trash-alt"></i>
            </v-btn>
          </template>

        </v-data-table>
      </v-layout>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="info"
            @click="CloseDialog()"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// import TimeRecordsTable from '../employees/timetable/TimeRecordsTable.vue'

export default {
  data() {
    return {
      timeRecordsTableKey: 0,
      pagination: {
        Sortieren: 'name'
      },
      selected: [],
      search: '',
      isMobile: false,
      headers: [
        {text: 'PNR', value: 'Personalnumber'},
        {text: 'Beginn', value: 'Start'},
        {text: 'Ende', value: 'End'},
        {text: 'Dauer(Stunden)', value: 'Duration'},
        {text: 'Kommentar', value: 'Comment'},
        {text: 'Kommentar speichern', value: 'saveComment'},
        {text: 'Löschen', value: 'deleteComment'},
      ],
      headerProps: {
        sortByText: 'Sortieren nach'
      },
    };
  },
  components: {
    // TimeRecordsTable
  },
  props: {
    employee: {},
    employeeID: Number,
    booking: {},
    comment: String,
    ID: Number,
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },
    CloseDialog() {
      this.$store.commit('eventdialog/changeTimeTableRecordsModalDialogtoFalse')
      this.$refs.timerecordtable.PrintArray()
    },
    RenderTimeRecordsTable() {
      this.timerecordstablekey++
    },
    getColor(item) {
      if (item.EmployeeColorHex == "") {
        return "#FFFFFFFF";
      } else {
        return item.EmployeeColorHex;
      }
    },
    async DeleteTimeRecord(timerecordID) {
      console.log(timerecordID)
      if (confirm("Möchten Sie diesen Eintrag wirklich löschen?")) {
        await this.$store.dispatch('employees/DeleteEmployeeTimeRecordsByID', timerecordID)
        this.$emit('RefreshTable')
      } else {
        //do nothing
      }
    },
    async SaveComment(id, commentToSave) {
      let saveTimeRecordCommentDTO = {
        ID: id,
        Comment: commentToSave
      }
      console.log("Saving Comment : " + commentToSave + " to EmployeeID: " + id)
      await this.$store.dispatch('employees/saveTimeRecordComment', saveTimeRecordCommentDTO)
      this.$emit('RefreshTable')
    }
  },
  created() {
    this.$store.dispatch('employees/getEmployeeTimeRecordsByEmployeeID', this.employeeID)
  },
  computed: {
    timerecords() {
      return this.$store.getters['employees/getEmployeeTimeRecords']
    },
  },
}
</script>


<style scoped>
.mobile {
  color: #000;
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: .5em;
    text-align: left;
    display: block;
    color: #999;

  }

  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }

  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }

}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}
</style>