<template>
  <v-container fluid>
    <h3 class="mb-4">Hinterlegte Dokumente</h3>
    <v-row v-if="showfiles">
      <EmployeeFileTable
          :employeeid="employeeid"
          :employeefiles="employeefiles"
          :key="filetablekey"
          @Renderfiletable="Renderfiletable"/>
    </v-row>
    <div v-else>Keine Dokumente hinterlegt</div>
  </v-container>
</template>

<script>
import EmployeeFileTable from './EmployeeFileTable.vue'

export default {
  data() {
    return {
      showfiles: true
    }
  },
  props: {
    employeeid: Number,
    filetablekey: Number,
    employeefiles: {},
  },
  components: {
    EmployeeFileTable,
  },
  methods: {
    showFiles() {
      this.showfiles = !this.showfiles
    },
    Renderfiletable() {
      this.$emit("Renderfiletable")
    },
    SetShowFiles(bool) {
      this.showfiles = bool;
    }
  },
  mounted() {
    this.$root.$refs.EmployeeDocuments = this
  }
}
</script>