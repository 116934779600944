<template>
  <v-form ref="form" :disabled="formdisabled">
    <v-col class="densecol mb-0 pb-0">
      <h2>Angehörige und Kontakte</h2>
      <div v-if="showsavecontacts">
        <div  v-for="item in filledform.patientrelatedcontacts" :key="item.ID">
          <PatientRelatedContactItem
            :form="item"
            :key="patientrelatedcontactitemkey"
            :titlesPool="titlesPool"
            @RenderContacts="RenderContactItems"
          />
        </div>
      </div>
      <v-btn
        color="primary"
        elevation="2"
        class="mb-3  mt-3"
        @click="ShowNewContact()" 
      >
        <i class="fas fa-plus"></i>Neuer Kontakt
      </v-btn>
      <PatientRelatedContactItem
        v-if="showcontact"
        :form="form.patientrelatedcontacts"
        ref="patientrelatedcontactitem"
        :patientID="patientID"
        @RenderContacts="RenderContactItems"
        :titlesPool="titlesPool"
      />
    </v-col>
  </v-form>
</template>
<script>

import PatientRelatedContactItem from "./PatientRelatedContactItem";


export default {
  components:{
    PatientRelatedContactItem
  },
  props: {
    titlesPool: {},
    patientObject: {},
    patientID: undefined,
    formdisabled: Boolean,

  },
  mixins: [],
  created(){
    this.FillInForm()
  },
  data() {
    const defaultForm = Object.freeze({
      patientrelatedcontacts: {
        ID: undefined,
        Title: undefined,
        FirstName: undefined,
        LastName: undefined,
        EMail: undefined,
        Street: undefined,
        PLZ: undefined,
        City: undefined,
        PhoneNumber: undefined,
        MobileNumber: undefined,
        RelatedContactType: undefined,
        IsEmergencyContact: undefined,
        AvailableAtNight: undefined,
        FirstKeyStoredPlace: undefined,
        SecondKeyStoredPlace: undefined,
        ThirdKeyStoredPlace: undefined,
        HasKey: undefined,
        PatientID: undefined,
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
      filledform: Object.assign({}, defaultForm),
      titlePool: this.titlesPool,
      showcontact: false,
      showsavecontacts: false,
      patientrelatedcontactitemkey: 0
    };
  },

  methods: {
    async SaveRelatedContactObject() {
      if(this.showcontact){
        await this.$refs.patientrelatedcontactitem.SaveRelatedContactObject()
      }
      if(this.filledform.patientrelatedcontacts.length != undefined){
        this.filledform.patientrelatedcontacts.forEach(element => {
          this.$store.dispatch('patientoverview/createPatientRelatedContact', element)
        });
      }
    },
    ResetForm() {
      // Warum kann ich diese Felder nur über diesen Weg leeren?
      this.form.patientrelatedcontacts.FirstKeyStoredPlace = null;
      this.form.patientrelatedcontacts.SecondKeyStoredPlace = null;
      this.form.patientrelatedcontacts.ThirdKeyStoredPlace = null;
      console.log("# calling ResetFrom() of RelatedContact #");
      this.$refs.form.reset();
      this.showsavecontacts = false
    },
    async FillInForm(){
      try{
        await this.$store.dispatch('patientoverview/getPatientRelatedContactByPatientID', this.patientID)
        this.filledform.patientrelatedcontacts = this.$store.getters['patientoverview/getContactData']
        this.ShowSaveContacts()
      }catch{
        this.showsavecontacts = false
      }
    },
    ShowNewContact(){
      this.showcontact = !this.showcontact;
    },
    ShowSaveContacts(){
      this.showsavecontacts = true
    },
    async RenderContactItems(){
      await this.FillInForm()
      this.patientrelatedcontactitemkey = this.patientrelatedcontactitemkey++
      this.showcontact = false
      let patientrelatedcontacts = {
        ID: undefined,
        Title: undefined,
        FirstName: undefined,
        LastName: undefined,
        EMail: undefined,
        Street: undefined,
        PLZ: undefined,
        City: undefined,
        PhoneNumber: undefined,
        MobileNumber: undefined,
        RelatedContactType: undefined,
        IsEmergencyContact: undefined,
        AvailableAtNight: undefined,
        FirstKeyStoredPlace: undefined,
        SecondKeyStoredPlace: undefined,
        ThirdKeyStoredPlace: undefined,
        HasKey: undefined,
        PatientID: undefined,
      }
      this.form.patientrelatedcontacts = patientrelatedcontacts
    }
  },

}

</script>

<style scoped>
i {
  margin-right: 10px;
}

input[type="text"],
text-area {
  border: 1px solid #ab7cab;
  background: white;
  margin: 2px;
}

.error--text {
  color: red !important;
  font-size: 22px;
  text-transform: uppercase !important;
  font-weight: bold;
  font-style: italic;
}

</style>
