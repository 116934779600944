<template>
  <v-container fluid>
    <v-row class="ml-2 mr-2">
      <v-select v-if="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'"
                label="Mitarbeiter"
                :items="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'? employees : employees.filter(val => val.ID === parseInt($cookies.get('currentUserID')))"
                item-value="ID"
                dense
                v-model="employeeID"
                ref="selectemployees"
                hint="Wählen sie einen Mitarbeiter"
                persistent-hint
                @change="GetDataAndSetBackAndSend()">
        <template v-slot:selection="{item}">
          {{ item.Personalnumber }} {{ item.Firstname }} {{ item.Lastname }}
        </template>
        <template v-slot:item="{item}">
          {{ item.Personalnumber }} {{ item.Firstname }} {{ item.Lastname }}
        </template>
      </v-select>

      <v-select v-else
                label="Mitarbeiter"
                :items="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'? employees : employees.filter(val => val.ID === parseInt($cookies.get('currentUserID')))"
                item-value="ID"
                dense
                v-model="selected"
                ref="selectemployees"
                hint="Wählen sie einen Mitarbeiter"
                persistent-hint>
        <template v-slot:selection="{item}">
          {{ item.Personalnumber }} {{ item.Firstname }} {{ item.Lastname }}
        </template>
        <template v-slot:item="{item}">
          {{ item.Personalnumber }} {{ item.Firstname }} {{ item.Lastname }}
        </template>
      </v-select>

    </v-row>
    <v-row class="ml-2 mr-2">
      <v-dialog
          ref="dialogpickdate"
          v-model="modalforpickdate"
          :return-value.sync="date"
          persistent
          width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              class="mt-6"
              v-model="date"
              label="Tag wählen"
              prepend-icon="mdi-calendar"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
              ref="datepicker"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            scrollable
            :first-day-of-week="1"
            locale="de"
            @change="setDateInCalendar">
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="modalforpickdate=false">
            Abbrechen
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="SaveDateInTextfield(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-row>
    <v-row>
      <v-row>
        <v-col class="ml-4">
          <a style="color: greenyellow">Anfangszeit : </a><b>{{ starttime }} </b>
        </v-col>
        <v-col>
          <a style="color: orange"> Endzeit : </a><b>{{ endtime }} </b>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col class="ml-4">
          <v-btn style="justify-content: inherit;"
                 :disabled="!employeeID"
                 @click="setWorkStart"
                 small
                 width="110px"
                 color="green">
            <v-icon left small>mdi-clock-time-four-outline</v-icon>
            Kommen
          </v-btn>
        </v-col>
        <v-col>
          <v-btn style="justify-content: inherit;"
                 :disabled="!employeeID"
                 @click="setWorkEnd"
                 small
                 width="110px"
                 color="orange">
            <v-icon left small>mdi-clock-time-four-outline</v-icon>
            Gehen
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-divider style="background-color: #f19898" class="mt-4 mb-4"></v-divider>
    <v-row class="ml-1 mt-2 mb-4">
      <!--  <v-btn
            @click="SaveWorktime"
            color="info">
          <i class="fas fa-save mr-2"></i>
          Speichern
        </v-btn>
        -->
      <v-btn class="ml-2"
             :disabled="!employeeID"
             @click="ShowTimeTableRecords"
             color="info">
        <v-icon class="mr-2" small> mdi-clipboard-edit-outline
        </v-icon>
        Report Arbeitszeit
      </v-btn>
      <v-btn
        class="ml-4"
        color="info"
        @click="ShowDateField()"
      >
        Report erzeugen
      </v-btn>
    </v-row>
    <v-row v-if="showdatefield">
      <v-col>
        <v-row>
          <v-col>
            <DatePickerRange
              :label="label"
              @SendDates="GetDates"
            />
          </v-col>
          <v-col class="mt-3">
            <v-btn
              color="info"
              @click="ExportData"
            >
              Report Herunterladen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ml-0">
      <v-dialog
          v-model="timerecordsmodaldialog"
          persistent max-width="1100px">
        <TimeTableRecordsDialog
            :employee="employee"
            :key="timeRecordsTableKey"
            :employeeID="employeeID"
            @RefreshTable="RenderTimeRecordsTable"/>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import TimeTableRecordsDialog from '../../dialogs/TimeTableRecordsDialog'
import DatePickerRange from '../../utils/DatePickerRange.vue'
import moment from "moment";

export default {
  components: {
    TimeTableRecordsDialog,
    DatePickerRange
  },
  props: {
    employees: {},
    currentEmployeeID: Number,
  },
  created() {
    this.loadLimitedOrReadOnlyEvents();
  },

  data() {
    return {
      modalforpickdate: false,
      modalforstarttime: false,
      modalforendtime: false,
      date: "",
      starttime: null,
      endtime: null,
      employeeID: "",
      timeRecordsTableKey: 0,
      timetablerecordskey: 0,
      employee: {},
      selected: parseInt(this.currentEmployeeID),
      showdatefield: false,
      label: "Zeitraum des Exports auswählen",
      timestampZero: undefined,
      timestampOne: undefined,
      timestampBeginPeriod: undefined,
      timestampEndPeriod: undefined
    }
  },
  // inject: ['role', 'currentID'],
  computed: {
    timerecordsmodaldialog() {
      return this.$store.getters['eventdialog/gettimetablerecordsmodaldialog']
    },
    // notStarted() {
    //   return this.starttime
    // },
    // Role() {
    //   return this.role
    // },
    // currentID() {
    //   return this.ID
    // },
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  methods: {
    loadLimitedOrReadOnlyEvents() {
      this.$emit("SendEmployeeID", this.currentEmployeeID);
    },
    setDateInCalendar() {
      this.$root.$refs.TimeTableCalendar.setNewDate(this.date);
    },
    async setWorkStart() {
      this.starttime = moment(String(new Date())).format('hh:mm')
      await this.SendTimeRecord("Start")
    },
    async setWorkEnd() {
      this.endtime = moment(String(new Date())).format('hh:mm')
      await this.SendTimeRecord("End")
    },
    async SendTimeRecord(endorstart) {
      let timestamp = new Date().getTime()
      let timerecord = {
        Start: 0,
        End: 0,
        EmployeeID: this.employeeID,
      }
      let errorobject = {
        message: "",
        type: ""
      }
      if (endorstart == "Start") {
        timerecord.Start = Number(timestamp)
        errorobject.message = "Arbeitszeitbeginn wurde erfolgreich gespeichert"
      }
      if (endorstart == "End") {
        timerecord.End = Number(timestamp)
        errorobject.message = "Arbeitszeitende wurde erfolgreich gespeichert"
      }
      try {
        await this.$store.dispatch('employees/createEmployeeTimeRecord', timerecord)
        errorobject.type = "success"
        this.SetAlertMessage(errorobject)
      } catch (error) {
        errorobject.message = error
        errorobject.type = "error"
        this.SetAlertMessage(errorobject)
      }
      this.RenderTimeRecordsDialog()
    },
    ShowTimeTableRecords() {
      this.RenderTimeRecordsTable()
      this.$store.commit('eventdialog/changeTimeTableRecordsModalDialogtoTrue')
    },
    SaveDateInTextfield(date) {
      this.$refs.dialogpickdate.save(date)
    },
    SetAlertMessage(errorobject) {
      this.$emit('SetAlert', errorobject)
    },
    SetBack() {
      this.date = ""
      this.starttime = null
      this.endtime = null
    },
    async GetDataAndSetBackAndSend() {
      await this.GetDataAndSetBack()
      this.$emit("SendEmployeeID", this.employeeID)
    },
    RenderTimeRecordsTable() {
      this.timeRecordsTableKey++

    },
    async GetDataAndSetBack() {
      this.SetBack()
      //let response = await this.$store.dispatch('employees/getEmployeeTimeRecordsByEmployeeID', this.employeeID)
      //console.log(response)
      this.employee = this.employees.find(employee => employee.ID == this.employeeID)
      console.log(this.employee)
      this.RenderTimeRecordsDialog()
      this.RenderTimeRecordsTable()
    },
    RenderTimeRecordsDialog() {
      this.timetablerecordskey++
    },
    ShowDateField(){
      console.log("ShowDateField")
      this.showdatefield=!this.showdatefield
    },
    GetDates(dates){
      console.log(dates)
      this.timestampZero = new Date(dates[0]).getTime()
      this.timestampOne = new Date(dates[1]).getTime()
      if (this.timestampZero <= this.timestampOne) {
          this.timestampBeginPeriod = this.timestampZero
          this.timestampEndPeriod = this.SetTimestampToEndofDay(this.timestampOne)
      } else {
          this.timestampBeginPeriod = this.timestampOne
          this.timestampEndPeriod = this.SetTimestampToEndofDay(this.timestampZero)
      }
    },
    SetTimestampToEndofDay(timestamp){
        let date = new Date(timestamp)
        let dateEndOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
        let timestampEndOfDay = dateEndOfDay.getTime()
        return timestampEndOfDay
    },
    async ExportData(){
      if(isNaN(this.timestampBeginPeriod) || isNaN(this.timestampEndPeriod)){
          confirm("Es muss ein Zeitraum ausgewählt sein")
          return 
      }
      let data = {
          timestampBeginPeriod: this.timestampBeginPeriod,
          timestampEndPeriod: this.timestampEndPeriod,
          employeeID: this.employeeID
      }
      console.log(data)
      try{
          let fileinformation = await this.$store.dispatch('file/downloadExportTimeRecording', data)
          var FileSaver = require('file-saver');
          FileSaver.saveAs(fileinformation.fileblob, fileinformation.filename)
      } catch(error){
          confirm(error)
      }
    }
  }
}
</script>