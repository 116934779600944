export const loading = {
    namespaced: true,
    state: {
        loadedbytes: 0,
        totalbytes: 0,
    },
    getters: {
        getloadedbytes: state =>{
            return state.loadedbytes
        },
        gettotalbytes: state =>{
            return state.totalbytes
        }
    },
    mutations: {
        setLoadedBytestoZero(state){
            state.loadedbytes = 0
        },
        setLoadedBytes(state, value){
            state.loadedbytes += value
        },
        setTotalBytes(state, value){
            state.totalbytes = value
        }
    }
}