<template>
    <div>
        <v-row>
            <v-text-field
                label="Name des Feiertags"
                v-model="feiertag.Name"
                disabled
            >
            </v-text-field>
            <v-checkbox
                label="Aktiv"
                v-model="feiertag.Activ"
                :disabled="disabled"
            >
            </v-checkbox>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        feiertag: {},
        disabled: Boolean
    }
}
</script>