<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h1>Rechnungs Einstellungen</h1>
      </v-col>
      <v-col cols="12">
        <v-container style="min-height: 800px">
          <v-row>
            <v-col cols="12">
              <v-expansion-panels>
                <InvoiceSenderInfo v-if="infoData.item !== undefined" :info="infoData.item"></InvoiceSenderInfo>
                <InvoiceNumberSettings v-if="numberData.item !== undefined"
                                       :setting="numberData.item"
                                       :debitorenSettings="debitorenData.item"
                ></InvoiceNumberSettings>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InvoiceSenderInfo from "../../components/settings/invoiceSenderInfo"
import InvoiceNumberSettings from "../../components/settings/InvoiceNumberSettings"

export default {
  mounted() {
    this.reload();
  },
  components: {
    InvoiceSenderInfo,
    InvoiceNumberSettings
  },
  computed: {
    infoData() {
      return this.$store.state.senderInfo.current;
    },
    numberData() {
      let x = this.$store.state.invoicenumbersetting.current;
      console.log(x);
      return x;
    },
    debitorenData() {
      let x = this.$store.state.invoicedebitorsetting.current;
      console.log(x);
      return x;
    },
    // debitorenKostentraegerData() {
    //   let x = this.$store.state.invoicekostentraegerdebitorsetting.current;
    //   console.log(x);
    //   return x;
    // }
  },
  methods: {
    async reload() {
      await this.$store.dispatch("senderInfo/get");
      await this.$store.dispatch("invoicenumbersetting/get");
      await this.$store.dispatch("invoicedebitorsetting/get");
      // await this.$store.dispatch("invoicekostentraegerdebitorsetting/get");
      this.$root.$refs.start.setMenuActive(true);
    },
  },
};
</script>
