<template>
  <div>
    <row :gutter="12" v-if="!articleExist">
      <column :xs="12">
        <p>keine Leistungen hinterlegt</p>
      </column>
    </row>
    <timed-articles
        v-for="item in articles.items"
        :key="item.ID"
        :calendarID="calendarID"
        :articles="item"
    ></timed-articles>
  </div>
</template>
<script>
import TimedArticles from "./TimedArticles";

export default {
  components: {TimedArticles},
  props: {
    calendarID: Number,
    articles: {}
  },
  mounted() {
    this.$root.$refs.PatientServiceArticleBooking = this
  },
  data() {
    return {
      articleExist: true,
    }
  },
  methods: {
    convertUhrVon(time) {
      return time.HH + ":" + time.mm
    },
    convertUhrBis(time) {
      return time.HH + ":" + time.mm
    },

    async SendMinutes() {
      if (confirm("Möchten Sie die Uhrzeiten wirklich speichern?")) {
        let saveAmountUnit = [];
        for (let i = 0; i < this.articles.items.length; i++) {
          let cookieUhrzeitVon = "von" + this.articles.items[i].ID
          let cookieUhrzeitBis = "bis" + this.articles.items[i].ID
          this.articles.items[i].ID

          if (this.articles.items[i].Timed || this.articles.items[i].NumberOfPieces) {
            let amount = "amount" + this.articles.items[i].ID
            let saveAmountUnitObject = {
              ID: this.articles.items[i].ID,
              AmountUnit: this.$cookies.get(amount) === null ? Number(this.articles.items[i].AmountUnit.toString().replace(",", ".")) : parseInt(this.$cookies.get(amount)),
              UhrzeitVon: this.$cookies.get(cookieUhrzeitVon),
              UhrzeitBis: this.$cookies.get(cookieUhrzeitBis),
            }
            saveAmountUnit.push(saveAmountUnitObject)
            await this.$store.dispatch('serviceArticles/saveAmountToPatientServiceArticleForCalendars', saveAmountUnit);
          }
        }
      } else {
        // doNothing
      }
      // Schliesse DIALOG Fenster
      this.$store.commit("eventdialog/changeTimeTableModalDialogToFalse");
    },
  }
}
</script>
