<template>
  <main>
    <v-form ref="form">
      <v-container fluid>
        <v-row class="" align-content="center">
          <h3>
            <v-icon
                color="secondary"
                dark
                :disabled="!patientID"
                v-bind="attrs"
                @click="showKlientDocuments"
                v-on="on">
              mdi-paperclip
            </v-icon>
            {{ form.chipsdata.LastName }}
            {{ form.chipsdata.LastName === undefined ? "" : "," }}
            {{ form.chipsdata.FirstName }}
          </h3>
          <v-chip v-if="form.chipsdata.Interested"
                  v-model="form.chipsdata.Interested"
                  class="ml-2"
                  color="chips_interested">
            <v-icon left></v-icon>
            Interessent
          </v-chip>

          <v-chip v-if="form.chipsdata.Active"
                  class="ml-2"
                  color="chips_active"
                  v-model="form.chipsdata.Active.sync"
          >
            <v-icon left></v-icon>
            {{ form.chipsdata.Active ? 'Aktiv' : 'INAKTIV' }}
          </v-chip>

          <v-chip
              v-if="form.chipsdata.ServiceHousehold"
              class="ml-2"
              color="chips_nearHousehold"
              v-model="form.chipsdata.ServiceHousehold">
            <v-icon left></v-icon>
            Alltagsunterstützende Dienste
          </v-chip>

          <v-chip v-if="form.chipsdata.ServiceEmergencyCall"
                  class="ml-2"
                  color="chips_emergency"
                  v-model="form.chipsdata.ServiceEmergencyCall">
            <v-icon left></v-icon>
            Hausnotruf
          </v-chip>

          <v-chip
              v-if="form.chipsdata.Deceased"
              class="ml-2"
              color="chips_deceased"
              v-model="form.chipsdata.Deceased">
            <v-icon left style="font-size: small">fas fa-cross fa-xs</v-icon>
            Verstorben
          </v-chip>
        </v-row>
        <div v-if="DebitorenNumber" style="float:right;margin-top: -20px;"><h4 style="display: inline">
          Debitoren-Nr.: </h4><b>{{ DebitorenNumber }}</b></div>
      </v-container>
    </v-form>
  </main>
</template>

<script>

export default {
  props: {
    patientObject: {},
    patientID: Number,
  },

  mounted() {
    this.$root.$refs.PatientChips = this;
  },
  data() {
    const defaultForm = Object.freeze({
      chipsdata: {
        FirstName: undefined,
        LastName: undefined,
        Interested: false,
        ServiceEmergencyCall: false,
        ServiceHousehold: false,
        Active: false,
        Deceased: false,
        PatientID: this.patientId,
        DebitorenNumber: undefined
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
      activeColor: 'red',
      inActiveColor: 'blue',
      Patient: this.patientObject,
    };
  },
  created() {
    if (Object.keys(this.patientObject).length === 0) {
      return this.chipsdata;
    } else {
      this.form.chipsdata.FirstName = this.patientObject.item.FirstName;
      this.form.chipsdata.LastName = this.patientObject.item.LastName;
      this.form.chipsdata.ServiceEmergencyCall = this.patientObject.item.ServiceEmergencyCall;
      this.form.chipsdata.ServiceHousehold = this.patientObject.item.ServiceHousehold
      this.form.chipsdata.Active = this.patientObject.item.Active
      this.form.chipsdata.Deceased = this.patientObject.item.Deceased
      this.form.chipsdata.Interested = this.patientObject.item.Interested;
      this.form.chipsdata.DebitorenNumber = this.DebitorenNumber;
    }
  },
  methods: {
    setDebitorenNumber(debitorenNumber) {
      this.DebitorenNumber = debitorenNumber;
    },
    showKlientDocuments() {
      this.$root.$refs.PatientInputMask.showKlientDocuments(this.patientID);
    },
    setFullName(first, last) {
      // alert(first + " " + last);
      this.form.chipsdata.FirstName = first;
      this.form.chipsdata.LastName = last;
    },
    setChips(Interested, ServiceEmergencyCall, ServiceHousehold, Active, Deceased) {
      this.form.chipsdata.Interested = Interested;
      this.form.chipsdata.ServiceEmergencyCall = ServiceEmergencyCall;
      this.form.chipsdata.ServiceHousehold = ServiceHousehold;
      this.form.chipsdata.Active = Active;
      this.form.chipsdata.Deceased = Deceased;
    },
    ResetForm() {
      console.log("# calling ResetFrom() of PatientChips #");
      this.form.chipsdata.FirstName = undefined;
      this.form.chipsdata.LastName = undefined;
      this.form.chipsdata.DebitorenNumber = undefined;
      this.form.chipsdata.Interested = false;
      this.form.chipsdata.ServiceEmergencyCall = false;
      this.form.chipsdata.ServiceHousehold = false;
      this.form.chipsdata.Active = false;
      this.form.chipsdata.Deceased = false;
    },
    ToggleInteressent() {
      this.form.chipsdata.Interested = !this.form.chipsdata.Interested;
    },
    ToggleActive() {
      this.form.chipsdata.Active = !this.form.chipsdata.Active;
    },
    ToggleServiceEmergencyCall() {
      this.form.chipsdata.ServiceEmergencyCall = !this.form.chipsdata.ServiceEmergencyCall;
    },
    ToggleServiceHousehold() {
      this.form.chipsdata.ServiceHousehold = !this.form.chipsdata.ServiceHousehold;
    },
    ToggleDeceased() {
      this.form.chipsdata.Deceased = !this.form.chipsdata.Deceased;
    },
    UpdateFirstName(firstname) {
      this.form.chipsdata.FirstName = firstname;
    },
    UpdateLastName(lastname) {
      this.form.chipsdata.LastName = lastname;
    }
  },
};
</script>

<style scoped>
i {
  margin-right: 10px;
}

.chips_emergency {
  background-color: red !important;
  color: white;
}

.chips_active {
  background-color: green !important;
  color: white;
}

.chips_inactive {
  background-color: lightgray !important;
  color: black;
}

.chips_interested {
  background-color: #ffff00 !important;
  color: black;
  border: 0.1px solid #dfe001;
}

.chips_nearHousehold {
  background-color: #531275 !important;
  color: white;
}

.chips_deceased {
  background-color: #000000 !important;
  color: white;
}

.chips_noStyle {

  color: #006ab5 !important;
  font-size: 1em;
}
</style>
