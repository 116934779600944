var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"padding":"10px","background-color":"#f1f1f1"},attrs:{"dark":""}},[_c('v-row',[_c('h3',{staticClass:"mt-4 ml-8"},[_vm._v(" Arbeitszeiten von")]),_c('v-chip',{staticClass:"mt-4 ml-2",style:({backgroundColor: _vm.employee.EmployeeColorHex})},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(_vm.employee.Firstname)+" "+_vm._s(_vm.employee.Lastname)+" ")],1)],1),_c('v-toolbar',{staticClass:"mt-8",attrs:{"light":"","color":"white"}},[_c('v-text-field',{attrs:{"clearable":"","append-icon":"mdi-text-box-search-outline","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"offset-y":"","nudge-left":170,"close-on-content-click":false}})],1),_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticStyle:{"padding-top":"26px"},attrs:{"column":""}},[_c('v-data-table',{staticClass:"elevation-1 ",class:{mobile: _vm.isMobile},attrs:{"light":"","headers":_vm.headers,"items":_vm.timerecords,"search":_vm.search,"pagination":_vm.pagination,"header-props":_vm.headerProps,"hide-headers":_vm.isMobile,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","single-select":"","item-key":"ID","isSelected":"true","items-per-page":5,"footer-props":{
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Einträge pro Seite',}},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.Comment",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{key:item.ID,attrs:{"label":"Kommentar"},model:{value:(item.Comment),callback:function ($$v) {_vm.$set(item, "Comment", $$v)},expression:"item.Comment"}})]}},{key:"item.saveComment",fn:function(ref){
          var item = ref.item;
return [_c('div',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.SaveComment(item.ID, item.Comment)}}},[_c('i',{staticClass:"fas fa-save mr-2"})])],1)]}},{key:"item.deleteComment",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","align":"center","color":"primary"},on:{"click":function($event){return _vm.DeleteTimeRecord(item.ID)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.CloseDialog()}}},[_vm._v(" Schließen ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }