<template>
  <v-container fluid>
    <v-row>
      <v-data-table style="padding:10px"
                    light
                    :items="patients"
                    :headers="headers"
                    :search="search"
                    :header-props="headerProps"
                    no-data-text="keine Daten vorhanden"
                    no-results-text="keine Suchergebnisse"
                    @click:row="rowClick"
                    single-select
                    item-key="ID"
                    isSelected="true"
                    :items-per-page="5"
                    :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
      >
        <template v-slot:top>
          <v-row style="width: 100%">
            <column class="mt-4 ml-2">
              <h3>
                <v-icon style="color: #006ab5">mdi-account-multiple</v-icon>
                Alle Klienten
              </h3>
            </column>
          <v-text-field
              clearable
              append-icon="mdi-magnify"
              v-model="search"
              label="Suchen"
              class="mx-4"
          >
          </v-text-field>
          </v-row>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      search: '',
      headers: [
        {text: 'PATNR', value: 'ID'},
        {text: 'Name', value: 'LastName'},
        {text: 'Vorname', value: 'FirstName'},
      ],
      headerProps: {
        sortByText: 'Sortieren nach'
      },
    }
  },
  computed: {
    patients() {
      return this.$store.getters['patients/getPatientForTables']
    }
  },
  methods: {
    rowClick(item, row) {
      row.select(true)
      console.log(item)
      this.$emit("sendPatientID", item.ID)
    }
  }
}
</script>