import {authHeader} from '../helpers';
import {store} from "../store";

export const patientService = {
    getAll,
    getPatient,
    newPatient,
    newAddress,
    updatePatient,
    updatePatientServiceInfo,
    updateAddress,
    deleteAddress,
    deletePatientById,
    newBankaccount,
    updateBankaccount,
    deleteBankaccountById,
    updatePatientPaymentAddress,
    newContact,
    newContactAddress,
    updateContact,
    updateContactAddress,
    deleteContact,
    deleteContactAddress,
    newMedication,
    updateMedication,
    deleteMedication,
    unlock,
    lock,
    deletePaymentAddress,
    getAllPatientDevicesTable,
    changeTemplate,
    servicepricePrivatesums,
    servicepriceHNsums,
    servicepriceAUSsums,
    getPatientsForTable,
    getPatientForEventDialogByID,
    uploadFile,
    downloadFile,
    getFiles,
    DeleteFile,

};

async function DeleteFile(DeleteFile) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(DeleteFile)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patient/file/delete/`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getFiles(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patient/files/id/` + id, requestOptions)
    let data = await handleResponse(response)

    return data
}

async function uploadFile(id, files) {
    const formData = new FormData();
    files.forEach(file => formData.append('file', file))
    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patient/file/upload/` + id, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function downloadFile(fileID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patient/file/download/` + fileID, requestOptions)
    console.log("test")
    let data = await downloadFileStream(response)

    return data
}

function getPatientForEventDialogByID(patientID) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/event/get/` + patientID, requestOptions).then(handleResponse);
}

function getPatientsForTable() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/patients/table/get`, requestOptions).then(handleResponse);
}


function getAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patients/overview`, requestOptions).then(handleResponse);
}

function getPatient(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/id/` + id, requestOptions).then(handleResponse);
}

function servicepriceHNsums(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/services/hn/price/` + id, requestOptions).then(handleResponse);
}

function servicepriceAUSsums(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/services/aus/price/` + id, requestOptions).then(handleResponse);
}

function servicepricePrivatesums(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/services/private/price/` + id, requestOptions).then(handleResponse);
}


function newPatient(patient) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(patient)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient`, requestOptions).then(handleResponse);
}

function unlock(patient) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(patient)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/lock/` + patient.ID, requestOptions).then(handleResponse);
}

function lock(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/unlock/` + id, requestOptions).then(handleResponse);
}

function updatePatient(patient) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(patient)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/change/id/` + patient.ID, requestOptions).then(handleResponse);
}


function changeTemplate(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/change/template/` + data.ID, requestOptions).then(handleResponse);
}


function updatePatientServiceInfo(patient) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(patient)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/changeservice/id/` + patient.ID, requestOptions).then(handleResponse);
}

function deletePaymentAddress(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/paymentaddress/delete/` + id, requestOptions).then(handleResponse);
}

function deletePatientById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/delete/id/` + id, requestOptions).then(handleResponse);
}

function newAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/address`, requestOptions).then(handleResponse);
}

function updateAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/address/change/` + address.ID, requestOptions).then(handleResponse);
}

function deleteAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/address/delete/` + address.ID, requestOptions).then(handleResponse);
}


function newBankaccount(bankaccount) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(bankaccount)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/bankaccount`, requestOptions).then(handleResponse);
}

function updateBankaccount(bankaccount) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(bankaccount)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/bankaccount/change/` + bankaccount.ID, requestOptions).then(handleResponse);
}

function deleteBankaccountById(bankaccount) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(bankaccount)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/bankaccount/delete/` + bankaccount.ID, requestOptions).then(handleResponse);
}

function updatePatientPaymentAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/paymentaddress/change/` + address.ID, requestOptions).then(handleResponse);
}

function newContact(contact) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(contact)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/contact`, requestOptions).then(handleResponse);
}


function newContactAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/contactaddress`, requestOptions).then(handleResponse);
}


function updateContact(contact) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(contact)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/contact/` + contact.ID, requestOptions).then(handleResponse);
}

function updateContactAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/contactaddress/` + address.ID, requestOptions).then(handleResponse);
}


function deleteContact(contact) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(contact)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/contact/` + contact.ID, requestOptions).then(handleResponse);
}

function deleteContactAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/contactaddress/` + address.ID, requestOptions).then(handleResponse);
}

function newMedication(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/medication`, requestOptions).then(handleResponse);
}


function updateMedication(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/medication/change/` + payload.ID, requestOptions).then(handleResponse);
}

function deleteMedication(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/patient/medication/delete/` + payload.ID, requestOptions).then(handleResponse);
}

async function getAllPatientDevicesTable() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/patient/devices/get/table`, requestOptions)
    let data = await handleResponse(response)
    return data
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}


async function downloadFileStream(response) {
    console.log("downloadFileStream")
    const contenttype = response.headers.get('Content-Type')
    let splitcontenttypearray = contenttype.split(';')
    console.log("contenttype:" + contenttype)
    let filename = getValueFromSplitContenttypeArray(splitcontenttypearray[0])
    let filesize = getValueFromSplitContenttypeArray(splitcontenttypearray[1])
    store.commit('loading/setTotalBytes', Number(filesize))
    console.log("filename: " + filename)
    const reader = response.body.getReader()
    let chunks = []
    let finished = false
    store.commit('loading/setLoadedBytestoZero')
    while (!finished) {
        const {done, value} = await reader.read()
        if (done) {
            break
        }
        chunks.push(value)
        store.commit('loading/setLoadedBytes', value.length)
        finished = done
    }
    let blob = new Blob(chunks)
    let fileInformation = {
        fileblob: blob,
        filename: filename,
        filesize: filesize
    }
    return fileInformation
}

function getValueFromSplitContenttypeArray(splitcontenttype) {
    let array = splitcontenttype.split('=')
    let value = array[1]
    return value
}
