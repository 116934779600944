<template>
  <v-container fluid>
    <h3> Template für die Rechnungsstellung</h3>
    <div class="mt-3 mb-0">
      <v-row>
        <v-col>
          <v-select
              class="mt-0"
              label="Nebenbetreff:"
              placeholder="Nebenbetreff"
              hide-details="auto"
              :items="SubjectBeside"
              item-text="TemplateName"
              item-value="ID"
              v-model="form.patientTemplateConfigDTO.SubjectBesideTemplateID"
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <v-row class="mt-0">
      <v-col>
        <v-select
            class="mt-0"
            label="Eingangstext:"
            placeholder="Eingangstext"
            hide-details="auto"
            :items="BegintextText"
            item-text="TemplateName"
            item-value="ID"
            v-model="form.patientTemplateConfigDTO.BegintextTextTemplateID"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col>
        <v-select
            class="mt-0"
            label="Zahlungsbedingungen:"
            placeholder="Zahlungsbedingungen"
            hide-details="auto"
            :items="EndtextTermsOfPayment"
            item-text="TemplateName"
            item-value="ID"
            v-model="form.patientTemplateConfigDTO.EndtextTermsOfPaymentTemplateID"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-4">
      <v-col>
        <v-select
            class="mt-0"
            label="Ausgangstext:"
            placeholder="Ausgangstext"
            hide-details="auto"
            :items="EndtextText"
            item-text="TemplateName"
            item-value="ID"
            v-model="form.patientTemplateConfigDTO.EndtextTextTemplateID"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
export default {
  name: "InvoicingTemplate",
  props:{
    patientID: undefined,
    HN: Boolean(false),
    AUS: Boolean(false),
    Private: Boolean(false)
  },
  async created(){
    console.log("*********create InvoicingTemplate**********")
    //await this.GetAllInvoiceTemplateDTO()
    await this.FillInForm()
    //console.log(JSON.stringify(this.SubjectMain))
  },
  data(){
    const defaultForm = Object.freeze({
      patientTemplateConfigDTO: {
        PatientID: undefined,
        SubjectBesideTemplateID: undefined,
        BegintextTextTemplateID: undefined,
        EndtextTextTemplateID: undefined,
        EndtextTermsOfPaymentTemplateID: undefined,
        InvoiceChoice: undefined,
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
    }
  },
  computed:{
    SubjectBeside(){
      return this.$store.getters['invoicePatient/getInvoiceTemplateDTOSubjectBeside']
    },
    BegintextText(){
      return  this.$store.getters['invoicePatient/getInvoiceTemplateDTOBegintextText']
    },
    EndtextText(){
      return this.$store.getters['invoicePatient/getInvoiceTemplateDTOEndtextText']
    },
    EndtextTermsOfPayment(){
      return this.$store.getters['invoicePatient/getInvoiceTemplateDTOEndtextTermsOfPayment']
    },
    PatientTemplateConfig(){
      return this.$store.getters['invoicePatient/getPatientTemplateConfigDTO']
    }
  },
  methods:{
    async SavePatientTemplateConfigDTO(){
      this.form.patientTemplateConfigDTO.PatientID = this.patientID
      if(this.HN == true){
        this.form.patientTemplateConfigDTO.InvoiceChoice = "HN"
      }
      if(this.AUS == true){
        this.form.patientTemplateConfigDTO.InvoiceChoice = "AUS"
      }
      if(this.Private == true){
        this.form.patientTemplateConfigDTO.InvoiceChoice = "Private"
      }
      console.log(JSON.stringify(this.form.patientTemplateConfigDTO))
      await this.$store.dispatch('invoicePatient/savePatientTemplateConfigDTO', this.form.patientTemplateConfigDTO)
    },
    async FillInForm(){
      console.log("FillInForm")
      let data = {
        PatientID: this.patientID,
        InvoiceChoice: undefined
      }
      if(this.HN == true){
        data.InvoiceChoice = "HN"
        this.form.patientTemplateConfigDTO.InvoiceChoice = "HN"
      }
      if(this.AUS == true){
        data.InvoiceChoice = "AUS"
        this.form.patientTemplateConfigDTO.InvoiceChoice = "AUS"
      }
      if(this.Private == true){
        data.InvoiceChoice = "Private"
        this.form.patientTemplateConfigDTO.InvoiceChoice = "Private"
      }
      console.log(data)
      let result = await this.$store.dispatch('invoicePatient/getPatientTemplateConfigDTOByPatientID', data)
      console.log(result)
      this.form.patientTemplateConfigDTO.ID = this.PatientTemplateConfig.ID
      this.form.patientTemplateConfigDTO.PatientID = this.PatientID
      this.form.patientTemplateConfigDTO.SubjectMainTemplateID = this.PatientTemplateConfig.SubjectMainTemplateID
      this.form.patientTemplateConfigDTO.SubjectBesideTemplateID = this.PatientTemplateConfig.SubjectBesideTemplateID
      this.form.patientTemplateConfigDTO.BegintextTextTemplateID = this.PatientTemplateConfig.BegintextTextTemplateID
      this.form.patientTemplateConfigDTO.EndtextTextTemplateID = this.PatientTemplateConfig.EndtextTextTemplateID
      this.form.patientTemplateConfigDTO.EndtextTermsOfPaymentTemplateID = this.PatientTemplateConfig.EndtextTermsOfPaymentTemplateID
      console.log(this.PatientTemplateConfig)
    }
  }
}
</script>

<style scoped>

.centered-input input {
  text-align: right;
  font-size: 1.10em;
}

</style>