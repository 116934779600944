<template>
  <v-card color="rgb(241, 241, 241)" light dense>
    <v-card-title>
      <v-icon style="color:#006ab5">mdi-devices</v-icon>
      <h3>Geräteübersicht für <span
          style="color:#951b81">{{ patient.item.FirstName }} {{ patient.item.LastName }}</span></h3>
    </v-card-title>

    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="6">
          <h3>Geräte zur Verfügung</h3>
          <InventoryDeviceTable
              class="mt-3"
              :devices="activedevices"
              @sendDeviceID="SelectDevice"
              :key="inventorydevicetablekey"
          />
        </v-col>
        <v-col cols="6">
          <h3>Geräte beim Patienten</h3>
          <InventoryDeviceTablePatient
              class="mt-3"
              :devicespatient="devicespatient.items"
              :key="inventorydevicetablepatientkey"
              @renderTable="RenderTables"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="device.Description"
              label="gewähltes Gerät"
              outlined
              readonly
              style="color:red"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="margin-top: -25px">
          <v-btn :disabled="!deviceSelected"
                 color="primary"
                 @click="AddDevice()"
          >
            Gerät hinzufügen
          </v-btn>
        </v-col>
        <v-row justify="end">
          <v-btn
              class="mb-4 mr-6"
              color="info"
              @click="CloseDialog()"
          >
            Schließen
          </v-btn>
        </v-row>
      </v-row>
    </v-card-text>


  </v-card>
</template>
<script>
import InventoryDeviceTable from './InventoryDeviceTable.vue'
import InventoryDeviceTablePatient from './InventoryDeviceTablePatient.vue'

export default {
  components: {
    InventoryDeviceTable,
    InventoryDeviceTablePatient
  },
  props: {
    patientid: Number
  },
  created() {
    this.$store.dispatch('patients/getPatientForInventoryDeviceModalDialog', this.patientid)
    this.$store.dispatch('inventory/getAllInventoryDevicesTable')
    this.$store.dispatch('inventory/getAllInventorDevicesByPatientID', this.patientid)
  },
  data() {
    return {
      device: "",
      inventorydevicetablekey: 0,
      inventorydevicetablepatientkey: 0,
      deviceSelected: false,
    }
  },
  computed: {
    patient() {
      return this.$store.getters['patients/getPatientforinventorydevicemodaldialog']
    },
    activedevices() {
      return this.$store.getters['inventory/getAllInventoryDevicesTableNotActive']
    },
    devicespatient() {
      return this.$store.getters['inventory/getDevicesPatient']
    }
  },
  methods: {
    async SelectDevice(deviceid) {
      console.log(deviceid)
      await this.$store.dispatch('inventory/getInventoryDevicesForFormByID', deviceid)
      this.device = this.$store.getters['inventory/getInventoryDeviceForm']
      this.deviceSelected = true;
    },
    async AddDevice() {
      let devicedata = {
        deviceid: this.device.ID,
        patienid: this.patientid
      }
      let response = await this.$store.dispatch('inventory/setInventoryDeviceOnPatient', devicedata)
      console.log(response)
      await this.RenderTables()
      this.device = ""
      this.deviceSelected = false;
    },
    async RenderTables() {
      await this.$store.dispatch("inventory/getAllInventoryDevicesTable")
      await this.$store.dispatch("inventory/getAllInventorDevicesByPatientID", this.patientid)
      this.inventorydevicetablekey++
      this.inventorydevicetablepatientkey++
    },
    CloseDialog() {
      this.$store.commit('inventory/changeInventoryDeviceDialogToFalse')
      this.device = ""
    }
  }
}
</script>