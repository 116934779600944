<template>
  <v-container fluid>
    <base-card>
      <v-row>
        <v-layout v-resize="onResize" column style="padding-top:26px;">
          <v-data-table
              light
              style="width: 100%"
              :items="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'? employees : employees.filter(val => val.ID === parseInt($cookies.get('currentUserID')))"
              :headers="headers"
              :search="search"
              :header-props="headerProps"
              no-data-text="keine Daten vorhanden"
              no-results-text="keine Suchergebnisse"
              single-select
              item-key="ID"
              isSelected="true"
              :hide-headers="isMobile"
              :class="{mobile: isMobile}"
              :items-per-page="10"
              :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',}">
            <template v-slot:top>
              <v-text-field
                  clearable
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Suchen"
                  class="mx-4"
              ></v-text-field>
            </template>

            <template v-slot:item.Personalnumber="{ item }">
              <v-chip :color="getColor(item)" label
                      :key="item.ID"
                      draggable="true"
                      @dragstart="startDrag($event, item)"
                      @click="GetItem(item)"
              >
                {{ item.Personalnumber }}
              </v-chip>
            </template>
          </v-data-table>
        </v-layout>
      </v-row>
    </base-card>
  </v-container>
</template>
<script>
import BaseCard from "../../Base/BaseCard";


export default {
  components: {BaseCard},
  props: {
    employees: {},
    employeeID: Number,
  },

  mounted() {
    this.$root.$refs.rosterEmployeeTable = this;
  },
  created() {
    console.log("allChipsActivated " + this.allChipsActivated)
  },
  data() {
    return {
      allChipsActivated: true,
      firstChipClick: null,
      tmpColor: null,
      colorList: {},
      tmp: {},
      search: '',
      isMobile: false,
      headers: [
        {text: 'PNR', value: 'Personalnumber'},
        {text: 'Vorname', value: 'Firstname'},
        {text: 'Nachname', value: 'Lastname'},
      ],
      headerProps: {
        sortByText: 'Sortieren nach'
      },
      id: []
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },
    CompareIDForClickstyle(itemID) {
      let compare = false
      this.id.forEach(item => {
        console.log(item)
        if (item == itemID) {
          compare = true
        }
      })
      return compare
    },
    getColor(item) {
      console.log(item)
      if (item.EmployeeColorHex == "") {
        return "#FFFFFFFF"
      } else {
        return item.EmployeeColorHex
      }
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('ID', item.ID)
      evt.dataTransfer.setData('Personalnumber', item.Personalnumber)
      evt.dataTransfer.setData('Firstname', item.Firstname)
      evt.dataTransfer.setData('Lastname', item.Lastname)
      evt.dataTransfer.setData('EmployeeColorHex', item.EmployeeColorHex)
    },
    GetItem(item) {
      console.log(item)
      this.clickOnChip(item);

      //this.id = item.ID
      let found = this.id.find(element => element == item.ID)

      if (found != undefined) {
        let index = this.id.indexOf(found);
        this.id.splice(index, 1);
        this.$store.commit('employees/popEventsByEmployeeID', item.ID);
        item.EmployeeColorHex = "#dadfec78"
        this.allChipsActivated = false
        return;
      }
      this.id.push(item.ID)
      item.EmployeeColorHex = this.getBackColor(item)
      this.$emit('SendEmployeeID', item.ID)
    },
    ItemRowBackground() {
      return 'clickstyle'
    },
    setAllChipsToActive() {
      if (this.allChipsActivated) {
        // do nothing
      } else {
        for (let i = 0; i < this.employees.length; i++) {
          this.employees[i].EmployeeColorHex = this.getAllBackColor(this.employees[i].Personalnumber);
        }
        this.allChipsActivated = true
      }
    },

    clickOnChip(item) {
      if (this.firstChipClick == null) {
        this.allChipsActivated = false
        this.colorList = JSON.parse(JSON.stringify(this.employees))
        for (let i = 0; i < this.employees.length; i++) {
          if (item.ID !== this.employees[i].ID) {
            this.employees[i].EmployeeColorHex = "#dadfec78"
          } else {
            // doNothing
          }
          this.firstChipClick = 1
        }
        return
      }
    },

    getAllBackColor(personalnumber) {
      let backColor = "";
      for (let i = 0; i < this.colorList.length; i++) {
        if (this.colorList[i].Personalnumber === personalnumber) {
          backColor = this.colorList[i].EmployeeColorHex;
        }
      }
      return backColor;
    },

    getBackColor(item) {
      let backColor = "";
      for (let i = 0; i < this.colorList.length; i++) {
        if (this.colorList[i].Personalnumber === item.Personalnumber) {
          // alert("three")
          backColor = this.colorList[i].EmployeeColorHex;
          item.EmployeeColorHex = backColor
        }
      }
      return backColor;
    },

  }
}
</script>


<style scoped>
.mobile {
  color: #000;
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: .5em;
    text-align: left;
    display: block;
    color: #999;

  }

  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }

  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }

}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.table_item {
  padding: 0 16px 0 16px
}

.clickstyle {
  background-color: #dadfec78 !important;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}
</style>
