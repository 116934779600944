<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <h3>Rechnungsnummernvergabe</h3>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <h4>HNR & AUS</h4>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Rechnungspräfix"
              v-model="setting.HNPrefix"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Anzahl der Stellen"
              v-model="setting.HNNumbers"
              ref="countOfDigits"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Erste Rechnungsnummer"
              v-model="setting.HNFirstNumber"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Beispiel"
              v-model="HNComplete"
              disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h4>Debitoren-Nummer</h4>
        </v-col>
<!--        <v-col cols="3">-->
<!--          <v-text-field-->
<!--              label="Debitoren-Nr. präfix"-->
<!--              v-model="debitorenSettings.DebitorenPrefix"-->
<!--          ></v-text-field>-->
<!--        </v-col>-->
        <v-col cols="3">
          <v-text-field
              label="Anzahl der Stellen"
              v-model="debitorenSettings.DebitorenNumbers"
              @change="test"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Erste Debitoren-Nr"
              v-model="debitorenSettings.DebitorenFirstNumber"
              :maxlength="max"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Beispiel"
              disabled
              v-model="DebitorenNumberComplete">
          </v-text-field>
        </v-col>
      </v-row>

<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <h4>Debitoren-Nummer Kostenträger</h4>-->
<!--        </v-col>-->
<!--        <v-col cols="3">-->
<!--          <v-text-field-->
<!--              value="KT-"-->
<!--              label="Debitoren-Nr. präfix"-->
<!--              v-model="debitorenKostentraegerSettings.DebitorenKostentraegerPrefix"-->
<!--          ></v-text-field>-->
<!--        </v-col>-->
<!--        <v-col cols="3">-->
<!--          <v-text-field-->
<!--              label="Anzahl der Stellen"-->
<!--              value="5"-->
<!--              v-model="debitorenKostentraegerSettings.DebitorenKostentraegerNumbers"-->
<!--          ></v-text-field>-->
<!--        </v-col>-->
<!--        <v-col cols="3">-->
<!--          <v-text-field-->
<!--              label="Erste Debitoren-Nr"-->
<!--              v-model="debitorenKostentraegerSettings.DebitorenKostentraegerFirstNumber"-->
<!--          ></v-text-field>-->
<!--        </v-col>-->
<!--        <v-col cols="3">-->
<!--          <v-text-field-->
<!--              label="Beispiel"-->
<!--              disabled-->
<!--              v-model="DebitorenNumberKostentraegerComplete">-->
<!--          </v-text-field>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <v-row>
        <v-col cols="12">
          <h4>Erste Hilfe</h4>
          <span>Aktuell nicht implementiert</span>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Rechnungspräfix"
              v-model="setting.HNPrefix"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Anzahl der Stellen"
              v-model="setting.HNNumbers"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Erste Rechnungsnummer"
              v-model="setting.HNFirstNumber"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Beispiel"
              v-model="HNComplete"
              disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h4>Inklusion</h4>
          <span>Aktuell nicht implementiert</span>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Rechnungspräfix"
              v-model="setting.HNPrefix"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Anzahl der Stellen"
              v-model="setting.HNNumbers"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Erste Rechnungsnummer"
              v-model="setting.HNFirstNumber"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Beispiel"
              v-model="HNComplete"
              disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h4>Jugendhilfe</h4>
          <span>Aktuell nicht implementiert</span>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Rechnungspräfix"
              v-model="setting.HNPrefix"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Anzahl der Stellen"
              v-model="setting.HNNumbers"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Erste Rechnungsnummer"
              v-model="setting.HNFirstNumber"
              disabled
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="Beispiel"
              v-model="HNComplete"
              disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-btn color="primary" @click="Save">
          Speichern
        </v-btn>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {

  props: {
    setting: {},
    debitorenSettings: {},
    // debitorenKostentraegerSettings: {}
  },
  computed: {
    max() {
      return this.debitorenSettings.DebitorenNumbers
    },
    HNComplete() {
      let current_parts = this.setting.HNFirstNumber.toString().length
      let out = ""
      if (current_parts == this.setting.HNNumbers) {
        out += this.setting.HNFirstNumber.toString()
      } else {
        for (let i = current_parts; i < this.setting.HNNumbers; i++) {
          out += "0"
          if (i == this.setting.HNNumbers - 1) {
            out += this.setting.HNFirstNumber.toString()
          }
        }
      }
      return this.setting.HNPrefix + out;
    },
    DebitorenNumberComplete() {
      let current_parts = this.debitorenSettings.DebitorenFirstNumber.toString().length
      let out = ""
      if (current_parts == this.debitorenSettings.DebitorenNumbers) {
        out += this.debitorenSettings.DebitorenFirstNumber.toString()
      } else {
        for (let i = current_parts; i < this.debitorenSettings.DebitorenNumbers; i++) {
          out += "0"
          if (i == this.debitorenSettings.DebitorenNumbers - 1) {
            out += this.debitorenSettings.DebitorenFirstNumber.toString()
          }
        }
      }
      return this.debitorenSettings.DebitorenPrefix + out;
    },

    // DebitorenNumberKostentraegerComplete() {
    //   let current_parts = this.debitorenKostentraegerSettings.DebitorenKostentraegerFirstNumber.toString().length
    //   let out = ""
    //   if (current_parts == this.debitorenKostentraegerSettings.DebitorenKostentraegerNumbers) {
    //     out += this.debitorenKostentraegerSettings.DebitorenKostentraegerFirstNumber.toString()
    //   } else {
    //     for (let i = current_parts; i < this.debitorenKostentraegerSettings.DebitorenKostentraegerNumbers; i++) {
    //       out += "0"
    //       if (i == this.debitorenKostentraegerSettings.DebitorenKostentraegerNumbers - 1) {
    //         out += this.debitorenKostentraegerSettings.DebitorenKostentraegerFirstNumber.toString()
    //       }
    //     }
    //   }
    //   return this.debitorenKostentraegerSettings.DebitorenKostentraegerPrefix + out;
    // },

  },

  methods: {

    Save() {
      let setting = {
        HNPrefix: this.setting.HNPrefix,
        HNFirstNumber: parseInt(this.setting.HNFirstNumber),
        HNNumbers: parseInt(this.setting.HNNumbers),
      }
      this.$store.dispatch("invoicenumbersetting/update", setting);

      let debitorSetting = {
        DebitorenPrefix: this.debitorenSettings.DebitorenPrefix,
        DebitorenNumbers: parseInt(this.debitorenSettings.DebitorenNumbers),
        DebitorenFirstNumber: parseInt(this.debitorenSettings.DebitorenFirstNumber)
      }
      // alert(JSON.stringify(debitorSetting))
      this.$store.dispatch("invoicedebitorsetting/update", debitorSetting);

      // let debitorenKostentraegerSettings = {
      //   DebitorenKostentraegerPrefix: this.debitorenKostentraegerSettings.DebitorenKostentraegerPrefix,
      //   DebitorenKostentraegerNumbers: parseInt(this.debitorenKostentraegerSettings.DebitorenKostentraegerNumbers),
      //   DebitorenKostentraegerFirstNumber: parseInt(this.debitorenKostentraegerSettings.DebitorenKostentraegerFirstNumber)
      // }
      // this.$store.dispatch("invoicekostentraegerdebitorsetting/update", debitorenKostentraegerSettings);

    },
  },
}
</script>