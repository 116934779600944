<template>
  <v-card color="primary" dark style="padding:10px">
    <v-chip class="mt-4 ml-4"
            :style="{backgroundColor: event.color}"
    >
      <v-icon left>
        mdi-account-circle
      </v-icon>
       {{event.Personalnumber}}
    </v-chip>
        <v-card-text>
        </v-card-text>
    <v-row>
      <v-col cols="9" class="ml-4">
          {{ event.name}}
      </v-col>
      <v-col style="float: right">

        <v-btn class="mr-1"
            color="info"
            @click="CloseDialog()"
        >
          Schließen
        </v-btn>
        <v-btn
            color="info"

            @click="DeleteEvent()"
        >
          Löschen
        </v-btn>

      </v-col>
    </v-row>

    </v-card>
</template>
<script>
export default {
    props:{
        event: {},
    },
    methods:{
        CloseDialog(){
            this.$store.commit('eventdialog/changeEventHolidayModalDialogToFalse')
        },
        DeleteEvent(){
            this.$emit("Delete")
            this.$store.commit('eventdialog/changeEventHolidayModalDialogToFalse')
        }
    }
}
</script>