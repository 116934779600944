export const eventdialog = {
    namespaced: true,
    state: {
        eventmodaldialog: false,
        timetablemodaldialog: false,
        eventholidaymodaldialog:false,
        timetablerecordsmodaldialog: false,
        notemodaldialog: false,
        medicationdialog: false,
        invoicerundialog: false,
        employeecreateneweventdialog: false,
        employeetablemodaldialog: false,
        copycalendardialog: false
    },
    getters: {
        getCopyCalendarDialog: state =>{
            return state.copycalendardialog
        },
        getemployeetablemodaldialog: state =>{
            return state.employeetablemodaldialog
        },
        getinvoicerundialog: state =>{
            return state.invoicerundialog
        },
        getmedicationdialog: state =>{
            return state.medicationdialog
        },
        geteventmodaldialog: state =>{
            return state.eventmodaldialog
        },
        gettimetablemodaldialog: state =>{
            return state.timetablemodaldialog
        },
        geteventholidaymodaldialog: state =>{
            return state.eventholidaymodaldialog
        },
        gettimetablerecordsmodaldialog: state =>{
            return state.timetablerecordsmodaldialog
        },
        getnotemodaldialog: state =>{
            return state.notemodaldialog
        },
        getEmployeeCreateNewEventDialog: state =>{
            return state.employeecreateneweventdialog
        }
    },
    mutations: {
        changeCopyCalendarDialogToTrue(state){
            state.copycalendardialog = true
        },
        changeCopyCalenarDialogToFalse(state){
            state.copycalendardialog = false
        },
        changeEmployeeTableModalDialogToFalse(state){
            state.employeetablemodaldialog = false
        },
        changeEmployeeTableModalDialogToTrue(state){
            state.employeetablemodaldialog = true
        },
        changeEmployeeCreateNewEventDialogToFalse(state){
            state.employeecreateneweventdialog = false
        },
        changeEmployeeCreateNewEventDialogToTrue(state){
            state.employeecreateneweventdialog = true
        },
        changeInvoiceRunDialogtoTrue(state){
            state.invoicerundialog = true
        },
        changeInvoiceRunDialogtoFalse(state){
            state.invoicerundialog = false
        },
        changeMediacationDialogtoFalse(state){
            state.medicationdialog = false
        },
        changeMediacationDialogtoTrue(state){
            state.medicationdialog = true
        },
        changeNoteModalDialogtoFalse(state){
            state.notemodaldialog = false
        },
        changeNoteModalDialogtoTrue(state){
            state.notemodaldialog = true
        },
        changeTimeTableRecordsModalDialogtoFalse(state){
            state.timetablerecordsmodaldialog = false
        },
        changeTimeTableRecordsModalDialogtoTrue(state){
            state.timetablerecordsmodaldialog = true
        },
        changeEventHolidayModalDialogToFalse(state){
            state.eventholidaymodaldialog = false
        },
        changeEventHolidayModalDialogToTrue(state){
            state.eventholidaymodaldialog = true
        },
        changeTimeTableModalDialogToFalse(state){
            state.timetablemodaldialog = false
        },
        changeTimeTableModalDialogToTrue(state){
            state.timetablemodaldialog = true
        },
        changeEventModalDialogToTrue(state){
            state.eventmodaldialog = true
        },
        changeEventModalDialogToFalse(state){
            state.eventmodaldialog = false
        }
    }
}