<template>
  <v-container style="min-height: 800px">

    <v-row align-content="center">
      <v-col>
        <v-card
            dark
            color="primary"
            :loading="loading"
            class="mx-auto my-12"
            max-width="500">
          <v-system-bar
              color="primary"
              style="float:right;"
          >

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small
                       color="info"
                       dark
                       v-bind="attrs"
                       v-on="on"
                       class="mt-5"
                >
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>Passwortlänge mindestens 8 Zeichen</span>
            </v-tooltip>


          </v-system-bar>
          <v-card-title>Mein Profil</v-card-title>
          <v-card-text>

            <v-row>
              <v-col cols="3">
                Benutzer:
              </v-col>
              <v-col style="color: white">
                {{ User.Username }}
              </v-col>
            </v-row>

            <v-row style="margin-top: -25px">
              <v-col cols="3">
                E-Mail:
              </v-col>
              <v-col style="color: white">
                {{ User.EMail }}
              </v-col>
            </v-row>

            <v-row style="margin-top: -25px">
              <v-col cols="3">
                Registriert am:
              </v-col>
              <v-col style="color: white">
                {{ User.CreatedAt }}
              </v-col>
            </v-row>

            <v-row>
              <v-alert class="mt-4 ml-2 mr-2" outline color="warning" align="center" v-if="!dismissClicked">
                <p style="color: black">
                  <b>ACHTUNG:</b> Nach dem Passwort ändern werden Sie automatisch abgemeldet und müssen sich neu
                  anmelden.
                </p>
                <v-btn @click="dismissClicked = true" small color="secondary">Verstanden</v-btn>
              </v-alert>
            </v-row>

            <v-row style="margin-top: -10px">
              <v-col>
                <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    label="Passwort"
                    :hint="PasswordHint"
                    counter
                    @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="passwordRepeat"
                    label="Passwort wiederholen"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show2 ? 'text' : 'password'"
                    :hint="PasswordHint"
                    counter
                    @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row style="margin-top: -10px">
              <v-col>
                <v-btn
                    @click="changePassword"
                    :disabled="!savePasswordBtnEnabled"
                    color="info">
                  PASSWORT ÄNDERN
                </v-btn>
                <v-btn
                    @click="ClearFields"
                    class="ml-4"
                    :disabled="!PasswordfieldsNotEmpty"
                    color="info">
                  ABBRECHEN
                </v-btn>
              </v-col>
            </v-row>


          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import {validateEmail} from "../../helpers/validator";

export default {
  data() {
    return {
      dismissClicked: false,
      modal: false,
      password: "",
      passwordRepeat: "",
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || this.RequiredField,
        min: (v) => v.length >= 8 || this.PasswordHint,
        email: (v) => (v && validateEmail(v)) || this.notvalidMail,
      },
      User: this.user,
    };
  },
  props: {
    user: {},
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  computed: {
    saveProfileBtnEnabled() {
      return (
          this.User.Username != "" &&
          this.User.Username != undefined &&
          this.User.FirstName != "" &&
          this.User.FirstName != undefined &&
          this.User.LastName != "" &&
          this.User.LastName != undefined &&
          this.User.EMail != "" &&
          this.User.EMail != undefined &&
          validateEmail(this.User.EMail)
      );
    },
    PasswordfieldsNotEmpty(){
      return this.password.length >= 1 || this.passwordRepeat.length >= 1;
    },
    savePasswordBtnEnabled() {
      return (
          this.password != "" &&
          this.passwordRepeat != "" &&
          this.password == this.passwordRepeat &&
          this.password.length >= 8

      );
    },
    formattedDateOfBirth: {
      get: function () {
        return moment(this.User.DateOfBirth).format("YYYY-MM-DD");
      },
      set: function (value) {
        this.User.DateOfBirth = value;
      },
    },
  },
  methods: {
    ClearFields(){
      this.password = "";
      this.passwordRepeat = "";
      this.show1 = false;
      this.show2 = false;
    },
    Save() {
      //Using DTO cause i dont want it to be possible to override: Password, Inactive, SubscriptionID
      let dto = {
        ID: parseInt(this.User.ID),
        Username: this.User.Username,
        FirstName: this.User.FirstName,
        LastName: this.User.LastName,
        DateOfBirth: moment(this.User.DateOfBirth).format(),
        EMail: this.User.EMail,
      };

      this.$store.dispatch("users/updateUser", dto);
    },
    changePassword() {

      if (confirm("Möchten Sie Ihr Passwort wirklich ändern?")) {
        let dto = {
          ID: parseInt(this.User.ID),
          Password: this.password,
        };

        console.log(dto);

        this.$store.dispatch("users/changePasswordByProfile", dto).then(() => {
          this.$router.push("/login");
        });
      } else {
        // do nothing
      }


    },


  },
};
</script>