var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"dataTables_wrapper mt-4",staticStyle:{"border":"1px solid lightgrey"}},[_c('v-data-table',{ref:"table",staticClass:"elevation-1 hdl",attrs:{"headers":_vm.headers,"items":_vm.patients,"item-key":"ID","loading":_vm.isLoading,"loading-text":"Wird geladen","search":_vm.search,"single-select":"","no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","locale":"de-de","footer-props":{
                  itemsPerPageAllText: 'Alle',
                  itemsPerPageText: 'Einträge pro Seite',                
              }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Suchen","width":"50%","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-4",attrs:{"small":""},on:{"click":_vm.clearSearchFilter}},[_vm._v("Leeren")])],1)]},proxy:true},{key:"item.Active",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.Active),callback:function ($$v) {_vm.$set(item, "Active", $$v)},expression:"item.Active"}})]}},{key:"item.ServiceEmergencyCall",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.ServiceEmergencyCall),callback:function ($$v) {_vm.$set(item, "ServiceEmergencyCall", $$v)},expression:"item.ServiceEmergencyCall"}})]}},{key:"item.ServiceHousehold",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.ServiceHousehold),callback:function ($$v) {_vm.$set(item, "ServiceHousehold", $$v)},expression:"item.ServiceHousehold"}})]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getData}},[_vm._v(" Neu laden")])]},proxy:true}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',[_c('i',{staticClass:"fas fa-users"}),_vm._v(" Alle Klienten")])}]

export { render, staticRenderFns }