import {
    inventoryService
} from '../services';

export const inventory = {
    namespaced: true,
    state: {
        devicestable: {},
        deviceform: {},
        inventorydevicedialog: false,
        devicespatient: {}
    },
    getters:{
        getInventorydevicedialog: state => {
            return state.inventorydevicedialog
        },
        getAllInventoryDevicesTableNotActive: state => {
            return state.devicestable.items.filter(device => !device.Active)
        },
        getInventoryDeviceForm: state =>{
            return state.deviceform
        },
        getDevicesPatient: state =>{
            return state.devicespatient
        }
    },
    actions: {
        getAllInventoryDevicesTable({commit}){
            commit('newRequest')
            return new Promise((resolve, reject) =>{
                inventoryService.getAllInventoryDevicesTable().then(
                    payload => {
                        console.log(payload)
                        commit('getAllInventoryDevicesTableSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getInventoryDevicesForFormByID({commit}, id){
            return new Promise((resolve, reject)=>{
                inventoryService.getInventoryDevicesForFormByID(id).then(
                    payload => {
                        commit('getInventoryDevicesForFormByIDSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        createInventoryDevicesForForm({commit}, inventorydevicesforform){
            return new Promise((resolve, reject) =>{
                inventoryService.createInventoryDevicesForForm(inventorydevicesforform).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure')
                        reject(error)
                    }
                )
            })
        },
        changeInventoryDeviceForFormByID({commit}, inventorydevicesforform){
            return new Promise((resolve, reject) =>{
                inventoryService.changeInventoryDeviceForFormByID(inventorydevicesforform, inventorydevicesforform.ID).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure')
                        reject(error)
                    }
                )
            })
        },
        deleteInventoryDeviceByID({commit}, deviceid){
            return new Promise((resolve, reject) =>{
                inventoryService.deleteInventoryDeviceByID(deviceid).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getAllInventorDevicesByPatientID({commit}, patientid){
            return new Promise((resolve, reject) =>{
                console.log(patientid)
                inventoryService.getAllInventorDevicesByPatientID(patientid).then(
                    payload => {
                        console.log(payload)
                        commit('getAllInventorDevicesByPatientIDSuccess', payload)
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        setInventoryDeviceOnPatient({commit}, devicedata){
            return new Promise((resolve, reject) =>{
                inventoryService.setInventoryDeviceOnPatient(devicedata.deviceid, devicedata.patienid).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        deselectDeviceFromPatient({commit}, deviceid){
            return new Promise((resolve, reject) =>{
                inventoryService.deselectDeviceFromPatient(deviceid).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        }
    },
    mutations: {
        getAllInventorDevicesByPatientIDSuccess(state, payload){
            state.devicespatient = {items: payload}
        },
        getInventoryDevicesForFormByIDSuccess(state, payload){
            state.deviceform = payload
        },
        getAllInventoryDevicesTableSuccess(state, payload){
            state.devicestable = {items: payload}
        },
        getFailure(state, error) {
            state.all = {
                error
            };
        },
        changeInventoryDeviceDialogToTrue(state){
            state.inventorydevicedialog = true
        },
        changeInventoryDeviceDialogToFalse(state){
            state.inventorydevicedialog = false
        },
    }
}