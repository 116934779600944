import {authHeader} from '../helpers';
import {userService} from '../services';
import {store} from '../store'

export const fileService = {
    uploadLogo,
    downloadExportPerformance,
    downloadExportTimeRecording
}

async function uploadLogo(file){
    const formData = new FormData();
    //file.forEach(file => formData.append('file', file))
    console.log("File: " + file)
    formData.append('file', file)
    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/file/upload/logo/`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function downloadExportPerformance(info){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/file/download/exportperformance/` + info.timestampBeginPeriod + '/' + info.timestampEndPeriod + '/' + info.patientID, requestOptions)
    let data = await downloadFileStream(response)
    return data
}

async function downloadExportTimeRecording(info){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/file/download/exporttimerecording/` + info.timestampBeginPeriod + '/' + info.timestampEndPeriod + '/' + info.employeeID, requestOptions)
    let data = await downloadFileStream(response)
    return data
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function downloadFileStream(response){
    if(!response.ok){
        let text = await response.text()
        let data = text && JSON.parse(text)
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    //console.log("downloadFileStream")
    const contenttype = response.headers.get('Content-Type')
    let splitcontenttypearray = contenttype.split(';')
    //console.log("contenttype:" + contenttype)
    let filename = getValueFromSplitContenttypeArray(splitcontenttypearray[0])
    let filesize = getValueFromSplitContenttypeArray(splitcontenttypearray[1])
    store.commit('loading/setTotalBytes', Number(filesize))
    //console.log("filename: " + filename)
    const reader = response.body.getReader()
    //let receivedLength = 0
    let chunks = []
    let finished = false
    store.commit('loading/setLoadedBytestoZero')
    while(!finished){
        const {done, value} = await reader.read()
        //console.log("done: " + done)
        if(done){
            break
        }
        chunks.push(value)
        store.commit('loading/setLoadedBytes', value.length)
        //receivedLength += value.length
        //console.log(`Received ${receivedLength}`)
        //console.log(`Content-Disposition ${contentdisposition}`)
        finished = done
    }
    let blob = new Blob(chunks)
    let fileInformation = {
        fileblob: blob,
        filename: filename,
        filesize: filesize
    }
    return fileInformation
}

function getValueFromSplitContenttypeArray(splitcontenttype){
    let array = splitcontenttype.split('=')
    let value = array[1]
    return value
}