<template>
  <div class="container">
    <ProfileInformation
      :user="user.item"
      v-if="user.item != undefined"
    ></ProfileInformation>
  </div>
</template>

<script>
import ProfileInformation from "../../components/profile/ProfileInformation";

export default {
  created() {
    this.GetUser();
  },
  components: {
    ProfileInformation,
  },
  computed: {
    userid() {
      let usr = this.$store.state.authentication.userID;
      console.log(usr);
      return usr;
    },
    user() {
      let usr = this.$store.state.users.selected_user;
      return usr;
    },
  },
  methods: {
    async GetUser() {
      await this.$store.dispatch("users/getUser", this.userid);
    },
  },
};
</script>
