<template>
  <main>
    <v-container fluid>
      <v-row class="mt-0">
        <v-col cols="12">
          <v-card height="50px" >
            <v-row>
              <v-col cols="4">
                <v-card-title style="margin-top: -15px">
                  <v-icon >mdi-view-dashboard</v-icon>
                  DASHBOARD
                  <span class="ml-3" style="font-size: 0.65em">{{
                      moment(new Date()).format('DD-MM-YYYY / HH:mm')
                    }}</span>
                </v-card-title>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>

        <v-col cols="3" class="mt-0">
          <v-card color="#951b81" dark class="equalHeight">
            <DashboardInvoices></DashboardInvoices>
          </v-card>
        </v-col>

        <v-col cols="3" class="mt-0">
          <v-card color="#951b81" dark class="equalHeight">
            <DashboardClients></DashboardClients>
          </v-card>
        </v-col>

        <v-col cols="6" class="mt-0">
          <v-card color="#951b81" dark class="equalHeight">
            <DashboardSales></DashboardSales>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
      <v-col cols="6" class="mt-0">
        <v-card color="#951b81" dark class="equalHeight">
          <DashboardStaff></DashboardStaff>
        </v-card>
      </v-col>

        <v-col cols="6" class="mt-0">
          <v-card color="#951b81" dark class="equalHeight">
            <PersonalShortList></PersonalShortList>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mt-0">
          <v-card class="equalHeight">
            <BirthdayAndanniversary></BirthdayAndanniversary>
          </v-card>
        </v-col>
      </v-row>



    </v-container>
  </main>
</template>

<script>

import DashboardSales from '../../components/dashboard/DashboardSales'
import DashboardClients from '../../components/dashboard/DashboardClients'
import DashboardInvoices from '../../components/dashboard/DashboardInvoices'
import DashboardStaff from '../../components/dashboard/DashboardStaff'
import BirthdayAndanniversary from '../../components/dashboard/BirthdayAndanniversary'

import moment from 'moment'
import PersonalShortList from "../../components/dashboard/PersonalShortList";

export default {
  name: "DashboardOverview",
  components: {
    PersonalShortList,
    DashboardClients,
    DashboardInvoices,
    DashboardSales,
    DashboardStaff,
    BirthdayAndanniversary,
  },
  data() {
    return {
      moment,
      months: ["Januar", "Februar", "März", "April",
        "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      years: ['2021', '2022', '2023', '2024', '2025', '2026'],
      selectedYear: '',
      selectedMonth: '',

    }
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  methods: {
    setSelectedMonth(month) {
      this.selectedMonth = month;
    },
    setSelectedYear(year) {
      this.selectedYear = year;
    }
  }
}
</script>

<style scoped>

i {
  margin-right: 10px;
}

.sales {
  color: black;
}

.invoice {
  color: lime;
}

.personal {
  color: #ffbebe;
}

.klienten {
  color: #c7e7ff;

}

.equalHeight {
  height: auto;
}

</style>