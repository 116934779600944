<template>
    <v-card color="#efefef" dense>
      <v-card-title>
        <v-row>
          <v-col cols="5">
            <v-text-field
              label="Templatename:"
              placeholder=""
              hide-details="auto"
              v-model="form.TemplateName"
            >
            </v-text-field>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="choices"
              placeholder="Auswahl"
              v-model="choice"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle style="background-color: white; margin-top: 0px; padding-top: 10px;">
        <v-textarea
            label="Text"
            v-model="form.Text"
        ></v-textarea>
        <v-btn color="primary" elevation="2" class="mt-3 mr-2" @click="SaveInvoiceTemplate()">
          <i class="fas fa-save"></i>
        </v-btn>
        <v-btn color="primary" elevation="2" class="mt-3" @click="DeleteInvoiceTemplate()">
          <i class="fas fa-trash"></i>
        </v-btn>
      </v-card-subtitle>
    </v-card>
</template>
<script>
export default {
    props: {
        form: {},
        changeTemplate: Boolean
    },
    created(){
      this.SetChoice()
    },
    data() {
      return {
          choices: ["Nebenbetreff", "Eingangstext", "Zahlungsbedingungen", "Ausgangstext"],
          choice: undefined
      };
    },
    methods: {
      async SaveInvoiceTemplate(){
        if(this.changeTemplate){
          await this.ChangeTemplate()
        }
        if(!this.changeTemplate){
          await this.CreateTemplate()
        }
        await this.GetNewTemplateList()
      },
      async CreateTemplate() {
        try{
          console.log("Create")
          this.SetAllTemplateTypesFalse()
          this.SetTemplateType(this.choice)
          console.log(JSON.stringify(this.form))
          await this.$store.dispatch('templates/createInvoiceTemplate', this.form)
        }catch{
          console.log("Fehler bei Create")
        }
        
      },
      async ChangeTemplate(){
        try{
          console.log("Change")
          this.SetAllTemplateTypesFalse()
          this.SetTemplateType(this.choice)
          console.log(JSON.stringify(this.form))
          await this.$store.dispatch('templates/changeInvoiceTemplate', this.form)
        }catch{
          console.log("Fehler bei Change")
        }
        
      },
      SetTemplateType(type){
        switch(type){
          case "Nebenbetreff":
            this.form.SubjectBeside = true
            break
          case "Eingangstext":
            this.form.BegintextText = true
            break
          case "Zahlungsbedingungen":
            this.form.EndtextTermsOfPayment = true
            break
          case "Ausgangstext":
            this.form.EndtextText = true
            break
        
        }
      },
      SetChoice(){
        if(this.form.SubjectBeside == true){
          this.choice = "Nebenbetreff"
        }
        if(this.form.BegintextText == true){
          this.choice = "Eingangstext"
        }
        if(this.form.EndtextTermsOfPayment == true){
          this.choice = "Zahlungsbedingungen"
        }
        if(this.form.EndtextText == true){
          this.choice = "Ausgangstext"
        }
      
      },
      SetAllTemplateTypesFalse(){
        this.form.SubjectBeside = false
        this.form.BegintextText = false
        this.form.EndtextTermsOfPayment = false
        this.form.EndtextText = false
      },
      async DeleteInvoiceTemplate(){
        console.log("test")
        console.log(JSON.stringify(this.form))
        await this.$store.dispatch('templates/deleteInvoiceTemplateByID', this.form.ID)
        await this.GetNewTemplateList()
      },
      async GetNewTemplateList(){
        await this.$store.dispatch('templates/getAllInvoiceTemplates')
        this.$emit("ShowNewTemplateList")
      }
    },
}
</script>

<style scoped>

</style>