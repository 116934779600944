<template>
  <main>
    <v-container fluid>
      <h2>
        <v-icon large style="color: #006ab5 !important;">mdi-devices</v-icon>
        Geräte im Überblick
      </h2>
      <v-row>
        <v-col cols="4">
          <base-card>
            <h3>Alle Geräte</h3>
            <v-row class="mt-2">
              <InventoryDeviceTable
                  :devices="devices"
                  @sendDeviceID="FillInForm"
                  :key="devicetablekey"
              />
            </v-row>
          </base-card>
          <v-btn
              color="primary"
              elevation="2"
              class="ml-2"
              @click="setBack()"
          >
            <v-icon>mdi-plus</v-icon>
            Hinzufügen
          </v-btn>
        </v-col>
        <v-col cols="8">
          <base-card>
            <h3>
              <v-icon style="color: #006ab5 !important;">mdi-devices</v-icon>
              Geräte hinzufügen oder abändern
            </h3>
            <v-row>
              <InventoryDeviceForm
                  ref="inventorydeviceform"
                  :data="deviceform"
              />
            </v-row>
          </base-card>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            elevation="2"
            class="ml-2"
            @click="setBack()"
        >
          <i class="fas fa-ban"></i>
          abbrechen
        </v-btn>
        <v-btn
            elevation="2"
            class="ml-2 deletecolor"
            @click="DeleteDevice()"
        >
          <i class="fas fa-trash-alt"></i>
          löschen
        </v-btn>
        <v-btn
            color="primary"
            elevation="2"
            class="ml-2 mr-2"
            @click="StoreOrChangeDevice()"
        >
          <i class="fas fa-save"></i>
          speichern
        </v-btn>
      </v-row>
    </v-container>
  </main>
</template>
<script>
import InventoryDeviceTable from '../../../components/inventory/Devices/InventoryDeviceTable.vue'
import InventoryDeviceForm from '../../../components/inventory/Devices/InventoryDeviceForm.vue'
import BaseCard from "../../../components/Base/BaseCard";

export default {
  components: {
    BaseCard,
    InventoryDeviceTable,
    InventoryDeviceForm
  },
  created() {
    this.$store.dispatch('inventory/getAllInventoryDevicesTable');
  },
  data() {
    const defaultDeviceForm = Object.freeze({
      device: {
        ID: undefined,
        Active: undefined,
        Inventorynumber: undefined,
        Description: undefined,
        PatientID: undefined,
        Callcode: undefined,
        Locationcode: undefined,
        Nextmaintainance: undefined,
      }
    })
    return {
      deviceform: Object.assign({}, defaultDeviceForm),
      defaultDeviceForm,
      storedevice: true,
      changedevice: false,
      devicetablekey: 0,
    }
  },
  computed: {
    devices() {
      return this.$store.state.inventory.devicestable.items
    }
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  methods: {
    routeTo(url) {
      if (url.startsWith("http")) {
        window.open(url, "_blank")
      } else {
        this.$router.push(url);
      }
    },
    setBack() {
      this.deviceform = Object.assign({}, this.defaultDeviceForm)
      this.$refs.inventorydeviceform.$refs.deviceform.reset()
      this.storedevice = true,
          this.changedevice = false

    },
    async FillInForm(id) {
      console.log(id)
      await this.$store.dispatch('inventory/getInventoryDevicesForFormByID', id)
      this.deviceform = this.$store.state.inventory.deviceform
      this.changedevice = true
      this.storedevice = false
    },
    async StoreOrChangeDevice() {
      if (this.storedevice) {
        await this.StoreDevice()
      }
      if (this.changedevice) {
        console.log("change")
        await this.ChangeDevice()
      }
      this.RenderDevicesTable()
      this.setBack()
    },
    async StoreDevice() {
      let inventorydeviceform = {
        Description: this.deviceform.Description == undefined ? "" : this.deviceform.Description,
        Inventorynumber: this.deviceform.Inventorynumber == undefined ? -1 : Number(this.deviceform.Inventorynumber),
        Locationcode: this.deviceform.Locationcode == undefined ? "" : this.deviceform.Locationcode,
        Callcode: this.deviceform.Callcode == undefined ? "" : this.deviceform.Callcode,
        Nextmaintainance: this.Nextmaintainance == undefined ? new Date() : new Date(this.deviceform.Nextmaintainance)
      }
      console.log(JSON.stringify(inventorydeviceform))
      await this.$store.dispatch('inventory/createInventoryDevicesForForm', inventorydeviceform)
    },
    async ChangeDevice() {
      let inventorydeviceform = this.deviceform
      inventorydeviceform.Inventorynumber = Number(inventorydeviceform.Inventorynumber)
      inventorydeviceform.Nextmaintainance = new Date(inventorydeviceform.Nextmaintainance)
      await this.$store.dispatch('inventory/changeInventoryDeviceForFormByID', inventorydeviceform)
    },
    async RenderDevicesTable() {
      await this.$store.dispatch('inventory/getAllInventoryDevicesTable');
      this.devicetablekey++
    },
    async DeleteDevice() {
      if (confirm("Möchten Sie das ausgewählte Gerät wirklich löschen?")) {
        if (this.changedevice) {
          let response = await this.$store.dispatch('inventory/deleteInventoryDeviceByID', this.deviceform.ID);
          console.log(response);
          this.RenderDevicesTable();
          this.setBack();
        }
      } else {
        //doNothing
      }
    }
  }
}
</script>