<template>
  <v-app>
    <v-app-bar app color="white" absolute="true" style="position:fixed" class="app_bar">
      <v-col cols="2">
        <router-link to="/home"
        ><img
            src="https://www.brandmauer.de/hs-fs/hubfs/CD/Logos/BRANDMAUER%20IT%20Software/curaCAT/curaCAT_by_BRANDMAUER_IT_Logo_620x164_RGB_201022.png"
            alt="CuraCat Logo"
            height="38px"
            class="mt-2"/></router-link>
      </v-col>
      <v-row>
        <v-col v-if="loggedIn && menuActive"
               cols="12"
               sm="6"
               class="py-2">
          <v-btn-toggle
              v-model="text"
              tile
              color="primary"
              light
              group>
            <v-btn v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')"
                   @click="routeTo('/patient/')"            >
              <v-icon>mdi-account-multiple</v-icon>
              Klienten
            </v-btn>

            <v-btn v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')"
                   @click="routeTo('/insurances')">
              <v-icon>mdi-contacts</v-icon>
              Kostenträger
            </v-btn>

            <v-btn v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')"
                   @click="routeTo('/invoices')">
              <v-icon>mdi-cash-register</v-icon>
              Abrechnung
            </v-btn>

            <v-btn v-if="loggedIn && $cookies.get('role') === 'Admin'"
                   @click="routeTo('/employeeoverview')">
              <v-icon>mdi-card-account-details-outline</v-icon>
              Personal
            </v-btn>

            <v-btn v-if="$cookies.get('role') !=='Admin' && $cookies.get('role') !=='Regular'" to="employeetimetable"
                   outlined
                   class="btn_style v-btn">
              <v-icon class="iconStyle">mdi-calendar-check</v-icon>
              Zeiterfassung
            </v-btn>

            <v-btn v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')"
                   @click="routeTo('/inventory')">
              <v-icon>mdi-layers-triple</v-icon>
              Inventar
            </v-btn>

            <v-btn
                @click="routeTo('/employeeroster')">
              <v-icon>mdi-calendar</v-icon>
              Dienstplan
            </v-btn>
            <v-menu
                class="settingsBtn"
                color="primary"
                dark
                v-bind="attrs"
                v-on:click.prevent
                v-on="on"
                :nudge-width="200"
                offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                  Einstellungen
                </v-btn>
              </template>

              <v-card>
                <v-list dense>
                  <v-list-item v-if="loggedIn">
                    <v-list-item-content>
                      <v-list-item-title>{{ username }}</v-list-item-title>
                      <v-list-item-subtitle>{{ role }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon @click="RouteLogout()">
                        <v-icon>mdi-lock</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-divider v-if="loggedIn"></v-divider>
                <v-list dense>
                  <v-list-item dense v-if="loggedIn" @click="RouteUserProfile()">
                    Mein Profil
                  </v-list-item>
                  <v-list-item dense @click="RouteSettings()"
                               v-if="loggedIn &&($cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular')">
                    CuraCAT Einstellungen
                  </v-list-item>
                  <v-list-item dense @click="RouteAbout()">
                    Über CuraCAT
                  </v-list-item>
                  <v-list-item dense @click="RouteInformation()">
                    Systeminformationen
                  </v-list-item>
                  <v-list-item dense @click="RouteHelp()">
                    Hilfe
                  </v-list-item>
                  <v-list-item dense v-if="loggedIn">
                    <v-divider></v-divider>
                  </v-list-item>
                  <v-list-item dense @click="RouteLogout()" v-if="loggedIn">
                    Abmelden
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-btn-toggle>
        </v-col>
      </v-row>

    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="520">
        <v-card>
          <v-card-title class="headline">
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text> {{ dialogMsg }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="clear">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
export default {

  beforeMount() {
    this.$root.$refs.start = this;
  },
  name: "App",
  methods: {
    // encode_utf8(s) {
    //   return unescape(encodeURIComponent(s));
    // },
    decode_utf8(s) {
      return decodeURIComponent(escape(s));
    },
    setMenuActive(menuStatus) {
      this.menuActive = menuStatus;
    },
    routeTo(url) {
      if (url.startsWith("http")) {
        window.open(url, "_blank");
      } else {
        this.$router.push(url);
      }
    },
    clear() {
      this.$store.dispatch("alert/clear");
    },
    RouteLogout() {
      this.$cookies.remove("role", null)
      this.$cookies.remove("currentUserID", null)
      this.$cookies.set("default_unit_second", "input_value", 0);
      this.$router.push("/login");
    },
    RouteSettings() {
      this.$router.push("/settings");
    },
    RouteAbout() {
      window.open("https://www.brandmauer.de/curacat", "_blank");
    },
    RouteInformation() {
      this.$router.push("/info");
    },
    RouteHelp() {
      window.open("https://support.brandmauer.de", "_blank");
    },
    RouteUserProfile() {
      this.$router.push("/me");
    },
    onClickOutside() {
      this.active = false
    },
    GetUserRole() {
      this.$store.dispatch("roles/GetUserRole", this.username).then(value => {
        this.role = value
        this.$cookies.set("role", value)
        this.$root.$refs.home.SetRole(value);
      });
      this.$cookies.set("currentUserID", this.$store.state.authentication.employeeID)
    },
  },
  updated() {
    if (this.loggedIn) {
      this.GetUserRole();
    }
  },
  data() {
    return {
      menuActive: false,
      role: undefined,
    }
  },
  mounted() {
    this.$root.$refs.start = this;
  },
  computed: {
    dialog() {
      let v =
          this.$store.state.alert.message != "" &&
          this.$store.state.alert.message != undefined &&
          this.$store.state.alert.message != null;
      return v;
    },
    dialogMsg() {
      return this.$store.state.alert.message;
    },
    dialogTitle() {
      return this.$store.state.alert.title;
    },
    loggedIn() {
      let state = this.$store.state.authentication.status.loggedIn;
      return state;
    },
    user() {
      let usr = this.$store.state.authentication.user;
      return usr;
    },
    username() {
      let usr = this.$store.state.authentication.publicUsername;
      return this.decode_utf8(usr);
    },
  },

};
</script>

<style scoped>

.settingsBtn:focus::before {
  opacity: 0 !important;
}

.hoverable {
  padding: 10px;
}

.hoverable > a > i {
  color: #006ab5 !important;
}

.hoverable > a > span {
  color: #006ab5 !important;
}

.hoverable:hover {
  background-color: #951b81;
  color: white !important;
}

.hoverable:hover > a > i {
  color: white !important;
}

.hoverable:hover > a > span {
  color: white !important;
}
</style>

<style>
div.hdl > div.v-data-table__wrapper > table > thead {
  background-color: #951b81 !important;
}

div.hdl > div.v-data-table__wrapper > table > thead span {
  color: white !important;
}

.v-application .primary--text {
  caret-color: #951b81 !important;
}

div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div
> a {
  padding: 20px;
}

button.deletecolor {
  background-color: #dde3ec !important;
}

i {
  margin-right: 4px;

}

tr:hover td {
  /*background-color: #951b81;*/
  background: #dadfec78 !important;
  color: #951b81 !important;
}

tr.v-data-table__selected {
  background: #dadfec78 !important;
  color: #951b81 !important;
}
</style>
