var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_c('i',{staticClass:"fas fa-calendar-alt"}),_vm._v("Für dieses Fahrzeug anstehende Termine")]),_c('div',{staticClass:"dataTables_wrapper"},[_c('v-data-table',{staticClass:"elevation-1 hdl",attrs:{"headers":_vm.headers,"items":_vm.appointments,"item-key":"id","loading":_vm.isLoading,"no-data-text":"Keine Termine","loading-text":"Wird geladen","locale":"de-de"},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){_vm.dialog = true}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Neuer Termin ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"960px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Neuer Termin")])]),_c('v-card-text',[[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" abbrechen ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Uhrzeit","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu2.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Termin","hint":""},model:{value:(_vm.termin),callback:function ($$v) {_vm.termin=$$v},expression:"termin"}})],1)],1)],1)]],2),_c('v-card-actions',[(!_vm.isChangeAppointment)?_c('v-btn',{attrs:{"color":"primary","text":"","disabled":(_vm.date == null || _vm.time == null || _vm.termin == "")},on:{"click":function($event){return _vm.NewAppointment()}}},[_vm._v(" Termin hinzufügen ")]):_c('v-btn',{attrs:{"color":"primary","text":"","disabled":(_vm.date == null || _vm.time == null || _vm.termin == "")},on:{"click":function($event){return _vm.ChangeAppointment()}}},[_vm._v(" Termin speichern ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Schließen ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }