import {
    patientServiceArticleService
} from '../services';

export const patientServiceArticles = {
    namespaced: true,
    state: {
        selected: {},
        new: {},
        update: {},
        delete: {},
        articlesforroster: {},
        patientServiceArticlesForBooking: {},
        patientServiceArticlesForCreate: []
    },
    getters:{
        getArticlesForRoster: state => {
            return state.articlesforroster
        },
        getPatientServiceArticlesForBooking: state =>{
            return state.patientServiceArticlesForBooking
        },
        getPatientServiceArticlesForCreate: state =>{
            return state.patientServiceArticlesForCreate
        },
        getServiceArticleIDs: state =>{
            let ids = []
            state.patientServiceArticlesForCreate.forEach(function(item){
                ids.push(item.ID)
            })
            return ids
        }
    },
    actions: {
        newServiceArticle({commit}, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                patientServiceArticleService.newServiceArticle(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        update({commit}, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientServiceArticleService.updateServiceArticle(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        deleteById({commit}, data) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientServiceArticleService.deleteById(data)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        getArticleByPatientID({commit}, patientID){
            return new Promise((resolve, reject) => {
                patientServiceArticleService.getArticleByPatientID(patientID).then(
                    payload =>{
                        console.log(payload)
                        commit('getArticleByPatientIDSuccuess', payload)
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getPatientServiceArticleForBookingByCalendarID({commit}, calendarID){
            return new Promise((resolve, reject) => {
                patientServiceArticleService.getPatientServiceArticleForBookingByCalendarID(calendarID).then(
                    payload =>{
                        console.log(payload)
                        commit('getPatientServiceArticleForBookingByCalendarIDSuccess', payload)
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getPatientServiceArticleForCreateCalendarByID({commit}, patientServiceArticleID){
            return new Promise((resolve, reject) => {
                patientServiceArticleService.getPatientServiceArticleForCreateCalendarByID(patientServiceArticleID).then(
                    payload =>{
                        console.log(payload)
                        commit('getPatientServiceArticleForCreateCalendarByIDSuccess', payload)
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
    },
    mutations: {
        deletePatientServiceArticlesForCreateCalendarByID(state, id){
            let pos = state.patientServiceArticlesForCreate.indexOf(item => item.ID == id)
            state.patientServiceArticlesForCreate.splice(pos, 1)
        },
        deletePatientServiceArticlesForCreateCalendar(state){
            state.patientServiceArticlesForCreate = []
        },
        getPatientServiceArticleForCreateCalendarByIDSuccess(state, payload){
            state.patientServiceArticlesForCreate.push(payload)
        },
        getPatientServiceArticleForBookingByCalendarIDSuccess(state, payload){
            state.patientServiceArticlesForBooking = {items: payload}
        },
        getArticleByPatientIDSuccuess(state, payload){
            state.articlesforroster = {items: payload}
        },
        deleteRequest(state) {
            state.delete = {
                loading: true
            }; 
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; 
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; 
        },

        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },

        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },

        getRequest(state) {
            state.selected = {
                loading: true
            };
        },
        getSuccess(state, payload) {
            state.selected = {
                item: payload,
                loading: false
            };
        },
        getFailure(state, error) {
            state.selected = {
                error,
                loading: false
            };
        },
    }
}