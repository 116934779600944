import {authHeader} from '../helpers';


export const invoiceService = {
    getAll,
    downloadFile,
    deleteById,
    accept,
    getById,
    updateInvoice,
    recreate,
    createTemplate,
    createInvoiceRun,
    getInvoiceRunsForTable,
    getAllInvoiceInformationsForListByInvoiceRunID,
    getInvoiceInformationToEditDTOByInvoiceGoID,
    getInvoicePositionToEditDTOByInvoiceGoID,
    approveAllInvoicesInInvoiceRunByInvoiceGoRunID,
    deleteInvoiceRun,
    approveOneInvoiceByInvoiceGoID,
    disapproveOneInvoiceByInvoiceGoID,
    StornoInvoiceByInvoiceGoID,
    CreateNewInvoiceFromStorno,
    changeInvoiceInformationsByInvoiceID

};

function getAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/runs`, requestOptions).then(handleResponse);
}

function createTemplate(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/template`, requestOptions).then(handleResponse);
}

async function downloadFile(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/download/` + id, requestOptions)
    let data = await handleResponseFile(response)
    return data
}

function deleteById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    console.log(id);
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/delete/id/` + id, requestOptions).then(handleResponse);
}

function getById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/id/` + id, requestOptions).then(handleResponse);
}

function accept(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    console.log(id);
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/ack/` + id, requestOptions).then(handleResponse);
}

function recreate(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    console.log(id);
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/rc/` + id, requestOptions).then(handleResponse);
}


function updateInvoice(invoice) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(invoice)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/change/` + invoice.ID, requestOptions).then(handleResponse);
}

async function createInvoiceRun(createInvoiceRunDTO) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(createInvoiceRunDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/run/create`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getInvoiceRunsForTable() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/run/get/all`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllInvoiceInformationsForListByInvoiceRunID(invoiceGoRunID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/list/get/` + invoiceGoRunID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getInvoiceInformationToEditDTOByInvoiceGoID(invoiceGoID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/edit/get/` + invoiceGoID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getInvoicePositionToEditDTOByInvoiceGoID(invoiceGoID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/positions/edit/get/` + invoiceGoID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function approveAllInvoicesInInvoiceRunByInvoiceGoRunID(approveInvoicesDTO) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(approveInvoicesDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/approve/all`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function deleteInvoiceRun(invoiceGoRunID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/run/delete/` + invoiceGoRunID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function approveOneInvoiceByInvoiceGoID(ApproveOneInvoiceDTO) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ApproveOneInvoiceDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/approve/one`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function StornoInvoiceByInvoiceGoID(ApproveOneInvoiceDTO) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ApproveOneInvoiceDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/status`, requestOptions)
    let data = await handleResponse(response)
    return data
}
async function CreateNewInvoiceFromStorno(ApproveOneInvoiceDTO) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ApproveOneInvoiceDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/newinvoicefromstorno`, requestOptions)
    let data = await handleResponse(response)
    return data
}


async function disapproveOneInvoiceByInvoiceGoID(ApproveOneInvoiceDTO) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ApproveOneInvoiceDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/disapprove/one`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function changeInvoiceInformationsByInvoiceID(invoiceInformationToEditDTO){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(invoiceInformationToEditDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/invoice/edit/change`, requestOptions)
    let data = await handleResponse(response)
    return data
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseFile(response) {
    return response.blob().then(blob => {
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                location.reload(true);
            }
            const error = (blob && blob.message) || response.statusText;
            return Promise.reject(error);
        }
        const objectURL = window.URL.createObjectURL(blob);
        return objectURL
    })
}




