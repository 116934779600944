<template>
  <v-form ref="form" :disabled="formdisabled">
    <v-container fluid>
      <v-col class="densecol mb-0 pb-0">
        <h2>Bankdaten</h2>
        <div>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="form.patientbankaccountdata.IBAN"
                  label="IBAN für Einzug:"
                  placeholder="DE07 1234 1234 1234 1234 12"
                  hide-details="auto"
                  :error-messages="IBANErrors"
                  @input="$v.form.patientbankaccountdata.IBAN.$touch()"
                  @blur="$v.form.patientbankaccountdata.IBAN.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  v-model="form.patientbankaccountdata.BIC"
                  label="BIC:"
                  placeholder="ABCDEFG"
                  hide-details="auto"
                  :error-messages="BICErrors"
                  @input="$v.form.patientbankaccountdata.BIC.$touch()"
                  @blur="$v.form.patientbankaccountdata.BIC.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <v-radio-group v-model="form.patientbankaccountdata.PaymentType"
                             row
              >
                <a class="mr-2" style="color: #006ab4;font-weight: bold;"
                >Zahlungsform:</a>
                <v-radio
                    class="ml-5"
                    :label="`Selbstzahler`"
                    :value="1"
                >
                </v-radio>
                <v-radio
                    :label="`Kasse`"
                    :value="2"
                >
                </v-radio>
                <v-radio
                    :label="`Lastschriftmandat`"
                    :value="3"
                ></v-radio>
              </v-radio-group>
<!--              <v-checkbox-->
<!--                  v-model="form.patientbankaccountdata.UseAlternatePaymentAddress"-->
<!--                  :label="`Rechnungsempfänger entspricht nicht Leistungsempfänger`"-->
<!--                  @click="setAlternatePaymentAddress"-->
<!--              ></v-checkbox> -->
            </v-col>
          </v-row>
<!--          <PatientPaymentAddress-->
<!--              v-if="form.patientbankaccountdata.UseAlternatePaymentAddress"-->
<!--              ref="alternatepaymentaddress"-->
<!--              :titles-pool="titlesPool"-->
<!--              :patientID="patientID"-->
<!--              :key="patientObject">-->
<!--          </PatientPaymentAddress>-->
        </div>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>

// import PatientPaymentAddress from "../../components/patients/PatientPaymentAddress"
import {helpers} from 'vuelidate/lib/validators';

const ibanValidator = helpers.regex('alphaNumAndDot', /^DE(?:\s*[0-9a-zA-Z]\s*){20}$/);
const bicValidator = helpers.regex('alphaNumAndDot', /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/);

export default {
  props: {
    titlesPool: {},
    patientID: undefined,
    formdisabled: Boolean
  },
  components: {
    // PatientPaymentAddress
  },
  async created() {
    await this.FillInForm()
  },
  data() {
    const defaultForm = Object.freeze({
      patientbankaccountdata: {
        IBAN: undefined,
        BIC: undefined,
        PaymentType: undefined,
        UseAlternatePaymentAddress: undefined,
        PatientID: undefined,
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
    };
  },
  validations: {
    form: {
      patientbankaccountdata: {
        IBAN: {ibanValidator},
        BIC: {bicValidator}
      }
    }
  },
  methods: {
    // setAlternatePaymentAddress() {
    //   this.UseAlternatePaymentAddress = !this.UseAlternatePaymentAddress
    // },
    async SavePersonalBankAccountObject() {
      console.log("### calling SavePersonalBankAccountObject() #");
      this.form.patientbankaccountdata.PatientID = this.patientID
      console.log('PersonalBankAccountObject: ' + JSON.stringify(this.form.patientbankaccountdata));
      //this.$refs.alternatepaymentaddress.SaveAlternatePaymentAddressObject();
      await this.$store.dispatch('patientoverview/createPatientBankAccount', this.form.patientbankaccountdata)
      // if (this.form.patientbankaccountdata.UseAlternatePaymentAddress == true) {
      //   console.log("test")
      //   await this.$refs.alternatepaymentaddress.SaveAlternatePaymentAddressObject();
      // }
    },
    ResetForm() {
      console.log("# calling ResetFrom() of PersonalBankAccountData #");
      this.$refs.form.reset();
      console.log('PersonalBankAccountData: ' + JSON.stringify(this.form.patientbankaccountdata));
    },
    async FillInForm() {
      console.log("PatientID: " + this.patientID)

      await this.$store.dispatch('patientoverview/getPatientBankAccountByPatientID', this.patientID)
      this.form.patientbankaccountdata = this.$store.getters['patientoverview/getAccountData']
    },
  },
  computed: {
    IBANErrors() {
      const errors = []
      if (!this.$v.form.patientbankaccountdata.IBAN.$dirty) return errors
      !this.$v.form.patientbankaccountdata.IBAN.ibanValidator && errors.push('Ungültiges IBAN Format')
      return errors
    },
    BICErrors() {
      const errors = []
      if (!this.$v.form.patientbankaccountdata.BIC.$dirty) return errors
      !this.$v.form.patientbankaccountdata.BIC.bicValidator && errors.push('Mindestens 8 Zeichen / Großbuchstaben')
      return errors
    },
  },
  // created() {
  //   // alert(Object.keys(this.patientObject).length===0)
  //   if (Object.keys(this.patientObject).length === 0) {
  //     return this.patientbankaccountdata;
  //   } else {
  //     this.form.patientbankaccountdata.IBAN = this.patientObject.item.Bankaccounts[0].IBAN;
  //     this.form.patientbankaccountdata.BIC = this.patientObject.item.Bankaccounts[0].BIC;
  //     this.form.patientbankaccountdata.PaymentType = this.patientObject.item.Bankaccounts[0].PatientBankaccountPaymentTypeID;
  //     this.form.patientbankaccountdata.UseAlternatePaymentAddress = this.patientObject.item.Bankaccounts[0].UseAlternatePaymentAddress;
  //     //
  //   }
  // },
};
</script>

<style scoped>

.error--text {
  color: red !important;
  font-size: 22px;
  text-transform: uppercase !important;
  font-weight: bold;
  font-style: italic;
}

</style>