<template>
  <div>
    <base-card>
    <template v-if="id == 0">
      <h3>Neues Auto anmelden</h3>
    </template>
    <template v-else>
      <h3><v-icon  style="color: #006ab5 !important;">mdi-car</v-icon> Neues Auto anmelden</h3>
    </template>

    <v-col cols="12">
      <h4>Daten zum Auto</h4>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Nummer"
            :rules="rules"
            hide-details="auto"
            tabindex="1"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Fahrgestellnummer"
            :rules="rules"
            hide-details="auto"
            tabindex="1"
            v-model="chassisNumber"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Kennzeichen *"
            :rules="kfzRules"
            hide-details="auto"
            tabindex="1"
            v-model="kfzNr"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-select
            v-model="selectedManufacturer"
            :items="manufacturers"
            item-text="Name"
            item-value="ID"
            tabindex="1"
            placeholder="Hersteller *"
            @change="HandleManufacturerChanged(selectedManufacturer)"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-dialog
            ref="datedialog"
            v-model="datemodal"
            :return-value.sync="datehuau"
            persistent
            tabindex="4"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datehuau"
                label="HU / AU *"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="datehuau" scrollable locale="de-de">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datemodal = false">
                Abbrechen
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.datedialog.save(datehuau)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedfuel"
            :items="fuels"
            item-text="Name"
            item-value="ID"
            tabindex="1"
            placeholder="Kraftstoff *"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Modell *"
            :rules="rules"
            hide-details="auto"
            tabindex="1"
            v-model="model"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3"
          ><v-row>
            <v-text-field
              label="Kilometerstand"
              v-model="KM"
              :rules="rules"
              hide-details="auto"
              tabindex="1"
            ></v-text-field> </v-row
        ></v-col>
        <v-col cols="3"
          ><v-row>
            <v-dialog
              ref="datekm"
              v-model="datekmmodal"
              :return-value.sync="datekm"
              persistent
              tabindex="4"
              width="290px"
              disabled
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datekm"
                  label="KM Datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  disabled
                ></v-text-field>
              </template>
              <v-date-picker v-model="datekm" scrollable locale="de-de">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="datekmmodal = false">
                  Abbrechen
                </v-btn>
                <v-btn text color="primary" @click="$refs.datekm.save(datekm)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-row></v-col
        >
        <v-col cols="3"
          ><v-row>
            <v-dialog
              ref="dateyear"
              v-model="dateyrmodal"
              :return-value.sync="dateyr"
              persistent
              tabindex="4"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateyr"
                  label="Baujahr *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateyr" scrollable locale="de-de">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateyrmodal = false">
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dateyear.save(dateyr)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-row></v-col
        >
        <v-col cols="3"
          ><v-row>
            <v-dialog
              ref="dateregister"
              v-model="dateregistermodal"
              :return-value.sync="datezlyr"
              persistent
              tabindex="4"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datezlyr"
                  label="Erstzulassung *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="datezlyr" scrollable locale="de-de">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateregistermodal = false">
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dateregister.save(datezlyr)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-row></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="selecteddrivers"
            :items="employeeNames"
            item-value="id"
            item-text="name"
            label="Fahrer"
            multiple
            @change="SelectedDriversChanged"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ selecteddrivers.length - 1 }} weitere)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Tankkarte"
            v-model="card"
            hide-details="auto"
            tabindex="1"
          ></v-text-field>
          <v-btn
            @click="NewCar"
            color="primary"
            elevation="2"
            style="float: right"
            class="mt-3 mr-5"
            :disabled="!btnEnabled"
            ><i class="fas fa-save"></i> Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
<!--    <v-divider></v-divider>-->
    <v-col cols="12">
      <v-row>
        <v-alert
      border="left"
      colored-border
      type="info"
      elevation="2"
    >
     Bitte speichern Sie erst die obrigen Daten um die unten stehenden Felder bearbeiten zu können.
    </v-alert>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <h3>Anstehende Termine</h3>

      </v-row>
    </v-col>
    </base-card>
  </div>
</template>

<script>
import moment from "moment";
import {validateKFZ }  from "../../../helpers/validator"
import BaseCard from "../../Base/BaseCard";

export default {
  components: {BaseCard},
  created() {
    this.GetAllManufacturer();
    this.GetAllEmployees();
    this.GetAllFuels();
  },
  data() {
    return {
      Name: "",
      Manufacturer: "",
      kfzNr: "",
      KM: 0,
      chassisNumber: "",
      selectedfuel: 0,
      selectedManufacturer: 0,
      currentManufacturer: {},
      model: "",
      datezlyr: "", //Registration Date
      dateyr: "", //Build Year
      card: "",
      datehuau: "",
      datekm: "",
      selecteddrivers: [],
      dateregistermodal: "",

      rules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld."
      ],

      kfzRules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
          (value && validateKFZ(value)) ||
          "Keine gültiges Kennzeichen",
      ],

    };
  },
  computed: {
    btnEnabled() {
      return (
              (this.kfzNr != "") &&
              (!!this.selectedManufacturer != 0) &&
              (this.datezlyr != "" || !!this.datezlyr) &&
              (this.dateyr != "" || !!this.dateyr) &&
              (this.datehuau != "" || !!this.datehuau) &&
              (this.model != "" || !!this.model)
              )
    },
    formatHUAU() {
      return this.datehuau ? moment(this.datehuau).format() : "";
    },
    formatBuildYear() {
      return this.dateyr ? moment(this.dateyr).format() : "";
    },
    formatRegistrationDate() {
      return this.datezlyr ? moment(this.datezlyr).format() : "";
    },
    selectedManufacturerID() {
      return this.currentManufacturer.ID;
    },
    selectedManufacturerWebsite() {
      return this.currentManufacturer.Website;
    },
    employeeNames() {
      let l = this.employees; let x = [];
      if (l != undefined) {
        l.forEach(element => { x.push({ id: element.ID, name: element.Firstname + "," + element.Lastname }); });
      }
      return x;
    },
    fuels(){
        let f = this.$store.state.fuels.all.items;
        let n = [{ ID: null, Name: "Benzin"}];
        return (f) ? f : n;
    },
    manufacturers(){
        return this.$store.state.automotiveManufacturers.all.items;
    },
    employees(){
        return this.$store.state.employees.all.items;
    },
    userID() {
      return this.$store.state.authentication.userID;
    },
  },
  methods: {
    SelectedDriversChanged() {
      console.log(this.selecteddrivers);
    },
    HandleManufacturerChanged(id) {
      this.currentManufacturer = this.manufacturers.filter(x => x.ID == id)[0];
    },
    async GetAllFuels() {
      this.$store.dispatch("fuels/getAll");
    },
    async GetAllManufacturer() {
      await this.$store.dispatch("automotiveManufacturers/getAll");
    },
    async GetAllEmployees() {
      this.$store.dispatch('employees/getAll');
    },
    async NewCar() {
      let car = {
        KfzNr: this.kfzNr,
        AutomotiveManufacturerID: this.selectedManufacturerID,
        ModelName: this.model,
        FuelID: this.selectedfuel,
        HUAU: this.formatHUAU,
        Operative: true,
        Available: true,
        Card: this.card,
        BuildYear: this.formatBuildYear,
        Registration: this.formatRegistrationDate,
        ChassisNumber: this.chassisNumber,
        KM: Number(this.KM)
      };
      console.log(JSON.stringify(car))
      this.$store.dispatch("cars/create", car).then((response) => {

        let carID = response.ID;

        this.selecteddrivers.forEach((ele) => {
          let payload = {
            AutomotiveID: parseInt(carID),
            EmployeeID: parseInt(ele)
          };
          console.log(payload);

          this.$store.dispatch("cars/linkdriver", payload).then((response_link) => {
                console.log(response_link);
          });
        })

        this.$root.$emit("reload_car_list");
        this.$root.$emit("reload_car_details");
        window.location.assign("/car/" + response.ID);
      });
    },
  },
};
</script>
