<template>
  <div>
    <v-row class="mt-2">
      <div class="ml-2">
        <v-checkbox
            v-model="form.IsEmergencyContact"
            :label="`Notfallkontakt`"
        ></v-checkbox>
      </div>
      <div class="ml-2">
        <v-checkbox
            v-model="form.AvailableAtNight"
            :label="`Erreichbar in der Nacht`"

        ></v-checkbox>
      </div>
      <div class="ml-2">
        <v-checkbox
            v-model="form.HasKey"
            :label="`Schlüssel vorhanden`"
        ></v-checkbox>
      </div>
    </v-row>
    <div v-if="form.HasKey">
      <a class="mr-2" style="color: #006ab4;font-weight: bold;">Schlüsselort</a>
      <input type="text" class="col-3" placeholder="Schlüsselort 1"
             v-model="form.FirstKeyStoredPlace"/>
      <input type="text" class="col-3" placeholder="Schlüsselort 2"
             v-model="form.SecondKeyStoredPlace"/>
      <input type="text" class="col-3" placeholder="Schlüsselort 3"
             v-model="form.ThirdKeyStoredPlace"/>
    </div>

    <v-row>

      <v-col class="col-2">
        <v-text-field
            v-model="form.RelatedContactType"
            label="Art:"
            placeholder="Art"
            hide-details="auto"
        >
        </v-text-field>
      </v-col>


      <v-col class="col-2">
        <v-select
            v-model="form.Title"
            :items="titlesPool.titlePool"
            placeholder="Anrede"></v-select>
      </v-col>
      <v-col>
        <v-text-field
            v-model="form.FirstName"
            label="Vorname:"
            placeholder="Vorname"
            hide-details="auto"
            :error-messages="firstNameErrors"
            @input="$v.form.FirstName.$touch()"
            @blur="$v.form.FirstName.$touch()"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model="form.LastName"
            label="Nachname:"
            placeholder="Nachname"
            hide-details="auto"
            :error-messages="lastNameErrors"
            @input="$v.form.LastName.$touch()"
            @blur="$v.form.LastName.$touch()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            v-model="form.Street"
            label="Straße:"
            placeholder="Straße"
            hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col class="col-1">
        <v-text-field
            v-model="form.PLZ"
            label="PLZ:"
            placeholder="PLZ"
            hide-details="auto"
            :error-messages="PLZErrors"
            @input="$v.form.PLZ.$touch()"
            @blur="$v.form.PLZ.$touch()"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model="form.City"
            label="Ort:"
            placeholder="Ort"
            hide-details="auto"
            :error-messages="CityErrors"
            @input="$v.form.City.$touch()"
            @blur="$v.form.City.$touch()"
        >
        </v-text-field>
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            v-model="form.PhoneNumber"
            prepend-icon="fas fa-phone-square"
            label="Telefonnummer:"
            placeholder="1234"
            hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model="form.MobileNumber"
            prepend-icon="fas fa-mobile-alt"
            label="Mobil:"
            placeholder="1234"
            hide-details="auto"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col>
        <v-text-field
            v-model="form.EMail"
            prepend-icon="fas fa-envelope"
            label="E-Mail:"
            placeholder="e-mail"
            hide-details="auto"
            :error-messages="emailErrors"
            @input="$v.form.EMail.$touch()"
            @blur="$v.form.EMail.$touch()"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-btn
          outlined
          elevation="2"
          class="mb-3 ml-2 mt-3"
          @click="DeleteRelatedContactObject()"
      >
        <i class="fas fa-minus"></i>Kontakt löschen
      </v-btn>
    </v-row>

  </div>
</template>
<script>

import {email, helpers, maxLength, minLength, numeric, required} from 'vuelidate/lib/validators';

const alphaNumAndDotAndWhiteSpaceValidator = helpers.regex('alphaNumAndDot', /^[a-zA-Z.\s-,+äüöÄÜÖß]*$/);


export default {
  props: {
    titlesPool: {},
    patientObject: {},
    patientID: undefined,
    formdisabled: Boolean,
    form: {}
  },
  validations: {
    form: {
        PLZ: {
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        City: {alphaNumAndDotAndWhiteSpaceValidator},
        EMail: {email},
        LastName: {required, alphaNumAndDotAndWhiteSpaceValidator},
        FirstName: {alphaNumAndDotAndWhiteSpaceValidator},
    }
  },
  mixins: [],
  data() {
    return {
      titlePool: this.titlesPool,
    };
  },
  methods: {
    async SaveRelatedContactObject() {
      console.log("### calling SaveRelatedContactObject() #");
      this.form.PatientID = this.patientID
      await this.$store.dispatch('patientoverview/createPatientRelatedContact', this.form)
      this.$emit("RenderContacts")
    },
    async DeleteRelatedContactObject(){
      console.log("### calling DeleteRelatedContactObject() #");
      await this.$store.dispatch('patientoverview/deletePatientRelatedContactByID', this.form.ID)
      this.$emit("RenderContacts")
    }
  },
  computed: {
    PLZErrors() {
      const errors = []
      if (!this.$v.form.PLZ.$dirty) return errors
      !this.$v.form.PLZ.numeric && errors.push('Nur Zahlen erlaubt')
      !this.$v.form.PLZ.minLength && errors.push('Maximal 5 Zeichen')
      !this.$v.form.PLZ.maxLength && errors.push('Anzahl der erlaubten Zeichen überschritten')
      return errors
    },
    CityErrors() {
      const errors = []
      if (!this.$v.form.City.$dirty) return errors
      !this.$v.form.City.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Keine Zahlen erlaubt')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.EMail.$dirty) return errors
      !this.$v.form.EMail.email && errors.push('Ungültige E-Mail Adresse')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.LastName.$dirty) return errors
      !this.$v.form.LastName.required && errors.push('Nachname ist ein Pflichtfeld')
      !this.$v.form.LastName.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Nachname darf keine Zahlen enthalten')
      return errors
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.FirstName.$dirty) return errors
      !this.$v.form.FirstName.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Vorname darf keine Zahlen enthalten')
      return errors
    },
  },
}

</script>

<style scoped>
i {
  margin-right: 10px;
}

input[type="text"],
text-area {
  border: 1px solid #ab7cab;
  background: white;
  margin: 2px;
}

.error--text {
  color: red !important;
  font-size: 22px;
  text-transform: uppercase !important;
  font-weight: bold;
  font-style: italic;
}

</style>
