<template>
    <div>
        <v-row>
        <v-col cols="3"
                class="mt-0">
            <v-select
                v-model="serviceArticleID"
                label="Leistungen"
                class="mt-0"
                hide-details="auto"
                placeholder="Unbekannt"
                :items="serviceArticles"
                item-text="ArticleName"
                item-value="ID"
                @change="FillForm()"
                :disabled="disabled"
            >
            </v-select>
        </v-col>

        <v-col cols="2">
            <v-text-field
                v-model="Price"
                class="centered-input mt-0"
                append-icon="mdi-currency-eur"
                label="Preis:"
                hide-details="auto"
            ></v-text-field>
        </v-col>
        <v-col cols="2" v-if="showAmount">
            <v-text-field
                v-model="Amount"
                class="mt-0 "
                label="Anzahl:"
                placeholder="amount"
                hide-details="auto"
            ></v-text-field>
        </v-col>
        <v-col>
            <v-text-field
                v-model="itemType"
                class="centered-input mt-0"
                label="Leistungsart:"
                hide-details="auto"
            ></v-text-field>
        </v-col>
        <v-col v-if="showDeleteButton" cols="2">
            <v-btn color="primary" elevation="2" class="mt-3" @click="DeletePatientServiceArticle()">
                <i class="fas fa-trash"></i>
            </v-btn>
        </v-col>
        </v-row>
        <v-row v-if="showSafeButton">
            <v-btn
                class="primary mt-3 mb-3 ml-3"
                @click="SafePatientServiceArticle()"
            >
                Speichern
            </v-btn>
        </v-row>
    </div>
</template>

<script>
export default {
    async created(){
        await this.$store.dispatch('invoicePatient/getServiceArticlesDTO')
        //console.log(JSON.stringify(this.serviceArticles))  
        if(this.fillFields == true){
            this.FillForm()
            this.disabled = true
        }
    },
    data() {
        return{
            itemType: undefined,
            Price: undefined,
            disabled: false
        }
    },
    props:{
        showDeleteButton: Boolean(true),
        showSafeButton: Boolean(false),
        patientID: Number,
        HN: Boolean(false),
        AUS: Boolean(false),
        Private: Boolean(false),
        serviceArticleID: undefined,
        patientServiceArticleID: undefined,
        fillFields: Boolean(false),
        showAmount: Boolean(true),
        Amount: undefined,
    },
    computed:{
        serviceArticles(){
            return this.$store.getters['invoicePatient/getServiceArticlesDTO']
        },
    },
    methods:{
        FillForm(){
            let serviceArticle = this.serviceArticles.find(element => element.ID == this.serviceArticleID)
            this.Price = serviceArticle.Price
            if(serviceArticle.Timed){
                this.itemType = "Stunden"
            }
            if(serviceArticle.ByCalendarEntry){
                this.itemType = "Dienstplaneintrag"
            }
            if(serviceArticle.Recurring){
                this.itemType = "Monatlich"
            }
            if(serviceArticle.NumberOfPieces){
                this.itemType = "Stückzahl"
            }
        },
        async SafePatientServiceArticle(){
            let patientServiceArticleForSafeDTO = {
                ServiceArticleID: this.serviceArticleID,
                PatientID: this.patientID,
                AUS: this.AUS,
                HN: this.HN,
                Private: this.Private
            }
            console.log(JSON.stringify(patientServiceArticleForSafeDTO))
            try{
                await this.$store.dispatch('invoicePatient/safePatientServiceArticle', patientServiceArticleForSafeDTO)
                this.$emit('RenderPatientServiceArticle')
            }catch (err){
                console.log(err)
            }
        },
        async DeletePatientServiceArticle(){
            console.log(this.patientServiceArticleID)
            try{
                await this.$store.dispatch('invoicePatient/deletePatientServiceArticleByID', this.patientServiceArticleID)
                this.$emit('RenderPatientServiceArticle')
            }catch(err){
                console.log(err)
                window.alert(err)
            }
            
        }
    }
}
</script>