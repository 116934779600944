<template>
  <div>
    <v-sheet height="64">
      <v-toolbar
          flat>
        <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday()">
          heute
        </v-btn>
        <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="700">
      <v-calendar
          ref="calendar"
          v-model="value"
          color="primary"
          :type="type"
          :locale="'de'"
          :events="timetablelist"
          :event-color="getEventColor"
          @click:event="ShowEvent"
          @mousemove:event="getEvent">
      </v-calendar>
    </v-sheet>
    <v-row class="ml-0">
      <v-dialog
          v-model="timetablemodaldialog"
          persistent max-width="1100px">
        <EmployeeTimeTableEventDialog
            :key="employeeTimeTableEventDialogKey"
            :event="selectedEvent"/>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import {employeeService} from '../../../services';
import EmployeeTimeTableEventDialog from "../../dialogs/EmployeeTimeTableEventDialog"
import {getUserFromJWT} from "../../../helpers"

export default {
  components: {
    EmployeeTimeTableEventDialog
  },
  props: {
    employeeID: Number
  },
  mounted() {
    this.$root.$refs.TimeTableCalendar = this;
  },
  data() {
    return {
      value: new Date().toISOString().substr(0, 10),
      type: 'day',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      timestart: null,
      timeend: null,
      modalstart: false,
      modalend: false,
      mouseoverevent: null,
    }
  },
  inject: ['role', 'currentID'],
  created() {
    //this.$store.dispatch('employees/getAllEmployeeTimeTables')
    let user = getUserFromJWT()
    console.log("employeeid:" + user.employeeID)
    if (this.employeeID !== 0) {
      this.$store.dispatch('employees/getAllEmployeeTimeTablesByEmployeeID', this.employeeID);
    }
  },
  computed: {
    Role() {
      return this.role
    },
    currentID() {
      return this.ID
    },
    timetablelist() {
      return this.$store.getters['employees/getTimeTablesByEmployeeID']
    },
    timetablemodaldialog() {
      return this.$store.getters['eventdialog/gettimetablemodaldialog']
    }
  },
  methods: {
    setNewDate(date) {
      this.value = new Date(date);
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.value = ''
    },
    getEvent({event}) {
      this.mouseoverevent = event
      // alert("MOUSEOVER");
    },
    ShowEvent() {
      this.selectedEvent = this.mouseoverevent
      console.log(this.selectedEvent)
      this.$store.commit("eventdialog/changeTimeTableModalDialogToTrue")
      this.employeeTimeTableEventDialogKey++
    },
    saveTimestampstartAndTimestampend() {
      console.log(this.timestart)
      let timestampbeginn = this.getTimestamp(this.timestart)
      let timestampend = this.getTimestamp(this.timeend)
      console.log(timestampbeginn)
      console.log(timestampend)
      if (timestampbeginn < timestampend) {
        let timestamps = [timestampbeginn, timestampend]
        return timestamps
      } else {
        return console.error();
      }
    },
    getTimestamp(timeofday) {
      let date = new Date(this.value)
      let day = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      let timeofdayarray = timeofday.split(':')
      let datestart = new Date(year, month, day, timeofdayarray[0], timeofdayarray[1])
      console.log(datestart)
      return datestart.getTime()
    },
    async calculateOvertime() {
      let employeeid = this.selectedEvent.EmployeeID
      let response = await employeeService.calculateOvertimeForEachEmployee(employeeid)
      console.log(response)
    }
  }
}
</script>