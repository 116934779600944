<template>
  <main>
    <v-container fluid>
      <h2><v-icon large  style="color: #006ab5 !important;">mdi-car</v-icon> Firmenwagen</h2>

      <v-row>
        <div
          class="col-4 border border-right border-left-0 border-top-0 border-bottom-0"
        >
          <base-card>
          <InventoryCarList></InventoryCarList>
          </base-card>
          <v-btn @click="New" color="primary" elevation="2"
            ><i class="fas fa-plus"></i>Fahrzeug Hinzufügen</v-btn
          >
          <br />
          <br />
          <CarAppointmentList></CarAppointmentList>
        </div>
        <v-col cols="8">
          <CarEmpty v-if="id == 0 || id == undefined"></CarEmpty>
          <CarDetails v-else></CarDetails>
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import InventoryCarList from "../../../components/inventory/Cars/InventoryCarList.vue";
import CarDetails from "../../../components/inventory/Cars/CarDetails.vue";
import CarEmpty from "../../../components/inventory/Cars/CarEmpty.vue";
import CarAppointmentList from "../../../components/inventory/Cars/CarAppointmentList.vue";
import BaseCard from "../../../components/Base/BaseCard";

export default {
  created() {
    this.$root.$refs.start.setMenuActive(true);
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  components: {
    BaseCard,
    InventoryCarList,
    CarDetails,
    CarAppointmentList,
    CarEmpty,
  },
  computed: {
  },
  methods: {
    routeTo(url) {
      if (url.startsWith("http")) {
        window.open(url, "_blank")
      }
      else {
        this.$router.push(url);
      }

    },
    New() {
      window.location.assign("/car/0");
      this.$root.$emit("reload_car_details");
    },
  },
};
</script>

<style scoped>
i{
  margin-right: 10px;
}
svg{
  margin-right: 10px;
}

</style>