<template>
  <main>
    <v-alert
        v-model="alert"
        dismissible
        :type="alerttype">
      {{ alertmessage }}
    </v-alert>
    <v-container fluid>
      <row :gutter="12" align="center">
        <column :lg="4">
          <v-card color="primary" dark>
            <v-card-title>
              <v-icon>mdi-calendar-check</v-icon>
              ARBEITSZEITERFASSUNG
            </v-card-title>
            <TimeRecording
                class="mt-1"
                :employees="employees"
                :currentEmployeeID="currentEmployeeID"
                @SetAlert="HandleAlert"
                @SendEmployeeID="ReceiveEmployeeID"/>
          </v-card>
        </column>
        <column :lg="8">
          <v-card>
            <v-card-title style="color:#006ab5 ">Leistungserfassung</v-card-title>
            <TimeTableCalendar
                :key="timeTableCalendarkey"
                :timerecords="timerecords"
                :employeeID="employeeID"/>
          </v-card>
        </column>
      </row>
    </v-container>
  </main>
</template>
<script>
import TimeTableCalendar from '../../components/employees/timetable/TimeTableCalendar.vue'
import TimeRecording from '../../components/employees/timetable/TimeRecording.vue'

export default {
  components: {
    TimeTableCalendar,
    TimeRecording
  },
  created() {
      this.$store.dispatch('employees/getAllEmployeeTables')
  },
  data() {
    return {
      alert: false,
      alertmessage: "",
      employeeID: 0,
      timeTableCalendarkey: 0,
      alerttype: "",
      currentEmployeeID: this.$cookies.get('currentUserID')
    }
  },
  computed: {
    employees() {
      return this.$store.state.employees.employeestable.items;
    },
  },
  methods: {
    routeTo(url) {
      this.$router.push(url);
    },
    HandleAlert(errorobject) {
      this.alertmessage = errorobject.message
      this.alerttype = errorobject.type
      this.alert = true
    },
    ReceiveEmployeeID(id) {
      this.employeeID = id
      this.timeTableCalendarkey++
    }
  }
}
</script>

<style scoped>
.custom_margin {
  margin-left: 2%;
  margin-right: 10%;
  max-width: 96%;
}
</style>