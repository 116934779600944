<template>
    <v-card>
        <v-card-text>
        <row>
            <h3>Bitte Mitarbeiter auswählen:</h3>
        </row>
        <row class="mt-3">
            <EmployeeTable
                :employees="employees"
                @sendEmployeeID="ReceiveEmployeeID"
            />
        </row>
        </v-card-text>
        <v-card-action>
            <v-btn
              color="info"
              class="ml-2 mb-2"
              @click="CloseDialog()">
            Schließen
          </v-btn>
        </v-card-action>
    </v-card>
</template>
<script>
import EmployeeTable from '../employees/overview/EmployeeTable.vue'
export default {
    components: {
        EmployeeTable
    },
    created() {
        console.log("Bin hier")
        this.$store.dispatch('employees/getAll');
    },
    computed: {
        employees() {
            return this.$store.state.employees.all.items;
        }
    },
    methods: {
        CloseDialog(){
            this.$store.commit('eventdialog/changeEmployeeTableModalDialogToFalse')
        },
        ReceiveEmployeeID(employeeID){
            //console.log(employeeID)
            this.$emit("sendEmployeeID", employeeID)
            this.CloseDialog()
        },
    }
}
</script>