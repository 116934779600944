import {
    carService
} from '../services';

export const cars = {
    namespaced: true,
    state: {
        all: {},
        single: {},
        delete: {},
        loading: false,
    },
    actions: {
        reset({
            commit
        }) {
            commit('carsReset');
        },

        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                carService.getAll()
                    .then(
                        data => {
                            commit('getAllSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        get({
            commit
        }, id) {
            commit('getSingleRequest');

            carService.getSingle(id)
                .then(
                    data => commit('getSingleSuccess', data),
                    error => commit('getSingleFailure', error)
                );
        },

        create({
            commit
        }, data) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                carService.create(data)
                    .then(
                        data => {
                            commit('newSuccess', data);
                            resolve(data)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        linkdriver({
            commit
        }, data) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                carService.linkDriver(data)
                    .then(
                        data => {
                            commit('newSuccess', data);
                            resolve(data)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        unlinkAlldriver({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                carService.unlinkAllDriver(id)
                    .then(
                        data => {
                            commit('deleteSuccess', data);
                            resolve(data)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updateCar({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                carService.update(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        delete({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                carService.deleteById(id)
                    .then(
                        insurance => {
                            commit('deleteSuccess', insurance);
                            resolve(insurance)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },
    },
    mutations: {
        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },

        deleteRequest(state) {
            state.delete = {
                loading: true
            }; //Wird geladen status
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Objekt das vom Server zurückgegeben wird
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },

        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },

        carsReset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },
        getSingleRequest(state) {
            state.single = {
                loading: true
            };
        },
        getSingleSuccess(state, data) {
            state.single = {
                item: data,
                loading: false
            };
        },
        getSingleFailure(state, error) {
            state.single = {
                error,
                loading: false
            };
        },
        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, data) {
            state.all = {
                items: data
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        }
    }
}