<template>
  <v-container fluid>
    <base-card>
    <v-form ref="form">
      <div class="densecol">
        <v-row>
         <v-chip v-if="employeeid != undefined" :color="EmployeeChip" style="font-size: 1.2em" label class="ml-6 mt-1">
           {{ LastName }}
           {{ LastName === "" ? "" : "," }}
           {{ FirstName }}
         </v-chip>
           <p v-if="employeeid != undefined" style="font-size: 0.95em" class="ml-4 mt-2">{{ EmployentType }}, {{ HoursPerWeek }} Std./Woche</p>
        </v-row>
        <div
            class="col-11 mt-2"
            style="background-color: #fdf8f8; padding-bottom: 0px;"
        >
          <v-tabs
              class="col-12 mt-0 mb-0 pa-1"
              style="border: 1px solid purple !important;"

          >
            <v-tab
                class="customTabsStyle"
                @click="selectTab(1)"
            >
              Persönliche Daten
            </v-tab>

            <v-tab
                class="customTabsStyle"
                @click.self="selectTab(2)"
            >
              Bankdaten und Abrechnung
            </v-tab>

            <v-tab
                class="customTabsStyle"
                @click="selectTab(3)"
            >
              Urlaubs- und Fehlzeiten
            </v-tab>

            <v-tab :disabled="noDocs"
                   class="customTabsStyle"
                   @click="selectTab(4)"
            >
              Hinterlegte Dokumente
            </v-tab>

          </v-tabs>
        </div>

        <div
            class="mt-0 col-11"
            style="background-color: #fdf8f8; padding: 0 10px 10px 10px;"
        >
          <v-container fluid v-show="selectedTabID === 1">
            <PersonalData
                :data="data.employee"
                :dropdown="dropdown.activities"
                :disableinput="disableinput">
            </PersonalData>

          </v-container>
          <v-container fluid v-show="selectedTabID === 2">
            <BillingData
                :data="data.employee"
                :dropdown="dropdown"
                :disableinput="disableinput">
            </BillingData>
          </v-container>
          <v-container fluid v-show="selectedTabID === 3">
            <HolidaySickHours
                :data="data.employee"
                :disableinput="disableinput"
                :employeeovertime="employeeovertime"
                :calculationdata="calculationdata"
            ></HolidaySickHours>
          </v-container>
          <v-container fluid v-show="selectedTabID === 4">
            <EmployeeDocuments
                :employeeid="employeeid"
                :filetablekey="filetablekey"
                :employeefiles="employeefiles"
                @Renderfiletable="Renderfiletable">
            </EmployeeDocuments>
          </v-container>

        </div>
      </div>
    </v-form>
  </base-card>
  </v-container>
</template>

<script>
import PersonalData from './PersonalData.vue'
import BillingData from './BillingData.vue'
import HolidaySickHours from './HolidaySickHours.vue'
import EmployeeDocuments from './EmployeeDocuments.vue'
import BaseCard from "../../Base/BaseCard";

export default {
  components: {
    BaseCard,
    PersonalData,
    BillingData,
    HolidaySickHours,
    EmployeeDocuments
  },
  data() {
    return {
      selectedTabID: 1,
    }
  },
  props: {
    data: {},
    dropdown: {},
    employeeid: Number,
    filetablekey: Number,
    employeefiles: {},
    disableinput: Boolean,
    employeeovertime: String,
    calculationdata: {},
    noDocs: Boolean,
  },
  methods: {
    Renderfiletable() {
      this.$emit("Renderfiletable")
    },
    selectTab(value) {
      this.selectedTabID = value;
    },
    EnableDocsBtn() {
      this.noDocs = true;
      this.$root.$refs.EmployeeDocuments.SetShowFiles(true);
    },
  },
  computed:{
    LastName() {
      return this.data.employee.Lastname
    },
    FirstName() {
      return this.data.employee.Firstname
    },
    EmployeeChip() {
      return this.data.employee.EmployeeColorHex;
    },
    EmployentType() {
      return this.data.employee.EmploymentType;
    },
    HoursPerWeek() {
      return this.data.employee.HoursPerWeek;
    }
  },
  mounted() {
    this.$root.$refs.EmployeeInputMask = this
  }
}
</script>

<style scoped>

.customTabsStyle {
  background-color: white !important;
  font-size: 0.83em;
}
</style>