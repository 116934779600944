import Vue from 'vue';
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueJwtDecode)

export function jwt_decode(jwt) {
    try {
        let x = VueJwtDecode.decode(jwt)
        return { username: x.user, email: x.email, employeeID: x.employeeID, userID: x.userID }
    }
    catch (e) {
        console.log(e)
        return  { username: "", email: "" }
    }
}

export function getUserFromJWT(){
    return jwt_decode(JSON.parse(localStorage.getItem('user')))
  }