<template>
  <main>
    <v-card-title class="invoice">
      <v-icon class="invoice">mdi-cash</v-icon>
      <h5>RECHNUNGEN</h5>
    </v-card-title>
    <v-card-subtitle> Gesamt</v-card-subtitle>
    <v-container fluid>

      <v-row style="margin: -20px 5px 5px; background-color: #ffd3d3; color: black">

        <v-col align="center" class="customSumAUS">
          AUS
          <div align="center">
            {{ sumSumAUS }}
          </div>
        </v-col>

        <v-col align="center" class="customSumHN">
          HN
          <div align="center">
            {{ sumSumHN }}
          </div>
        </v-col>
        <v-col align="center" class="customSumSUM">
          ∑
          <div align="center">
            {{ SumSumAUSAndHN }}
          </div>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedDateFormattedMomentjs"
                  label="Monat"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                type="month"
                locale="de"
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="modal = false"
              >
                Abbrechen
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-btn color="warning" class="mt-6" outlined small style="color: white" @click="nextMonth">+</v-btn>
        <v-btn color="warning" class="mt-6 ml-2 mr-2" outlined small @click="prevMonth">-</v-btn>
      </v-row>

      <v-row style="border: 1px solid lightcyan; margin: 0px 5px 5px;">
        <v-col align="center" class="customAUS">
          AUS
          <div align="center">
            {{ monthlyAUS }}
          </div>
        </v-col>

        <v-col align="center" class="customHN">
          HN
          <div align="center">
            {{ monthlyHN}}
          </div>
        </v-col>
        <v-col align="center" class="customSUM">
          ∑
          <div align="center">
            {{ SumMonthlyAUSAndHN}}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import moment from 'moment'

export default {
  name: "DashboardInvoices",
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
    sumSumAUS : 37,
    sumSumHN: 12,
    monthlyAUS: 5,
    monthlyHN: 2,
  }),
  computed: {
    computedDateFormattedMomentjs() {
      moment.locale("de");
      return this.date ? moment(this.date).format('MMMM  YYYY') : '';
    },
    SumSumAUSAndHN() {
      return this.sumSumAUS + this.sumSumHN;
    },
    SumMonthlyAUSAndHN() {
      return this.monthlyAUS + this.monthlyHN;
    }
  },
  methods: {
    nextMonth() {
      this.date = moment(this.date).add(1, 'months');

    },
    prevMonth() {
      this.date = moment(this.date).add(-1, 'months');
    },
  },
}
</script>


<style>


.customAUS {
  font-weight: bold;
  color: greenyellow;
}

.customHN {
  font-weight: bold;
  color: yellow;
}

.customSUM {
  font-weight: bold;
}

.customSumAUS {
  font-weight: bold;
  color: darkgreen;
}

.customSumHN {
  color: purple;
  font-weight: bold;
}

.customSumSUM {
  color: darkblue;
  font-weight: bold;
}

</style>