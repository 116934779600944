<template>
  <v-card color="primary" dark style="padding:10px">
    <v-chip
        :style="{backgroundColor: event.color}">
      <v-icon left>
        mdi-account-circle
      </v-icon>
      {{ event.name }}
    </v-chip>
    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-card-text>
      <a class="titleStyle"> <b>Uhrzeit</b></a>
      <row :gutter="12" align="center">
        <column :lg="4">
          <v-text-field
              :value="from"
              label="Von"
              disabled
          ></v-text-field>
        </column>

        <column :lg="4">
          <v-text-field
              :value="to"
              label="Bis"
              disabled
          ></v-text-field>
        </column>
      </row>
      <a class="titleStyle"> <b>Klient</b></a>
      <PatientInfo
          :key="patientinfokey"
          :patientid="event.PatientID"
      />
      <column class="titleStyle" :xs="12" :gutter="12" v-if="articles.items.length === 0">
        <p>keine Leistungen hinterlegt</p>
      </column>
      <column v-else class="titleStyle" :xs="12" :gutter="12">
        <p>Zu buchende Leistungen:</p>
      </column>
      <PatienServiceArticleBooking
          :calendarID="event.ID"
          :articles="articles"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="info"
          @click="CloseAndSaveDialog()"
      >
        <v-icon>mdi-content-save-settings-outline</v-icon>
        Speichern und Schließen
      </v-btn>
      <v-btn
          color="white"
          outlined
          @click="cancel()">
        Abrechen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import PatientInfo from "./PatientInfo"
import PatienServiceArticleBooking from "./PatienServiceArticleBooking"

export default {
  components: {
    PatientInfo,
    PatienServiceArticleBooking
  },
  props: {
    event: {}
  },
  async created() {
    await this.$store.dispatch("patientServiceArticles/getPatientServiceArticleForBookingByCalendarID", this.event.ID);
  },
  data() {
    return {
      patientinfokey: 0,
    }
  },
  computed: {
    from() {
      let date = new Date(this.event.start)
      return date.getHours() + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " Uhr"
    },
    to() {
      let date = new Date(this.event.end)
      return date.getHours() + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " Uhr"
    },
    articles() {
      let items = this.$store.getters['patientServiceArticles/getPatientServiceArticlesForBooking']
      return items
    }
  },
  methods: {
    cancel() {
      this.$store.commit("eventdialog/changeTimeTableModalDialogToFalse")
    },
    async CloseAndSaveDialog() {
      this.$root.$refs.PatientServiceArticleBooking.SendMinutes()
      await this.SendDeparture()
    },

    async SendDeparture() {
      let sendDeparture = {
        ID: this.event.ID,
        TraveledKilometers: Number(this.event.TraveledKilometers),
        DepartureTime: Number(this.event.DepartureTime)
      }
      console.log(JSON.stringify(sendDeparture))
      await this.$store.dispatch('employees/saveDeparture', sendDeparture)
    }
  }
}
</script>

<style scoped>

.titleStyle {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}
</style>