<template>
    <v-container fluid>
        <form ref="deviceform">
            <v-row>
                <v-col>
                    <v-text-field
                        ref="Description"
                        label="Beschreibung"
                        v-model="data.Description"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        label="Inventarnummer"
                        v-model="data.Inventorynumber"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
             <v-row>
                <v-col>
                    <v-text-field
                        label="Standortcode"
                        v-model="data.Locationcode"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        label="Rufcode"
                        v-model="data.Callcode"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-dialog
                        ref="dialognextmaintainance"
                        v-model="modalfornextmaintainance"
                        :return-value.sync="data.Nextmaintainance"
                        persistent
                        width="290px"  
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="data.Nextmaintainance"
                            dense
                            label="nächster Wartungstermin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ref="nextmaintainance"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            scrollable
                            :first-day-of-week="1"
                            locale="de"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modalfornextmaintainance = false"
                            >
                                Abbrechen
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="storeNextmaintainance(date)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col>
                     <v-text-field
                        label="Status"
                        v-model="Aktiv"
                        readonly
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        label="KlientID"
                        v-model="data.PatientID"
                        :disabled="disableinput"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
        </form>
    </v-container>
</template>
<script>
export default {
    props:{
        data:{},
    },
    data(){
        return {
            modalfornextmaintainance: false,
            date: new Date().toISOString().substr(0, 10),
            disableinput: true
        }
    },
    methods:{
        storeNextmaintainance(date){
            this.$refs.dialognextmaintainance.save(date)
            this.date = new Date().toISOString().substr(0, 10)

        },
    },
  computed:{
      Aktiv (){
        if (this.data.Active) {
          return "In Verwendung";
        } else  {
          return "Verfügbar"
        }
      }
  }
}
</script>