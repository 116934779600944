<template>
  <v-container>
    <v-row>
      <v-data-table style="padding:10px"
                    light
                    :items="articles.items"
                    :headers="headers"
                    :header-props="headerProps"
                    :search="search"
                    no-data-text="keine Daten vorhanden"
                    no-results-text="keine Suchergebnisse"
                    @click:row="rowClick"
                    single-select
                    item-key="ID"
                    isSelected="true"
                    :items-per-page="5"
                    :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
      >
        <template v-slot:top>
          <v-row style="width: 100%">
            <column class="mt-4 ml-2">
              <h3>
                Alle Artikel
              </h3>
            </column>
          <v-text-field
              clearable
              append-icon="mdi-magnify"
              v-model="search"
              label="Suchen"
              class="mx-4"
          >
          </v-text-field>
          </v-row>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    patientID: Number
  },
  data() {
    return {
      search: '',
      headers: [
        {text: 'ID', value: 'ID'},
        {text: 'Leistung', value: 'ArticleName'},
        {text: 'Preis', value: 'Price'},
        {text: 'Abrechungsart', value: 'BillingType'},
        {text: 'Erfassungsart', value: 'TypeOfService'},
      ],
      headerProps: {
        sortByText: 'Sortieren nach'
      },
    }
  },
  created() {
    this.$store.dispatch("patientServiceArticles/getArticleByPatientID", this.patientID)
  },
  computed: {
    articles() {
      return this.$store.getters['patientServiceArticles/getArticlesForRoster']
    }
  },
  methods: {
    rowClick(item, row) {
      row.select(true)
      console.log(item)
      this.$emit("sendPatientServiceArticleID", item.ID)
    }
  }
}
</script>