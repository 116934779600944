import {
    authHeader
} from '../helpers';

export const invoicePositionService = {
    deleteById,
    update,
    create,
    updatePositionByID,
    DeletePositionByID,
    createNewPosition
};

function deleteById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/position/delete/id/` + id, requestOptions).then(handleResponse);
}

// Lösche Rechnungsposition
function DeletePositionByID(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/positions/delete/` + id, requestOptions).then(handleResponse);
}

function create(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/position`, requestOptions).then(handleResponse);
}

function createNewPosition(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/position/createNewPosition`, requestOptions).then(handleResponse);
}

function update(payload) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/change/` + payload.ID, requestOptions).then(handleResponse);
}

function updatePositionByID(payload) {
    console.log(authHeader())
     // alert("Service.js " + JSON.stringify(payload))
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/invoice/positions/updatePositionByID/` ,requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}

