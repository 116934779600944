import {invoiceService} from '../services';

export const invoices = {
    namespaced: true,
    state: {
        all: {},
        selected: null,
        invoiceRunForListDTO: {},
        invoiceRunID: Number,
        invoiceInformationForListDTO: {},
        invoiceInformationToEditDTO: {},
        invoicePositionToEditDTO: {}
    },
    getters:{
        getinvoicePositionToEditDTO: state =>{
            return state.invoicePositionToEditDTO.items
        },
        getinvoiceInformationToEditDTO: state =>{
            return state.invoiceInformationToEditDTO.item
        },
        getinvoiceInformationForListDTO: state =>{
            return state.invoiceInformationForListDTO.items
        },
        getInvoiceRunID: state => {
            return state.invoiceRunID
        },
        getInvoiceRunForListDTO: state =>{
            return state.invoiceRunForListDTO.items
        }
    },
    actions: {
        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                invoiceService.getAll()
                    .then(
                        payload => {
                            commit('getAllSuccess', payload)
                            resolve(payload)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        getInvoice({
            commit
        }, id) {
            commit('getRequest');
            return new Promise((resolve, reject) => {
                invoiceService.getById(id)
                    .then(
                        payload => {
                            commit('getSuccess', payload)
                            resolve(payload)
                        },
                        error => {
                            commit('getFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        downloadFile({commit}, id){
            return new Promise((resolve, reject) => {
                invoiceService.downloadFile(id).then(
                    response => {
                        resolve(response)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },

        delete({
            commit
        }, id) {
            commit('deleteRequest');
            console.log(id);
            return new Promise((resolve, reject) => {
                invoiceService.deleteById(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        accept({
            commit
        }, id) {
            commit('deleteRequest');
            console.log(id);
            return new Promise((resolve, reject) => {
                invoiceService.accept(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        recreate({
            commit
        }, id) {
            commit('deleteRequest');
            console.log(id);
            return new Promise((resolve, reject) => {
                invoiceService.recreate(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        createTemplate({
            commit
        }, data) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                invoiceService.createTemplate(data)
                    .then(
                        data => {
                            commit('newSuccess', data);
                            resolve(data)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updateInvoice({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                invoiceService.updateInvoice(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        createInvoiceRun({commit}, createInvoiceRunDTO){
            return new Promise((resolve, reject) => {
                invoiceService.createInvoiceRun(createInvoiceRunDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        getInvoiceRunsForTable({commit}){
            return new Promise((resolve, reject) => {
                invoiceService.getInvoiceRunsForTable().then(
                    payload => {
                        commit('getInvoiceRunsForTableSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        getAllInvoiceInformationsForListByInvoiceRunID({commit}, invoiceGoRunID){
            return new Promise((resolve, reject) => {
                invoiceService.getAllInvoiceInformationsForListByInvoiceRunID(invoiceGoRunID).then(
                    payload => {
                        commit('getAllInvoiceInformationsForListByInvoiceRunIDSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        getInvoiceInformationToEditDTOByInvoiceGoID({commit}, invoiceGoID){
            return new Promise((resolve, reject) => {
                invoiceService.getInvoiceInformationToEditDTOByInvoiceGoID(invoiceGoID).then(
                    payload => {
                        commit('getInvoiceInformationToEditDTOByInvoiceGoIDSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        getInvoicePositionToEditDTOByInvoiceGoID({commit}, invoiceGoID){
            return new Promise((resolve, reject) => {
                invoiceService.getInvoicePositionToEditDTOByInvoiceGoID(invoiceGoID).then(
                    payload => {
                        commit('getInvoicePositionToEditDTOByInvoiceGoIDSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        approveAllInvoicesInInvoiceRunByInvoiceGoRunID({commit}, approveInvoicesDTO){
            return new Promise((resolve, reject) => {
                invoiceService.approveAllInvoicesInInvoiceRunByInvoiceGoRunID(approveInvoicesDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        deleteInvoiceRun({commit}, invoiceGoRunID){
            return new Promise((resolve, reject) => {
                invoiceService.deleteInvoiceRun(invoiceGoRunID).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        approveOneInvoiceByInvoiceGoID({commit}, ApproveOneInvoiceDTO){
            return new Promise((resolve, reject) => {
                invoiceService.approveOneInvoiceByInvoiceGoID(ApproveOneInvoiceDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        StornoInvoiceByInvoiceGoID({commit}, ApproveOneInvoiceDTO){
            return new Promise((resolve, reject) => {
                invoiceService.StornoInvoiceByInvoiceGoID(ApproveOneInvoiceDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },

        CreateNewInvoiceFromStorno({commit}, ApproveOneInvoiceDTO){
            return new Promise((resolve, reject) => {
                invoiceService.CreateNewInvoiceFromStorno(ApproveOneInvoiceDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },

        disapproveOneInvoiceByInvoiceGoID({commit}, ApproveOneInvoiceDTO){
            return new Promise((resolve, reject) => {
                invoiceService.disapproveOneInvoiceByInvoiceGoID(ApproveOneInvoiceDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },

        changeInvoiceInformationsByInvoiceID({commit}, invoiceInformationToEditDTO){
            return new Promise((resolve, reject) => {
                invoiceService.changeInvoiceInformationsByInvoiceID(invoiceInformationToEditDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
    },
    mutations: {
        getInvoicePositionToEditDTOByInvoiceGoIDSuccess(state, payload){
            state.invoicePositionToEditDTO = {items: payload}
        },
        getInvoiceInformationToEditDTOByInvoiceGoIDSuccess(state, payload){
            state.invoiceInformationToEditDTO = {item: payload}
        },
        getAllInvoiceInformationsForListByInvoiceRunIDSuccess(state, payload){
            state.invoiceInformationForListDTO = {items: payload}
        },
        setInvoiceRunID(state, id){
            state.invoiceRunID = id
        },
        getInvoiceRunsForTableSuccess(state, payload){
            state.invoiceRunForListDTO = {items: payload}
        },
        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },

        deleteRequest(state) {
            state.delete = {
                loading: true
            }; //Wird geladen status
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Objekt das vom Server zurückgegeben wird
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },

        getRequest(state) {
            state.selected = {
                loading: true
            };
        },
        getSuccess(state, data) {
            state.selected = {
                item: data
            };
        },
        getFailure(state, error) {
            state.selected = {
                error
            };
        },
        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, data) {
            state.all = {
                items: data
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        }
    }
}