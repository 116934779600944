<template>
  <main>
    <v-card-title class="Klienten">
      <v-icon class="Klienten">mdi-account-multiple</v-icon>
      <h5>KLIENTEN</h5>
    </v-card-title>
    <v-card-subtitle> Gesamt</v-card-subtitle>
    <v-container fluid>
      <div style="margin: 0px 5px 10px 5px; padding: 10px;background-color: #ffd3d3; color:black">
        <v-row>
          <v-col></v-col>
          <v-col class="rowTitle">Aktiv</v-col>
          <v-col class="rowTitle">Interessent</v-col>
        </v-row>
        <v-row class="customSumAUS">
          <v-col>AUS</v-col>
          <v-col>{{ sumAUSActive }}</v-col>
          <v-col>{{ sumAUSInterested }}</v-col>
        </v-row>
        <v-row class="customSumHN">
          <v-col>HN</v-col>
          <v-col>{{ sumHNActive }}</v-col>
          <v-col>{{ sumHNInterested }}</v-col>
        </v-row>
        <v-row class="customSumSUM">
          <v-col>∑</v-col>
          <v-col>{{ SumActive }}</v-col>
          <v-col>{{ SumInterested }}</v-col>
        </v-row>
      </div>
    </v-container>
  </main>
</template>

<script>
export default {
  name: "DashboardClients",
  data() {
    return {
      sumAUSActive: 84,
      sumAUSInterested: 38,
      sumHNActive: 18,
      sumHNInterested: 4,
    };
  },
  computed: {
    SumActive() {
      return (this.sumAUSActive + this.sumHNActive);
    },
    SumInterested() {
      return (this.sumAUSInterested + this.sumHNInterested);
    }
  }
}
</script>

<style>
.rowTitle {
  font-weight: bold;
}
</style>