<template>
  <div>
    <h3>{{ insurance.item.InsuranceName }}</h3>
    <div class="row">
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <v-text-field
                label="Name"
                placeholder="-"
                hide-details="auto"
                tabindex="1"
                v-model="insurance.item.InsuranceName"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
                label="Debitoren-Nr."
                readonly
                hide-details="auto"
                tabindex="1"
                v-model="insurance.item.DebitorenNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-checkbox
                v-model="insurance.item.isCareHealthInsurance"
                :label="`Pflegekasse`"
                tabindex="12"
            ></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-checkbox
                class="ml-8"
                v-model="insurance.item.isNormalInsurance"
                :label="`Krankenkasse`"
                tabindex="12"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-btn
                @click="Save"
                color="primary"
                elevation="2"
                style="float: right"
                class="mt-3 mr-5"
            ><i class="fas fa-save"></i> Speichern
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3>Adresse</h3>
            <div v-for="a in insurance.item.Address" v-bind:key="a.ID">
              <InsuranceAddress
                  :address-id="a.ID"
                  :street="a.Street"
                  :plz="a.PLZ"
                  :city="a.City"
                  :patient-id="insurance.item.ID"
                  :address-count="insurance.item.Address.length"
              ></InsuranceAddress>
              <!--              <v-divider inset></v-divider>-->
            </div>
            <v-btn v-if="addressCountOK" @click="NewAddress" color="primary" class="mt-3"
            ><i class="fas fa-plus"></i> Neue Adresse hinzufügen
            </v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="mb-3">Bankdaten und Abrechnung</h3>
            <template v-if="insurance.item.Bankaccount != null">
              <template v-if="insurance.item.Bankaccount.length > 0">
                <div v-for="b in insurance.item.Bankaccount" v-bind:key="b.ID">
                  <InsuranceBank
                      :bankaccount="b"
                      :insurance-object="insurance.item"
                  ></InsuranceBank>
                </div>
              </template>
            </template>
            <template v-if="insurance.item.Bankaccount == null">
              <!--              <v-divider inset></v-divider>-->
              <v-btn @click="NewBankaccount" color="primary">
                <i class="fas fa-plus"></i>Neue Bankverbindung hinzufügen
              </v-btn>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3>Kontakte</h3>
            <template v-if="insurance.item.ContactPerson != null">
              <template v-for="c in insurance.item.ContactPerson">
                <InsuranceContact
                    :contact-title="c.Title"
                    :contact-first-name="c.FirstName"
                    :contact-last-name="c.LastName"
                    :contact-email="c.Email"
                    :contact-phone="c.Phone"
                    :contact-id="c.ID"
                    :contact-position="c.Position"
                    :contact-department="c.Department"
                    :contact-note="c.Note"
                    :contact-street="c.Street"
                    :contact-plz="c.PLZ"
                    :contact-city="c.City"
                    :patient-id="insurance.item.ID"
                    :key="c.ID"
                >
                </InsuranceContact>
              </template>
            </template>
            <v-btn color="primary" class="mb-3 mt-3" @click="NewContact()"
            ><i class="fas fa-plus"></i> Neuer Kontakt
            </v-btn>
          </v-col>
        </v-row>

        <v-btn @click="ShowDeleteDialog()" class="deletecolor" elevator="2" style="float: right"
        ><i class="fas fa-trash"></i>Löschen
        </v-btn
        >
      </v-col>
    </div>
    <v-row class="ml-0">
      <v-dialog
          v-model="deletebuttondialog"
          persistent max-width="960px"
      >
        <DeleteButtonDialog
            :itemname="insurance.item.InsuranceName"
            :text="deletewarningtext"
            @CloseAndDelete="DeleteItemAfterDialog"
        />
      </v-dialog>
    </v-row>
  </div>
</template>


<script>
import InsuranceAddress from "../../components/insurances/InsuranceAddress";
import InsuranceBank from "../../components/insurances/InsuranceBankaccount";
import InsuranceContact from "../../components/insurances/InsuranceContact";
import DeleteButtonDialog from "../../components/dialogs/DeleteButtonDialog"

export default {
  components: {
    InsuranceAddress,
    InsuranceBank,
    InsuranceContact,
    DeleteButtonDialog
  },
  created() {
    this.reload();
  },
  destroyed() {
  },
  data() {
    return {
      deletewarningtext: ""
    }
  },
  computed: {
    insurance() {
      return this.$store.state.insurances.selected_insurance;
    },
    insuranceData() {
      return this.$store.state.insurances.all;
    },
    id() {
      return this.$route.params.id;
    },
    deletebuttondialog() {
      return this.$store.getters['button/getdeletebuttonmodaldialog']
    },
    addressCountOK() {
      return this.insurance.item.Address === undefined
    }
  },
  mounted() {
    this.$root.$on("reload_insurance_details", () => {
      this.reload();
    });
  },
  methods: {
    DeleteItemAfterDialog() {
      this.$store
          .dispatch("insurances/deleteInsuranceById", this.insurance.item.ID)
          .then(() => {
            this.$root.$emit("reload_insurance_list");
            window.location.assign("/insurances");
          });
      this.deletewarningtext = ""
    },
    ShowDeleteDialog() {
      this.deletewarningtext = "Möchten Sie Kasse " +
          this.insurance.item.InsuranceName +
          " wirklich löschen? Alle Klienten welche diese Kasse nutzen sind dann nicht mehr verlinkt. Dies hat Auswirkungen auf die Abrechnung!"
      this.$store.commit('button/changeDeleteButtonModalDialogToTrue')
    },
    NewContact() {
      let contact = {
        Title: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Position: "",
        Department: "",
        Note: "",
        InsuranceID: this.insurance.item.ID,
        DebitorenNumber: undefined
      };

      this.$store
          .dispatch("insurances/newContact", contact)
          .then((response) => {
            console.log(response);
            this.reload();
          });
    },
    NewBankaccount() {
      let bankaccount = {
        BankName: "",
        KTN: "",
        BIC: "",
        IBAN: "",
        InsuranceID: this.insurance.item.ID,
      };
      this.$store
          .dispatch("insurances/newBankaccount", bankaccount)
          .then((response) => {
            console.log(response);
            this.reload();
          });
    },
    NewAddress() {
      let address = {
        Street: "",
        PLZ: "",
        City: "",
        InsuranceID: this.insurance.item.ID,
      };
      this.$store
          .dispatch("insurances/newAddress", address)
          .then((response) => {
            console.log(response);
            this.reload();
          });
    },
    Save() {
      this.submitted = true;

      let submit_insurance = {
        ID: this.insurance.item.ID,
        InsuranceName: this.insurance.item.InsuranceName,
        IsCareHealthInsurance: this.insurance.item.isCareHealthInsurance,
        IsNormalInsurance: this.insurance.item.isNormalInsurance,
        IK: this.insurance.item.IK,
        DebitorenNumber: parseInt(this.insurance.item.DebitorenNumber)
      };

      console.log(submit_insurance);
      this.$store
          .dispatch("insurances/updateInsurance", submit_insurance)
          .then((response) => {
            console.log(response.ID);
            this.$root.$emit("reload_insurance_list");
            this.$root.$emit("reload_insurance_details");
          });
    },
    submit() {
      console.log(this.user);
    },
    reload() {
      if (this.id) {
        this.$store
            .dispatch("insurances/getInsurance", this.id)
            .then((response) => {
              console.log(response);
            });
      }
    },
  },
};
</script>

<style scoped>
label.disabled {
  opacity: 0.5;
}

.densecol {
  padding-left: 0px !important;
}

i {
  margin-right: 10px;
}

svg {
  margin-right: 10px;
}
</style>
