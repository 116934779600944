<template>
  <div class="densecol">
    <v-form ref="form" :disabled="formdisabled">
      <v-container fluid>
        <v-card>
          <v-toolbar
              flat
              height="50px"
              color="primary"
              dark
          >
            <v-toolbar-title style="font-size: 1.1em; font-weight: bold">
              <v-icon>mdi-clipboard-edit-outline</v-icon>
              {{ selectedInvoiceType }}
            </v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
            <v-tab
                @click="selectInvoiceType(1)"
                :disabled="tabdisabled">
              <v-icon left>
                mdi-cash-lock-open
              </v-icon>
              ABRECHNUNG HN
            </v-tab>
            <v-tab-item>
              <v-card flat>
                <InvoiceHN style="background-color: #fff7f7"
                           ref="invoiceHN"
                           :key="InvoiceHNkey"
                           :patientID="patientID"
                ></InvoiceHN>
              </v-card>
            </v-tab-item>
            <v-tab
                @click="selectInvoiceType(2)"
                :disabled="tabdisabled">
              <v-icon left>
                mdi-cash-lock-open
              </v-icon>
              ABRECHNUNG AUS
            </v-tab>
            <v-tab-item>
              <v-card flat>
                <InvoiceAUS style="background-color: #fff7f7"
                            ref="invoiceAUS"
                            :key="InvoiceAUSkey"
                            :patientID="patientID"
                ></InvoiceAUS>
              </v-card>
            </v-tab-item>
            <v-tab
                @click="selectInvoiceType(3)"
                :disabled="tabdisabled">
              <v-icon left>
                mdi-cash-lock
              </v-icon>
              ABRECHNUNG PRIVAT
            </v-tab>
            <v-tab-item>
              <InvoicePrivate style="background-color: #fff7f7"
                              ref="invoicePrivate"
                              :key="InvoicePrivatekey"
                              :patientID="patientID"
                              :formdisabled="formdisabled"
              ></InvoicePrivate>
            </v-tab-item>
            <v-tab
                @click="selectInvoiceType(4)"
                :disabled="tabdisabled">
              <v-icon left>
                mdi-cash-lock
              </v-icon>
              EXPORT
            </v-tab>
            <v-tab-item>
              <ExportPerformance style="background-color: #fff7f7"
                              ref="exportPerformance"
                              :key="ExportPerformancekey"
                              :patientID="patientID"
              ></ExportPerformance>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import InvoiceHN from "@/components/invoices/InvoiceHN";
import InvoiceAUS from "@/components/invoices/InvoiceAUS";
import InvoicePrivate from "@/components/invoices/InvoicePrivate";
import ExportPerformance from "@/components/invoices/ExportPerformance";

export default {
  name: "InvoiceData",
  components: {
    InvoiceHN,
    InvoiceAUS,
    InvoicePrivate,
    ExportPerformance
  },
  props: {
    patientID: Number,
    formdisabled: Boolean,
    tabdisabled: Boolean
  },
  invoiceType: "",
  data() {
    return {
      selectedInvoiceType: "ABRECHNUNG (HN)",
      InvoiceHNkey: 0,
      InvoiceAUSkey: 0,
      InvoicePrivatekey: 0,
      ExportPerformancekey: 0
    };
  },
  methods: {
    selectInvoiceType(invoiceType) {
      if (invoiceType === 1) {
        this.InvoiceHNkey++
        this.selectedInvoiceType = "Abrechnung (HN)";
        this.$emit("invoiceChoice", "HN")
      } else if (invoiceType === 2) {
        this.InvoiceAUSkey++
        this.selectedInvoiceType = "Abrechnung (AUS)";
        this.$emit("invoiceChoice", "AUS")
      } else if (invoiceType === 3){
        this.InvoicePrivatekey++
        this.selectedInvoiceType = "Abrechnung (Privat)";
        this.$emit("invoiceChoice", "Private")
      } else {
        this.ExportPerformancekey++
        this.selectedInvoiceType = "Export";
      }
    },
    ResetForm() {
      this.$store.commit('invoicePatient/emptyServiceArticleToShowDTO')
      this.$refs.form.reset()
    },
  }
}
</script>

<style scoped>

</style>