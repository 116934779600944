<template>
    <main>
        <div class="denscol">
            <h3 class="mb-1">Export der geleistete Stunden/Leistungen</h3>
            <v-card class="mb-2" rounded style="background-color: snow ">
                <v-row class="mt-0 mb-4 mr-3 ml-3">
                    <v-col>
                        <DatePickerRange
                            :label="label"
                            @SendDates="GetDates"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn
                        color="primary"
                        class="mt-0 mb-4 ml-6"
                        @click="ExportData()"
                    >
                        Exportieren
                    </v-btn>
                </v-row>
            </v-card>
        </div>
    </main>
</template>
<script>

import DatePickerRange from '@/components/utils/DatePickerRange.vue'
export default {
    components: {
        DatePickerRange
    },
    props:{
        patientID: Number
    },
    data() {
        return {
            label: "Zeitraum des Exports auswählen",
            timestampZero: undefined,
            timestampOne: undefined,
            timestampBeginPeriod: undefined,
            timestampEndPeriod: undefined
        }
    },
    methods: {
        GetDates(dates){
            console.log(dates)
            this.timestampZero = new Date(dates[0]).getTime()
            this.timestampOne = new Date(dates[1]).getTime()
            if (this.timestampZero <= this.timestampOne) {
                this.timestampBeginPeriod = this.timestampZero
                this.timestampEndPeriod = this.SetTimestampToEndofDay(this.timestampOne)
            } else {
                this.timestampBeginPeriod = this.timestampOne
                this.timestampEndPeriod = this.SetTimestampToEndofDay(this.timestampZero)
            }
        },
        SetTimestampToEndofDay(timestamp){
            let date = new Date(timestamp)
            let dateEndOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            let timestampEndOfDay = dateEndOfDay.getTime()
            return timestampEndOfDay
        },
        async ExportData(){
            console.log("timestampBeginPeriod: " + this.timestampBeginPeriod)
            console.log("timestampEndPeriod: " + this.timestampEndPeriod)
            if(isNaN(this.timestampBeginPeriod) || isNaN(this.timestampEndPeriod)){
                confirm("Es muss ein Zeitraum ausgewählt sein")
                return 
            }
            let data = {
                timestampBeginPeriod: this.timestampBeginPeriod,
                timestampEndPeriod: this.timestampEndPeriod,
                patientID: this.patientID
            }
            console.log(data)
            try{
                let fileinformation = await this.$store.dispatch('file/downloadExportPerformance', data)
                var FileSaver = require('file-saver');
                FileSaver.saveAs(fileinformation.fileblob, fileinformation.filename)
            } catch(error){
                confirm(error)
            }
        }
    }
}
</script>