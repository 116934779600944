import {
    patientService
} from '../services';

export const patients = {
    namespaced: true,
    state: {
        all: {},
        pricesHN: 0,
        pricesAUS: 0,
        pricesPrivate: 0,
        selected_patient: {},
        new: {},
        update: {},
        delete: {},
        devicestable: {},
        patientforinventorydevicemodaldialog:{},
        patientfortable: {},
        loading: true,
        patientforevent: {}
    },
    getters:{
        getPatientForEvent: state => {
            return state.patientforevent.item
        },
        getPatientforinventorydevicemodaldialog: state => {
            return state.patientforinventorydevicemodaldialog
        },
        getPatientForTables: state => {
            return state.patientfortable.items
        },
        getPatientLoading: state => {
            return state.loading
        }
    },
    actions: {

        DeleteFile({commit}, DeleteFile){
            return new Promise((resolve, reject) => {
                patientService.DeleteFile(DeleteFile).then(
                    response =>{
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },

        uploadFile({commit}, fileupload){
            return new Promise((resolve, reject) => {
                patientService.uploadFile(fileupload.ID, fileupload.file).then(
                    response =>{
                        //commit('employeeDataSuccess', response)
                        resolve(response)
                    },
                    error =>{
                        commit("getFailure", error);
                        reject(error)
                    }
                )
            })
        },
        downloadFile({commit}, fileID){
            return new Promise((resolve, reject) => {
                patientService.downloadFile(fileID).then(
                    response => {
                        resolve(response)
                    },
                    error =>{
                        commit("getFailure", error)
                        reject(error)
                    }
                )
            })
        },
        getFiles({commit}, id){
            return new Promise((resolve, reject) => {
                patientService.getFiles(id).then(
                    response => {
                        commit('getFilesSuccess', response)
                        resolve(response)
                    },
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getPatientForEventDialogByID({commit}, patientID){
            return new Promise((resolve, reject) => {
                patientService.getPatientForEventDialogByID(patientID)
                    .then(
                        payload =>{
                            commit('getPatientForEventDialogByIDSuccess', payload)
                            resolve(payload)
                        },
                        error =>{
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    )
            })
        },
        getPatientsForTable({commit}){
            return new Promise((resolve, reject) => {
                patientService.getPatientsForTable()
                    .then(
                        payload =>{
                            commit('getPatientsForTableSuccess', payload)
                            resolve(payload)
                            commit('setLoadingFalse')
                        },
                        error =>{
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    )
            })
        },
        reset({
            commit
        }) {
            commit('patientsReset');
        },
        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                patientService.getAll()
                    .then(
                        patients => {
                            commit('getAllSuccess', patients)
                            resolve(patients)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        servicepriceHNsums({
            commit
        }, id) {
            commit('getPatientHNSumsRequest');

            patientService.servicepriceHNsums(id)
                .then(
                    payload => commit('getPatientHNSumsSuccess', payload),
                    error => commit('getPatientHNSumsFailure', error)
                );
        },


        servicepriceAUSsums({
            commit
        }, id) {
            commit('getPatientAUSSumsRequest');

            patientService.servicepriceAUSsums(id)
                .then(
                    payload => commit('getPatientAUSSumsSuccess', payload),
                    error => commit('getPatientAUSSumsFailure', error)
                );
        },

        servicepricePrivatesums({
            commit
        }, id) {
            commit('getPatientPrivateSumsRequest');

            patientService.servicepricePrivatesums(id)
                .then(
                    payload => commit('getPatientPrivateSumsSuccess', payload),
                    error => commit('getPatientPrivateSumsFailure', error)
                );
        },

        getPatient({
            commit
        }, id) {
            commit('getPatientRequest');

            patientService.getPatient(id)
                .then(
                    patient => commit('getPatientSuccess', patient),
                    error => commit('getPatientFailure', error)
                );
        },
        newPatient({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                patientService.newPatient(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updatePatient({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updatePatient(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        unlock({ //Sperrt in der Datenbank. Entsperrt für das Frontend
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.unlock(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        lock({ //Entsperrt in der Datenbank. Sperrt für das Frontend
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.lock(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updatePatientServiceInfo({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updatePatientServiceInfo(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        

        deletePatientById({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deletePatientById(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deletePaymentAddress({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deletePaymentAddress(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        

        newAddress({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                patientService.newAddress(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        updateAddress({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updateAddress(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        deleteAddress({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deleteAddress(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        newBankaccount({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                patientService.newBankaccount(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        updateBankaccount({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updateBankaccount(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        deleteBankaccount({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deleteBankaccountById(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },


        updatePatientPaymentAddress({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updatePatientPaymentAddress(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deletePatientPaymentAddress({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deletePatientPaymentAddress(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        newContact({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                patientService.newContact(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        
        newContactAddress({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                patientService.newContactAddress(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },


        updateContactAddress({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updateContactAddress(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updateContact({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updateContact(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        changeTemplate({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.changeTemplate(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },




        deleteContactAddress({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deleteContactAddress(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deleteContact({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deleteContact(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        newMedication({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                patientService.newMedication(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updateMedication({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                patientService.updateMedication(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        deleteMedication({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                patientService.deleteMedication(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        getAllPatientDevicesTable({commit}){
            commit('newRequest')
            return new Promise((resolve, reject) =>{
                patientService.getAllPatientDevicesTable().then(
                    payload => {
                        commit('getAllPatientDevicesTableSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getAllFailure', error)
                        reject(error)
                    }
                )    
            })
        },
        getPatientForInventoryDeviceModalDialog({commit}, id) {
            return new Promise((resolve, reject) =>{
                patientService.getPatient(id).then(
                    payload =>{
                        console.log(payload)
                        commit('getPatientForInventoryDeviceModalDialog', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getAllFailure', error)
                        reject(error)
                    }
                )
            })

            /*commit('getPatientRequest');

            patientService.getPatient(id)
                .then(
                    patient => commit('getPatientSuccess', patient),
                    error => commit('getPatientFailure', error)
                );*/
        },
    },
    mutations: {
        getPatientForEventDialogByIDSuccess(state, payload){
            state.patientforevent = {item: payload}
        }, 
        setLoadingTrue(state){
            state.loading = true
        },
        setLoadingFalse(state){
            state.loading = false
        },
        getPatientsForTableSuccess(state, payload){
            state.patientfortable = {items: payload}
        },
        getPatientForInventoryDeviceModalDialog(state, payload){
            state.patientforinventorydevicemodaldialog = {item: payload}
        },
        getAllPatientDevicesTableSuccess(state, payload){
            state.devicestable = {items: payload}
        },
        deleteRequest(state) {
            state.delete = {
                loading: true
            }; 
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; 
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; 
        },

        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },

        patientsReset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },
        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },

        getPatientRequest(state) {
            state.selected_patient = {
                loading: true
            };
        },
        getPatientSuccess(state, patient) {
            state.selected_patient = {
                item: patient,
                loading: false
            };
        },
        getPatientFailure(state, error) {
            state.selected_patient = {
                error,
                loading: false
            };
        },

        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, patients) {
            state.all = {
                items: patients
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        },
        getPatientHNSumsRequest(state) {
            state.priceHN = 0;
        },
        getPatientHNSumsSuccess(state, payload) {
            state.priceHN = payload;
        },
        getPatientHNSumsFailure(state, error) {
            state.priceHN = 0;
            console.log(error);
        },
        getPatientAUSSumsRequest(state) {
            state.priceAUS = 0;
        },
        getPatientAUSSumsSuccess(state, payload) {
            state.priceAUS = payload;
        },
        getPatientAUSSumsFailure(state, error) {
            state.priceAUS = 0;
            console.log(error);
        },
        getPatientPrivateSumsRequest(state) {
            state.pricePrivate = 0;
        },
        getPatientPrivateSumsSuccess(state, payload) {
            state.pricePrivate = payload;
        },
        getPatientPrivateSumsFailure(state, error) {
            state.pricePrivate = 0;
            console.log(error);
        },
        getFilesSuccess(state, file){
            state.files = {items: file}
        },
    }
}