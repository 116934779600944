import { authHeader} from '../helpers';
import {networkUtils} from '../utils';




export const noteService = {
    createNote,
    getNotesReferencedEmployeeByEmployeeID,
    getNotesReferencedPatientByEmployeeID,
    deleteNoteByID,
    changeNote
}

async function changeNote(note){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(note)
    }
    return await networkUtils.httpRequest('/note/change', requestOptions)
}
 
async function deleteNoteByID(noteID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    }
    return await networkUtils.httpRequest('/note/delete/id/' + noteID, requestOptions)
}

async function createNote(note){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(note)
    };
    return await networkUtils.httpRequest('/note/create', requestOptions)
}

async function getNotesReferencedEmployeeByEmployeeID(referenceEmployeeID){
    console.log('test')
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return await networkUtils.httpRequest('/note/get/employee/' + referenceEmployeeID, requestOptions)
}

async function getNotesReferencedPatientByEmployeeID(referencePatientID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return await networkUtils.httpRequest('/note/get/patient/' + referencePatientID, requestOptions)
}