import {invoicePositionService} from '../services';

export const invoicePositions = {
    namespaced: true,
    state: {
        all: {},
        selected: null
    },
    actions: {

        delete({commit}, id) {
            commit('deleteRequest');
            console.log(id);
            return new Promise((resolve, reject) => {
                invoicePositionService.deleteById(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })
        },

        DeletePositionByID({commit}, id) {
            commit('deleteRequest');
            console.log(id);
            return new Promise((resolve, reject) => {
                invoicePositionService.DeletePositionByID(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })
        },

        create({commit}, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                invoicePositionService.create(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },
        createNewPosition({commit}, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                invoicePositionService.createNewPosition(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })
        },

        update({commit}, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                invoicePositionService.update(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })
        },

        updatePositionByID({commit}, payload) {
             // alert("store" + JSON.stringify(payload))
            commit('updateRequest');
            console.log(payload);
            return new Promise((resolve, reject) => {
                invoicePositionService.updatePositionByID(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

    },
    mutations: {
        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            };
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            };
        },

        deleteRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        deleteSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            };
        },
        deleteFailure(state, error) {
            state.update = {
                error,
                loading: false
            };
        },

        newRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.update = {
                error,
                loading: false
            };
        },

        getRequest(state) {
            state.selected = {
                loading: true
            };
        },
        getSuccess(state, data) {
            state.selected = {
                item: data
            };
        },
        getFailure(state, error) {
            state.selected = {
                error
            };
        },
        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, data) {
            state.all = {
                items: data
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        }
    }
}