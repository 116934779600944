<template>
  <main>
    <div class="denscol">
      <v-container fluid>
        <v-col>
          <v-card color="#006ab5" dark class="privatStyle">
            PRIVAT
          </v-card>
          <v-row
              class="d-flex justify-end">
            <v-col>
              <v-text-field
                  label="Klient-ID:"
                  placeholder="Klient-ID"
                  hide-details="auto"
                  v-model="KlientID"
              ></v-text-field>
            </v-col>
            <v-col cols="3"
                   class="mb-0"
                   style="width: 200px">
              <v-text-field
                  class="centered-input"
                  append-icon="mdi-currency-eur"
                  label="Guthaben:"
                  value="0.00"
                  v-model="privatePrepaid"
              ></v-text-field>
            </v-col>
          </v-row>
<!--          <v-checkbox-->
<!--              :label="`Rechnungsempfänger entspricht nicht Leistungsempfänger`"-->
<!--              @click="setAlternatePaymentAddress"-->
<!--          ></v-checkbox>-->

          <v-card rounded style="background-color: snow ">
            <InvoicingTemplate
                ref="invoicingTemplatePrivate"
                :patientID="patientID"
                :Private="true"
            ></InvoicingTemplate>
          </v-card>
          <v-card class="mt-2" rounded style="background-color: snow ">
            <InvoiceAssignedBenefits
                :patientID="patientID"
                :Private="true"
            ></InvoiceAssignedBenefits>
          </v-card>
          <base-card>
            <PatientPaymentAddress
                ref="alternatepaymentaddress"
                :titles-pool="titlesPool"
                :formdisabled="formdisabled"
                :patientID="patientID">
            </PatientPaymentAddress>
          </base-card>
        </v-col>
      </v-container>
    </div>
  </main>
</template>

<script>
import InvoicingTemplate from "../../components/invoices/InvoicingTemplate"
import InvoiceAssignedBenefits from "../../components/invoices/InvoiceAssignedBenefits";
import PatientPaymentAddress from "../../components/patients/PatientPaymentAddress";
import BaseCard from "../Base/BaseCard";

export default {
  name: "InvoiceKasse",
  components: {
    BaseCard,
    PatientPaymentAddress,
    InvoicingTemplate,
    InvoiceAssignedBenefits
  },
  props: {
    patientID: Number,
    formdisabled: Boolean,
  },
  data() {
    return {
      privatePrepaid: undefined,
      klientID: undefined,
    }
  },
  async created() {
    if (this.patientID != undefined) {
      await this.FillForm()
    }
  },
  computed: {
    patientDataForInvoices() {
      return this.$store.getters['invoicePatient/getPatientDataForInvoiceDTO']
    }
  },
  methods: {
    // setAlternatePaymentAddress() {
    //   this.UseAlternatePaymentAddress = !this.UseAlternatePaymentAddress
    // },
    async FillForm() {
      await this.$store.dispatch('invoicePatient/getPatientDataForInvoiceDTOByPatientID', this.patientID)
      this.privatePrepaid = this.patientDataForInvoices.PrivatePrepaid
      this.KlientID = this.patientDataForInvoices.KlientID
    },
    async SavePrivatePrepaidDTO() {
      let privatePrepaidDTO = {
        PatientID: this.patientID,
        PrivatePrepaid: Number(this.privatePrepaid),
        KlientID: this.KlientID
      }
      await this.$store.dispatch('invoicePatient/savePrivatePrepaidDTO', privatePrepaidDTO)
    }
  }
}
</script>

<style scoped>
.privatStyle {
  height: 35px;
  width: 69px;
  padding: 8px;
}

.centered-input input {
  text-align: right;
  font-size: 1.10em;
  padding: 5px;
}

</style>