<template>
  <main>
    <v-card-title class="contentTitle">
      <v-icon class="Klienten">mdi-account-cash</v-icon>
      <h5>UMSATZ</h5>
    </v-card-title>
    <v-card-subtitle> Gesamt</v-card-subtitle>
    <v-container fluid>

      <v-row style="margin-top: -25px">
        <v-col>
          <v-row style="margin: 0px 5px 5px; padding: 10px; background-color: #ffd3d3; color: black">
            <div class="mt-9 mr-4" style="font-weight: bold">Letzter Rechnungslauf :</div>
            <v-col align="center" class="customSumAUS">
              AUS
              <div align="center">
                {{sumInvoiceAUS }} €
              </div>
            </v-col>

            <v-col align="center" class="customSumHN">
              HN
              <div align="center">
                {{sumInvoiceHN }} €
              </div>
            </v-col>

            <v-col align="center" class="customSumSUM">
              ∑
              <div align="center">
                {{sumInvoiceAUSAndHN }} €
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row style="border: 1px solid snow; margin: -20px 5px 5px; padding: 10px">
            <v-row dense>
              <v-col cols="8">
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="computedDateFormattedMomentjs"
                        label="Monat"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      type="month"
                      locale="de"
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-btn color="warning" class="mt-6" outlined small style="color: white" @click="nextMonth">+</v-btn>
              <v-btn color="warning" class="mt-6 ml-2 mr-2" outlined small @click="prevMonth">-</v-btn>
            </v-row>
            <v-col align="center" class="customAUS">
              AUS
              <div align="center">
                {{monthlyInvoiceAUS }} €
              </div>
            </v-col>

            <v-col align="center" class="customHN">
              HN
              <div align="center">
                {{monthlyInvoiceHN}} €
              </div>
            </v-col>

            <v-col align="center" class="customSUM">
              ∑
              <div align="center">
                {{ MonthlySumAUSAndHN }} €
              </div>
            </v-col>

          </v-row>
        </v-col>


      </v-row>

    </v-container>
  </main>
</template>

<script>
import moment from 'moment'

export default {
  name: "DashboardSales",
  props: {
    sumInvoiceAUS : {
      type: Float32Array,
      default: 5432.35,
    },
    sumInvoiceHN: {
      type: Float32Array,
      default: 1673.83,
    },
    monthlyInvoiceAUS:{
      type: Float32Array,
      default: 789.34,
    },
    monthlyInvoiceHN:{
      type: Float32Array,
      default: 223.13,
    },

  },
  computed: {
    computedDateFormattedMomentjs() {
      moment.locale("de");
      return this.date ? moment(this.date).format('MMMM  YYYY') : '';
    },
    MonthlySumAUSAndHN(){
      return   (this.monthlyInvoiceAUS + this.monthlyInvoiceHN).toFixed(2)
    }
  },

  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      sumInvoiceAUSAndHN: (this.sumInvoiceAUS + this.sumInvoiceHN).toFixed(2)

    }
  },
  methods: {
    nextMonth() {
      this.date = moment(this.date).add(1, 'months');

    },
    prevMonth() {
      this.date = moment(this.date).add(-1, 'months');
    },
  }
}
</script>

<style scoped>


h2 {
  color: #951b81 !important;
}



</style>