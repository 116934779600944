import { render, staticRenderFns } from "./InvoicingTemplate.vue?vue&type=template&id=718ae966&scoped=true&"
import script from "./InvoicingTemplate.vue?vue&type=script&lang=js&"
export * from "./InvoicingTemplate.vue?vue&type=script&lang=js&"
import style0 from "./InvoicingTemplate.vue?vue&type=style&index=0&id=718ae966&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718ae966",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCol,VContainer,VRow,VSelect})
