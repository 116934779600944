<template>
  <v-container>
    <v-row style="margin-top: -26px">
      <v-col>
        <v-file-input
            full-width
            v-model="files"
            color="deep-purple accent-4"
            counter
            label="Dateien....."
            multiple
            placeholder="Hier klicken um Dateien auszuwählen"
            prepend-icon="mdi-paperclip"
            outlined
            dense
            :show-size="1000">
          <template v-slot:selection="{ index, text }">
            <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label
                small>
              {{ text }}
            </v-chip>
            <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2">
                        +{{ files.length - 2 }} File(s)
                    </span>
          </template>
        </v-file-input>
      </v-col>
      <v-btn
          color="info"
          class="mt-3 mr-2"
          @click="uploadFile()">
        <v-icon small>mdi-content-save</v-icon>
        Speichern
      </v-btn>
      <v-btn
          class="mt-3"
          color="info"
          @click="CloseAddFile()">
        <v-icon small>mdi-cancel</v-icon>
        abbrechen
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      files: [],
      overlay: false,
      loading: true,
      errorinfo: false,
      errormessage: ""
    }
  },
  props: {
    patientID: Number
  },
  watch: {
    files: function () {
      this.$emit("sendFiles", this.files)
    }
  },
  methods: {
    async uploadFile() {
      let fileupload = {
        ID: this.patientID,
        file: this.files
      }
      this.overlay = true
      this.loading = true
      await this.TryUpload(fileupload)
    },
    async TryUpload(fileupload) {
      try {
       await this.$store.dispatch('patients/uploadFile', fileupload)
        this.overlay = false
        this.$root.$refs.PatientInputMask.renderFileTable()
        this.$emit("renderFileTable")
        this.loading = false
        this.files = []
      } catch (error) {
        console.log(error)
        this.loading = false
        this.errorinfo = true
        this.errormessage = "Fehler beim Upload der Datei/en"
      }
      this.$root.$refs.KlientTable.interval();
    },
    CloseAddFile() {
      this.$emit("CloseAddFile")
    },
    CloseOverlayAndErrorinfo() {
      this.overlay = false;
      this.erroinfo = false;
      this.files = []
    }
  }
}
</script>