<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Straße"
          hide-details="auto"
          tabindex="3"
          :rules="rules"
          v-model="address.Street"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="row">
      <div class="col-6">
        <v-text-field
          label="PLZ"
          hide-details="auto"
          tabindex="3"
          :rules="plzRules"
          v-model="address.PLZ"
        ></v-text-field>
      </div>
      <div class="col-6">
        <v-text-field
          label="Ort"
          hide-details="auto"
          tabindex="3"
          :rules="rules"
          v-model="address.City"
        ></v-text-field>
        <v-btn
          @click="Delete"
          color="error"
          style="float: right"
          class="mt-3"
          v-show="addressCount <= 1"
        ><i class="fas fa-trash"></i>Löschen</v-btn
        >
        <v-btn @click="Save" color="primary" style="float: right" class="mt-3 mr-3"><i class="fas fa-save"></i> Speichern </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { validatePLZ } from '../../helpers/validator';

export default {
  data() {
    return {
      address: {
        ID: parseInt(this.addressId),
        Street: this.street,
        PLZ: this.plz,
        City: this.city,
        PatientID: parseInt(this.patientId),
      },
      rules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
          (value && value.length >= 2) || "Mindestens 2 Zeichen eingeben",
      ],
      plzRules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) => (value && validatePLZ(value)) || "Keine gültige Postleitzahl",
      ],
    };
  },
  props: {
    addressId: Number,
    street: String,
    plz: String,
    city: String,
    patientId: Number,
    addressCount: Number,
  },
  methods: {
    Save() {
      this.submitted = true;

      console.log(this.address);
      this.$store
        .dispatch("insurances/updateAddress", this.address)
        .then((address_response) => {
          console.log(address_response.ID);
          this.submitted = false;
        });
    },
    Delete() {
      this.$store
        .dispatch("insurances/deleteAddress", this.address)
        .then((address_response) => {
          console.log(address_response.ID);
          this.submitted = false;
          this.$root.$emit("reload_insurance_details");
        });
    },
  },
};
</script>

<style scoped>
i {
  margin-right: 10px;
}
svg {
  margin-right: 10px;
}
</style>