<template>
    <v-simple-table>
        <template v-slot:default>
        <thead>
            <tr>
            <th class="text-left">
                Inventarnummer
            </th>
            <th class="text-left">
                Beschreibung
            </th>
            <th class="text-left">
                löschen
            </th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="item in devicespatient"
                :key="item.ID"
            >
                <td>{{ item.Inventorynumber }}</td>
                <td>{{ item.Description }}</td>
                <td><v-btn
                        @click="DeselectDeviceFromPatient(item.ID)"
                    >
                  <v-icon color="primary" small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    props:{
        devicespatient: {}
    },
    methods:{
        async DeselectDeviceFromPatient(deviceid){
            console.log(deviceid)
            await this.$store.dispatch('inventory/deselectDeviceFromPatient', deviceid)
            this.$emit('renderTable')
        }
    }
}
</script>