<template>
  <main>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="form-group btn-group-left">
            <h2>
              <v-icon large style="color: #006ab5 !important;">mdi-cash-register</v-icon>
              Rechnungsläufe
            </h2>
          </div>
        </v-col>
      </v-row>
      <base-card>
        <InvoiceRunsCompleteList
            :key="InvoiceRunListKey"
            @RenderInvoiceRunList="RenderInvoiceRunList"
        ></InvoiceRunsCompleteList>
      </base-card>
      <v-row>
        <v-col cols="3">
          <v-btn
              color="primary"
              @click="OpenInvoiceRunModalDialog()">
            Rechnungslauf erzeugen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="invoicerunmodaldialog"
        persistent
        max-width="960px">
      <InvoiceRunDialog
          :key="invoiceRunDialogKey"
          @RenderInvoiceRunList="RenderInvoiceRunList"
      />
    </v-dialog>
  </main>
</template>

<script>
import InvoiceRunsCompleteList from "../../components/invoices/InvoiceRunsCompleteList";
import InvoiceRunDialog from '../../components/dialogs/InvoiceRunDialog.vue'
import {dialogmixin} from '../../mixins'
import BaseCard from "../../components/Base/BaseCard";

export default {
  components: {
    BaseCard,
    InvoiceRunsCompleteList,
    InvoiceRunDialog
  },
  mixins: [dialogmixin],
  data() {
    return {
      InvoiceRunListKey: 0,
      invoiceRunDialogKey: 0,
    };
  },
  methods: {
    RenderInvoiceRunList() {
      this.InvoiceRunListKey++
      this.invoiceRunDialogKey++
    },
  },
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  }
};
</script>

<style scoped>
div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab.v-tab--active {
  background-color: #751265 !important;
  color: white !important;
}

div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab.v-tab--active
> a {
  background-color: #751265 !important;
  color: white !important;
}

div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab:hover {
  background-color: #951b81 !important;
  color: white !important;
}

div.subNav.v-sheet.theme--light.elevation-6
> div
> div
> div.v-slide-group__wrapper
> div
> div.v-tab:hover
> a {
  background-color: #951b81 !important;
  color: white !important;
}
</style>
