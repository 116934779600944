<template>
  <div>
    <v-alert
        v-model="alert"
        type="error"
        color="red"
        dismissible>
      {{ alertmessage }}
    </v-alert>
    <row :gutter="12" class="mt-4 mb-1">
      <column :lg="1">
        <v-btn
            icon
            @click="ChangeToPrev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
            icon
            @click="ChangeToNext">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </column>
      <column :lg="2">
        <v-select
            v-model="type"
            :items="types"
            dense
            outlined
            hide-details
            label="Zeitraum"
            @change="SendTypeAndValue()"
        ></v-select>
      </column>
      <column :lg="2">
        <v-toolbar-title class="mt-1 ml-4" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
      </column>
      <column :lg="3">
        <v-select
            v-model="weekday"
            :items="weekdays"
            dense
            outlined
            hide-details
            label="Wochentage"
        ></v-select>
      </column>
      <column :lg="3">
        <v-select
            v-model="dragorshowevent"
            :items="dragorshowevents"
            dense
            outlined
            hide-details
            label="Termine anzeigen oder berarbeiten"
            @change="SendTypeAndValue()"
        ></v-select>
      </column>
      <!--
      <column :lg="1">
        <v-btn
            color="info"
            small
            class="mb-2"
            @click="printCalendar">
          <v-icon class="ml-1">mdi-printer</v-icon>
        </v-btn>
      </column>
      -->
    </row>

    <!--    Print -->
    <v-sheet
        id="print"
        height="600"
        @drop='dropFromTable($event)'
        @dragover.prevent
        @dragenter.prevent>

      <v-calendar
          :now="value"
          :show-intervall-label="value"
          :show-week="true"
          ref="calendar"
          v-model="value"
          color="primary"
          :weekdays="weekday"
          :type="type"
          :events="$cookies.get('role') === 'Admin' || $cookies.get('role') === 'Regular'? eventlist : eventlist.filter(val => val.EmployeeID === parseInt($cookies.get('currentUserID')))"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          :event-ripple="true"
          :locale="'de'"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
          @mousemove:event="getEvent"
          @mouseenter:event="ShowInfoEvent"
          @mouseleave:event="HideInfoEvent"
          @click:event="ShowEvent"
          @click:date="GetDay"
          :interval-count="daylength"
          :first-interval="firstinterval"      >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div
              class="v-event-draggable"
              v-html="eventSummary()"
          ></div>
          <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
          ></div>
        </template>

        <template v-if="type == 'day'" v-slot:day-header="{date}">
          <column align="center">
            <div v-if="window.width < 350" style="color: red; font-size:24px" @click="ShowSnackbar(date)">
              <b>{{ GetFeiertageSymbol(date) }}
              </b></div>
            <div v-else>{{ GetFeiertagByDate(date) }}</div>
          </column>
        </template>

        <template v-else v-slot:day-header="{date}">
          <column align="center">
            <div v-if="window.width < 1200" style="color: red; font-size:24px" @click="ShowSnackbar(date)">
              <b>{{ GetFeiertageSymbol(date) }}
              </b></div>
            <div v-else>{{ GetFeiertagByDate(date) }}</div>
          </column>
        </template>

        <template v-slot:day-label="{date, day}">
          <v-btn
              fab
              small
              :elevation="0"
              :class="value==date ? 'classForButtonclicked' : ''"
              @click="GetValue(date)"
          >{{ day }}
          </v-btn>
          <column>
            <div v-if="window.width < 800" style="color: red; font-size:24px" @click="ShowSnackbar(date)">
              <b>{{ GetFeiertageSymbol(date) }}
              </b></div>
            <div v-else>{{ GetFeiertagByDate(date) }}</div>
          </column>
        </template>
      </v-calendar>
      <v-menu
          v-model="openmenu"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          fixed
          :left="left"
          :right="rigth"      >
        <HoverEventCard
            :eventcolor="GetColorForHoveredEvent(hoveredevent)"
            :eventname="GetNameForHoverdEvent(hoveredevent)"
            :endtime="GetEndtimeForHoveredEvent(hoveredevent)"
            :starttime="GetStarttimeForHoveredEvent(hoveredevent)"
            :eventtype="GetHoveredEventType(hoveredevent)"
        />
      </v-menu>
    </v-sheet>
    <v-dialog
        v-model="eventmodaldialog"
        persistent max-width="1100px"    >
      <EmployeeRosterEventDialog
          :event="selectedEvent"
          :key="employeerostereventdialogkey"
          @CloseAndDelete="deleteEvent"
          @RefreshCalendar="RefreshCalendar"      />
    </v-dialog>
    <v-dialog
        v-model="eventholidaymodaldialog"
        persistent max-width="1100px">
      <EmployeeRosterEventHolidayDialog
          :event="selectedEvent"
          @Delete="deleteEvent"/>
    </v-dialog>

    <template>
      <div class="text-center ma-2">
        <v-snackbar
            v-model="snackbar">
          {{ GetFeiertagsDate }} <br> {{ GetFeiertagsDesc }}
          <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="snackbar = false">
              Schliessen
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>
  </div>
</template>

<script>

import {employeeService} from '../../../services';
import EmployeeRosterEventDialog from "../../dialogs/EmployeeRosterEventDialog"
import EmployeeRosterEventHolidayDialog from "../../dialogs/EmployeeRosterEventHolidayDialog.vue"
import HoverEventCard from "./HoverEventCard.vue"
import moment from "moment";

export default {
  components: {
    EmployeeRosterEventDialog,
    EmployeeRosterEventHolidayDialog,
    HoverEventCard
  },
  props: {
    employeecalendarkey: Number,
    type: String,
    initvalue: String,
    dragorshowevent: String,
    employeeID: Number,
    daylength: Number,
    firstinterval: Number,
  },
  data() {
    return {
      snackbar: false,
      value: "",
      //type: 'month',
      GetFeiertagsDate: "",
      GetFeiertagsDesc: "",
      window: {
        width: 0,
        height: 0
      },
      types: [
        {text: 'Monat', value: 'month'},
        {text: 'Woche', value: 'week'},
        {text: 'Tag', value: 'day'},
      ],
      mode: 'column',//column or stack
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        {text: 'Mo - So', value: [1, 2, 3, 4, 5, 6, 0]},
        {text: 'Mo - Fr', value: [1, 2, 3, 4, 5]},
      ],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      selectedEvent: {},
      selectedOpen: false,
      selectedElement: null,
      mouseoverevent: null,
      mouseovernativeevent: null,
      originend: null,
      originstart: null,
      dragorshowevents: [
        {text: 'Anzeigen', value: 'show'},
        {text: 'Bearbeiten', value: 'drag'}
      ],
      //dragorshowevent: 'drag',
      employeeDataForEventtoSetOnTime: undefined,
      employeerostereventdialogkey: 0,
      openmenu: false,
      hoveredevent: null,
      drag: false,
      left: true,
      rigth: false,
      alert: false,
      alertmessage: "",
      currentUserID: undefined
    }
  },
  created() {
    this.value = this.initvalue
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.GetAllFeiertage()
    if (this.employeeID === 0) {
      this.$store.dispatch('employees/getAllEmployeeCalendars')
    } else {
      this.$store.dispatch('employees/getAllCalendarsByEmployeeID', this.employeeID);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    feiertage() {
      return this.$store.getters['employees/getAllFeiertage']
    },
    eventlist() {
      if (this.employeeID === 0) {
        return this.$store.getters['employees/getAllEvents']
      } else {
        return this.$store.getters['employees/getAllEventsByEmployeeID']
      }
    },
    eventmodaldialog() {
      return this.$store.getters['eventdialog/geteventmodaldialog']
    },
    eventholidaymodaldialog() {
      return this.$store.getters['eventdialog/geteventholidaymodaldialog']
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    GetDayFromDate(date) {
      let array = date.split('-')
      return array[2]
    },
    GetValue(date) {
      this.value = date
      console.log("emit")
      this.$emit('sendDateToParent', date)
    },
    GetFeiertageSymbol(date) {
      if (this.feiertage == undefined) {
        {
          return ""
        }
      }
      let feiertag = this.feiertage.find(element => element.Date == date)
      if (feiertag == undefined) {
        return ""
      } else {
        return "*"
      }
    },
    ShowSnackbar(date) {
      if (this.feiertage == undefined) {
        {
          return ""
        }
      }
      let feiertag = this.feiertage.find(element => element.Date == date)
      if (feiertag == undefined) {
        return ""
      } else {
        this.GetFeiertagsDate = this.GermanDateFormat(feiertag.Date);
        this.GetFeiertagsDesc = feiertag.Name;
        this.snackbar = true
      }
    },
    GetFeiertagByDate(date) {
      if (this.feiertage == undefined) {
        {
          return ""
        }
      }
      let feiertag = this.feiertage.find(element => element.Date == date)
      if (feiertag == undefined) {
        return ""
      } else {
        return feiertag.Name
      }
    },
    GermanDateFormat(date) {
      return moment(String(date)).format("DD.MM.YYYY")
    },
    async GetAllFeiertage() {
      let array = this.value.split('-')
      let year = array[0]

      try {
        await this.$store.dispatch('employees/getAllFeiertageByYearByBundesland', year)
      } catch (error) {
        console.log(error)
        this.alert = true
        this.alertmessage = "Die Feiertags API ist zu Zeit nicht erreichbar. Sie können deswegen keinen Urlaub eintragen. Versuchen sie es später noch einmal."
        console.log("vor emit")
        this.$emit('DisableButtonHolidays')
      }
    },
    GetDayForHoveredEvent(event) {
      let date = new Date(event.start)
      let day = date.getDay()
      return day
    },
    GetHoveredEventType(event) {
      if (event == null) {
        return
      }
      return event.eventtype
    },
    GetEndtimeForHoveredEvent(event) {
      if (event == null) {
        return
      }
      return event.end
    },
    GetStarttimeForHoveredEvent(event) {
      if (event == null) {
        return
      }
      return event.start
    },
    GetNameForHoverdEvent(event) {
      if (event == null) {
        return
      }
      return event.name
    },
    GetColorForHoveredEvent(event) {
      if (event == null) {
        return
      }
      return event.color
    },
    HideInfoEvent() {
      // console.log(event)
      this.openmenu = false
      this.selectedElement = null
      this.hoveredevent = null
    },
    ShowInfoEvent({nativeEvent, event}) {
      console.log(event)
      // console.log(nativeEvent)
      if (this.drag == false) {
        if (this.GetDayForHoveredEvent(event) == 1) {
          this.right = true
          this.left = false
        } else {
          this.right = false
          this.left = true
        }
        this.openmenu = true
        this.selectedElement = nativeEvent.target
        this.hoveredevent = event
        nativeEvent.stopPropagation()
      }
    },
//     printCalendar() {

//       const prtHtml = document.getElementById('print').innerHTML;
//       let stylesHtml = '';
//       for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
//         stylesHtml += node.outerHTML;
//       }
//       const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
//       const title = "Dienstplan" + " " + this.$refs.calendar.title;

//       WinPrint.document.write(`<!DOCTYPE html>
// <html>
//   <head>
//     ${stylesHtml}
//     <h2><b>${title}</b></h2><br>
//   </head>
//   <body>
//     ${prtHtml}
//   </body>
// </html>`);

//       WinPrint.document.close();
//       WinPrint.focus();
//       WinPrint.print();
//       WinPrint.close();
//     },
    startDrag({event, timed}) {
      if (event && timed && this.dragorshowevent === "drag") {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
        this.originstart = event.start
        this.originend = event.end
        this.drag = true
        this.openmenu = false
      }
    },
    getEvent({nativeEvent, event}) {
      this.mouseoverevent = event
      this.mouseovernativeevent = nativeEvent
    },
    startTime(tms) {
      const mouse = this.toTime(tms)
      //console.log(mouse)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start
        this.dragTime = mouse - start
      }
    },
    extendBottom(event) {
      //console.log(event)
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
      this.originstart = event.start
      this.originend = event.end
      this.hoveredevent = null
      this.openmenu = false
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)
      //console.log(tms)
      if (this.dragEvent && this.dragTime !== null) {
        //Zuständig wenn der Event als ganzes verschoben wird
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration
        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        //Zuständig für Veränderung des Endes des Events
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)
        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    async endDrag() {
      //Funktion die bei jedem Ende einer Dragoperation aufgerufen wird
      //const mouse = this.toTime(tms)
      //console.log(mouse)
      if (this.dragEvent) {
        //console.log(this.dragEvent)
        console.log("dragevent")
        await this.changeEventDb(this.dragEvent)
      }
      if (this.createEvent) {
        //console.log(this.createEvent)
        await this.changeEventDb(this.createEvent)
      }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
      this.drag = false
    },
    async changeEventDb(changeEvent) {
      console.log("changeEvent.start: " + changeEvent.start)
      console.log(JSON.stringify(changeEvent))
      let response = await employeeService.getWorkTimePerDay(changeEvent.ID, changeEvent.EmployeeID, changeEvent.start, changeEvent.end)
      if (response.Hours <= response.Maxhours) {//Wenn die Stundenzahl unterhalb der max. Stundezahl des Mitarbeiter liegt dann lege einen neuen Event an ansonsten nicht
        let event = {
          ID: changeEvent.ID,
          start: changeEvent.start,
          end: changeEvent.end,
          EmployeeID: changeEvent.EmployeeID,
          Personalnumber: changeEvent.Personalnumber,
          color: changeEvent.color,
          timed: changeEvent.timed,
          name: changeEvent.name,
          eventtype: changeEvent.eventtype,
          PatientID: changeEvent.PatientID,
          DepartureTime: changeEvent.DepartureTime,
          TraveledKilometers: changeEvent.TraveledKilometers
        }
        await this.$store.dispatch('employees/changeEmployeeCalender', event)
      } else {
        this.setBackEventToOrigin(changeEvent)
      }
    },
    setBackEventToOrigin(event) {
      let index = this.eventlist.findIndex(element => element.ID === event.ID && element.eventtype === event.eventtype)
      if (index !== -1) {
        this.eventlist.splice(index, 1)
        event.start = this.originstart
        event.end = this.originend
        this.eventlist.push(event)
      }
      this.SetAlertMessage("Der Mitarbeiter hat entweder Urlaub/Gleitzeit oder ist bereits über seine Tagesarbeitszeit verbucht")
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor(event) {
      return event.color
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    },
    async dropFromTable(evt) {
      if (this.dragorshowevent == "drag") {
        let ID = evt.dataTransfer.getData('ID')
        let allowed = await this.AllowtoCreateNewEvent(ID)
        if (allowed) {
          let Personalnumber = evt.dataTransfer.getData('Personalnumber')
          let Lastname = evt.dataTransfer.getData('Lastname')
          let Firstname = evt.dataTransfer.getData('Firstname')
          let EmployeeColorHex = evt.dataTransfer.getData('EmployeeColorHex')
          let employeedata = {
            ID: ID,
            EmployeeID: ID,
            Personalnumber: Personalnumber,
            Firstname: Firstname,
            Lastname: Lastname,
            EmployeeColorHex: EmployeeColorHex
          }
          this.createNewEvent(employeedata)
        } else {
          this.SetAlertMessage("Der Mitarbeiter hat entweder Urlaub/Gleitzeit oder ist bereits über seine Tagesarbeitszeit verbucht")
        }
      }
      /*let ID = evt.dataTransfer.getData('ID')
      let Personalnumber = evt.dataTransfer.getData('Personalnumber')
      let Lastname = evt.dataTransfer.getData('Lastname')
      let Firstname = evt.dataTransfer.getData('Firstname')
      let EmployeeColorHex = evt.dataTransfer.getData('EmployeeColorHex')
      let employeedata = {
          ID: ID,
          EmployeeID: ID,
          Personalnumber: Personalnumber,
          Firstname: Firstname,
          Lastname: Lastname,
          EmployeeColorHex: EmployeeColorHex
      }
      console.log(employeedata)
      this.employeeDataForEventtoSetOnTime=employeedata*/
    },
    //*************************Die Funktion wird vielleicht später noch verwendet
    async SetDragEventOnTime(time) {
      if (this.type == 'day' && this.employeeDataForEventtoSetOnTime != undefined) {
        console.log(this.toTime(time))
        if (this.employeeDataForEventtoSetOnTime.EmployeeColorHex == "") {
          this.employeeDataForEventtoSetOnTime.EmployeeColorHex = 'black'
        }
        let event = {
          start: this.toTime(time),
          end: this.toTime(time) + 3600,
          EmployeeID: Number(this.employeeDataForEventtoSetOnTime.ID),
          timed: true
        }
        await this.$store.dispatch('employees/createEmployeeCalendar', event)
        this.employeeDataForEventtoSetOnTime = undefined
      }
      this.employeeDataForEventtoSetOnTime = undefined
    },
    async createNewEvent(employeedata) {
      //neuer Event wird erst in der Datenbank angelegt und dann anzeigt d.h der Event wird der Eventliste hinzugefügt
      if (this.type != "month" && this.value != "") {
        let eventdate = this.createStartAndEndDate()
        if (employeedata.EmployeeColorHex == "") {
          employeedata.EmployeeColorHex = 'black'
        }
        let event = {
          start: eventdate.start.getTime(),
          end: eventdate.end.getTime(),
          EmployeeID: Number(employeedata.ID),
          timed: true
        }
        await this.$store.dispatch('employees/createEmployeeCalendar', event)
      }

    },
    createStartAndEndDate() {
      let date = ""
      if (this.type == 'day' && this.value == "")
        date = new Date()
      if (this.type == 'day' && this.value != "")
        date = new Date(this.value)
      if (this.type == 'week')
        date = new Date(this.value)
      let day = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      let weekday = 0
      /*if(this.type == 'week'){
          weekday = date.getDay() - 1
      }*/
      let datestart = new Date(year, month, day - weekday, 12)
      let dateend = new Date(year, month, day - weekday, 13)
      let eventdate = {
        start: datestart,
        end: dateend
      }
      return eventdate
    },
    ShowEvent() {
      if (this.dragorshowevent == "show") {
        /*this.selectedEvent = this.mouseoverevent
        this.selectedElement = this.mouseovernativeevent.target
        this.selectedOpen = true
        this.mouseovernativeevent.stopPropagation()*/
        this.selectedEvent = this.mouseoverevent
        console.log(this.selectedEvent)
        if (this.selectedEvent.eventtype == "appointment") {
          this.$store.commit("eventdialog/changeEventModalDialogToTrue")
          this.employeerostereventdialogkey++
        } else {
          console.log("Test")
          this.$store.commit('eventdialog/changeEventHolidayModalDialogToTrue')
          this.employeerostereventdialogkey++
        }
      }
    },
    async deleteEvent() {
      if (this.selectedEvent.eventtype == "appointment") {
        this.deleteApointment()
      }
      if (this.selectedEvent.eventtype == "flextimereduction" || this.selectedEvent.eventtype == "holiday" || this.selectedEvent.eventtype == "sick" || this.selectedEvent.eventtype == "freeday") {
        this.deleteHoliday()
      }
    },
    async deleteApointment() {
      try {
        await this.$store.dispatch('employees/deleteEmployeeCalendar', this.selectedEvent)
        let index = this.eventlist.findIndex(element => element.ID == this.selectedEvent.ID && element.eventtype == "appointment")
        this.eventlist.splice(index, 1)
      } catch (error) {
        console.log(error)
        this.alertmessage = error
        this.alert = true
      }
    },
    async deleteHoliday() {
      console.log(JSON.stringify(this.selectedEvent))
      await this.$store.dispatch('employees/deleteEmployeeHoliday', this.selectedEvent)
      let index = this.eventlist.findIndex(element => element.ID == this.selectedEvent.ID && (element.eventtype == "flextimereduction" || element.eventtype == "holiday" || element.eventtype == "sick" || this.selectedEvent.eventtype == "freeday"))
      this.eventlist.splice(index, 1)
    },
    async AllowtoCreateNewEvent(employeeid) {
      let timestampday = new Date(this.value).getTime()
      if (this.value == "") {
        return false
      }
      try {
        let response = await employeeService.getWorkTimePerDayToCreateNewEvent(employeeid, timestampday)
        if (response.Hours < response.Maxhours) {//Wenn die Stundenzahl unterhalb der max. Stundezahl des Mitarbeiter liegt dann lege einen neuen Event an ansonsten nicht
          return true
        } else {
          return false
        }
      } catch (error) {
        this.SetAlertMessage(error)
      }
    },
    SetAlertMessage(errormessage) {
      this.$emit('SetAlertMessage', errormessage)
    },
    SendTypeAndValue() {
      let sendobject = {
        Type: this.type,
        Value: this.value,
        DragOrShowEvent: this.dragorshowevent
      }
      this.$emit('SendTypeAndValue', sendobject);
      this.$emit('sendDateToParent', this.value)
    },
    GetDay(day) {
      console.log(day.date)
      this.SendTypeAndValue()
    },
    ChangeToPrev() {
      this.$refs.calendar.prev()
      this.SendTypeAndValue()
      this.CheckForYearChangeToGetNewFeiertage()
    },
    ChangeToNext() {
      this.$refs.calendar.next()
      this.SendTypeAndValue()
      this.CheckForYearChangeToGetNewFeiertage()
    },
    CheckForYearChangeToGetNewFeiertage() {
      let array = this.value.split('-')
      let month = array[1]
      if (month == '12' || month == '01') {
        this.GetAllFeiertage()
      }
    },
    RefreshCalendar() {
      this.$emit("RefreshCalendar")
    }
  }
}
</script>

<style scoped>

@media print {
  body, html, #wrapper, sheet {
    height: 100%;
  }
}

.more_info {
  border-bottom: 1px dotted;
  position: relative;
}

.more_info .title {
  position: absolute;
  top: 20px;
  background: silver;
  padding: 4px;
  left: 0;
  white-space: nowrap;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}

.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: '';
}

.v-event-drag-bottom:hover::after {
  display: block;
}


</style>

<style scoped>
.header_calendar {
  margin-top: 12px;
}

.classForButtonclicked {
  background-color: #951b81 !important;
  color: white !important
}
</style>
