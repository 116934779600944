export const dialogmixin = {
    data(){
        return{
            notedialogkey: 0, 
            medicationdialogkey: 0,
            invoicerundialgokey: 0
        }
    },
    computed:{
        invoicerunmodaldialog(){
            return this.$store.getters['eventdialog/getinvoicerundialog']
        },
        notemodaldialog(){
            return this.$store.getters['eventdialog/getnotemodaldialog']
        },
        medicationmodaldialog(){
            return this.$store.getters['eventdialog/getmedicationdialog']
        }
    },
    methods: {
        async OpenInvoiceRunModalDialog(){
            this.invoicerundialgokey++
            this.$store.commit('eventdialog/changeInvoiceRunDialogtoTrue')
        },
        async OpenNoteModalDialog(){
            this.notedialogkey++
            this.$store.commit('eventdialog/changeNoteModalDialogtoTrue')
        },
        async OpenMedicationModalDialog(){
            this.medicationdialogkey++
            this.$store.commit('eventdialog/changeMediacationDialogtoTrue')
        }
    },
}