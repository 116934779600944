<template>
  <main>
    <v-container fluid>

      <base-card>
        <div class="densecol">
          <PatientChips style="height: 35px !important"
                        ref="patientchips"
                        :patientID="patientID">
          </PatientChips>
          <div
              class="col-11"
              style="background-color: #fdf8f8; padding-bottom: 0px;">
            <v-tabs
                show-arrows
                class="col-12 mt-0 mb-0 pa-1"
                style="border: 1px solid purple !important;">
              <v-tab
                  style="background-color: white !important;"
                  @click="selectTab(1)">
                Persönliche Daten
              </v-tab>
              <v-tab
                  style="background-color: white !important;"
                  @click.self="selectTab(2)">
                Adressen
              </v-tab>
              <v-tab
                  style="background-color: white !important;"
                  @click="selectTab(3)">
                Bankdaten
              </v-tab>
              <v-tab
                  style="background-color: white !important;"
                  @click="selectTab(4)">
                Angehörige und Kontakte
              </v-tab>
              <v-tab
                  style="background-color: white !important;"
                  @click="selectTab(5)">
                <v-icon class="mr-1">mdi-key</v-icon>
                Schlüssel
              </v-tab>
              <v-tab
                  style="background-color: white !important;"
                  @click="selectTab(6)">
                <v-icon class="mr-1">mdi-clipboard-edit-outline</v-icon>
                Abrechnung
              </v-tab>
            </v-tabs>
          </div>
          <div
              class="mt-0 col-11"
              style="background-color: #fdf8f8; padding: 0 10px 10px 10px;">
            <v-container fluid v-show="selectedTabID === 1">
              <PatientPersonalData
                  ref="patientpersonaldata"
                  :titles-pool="titlePool"
                  :patientID="patientID"
                  @toggleInteressent="toggleInteressent"
                  @toggleActive="toggleActive"
                  @toggleServiceHouseHold="toggleServiceHousehold"
                  @toggleServiceEmergencyCall="toggleServiceEmergencyCall"
                  @toggleDeceased="toggleDeceased"
                  @chipsPatientFirstname="updateFirstNameInChips($event,value)"
                  @chipsPatientLastname="updateLastNameInChips($event,value)">
              </PatientPersonalData>
            </v-container>
            <v-container fluid v-show="selectedTabID === 2">
              <PatientAddressData
                  ref="patientaddressdata"
                  :patientID="patientID"
                  :formdisabled="formdisabled">
              </PatientAddressData>
            </v-container>
            <v-container fluid v-show="selectedTabID === 3">
              <PatientBankaccountData
                  ref="patientbankaccountdata"
                  :titles-pool="titlePool"
                  :patientID="patientID"
                  :formdisabled="formdisabled">
              </PatientBankaccountData>
            </v-container>
            <v-container fluid v-show="selectedTabID === 4">
              <PatientRelatedContactsData
                  ref="patientrelatedcontacts"
                  :titles-pool="titlePool"
                  :patientID="patientID"
                  :formdisabled="formdisabled">
              </PatientRelatedContactsData>
            </v-container>
            <v-container fluid v-show="selectedTabID === 5">
              <KeyPlacement
                  ref="keyplacement"
                  :patientID="patientID"
                  :formdisabled="formdisabled"
                  :tabdisabled="tabdisabled">
              </KeyPlacement>
            </v-container>
            <v-container fluid v-show="selectedTabID === 6">
              <InvoiceData
                  ref="invoicedata"
                  :titles-pool="titlePool"
                  :patientID="patientID"
                  :formdisabled="formdisabled"
                  :tabdisabled="tabdisabled"
                  @invoiceChoice="invoiceChoice">
              </InvoiceData>
            </v-container>
            <v-container fluid v-show="selectedTabID === 7">
              <PatientDocumentLayout
                  :patientid="patientID"
                  :key="filetablekey"
                  @Renderfiletable="renderFileTable">
              </PatientDocumentLayout>
            </v-container>
          </div>
        </div>
      </base-card>
    </v-container>
  </main>
</template>

<script>
import PatientPersonalData from "@/components/patients/PatientPersonalData";
import PatientAddressData from "@/components/patients/PatientAddressData";
import PatientBankaccountData from "@/components/patients/PatientBankaccountData";
import PatientRelatedContactsData from "@/components/patients/PatientRelatedContactsData";
import PatientChips from "@/components/patients/PatientChips";
import InvoiceData from "@/components/invoices/InvoiceOverview";
import {titleMixin} from "@/mixins/titleMixin";
import BaseCard from "../Base/BaseCard";
import KeyPlacement from "./KeyPlacement";
import PatientDocumentLayout from "../../views/patients/PatientDocumentLayout";

export default {
  mixins: [titleMixin],
  props: {
    patientID: Number,
    formdisabled: Boolean,
    tabdisabled: Boolean,
  },
  components: {
    PatientDocumentLayout,
    KeyPlacement,
    BaseCard,
    PatientPersonalData,
    PatientAddressData,
    PatientBankaccountData,
    PatientRelatedContactsData,
    PatientChips,
    InvoiceData,
  },
  provide() {
    return {
      titlePool: this.titlePool
    }
  },
  data() {
    return {
      selectedTabID: 1,
      titlePool: titleMixin.data(),
      PatientID: this.patientId,
      filetablekey: 0
    };
  },
  mounted() {
    this.$root.$refs.PatientInputMask = this;
  },
  computed: {},
  methods: {
    renderFileTable() {
      this.filetablekey++
      // this.$emit("Renderfiletable")
    },
    showKlientDocuments() {
      this.selectTab(7);
      this.renderFileTable()
    },
    selectTab(value) {
      this.selectedTabID = value;
    },
    toggleInteressent() {
      this.$refs.patientchips.ToggleInteressent();
    },
    toggleActive() {
      this.$refs.patientchips.ToggleActive();
    },
    toggleServiceEmergencyCall() {
      this.$refs.patientchips.ToggleServiceEmergencyCall();
    },
    toggleServiceHousehold() {
      this.$refs.patientchips.ToggleServiceHousehold();
    },
    toggleDeceased() {
      this.$refs.patientchips.ToggleDeceased();
    },
    updateFirstNameInChips(firstname) {
      this.$refs.patientchips.UpdateFirstName(firstname);
    },
    updateLastNameInChips(lastname) {
      this.$refs.patientchips.UpdateLastName(lastname);
    },
    invoiceChoice(invoiceChoice) {
      this.$emit("invoiceChoice", invoiceChoice)
    }
  },
  created() {
    console.log("###--> CREATED PatientInputMask PatientID: " + this.PatientID)
    if (this.PatientID === undefined) {
      this.$store.state.patients.selected_patient = [];
    } else {
      this.$store.dispatch('patients/getPatient', this.PatientID);
    }
  }
}
</script>

<style scoped>
</style>