<template>
  <div>
    <row :gutter="12" v-if="!articleExist">
      <column :xs="12">
        <p>keine Leistungen hinterlegt</p>
      </column>
    </row>
    <row :gutter="12"
         v-for="item in articles"
         :key="item.ID"
         style="border: 1px solid #e1bed9; margin-top: 15px"
    >
      <column :xs="12" :md="4" :lg="3">
        <v-text-field
            :value="item.PatientServiceArticleServiceID"
            label="ID"
            disabled
        ></v-text-field>
      </column>
      <column :xs="12" :md="4" :lg="3">
        <v-text-field
            :value="item.ArticleName"
            label="Leistung"
            disabled
        ></v-text-field>
      </column>
      <column :xs="12" :md="4" :lg="3">
        <v-text-field
            :value="item.Price"
            label="Preis"
            disabled
        ></v-text-field>
      </column>
      <column :xs="12" :md="4" :lg="3">
        <v-text-field
            :value="item.BillingType"
            label="Abrechnungsart"
            disabled
        ></v-text-field>
      </column>
      <column :xs="12" :md="4" :lg="3">
        <v-text-field
            :value="item.TypeOfService"
            label="Leistungsart"
            disabled
        ></v-text-field>
      </column>
      <column :xs="12" :md="4" :lg="3">
        <v-text-field
            :value="item.AmountUnit"
            label="Gebucht"
            disabled
        ></v-text-field>
      </column>
      <column  :lg="5">
        <v-btn
            color="primary"
            elevation="2"
            class="ml-2 mb-2"
            @click="DeleteArticle(item.ID)"
        >
          <i class="fas fa-trash-alt"></i>
          Artikel löschen
        </v-btn>
      </column>
    </row>
  </div>
</template>
<script>
export default {
  props: {
    calendarID: Number,
    calendarEntryPresent: Boolean(true)
  },
  data() {
    return {
      articleExist: true
    }
  },
  async created() {
    if (this.calendarEntryPresent == true) {
      await this.$store.dispatch("serviceArticles/getAllPatientServiceArticlesByCalendarID", this.calendarID)
      // let items = this.$store.getters['serviceArticles/getPatientServiceArticles']
      // console.log("items: " + items)
      // if(items == ""){
      //     this.articleExist = false
      // }
    } else {
      if (this.articles.length == 0) {
        this.articleExist = false
      }
    }
  },
  computed: {
    articles() {
      if (this.calendarEntryPresent == true) {
        let items = this.$store.getters['serviceArticles/getPatientServiceArticles']
        return items
      } else {
        let items = this.$store.getters['patientServiceArticles/getPatientServiceArticlesForCreate']
        return items
      }
    }
  },
  methods: {
    async DeleteArticle(id) {
      if (this.calendarEntryPresent == true) {
        await this.$store.dispatch('serviceArticles/deleteCalendarToPatientServiceArticleByID', id)
        this.$emit("RenderComponent")
      } else {
        this.$store.commit('patientServiceArticles/deletePatientServiceArticlesForCreateCalendarByID', id)
      }
    }
  }
}
</script>