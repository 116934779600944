<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="2">
        <v-text-field
          label="Rechnungsnummer"
          v-model="Invoice.InvoiceNr"
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Unternehmen / Versicherung"
          v-model="Invoice.ReceiptCompany"
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Rechnungsempfänger Vorname"
          v-model="Invoice.ReceiptPersonFirstName"
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Rechnungsempfänger Nachname"
          v-model="Invoice.ReceiptPersonLastName"
          hide-details="auto"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="4" style="margin-top: -20px;">
        <v-btn
          color="primary"
          elevation="2"
          class="mt-3 mr-2"
          :disabled="!Invoice.Generated"
          @click="downloadFile(Invoice.ID)"
        >
          <i class="fas fa-search-plus"></i>
        </v-btn>

         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              elevation="2"
              class="mt-3 mr-2"
              :disabled="!Invoice.Generated || Invoice.Accepted"
              @click="Edit(Invoice.ID)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fas fa-pen"></i>
            </v-btn>
          </template>
          <span>Rechnungsinfos anpassen</span>
        </v-tooltip>

         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              elevation="2"
              class="mt-3 mr-2"
              :disabled="!Invoice.Generated || Invoice.Accepted"
              @click="Recreate(Invoice.ID)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fas fa-redo"></i>
            </v-btn>
          </template>
          <span>Neu erstellen</span>
        </v-tooltip>

        <br/>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="info"
              elevation="2"
              class="mt-3 mr-2"
              :disabled="!Invoice.Generated || Invoice.Accepted"
              @click="Accept(Invoice.ID)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fas fa-check"></i>
            </v-btn>
          </template>
          <span>Freigeben</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              elevation="2"
              class="mt-3 mr-2"
              :disabled="
                !Invoice.Generated || !Invoice.Accepted || Invoice.Sended
              "
              @click="Send(Invoice.ID)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fas fa-envelope"></i>
            </v-btn>
          </template>
          <span>Versenden</span>
        </v-tooltip>

        <v-btn
          color="primary"
          elevation="2"
          class="mt-3"
          :disabled="Invoice.Accepted || Invoice.Sended"
          @click="Delete(Invoice.ID)"
        >
          <i class="fas fa-trash"></i>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      ID: this.id,
      Invoice: this.invoice,
    };
  },
  props: {
    id: Number,
    invoice: {},
  },
  methods:{
        async downloadFile(id){
            let file = await this.$store.dispatch('invoices/downloadFile', id)
            console.log(file)
            //window.location.assign(file);
            window.open(file, '_blank');
        },
        Edit(id) {
          this.$router.push("/invoice/" + id);
        },
        Recreate(id) {
          this.$store.dispatch('invoices/recreate', id).then((response) => {
            console.log(response);
            this.$root.$emit("reload_invoice_list");
            window.location.reload();
          })
        },
        Delete(id) {
          this.$store.dispatch('invoices/delete', id).then((response) => {
            console.log(response);
            //this.$root.$emit("reload_invoice_list");
            window.location.reload();
          })
        },
        Accept(id) {
          this.$store.dispatch('invoices/accept', id).then((response) => {
            console.log(response);
            this.$root.$emit("reload_invoice_list");
            window.location.reload();
          })
        },
        Send(id) {
          alert("Diese Funktion ist aktuell noch nicht implementiert.");
          console.log(id);
          //this.$store.dispatch('invoices/send', id);
        }
    }
};
</script>
