import {
    invoiceRunsService
} from '../services';

export const invoiceRuns = {
    namespaced: true,
    state: {
        all: {},
        selected: {}
    },
    actions: {
        reset({
            commit
        }) {
            commit('invoiceRunsReset');
        },

        delete({
            commit
        }, id) {
            commit('deleteRequest');
            console.log(id);
            return new Promise((resolve, reject) => {
                invoiceRunsService.deleteById(id)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                invoiceRunsService.getAll()
                    .then(
                        data => {
                            commit('getAllSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        getInvoiceRun({
            commit
        }, id) {
            commit('getSelectedRequest');

            invoiceRunsService.getInvoiceRun(id)
                .then(
                    payload => commit('getSelectedSuccess', payload),
                    error => commit('getSelectedFailure', error)
                );
        },

        create({
            commit
        }, data) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                invoiceRunsService.create(data)
                    .then(
                        data => {
                            commit('newSuccess', data);
                            resolve(data)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },
    },
    mutations: {
        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },

        deleteRequest(state) {
            state.delete = {
                loading: true
            }; //Wird geladen status
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Objekt das vom Server zurückgegeben wird
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },
        
        invoiceRunsReset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },
        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, data) {
            state.all = {
                items: data
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        },
        getSelectedRequest(state) {
            state.selected = {
                loading: true
            };
        },
        getSelectedSuccess(state, patient) {
            state.selected = {
                item: patient,
                loading: false
            };
        },
        getPSelectedailure(state, error) {
            state.selected = {
                error,
                loading: false
            };
        }

    }
}