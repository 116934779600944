<template>
  <div>
    <div class="card-body">
      <div class="dataTables_wrapper">
        <v-data-table
          :headers="headers"
          :items="invoiceInformationForListDTO"
          item-key="ID"
          :loading="isLoading"
          loading-text="Wird geladen"
          :search="search"
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          locale="de-de"
          class="elevation-1 hdl"
          @click:row="rowClick"
          single-select
          :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              clearable
              label="Suchen"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> Neu laden </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created(){
    await this.$store.dispatch('invoices/getAllInvoiceInformationsForListByInvoiceRunID', this.invoiceRunID)
  },
  props: {
      invoiceRunID: Number
  },
  mounted() {
  this.$root.$refs.InvoiceList = this
    },
  data() {
    return {
      headers: [
        { text: "R.-Nr", value: "InvoiceNumber" },
        { text: "Debitoren-Nr.", value: "DebitorenNumber" },
        { text: "Klientenvorname", value: "Firstname" },
        { text: "Klientennachname", value: "Lastname" },
        { text: "Status", value: "Status" },
        { text: "Referenz", value: "InvoiceNumberReferenz" },

      ],
      search: "",
    };
  },
  computed: {
    invoiceInformationForListDTO() {
      return this.$store.getters['invoices/getinvoiceInformationForListDTO'];
    },
    InvoiceInformationToEditDTO() {
      return this.$store.getters['invoices/getinvoiceInformationToEditDTO']
    }
  },
  methods:{
    setNewStatus(newstatus) {
      console.log("InvoiceList: " + newstatus)
    },
    rowClick(item, row) {
      console.log(row)
      row.select(true)
      console.log(item.ID)

      this.$emit("SendInvoiceID", item.ID)
      this.$emit("SendApproval", item.Approved)
      this.$emit("SendStatus", item.Status)
      this.$emit("SendReferenz", item.InvoiceNumberReferenz)
      this.$emit("SendInvoiceNumber", item.InvoiceNumber)
      this.$root.$refs.EditInvoicePositions.SetInvoiceGoId(item.ID)
      // this.$root.$refs.InvoiceEditLayout.SelectBill(item.InvoiceNumber)

      // this.$root.$refs.EditInvoicePositions.SetApprovedStatus(item.Approved)

    },
  },
};
</script>
