<template>
  <v-container>
    <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              color="#6e6e6e" align="center" class="secondary" style="padding-bottom: 5px" dark>
        <v-card-title class="justify-center">
          <v-icon x-large>mdi-car</v-icon>
          IMPORT Fuhrpark
        </v-card-title>
        <v-card-subtitle>.csv Datei</v-card-subtitle>

        <v-file-input
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            accept=".csv"
            label="Datei auswählen"
            outlined
            class="mt-4 mr-2"
            v-model="chosenFile"
            @change="loadCSV"
            @click:clear="clearChosenFile">
        </v-file-input>

        <a class="ml-2 mt-0 font-weight-bold" style="color:white">Datensätze:
          {{ count }}
        </a>
        <p style="color:white;" class="ml-4 font-weight-bold">Datensätze importiert:
          {{ importedData }}
        </p>

        <v-row v-if="count !== 0" class="mt-2 ml-0 justify-center">
          <v-btn @click="show" class="ml-2 mb-6" style="background-color: white; color:#0e4194">
            {{ !showData ? "Anzeigen" : "Ausblenden" }}
          </v-btn>
          <v-btn class="ml-4 secondary" outlined @click="importToDB">
            {{ !importDone ? "Importieren" : "Importieren" }}
          </v-btn>
        </v-row>
      </v-card>
    </v-hover>
    <v-col class="mt-8" v-if="showData">
      <v-row>
        <table v-if="parse_csv" style="background-color: #0e4194; color:white; border: 2px solid #0e4194">
          <thead>
          <tr>
            <th v-for="key in parse_header" v-bind:key="key"
                @click="sortBy(key)"
                :class="{ active: sortKey == key }">
              {{ key | capitalize }}
              <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                </span>
            </th>
          </tr>
          </thead>
          <tr v-for="csv in parse_csv" v-bind:key="csv" style="background-color: white; color:#0e4194">
            <td v-for="key in parse_header" v-bind:key="key">
              {{ csv[key] }}
            </td>
          </tr>
        </table>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import moment from "moment";

window.datasetcount = 0;
export default {
  updated() {
    window.datasetcount = 0;
  },
  data() {
    return {
      chosenFile: null,
      importDone: false,
      count: window.datasetcount,
      importedDataCount: 0,
      filename: '',
      showData: false,
      channel_name: '',
      channel_fields: [],
      channel_entries: [],
      parse_header: [],
      parse_csv: [],
      sortOrders: {},
      sortKey: '',
      selecteddrivers: [],
      cars: [],
      datezlyr: "",
      dateyr: "",
      datehuau: "",

    };
  },
  computed: {
    employeeNames() {
      let l = this.employees;
      let x = [];
      if (l != undefined) {
        l.forEach(element => {
          x.push({id: element.ID, name: element.Firstname + "," + element.Lastname});
        });
      }
      return x;
    },
    importedData() {
      return this.importedDataCount;
    },
    getEmployeeID() {
      return this.$store.state.authentication.employeeID;
    },
    userID() {
      return this.$store.state.authentication.userID;
    },
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  methods: {
    clearChosenFile() {
      this.chosenFile = null;
      this.count = 0;
      this.showData = false;
    },
    formatDate(tmpDate) {
      return tmpDate ? moment(tmpDate).format() : "";
    },
    updateDatasetLength() {
      this.count = window.datasetcount;
    },
    pushTmpCarsToArr(carsImported) {
      this.cars.push(carsImported);
    },
    show() {
      this.showData = !this.showData;
    },
    importToDB() {
      if (confirm("Importvorgang starten?")) {
        for (let i = 0; i < this.cars.length; i++) {
          this.datehuau = this.cars[i].HUAU;
          this.dateyr = this.cars[i].BuildYear;
          this.datezlyr = this.cars[i].Registration;
          let tmpCar = {
            KfzNr: this.cars[i].KfzNr,
            AutomotiveManufacturerID: Number(this.cars[i].AutomotiveManufacturerID),
            FuelID: Number(this.cars[i].FuelID),
            ModelName: this.cars[i].ModelName,
            HUAU: this.formatDate(this.datehuau),
            Registration: this.formatDate(this.datezlyr),
            BuildYear: this.formatDate(this.dateyr),
            Operative: true,
            Available: true,
            Card: "",
            KM: this.cars[i].KM,
            ChassisNumber: "",
          };

          this.$store.dispatch("cars/create", tmpCar).then((response) => {

            let carID = response.ID;
            this.selecteddrivers.forEach((ele) => {
              let payload = {
                AutomotiveID: parseInt(carID),
                EmployeeID: parseInt(ele)
              };

              this.$store.dispatch("cars/linkdriver", payload).then((response_link) => {
                console.log(response_link);
              });
            })

            this.$root.$emit("reload_car_list");
            this.$root.$emit("reload_car_details");
          });

          this.importedDataCount = i + 1;
          this.importDone = true;
        }
        alert("Datensätze importiert.");
      }
    },

    sortBy: function (key) {
      var vm = this
      vm.sortKey = key
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
    csvJSON(csv) {
      var vm = this
      var lines = csv.split("\n")
      var result = []
      var headers = lines[0].split(",")
      vm.parse_header = lines[0].split(",")
      lines[0].split(",").forEach(function (key) {
        vm.sortOrders[key] = 1
      })
      lines.map(function (line, indexLine) {
        if (indexLine < 1) return // Jump header line
        var obj = {}
        var currentline = line.split(",")
        headers.map(function (header, indexHeader) {
          obj[header] = currentline[indexHeader]
        })
        result.push(obj)
      })
      //result.pop() // remove the last item because undefined values
      return result
    },
    loadCSV() {
      var vm = this
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(this.chosenFile);
        this.filename = this.chosenFile.name;
        // Handle errors load
        reader.onload = function (event) {
          var csv = event.target.result;
          vm.parse_csv = vm.csvJSON(csv)
          let csvToJson = require('convert-csv-to-json');
          let json = csvToJson.base64Encoding().csvStringToJson(csv);
          // eslint-disable-next-line no-undef
          window.datasetcount = json.length;
          vm.updateDatasetLength();
          for (let i = 0; i < json.length; i++) {
            let car = {
              KfzNr: json[i].KfzNummer,
              AutomotiveManufacturerID: json[i].Marke,
              FuelID: Number(json[i].Kraftstoff),
              ModelName: json[i].Typ,
              HUAU: json[i].HuAu,
              KM: Number(json[i].Kilometerstand),
              BuildYear: json[i].Baujahr,
              Registration: json[i].Erstzulassung,
            }
            vm.pushTmpCarsToArr(car);
          }
        };
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Datei kann nicht gelesen werden!");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  margin: 2px auto;
}

.panel {
  border: 2px solid #dfdfdf;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0 0;
  margin: 10px;
}

.panel.panel-sm {
  max-width: 100%;
  margin: 10px auto;
}

.panel-heading {
  border-bottom: 2px solid #dfdfdf;
}

.panel-heading h1, .panel-heading h2, .panel-heading h3, .panel-heading h4, .panel-heading h5, .panel-heading h6 {
  margin: 0;
  padding: 0;
}

.panel-body .checkbox-inline {
  padding: 15px 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 100%;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>