import {
    authHeader
} from '../helpers';

export const carService = {
    getAll,
    getSingle,
    create,
    linkDriver,
    update,
    deleteById,
    unlinkAllDriver
};

function getAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/automotives/`, requestOptions).then(handleResponse);
}

function getSingle(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/automotive/` + id, requestOptions).then(handleResponse);
}

function create(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/automotive`, requestOptions).then(handleResponse);
}

function update(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/automotive/change/` + data.ID, requestOptions).then(handleResponse);
}

function deleteById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/automotive/delete/` + id, requestOptions).then(handleResponse);
}

function linkDriver(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/automotive/driver/link`, requestOptions).then(handleResponse);
}

function unlinkAllDriver(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/automotive/driver/unlinkAll/` + id, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}