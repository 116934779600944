<template>
  <v-container>
    <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              color="#6e6e6e" align="center" class="primary" style="padding-bottom: 5px" dark>
        <v-card-title class="justify-center">
          <v-icon x-large>mdi-account-multiple</v-icon>
          IMPORT Klienten
        </v-card-title>
        <v-card-subtitle>.csv Datei</v-card-subtitle>

        <v-file-input
            accept=".csv"
            label="Datei auswählen"
            outlined
            class="mt-4 mr-2"
            v-model="chosenFile"
            @change="loadCSV"
            @click:clear="clearChosenFile">
        </v-file-input>

        <a class="ml-2 mt-0 font-weight-bold" style="color:white">Datensätze:
          {{ count }}
        </a>
        <p style="color:white;" class="ml-4 font-weight-bold">Datensätze importiert:
          {{ importedData }}
        </p>


        <v-row v-if="count !== 0" class="mt-5 ml-0 justify-center">
          <v-btn @click="show" class="ml-2 mb-6" style="background-color: white; color:#951b81">
            {{ !showData ? "Anzeigen" : "Ausblenden" }}
          </v-btn>
          <v-btn class="ml-4 primary" outlined @click="importToDB">
            {{ !importDone ? "Importieren" : "Importieren" }}
          </v-btn>
        </v-row>
      </v-card>
    </v-hover>
    <v-col class="mt-8" v-if="showData">
      <v-row>
        <table v-if="parse_csv" style="background-color: #951b81; color:white; border: 2px solid #951b81">
          <thead>
          <tr>
            <th v-for="key in parse_header" v-bind:key="key"
                :class="{ active: sortKey == key }">
              {{ key | capitalize }}
              <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                </span>
            </th>
          </tr>
          </thead>
          <tr v-for="csv in parse_csv" v-bind:key="csv" style="background-color: white; color:#951b81;">
            <td v-for="key in parse_header" v-bind:key="key">
              {{ csv[key] }}
            </td>
          </tr>
        </table>
      </v-row>
    </v-col>

  </v-container>
</template>

<script>
window.datasetcount = 0;
export default {
  updated() {
    window.datasetcount = 0;
  },
  data() {
    return {
      chosenFile: null,
      importDone: false,
      count: window.datasetcount,
      importedDataCount: 0,
      filename: '',
      showData: false,
      channel_name: '',
      channel_fields: [],
      channel_entries: [],
      parse_header: [],
      parse_csv: [],
      sortOrders: {},
      sortKey: '',
      patient: [],
    };
  },
  computed: {
    importedData() {
      return this.importedDataCount;
    },
    getEmployeeID() {
      return this.$store.state.authentication.employeeID;
    },
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  methods: {
    clearChosenFile() {
      this.chosenFile = null;
      this.count = 0;
      this.showData = false;
    },
    updateDatasetLength() {
      this.count = window.datasetcount;
    },
    pushTmpPatientToArr(patientImported) {
      this.patient.push(patientImported);
    },
    show() {
      this.showData = !this.showData;
    },
    importToDB() {
      if (confirm("Importvorgang starten?")) {
        for (let i = 0; i < this.patient.length; i++) {
          let p = {
            FirstName: this.patient[i].item.FirstName,
            LastName: this.patient[i].item.LastName,
            Title: this.patient[i].item.Title,
            InsuranceID: 1,
            DateOfBirth: this.patient[i].item.DateOfBirth,
            ServiceHousehold: this.patient[i].item.ServiceHousehold == "AUS" ? true : false,
            ServiceEmergencyCall: this.patient[i].item.ServiceEmergencyCall == "HN" ? true : false,
            HealthInsuranceNumber: this.patient[i].item.HealthInsuranceNumber,
            EmployeeID: this.getEmployeeID,
            DebitorenNumber: parseInt(this.patient[i].item.DebitorenNumber)
          };
          this.$store
              .dispatch("patients/newPatient", p)
              .then((patient_response) => {
                // alert(JSON.stringify(p)) // receiving all data correct
                let a = {
                  Street: this.patient[i].item.Addresses.Street,
                  PLZ: this.patient[i].item.Addresses.PLZ,
                  City: this.patient[i].item.Addresses.City,
                  PatientID: patient_response.ID,
                };
                this.$store
                    .dispatch("patients/newAddress", a)
                    .then(() => {
                      this.selected_insuranceID = 1;
                    });

                let b = {
                  PatientID: patient_response.ID,
                  IBAN: this.patient[i].item.Bankaccount.ibannr,
                  BIC: this.patient[i].item.Bankaccount.biccode,
                };
                this.$store.dispatch("patients/newBankaccount", b);

                let c = {
                  Title: "",
                  FirstName: "",
                  LastName: "",
                  EMail: "",
                  Info: "",
                  PatientID: patient_response.ID,
                };
                this.$store.dispatch("patients/newContact", c);
              });

          this.importedDataCount = i + 1;
          this.importDone = true;
        }
        alert("Datensätze importiert.");
      }
    },

    sortBy: function (key) {
      var vm = this
      vm.sortKey = key
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
    csvJSON(csv) {
      var vm = this
      var lines = csv.split("\n")
      var result = []
      var headers = lines[0].split(",")
      vm.parse_header = lines[0].split(",")
      lines[0].split(",").forEach(function (key) {
        vm.sortOrders[key] = 1
      })
      lines.map(function (line, indexLine) {
        if (indexLine < 1) return // Jump header line
        var obj = {}
        var currentline = line.split(",")
        headers.map(function (header, indexHeader) {
          obj[header] = currentline[indexHeader]
        })
        result.push(obj)
      })
      result.pop() // remove the last item because undefined values
      return result
    },
    loadCSV() {
      var vm = this
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(this.chosenFile);
        this.filename = this.chosenFile.name;
        // Handle errors load
        reader.onload = function (event) {
          var csv = event.target.result;
          vm.parse_csv = vm.csvJSON(csv)
          let csvToJson = require('convert-csv-to-json');
          let json = csvToJson.base64Encoding().csvStringToJson(csv);
          // eslint-disable-next-line no-undef
          window.datasetcount = json.length;
          vm.updateDatasetLength();
          for (let i = 0; i < json.length; i++) {
            let patient = {
              item: {
                Title: json[i].Anrede,
                FirstName: json[i].Vorname,
                LastName: json[i].Nachname,
                DateOfBirth: json[i].Geburtsdatum,
                PhoneNumber: json[i].Telefonnummer,
                ServiceHousehold: json[i].Art,
                ServiceEmergencyCall: json[i].Art,
                InsuranceID: 0,
                DebitorenNumber: json[i].Debitorennummer,
                Addresses: {
                  Street: json[i].Strasse,
                  PLZ: json[i].Plz,
                  City: json[i].Ort,
                },
                Bankaccount: {
                  ibannr: json[i].iban,
                  biccode: json[i].bic,
                },
                HealthInsuranceNumber: json[i].PK,
              },
            }
            vm.pushTmpPatientToArr(patient);
          }
        };
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Datei kann nicht gelesen werden!");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  margin: 2px auto;
}

.panel {
  border: 2px solid #dfdfdf;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0 0;
  margin: 10px;
}

.panel.panel-sm {
  max-width: 100%;
  margin: 10px auto;
}

.panel-heading {
  border-bottom: 2px solid #dfdfdf;
}

.panel-heading h1, .panel-heading h2, .panel-heading h3, .panel-heading h4, .panel-heading h5, .panel-heading h6 {
  margin: 0;
  padding: 0;
}

.panel-body .checkbox-inline {
  padding: 15px 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 100%;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>