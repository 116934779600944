<template>
  <main>
    <row :gutter="12"
         style="border: 1px solid #e1bed9; margin-top: 15px">
      <column :xs="12" :md="4" :lg="4">
        <v-text-field
            :value="articles.ArticleName"
            label="Leistung"
            disabled
        ></v-text-field>
      </column>
      <column :xs="12" :md="2" :lg="2">
        <v-text-field
            :value="GetValueForTextField(articles)"
            label="Eingabe in"
            disabled
        ></v-text-field>
      </column>
      <column :xs="8" :md="1" :lg="1">

        <v-text-field
            v-if="articles.Timed"
            :value="anzahlCalculated"
            v-model="anzahlCalculated"
            label="Anzahl"
            readonly
        ></v-text-field>

        <v-text-field
            v-if="articles.NumberOfPieces"
            :value="articles.AmountUnit"
            v-model="articles.AmountUnit"
            @change="pieces"
            label="Anzahl"
        ></v-text-field>

      </column>
      <div v-if="!articles.NumberOfPieces">
        <row>
      <column v-if="articles.Timed || articles.NumberOfPieces" class="ml-2">
        <label>Uhrzeit von:</label><br>
        <vue-timepicker
            drop-direction="up"
            manual-input
            v-model="articles.UhrzeitVon"
            @change="von(articles.UhrzeitVon)"
            class="mt-1 timepicker">
          <template v-slot:clearButton>
            <a style="color:purple; font-size: 1.0em">X</a>
          </template>
        </vue-timepicker>
      </column>
      <column  v-if="articles.Timed || articles.NumberOfPieces" class="ml-2">
        <label>Uhrzeit bis:</label><br>
        <vue-timepicker
            drop-direction="up"
            manual-input
            v-model="articles.UhrzeitBis"
            @change="bis(articles.UhrzeitBis)"
            class="mt-1 timepicker">
          <template v-slot:clearButton>
            <a style="color:purple; font-size: 1.0em">X</a>
          </template>
        </vue-timepicker>
      </column>
        </row>
      </div>
    </row>
  </main>
</template>
<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  name: "TimedArticles",
  components: {VueTimepicker},
  props: {
    calendarID: Number,
    articles: {},
  },
  data() {
    return {
      articleExist: true,
      uhrVon: '',
      uhrBis: '',
      modaltimestart: false,
      modaltimeend: false,
      gesamtMinuten: '',
      timeStart2: this.articles.UhrzeitVon,
      timeEnd2: this.articles.UhrzeitBis,
      NumberOfPieces: false
    }
  },
  mounted() {
    this.$root.$refs.TimedArticles = this;
    this.anzahlCalculated = this.articles.AmountUnit
  },
  computed: {
    timeStart() {
      return this.uhrVon;
    },
    timeEnd() {
      return this.uhrBis;
    },
    anzahlCalculated() {

      let splitStart = this.uhrVon.split(':');
      let hourVon = splitStart[0]
      let minuteVon = splitStart[1]
      let splitEnd = this.uhrBis.split(':')
      let hourBis = splitEnd[0]
      let minuteBis = splitEnd[1]
      let startDate = "2021-1-1 " + hourVon + ":" + minuteVon + ":00"
      let endDate = "2021-1-1 " + hourBis + ":" + minuteBis + ":00"
      let uhrzeitVon = new Date(startDate);
      let uhrzeitBis = new Date(endDate);
      let diff = (uhrzeitBis.getTime() - uhrzeitVon.getTime());
      let hours = Math.floor(diff / (1000 * 60 * 60));
      diff -= hours * (1000 * 60 * 60);
      let mins = Math.floor(diff / (1000 * 60));
      diff -= mins * (1000 * 60);

      if (uhrzeitVon > uhrzeitBis) {
        return 0
      }

      if (this.timeStart === '' || this.timeEnd === '') {
        return 0;
      } else {
        var calc = hours * 60 + mins;
        let amount = "amount" + this.articles.ID
        this.$cookies.set(amount, calc)
        let cookieUhrzeitVon = "von" + this.articles.ID;
        let cookieUhrBis = "bis" + this.articles.ID;
        this.$cookies.set(cookieUhrzeitVon, this.uhrVon)
        this.$cookies.set(cookieUhrBis, this.uhrBis)
        return calc
      }
    }
  },
  created() {
    this.uhrVon = this.articles.UhrzeitVon === '' ? '' : (this.articles.UhrzeitVon.HH + ":" + this.articles.UhrzeitVon.mm);
    this.uhrBis = this.articles.UhrzeitBis === '' ? '' : (this.articles.UhrzeitVon.HH + ":" + this.articles.UhrzeitVon.mm);
  },
  methods: {
    pieces() {
      let amount = "amount" + this.articles.ID
      this.$cookies.remove(amount)
    },
    von(v) {
      if (this.timeStart2 === undefined) {
        this.uhrVon = v.HH + ":" + v.mm;
      } else if (this.uhrVon !== '' || this.uhrVon === undefined) {
        this.uhrVon = v;
      } else {
        this.uhrVon = v.HH + ":" + v.mm;
      }
    },
    bis(b) {
      if (this.timeEnd2 === undefined) {
        this.uhrBis = b.HH + ":" + b.mm;
      } else if (this.uhrBis !== '' || this.uhrBis === undefined) {
        this.uhrBis = b;
      } else {
        this.uhrBis = b.HH + ":" + b.mm;
      }
    },
    GetAmountUnit() {
      return this.anzahlCalculated;
    },
    GetUhrzeitVon() {
      return this.uhrVon;
    },
    GetUhrzeitBis() {
      return this.uhrBis;
    },
    GetTimeStart(time) {
      this.uhrVon = time;
    },
    GetTimeEnd(time) {
      this.uhrBis = time;
    },
    async DeleteArticle(id) {
      await this.$store.dispatch('serviceArticles/deleteCalendarToPatientServiceArticleByID', id)
      this.$emit("RenderComponent")
    },
    GetValueForTextField(article) {
      if (article.ByCalendarEntry) {
        return "Kalendereintrag"
      }
      if (article.Timed) {
        return "Minuten"
      }
      if (article.NumberOfPieces) {
        return "Stückzahl"
      }
    },
    GetTextForLabel(article) {
      if (article.Timed) {
        return "Anzahl Minuten eingeben"
      }
      if (article.NumberOfPieces) {
        return "Stückzahl eingeben"
      }
    },
  }
}
</script>

<style scoped>
.timepicker {
  font-size: 1.1em;
  color: white !important;
  background-color: rgb(236, 201, 216);
}
</style>