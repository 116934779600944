<template>
    <v-row>
        <v-col>
            <v-text-field
                v-model="medicationitem.Medication"
                label="Medikament"
                placeholder=""
                hide-details="auto"
            
            >
            </v-text-field>
        </v-col>
        <v-col>
            <v-text-field
                v-model="medicationitem.Dosis"
                label="Dosis"
                placeholder=""
                hide-details="auto"
                
            >
            </v-text-field>
        </v-col>
        <v-col>
            <v-checkbox
                v-model="medicationitem.AtMorning"
                :label="`Morgens`"
            ></v-checkbox>
        </v-col>
        <v-col>
            <v-checkbox
                v-model="medicationitem.AtMidday"
                :label="`Mittags`"
            ></v-checkbox>
        </v-col>
        <v-col>
            <v-checkbox
                v-model="medicationitem.AtEvening"
                :label="`Abends`"
            ></v-checkbox>
        </v-col>
        <v-col>
            <v-btn
                color="primary"
                @click="SaveMedication()"
            >
            <i class="fas fa-save"></i>
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props:{
        patientID: Number,
        medicationitem: {}
    },
    methods:{
        SaveMedication(){
            console.log("med: "  + this.medicationitem.Medication)
            console.log("patientID: " + this.patientID)
            let medication = {
                Medication: this.medicationitem.Medication,
                Dosis: this.medicationitem.Dosis,
                AtMorning: this.medicationitem.AtMorning,
                AtMidday:  this.medicationitem.AtMidday,
                AtEvening: this.medicationitem.AtEvening,
                PatientID: this.patientID,
            }
            console.log(JSON.stringify(medication))
            this.$store.dispatch('patientMedication/newMedication', medication)
            this.$emit('Render')
        }
    }
}
</script>