import {
    templateService
} from '../services';

export const templates = {
    namespaced: true,
    state: {
        all: {},
        templateinvoices: {}
    },
    getters:{
        getTemplateInvoices: state => {
            return state.templateinvoices.items
        },
    },
    actions: {
        reset({
            commit
        }) {
            commit('reset');
        },
        getAll({
            commit
        }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                templateService.getAll()
                    .then(
                        data => {
                            commit('getAllSuccess', data)
                            resolve(data)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            })
        },

        newTemplate({
            commit
        }, data) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                templateService.create(data)
                    .then(
                        data => {
                            commit('newSuccess', data);
                            resolve(data)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        update({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                templateService.update(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        delete({
            commit
        }, id) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                templateService.deleteById(id)
                    .then(
                        insurance => {
                            commit('deleteSuccess', insurance);
                            resolve(insurance)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        createInvoiceTemplate({commit}, templateDTO){
            return new Promise((resolve, reject) => {
                templateService.createInvoiceTemplate(templateDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        getAllInvoiceTemplates({commit}){
            return new Promise((resolve, reject) => {
                templateService.getAllInvoiceTemplates().then(
                    payload => {
                        commit('getAllInvoiceTemplatesSuccess', payload)
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        changeInvoiceTemplate({commit}, templateDTO){
            return new Promise((resolve, reject) => {
                templateService.changeInvoiceTemplate(templateDTO).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
        deleteInvoiceTemplateByID({commit}, templateID){
            return new Promise((resolve, reject) => {
                templateService.deleteInvoiceTemplateByID(templateID).then(
                    payload => {
                        resolve(payload)
                    },
                    error => {
                        commit('getFailure', error);
                        reject(error);
                    }
                )
            })
        },
    },
    mutations: {
        getAllInvoiceTemplatesSuccess(state, payload){
            state.templateinvoices = {items: payload}
        },
        reset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },
        newRequest(state) {
            state.new = {
                loading: true
            }; //Wird geladen status
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            };
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },
        deleteRequest(state) {
            state.delete = {
                loading: true
            }; //Wird geladen status
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            }; //Das neu angelegte Patient Objekt das vom Server zurückgegeben wird
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            }; //Die Fehlermeldung
        },

        updateRequest(state) {
            state.update = {
                loading: true
            }; //Wird geladen status
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },

        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, data) {
            state.all = {
                items: data
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        }
    }
}