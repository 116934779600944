import {
    authHeader
} from '../helpers';

export const insuranceService = {
    getAll,
    deleteInsuranceById,
    getInsurance,
    newAddress,
    updateAddress,
    deleteAddress,
    newContact,
    updateContact,
    deleteContact,
    updateInsurance,
    deleteBankaccountById,
    updateBankaccount,
    newBankaccount,
    create,
    updateInsurancePaymentAddress
};

function updateInsurancePaymentAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/paymentaddress/change/` + address.ID, requestOptions).then(handleResponse);
}

function getAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurances`, requestOptions).then(handleResponse);
}

function getInsurance(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/id/` + id, requestOptions).then(handleResponse);
}

function updateInsurance(insurance) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(insurance)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/change/` + insurance.ID, requestOptions).then(handleResponse);
}

function deleteInsuranceById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/delete/id/` + id, requestOptions).then(handleResponse);
}


function newAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/address`, requestOptions).then(handleResponse);
}

function updateAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/address/change/` + address.ID, requestOptions).then(handleResponse);
}

function deleteAddress(address) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/address/delete/id/` + address.ID, requestOptions).then(handleResponse);
}

function newContact(contact) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(contact)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/contact`, requestOptions).then(handleResponse);
}
function updateContact(contact) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(contact)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/contact/change/` + contact.ID, requestOptions).then(handleResponse);
}
function deleteContact(contact) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(contact)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/contact/delete/id/` + contact.ID, requestOptions).then(handleResponse);
}

function create(insurance) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(insurance)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance`, requestOptions).then(handleResponse);
}

function newBankaccount(bankaccount) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(bankaccount)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/bankaccount`, requestOptions).then(handleResponse);
}

function updateBankaccount(bankaccount) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(bankaccount)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/bankaccount/change/` + bankaccount.ID, requestOptions).then(handleResponse);
}

function deleteBankaccountById(bankaccount) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(bankaccount)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/insurance/bankaccount/delete/id/` + bankaccount.ID, requestOptions).then(handleResponse);
}









function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}