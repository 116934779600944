import {authHeader} from '../helpers';

export const settingsService = {
    getAllSpecialHolidaysByBundesland,
    saveSpecialHolidayConfig,
    getSpecialHolidayConfig,
}

async function getSpecialHolidayConfig(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/settings/holidays/special/config/get`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function saveSpecialHolidayConfig(specialHolidayConfigDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(specialHolidayConfigDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/settings/holidays/special/save`, requestOptions)
    let data = await handleResponse(response)
    return data
}


async function getAllSpecialHolidaysByBundesland(bundesland){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/settings/holidays/special/get/` + bundesland, requestOptions)
    let data = await handleResponse(response)
    return data
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}