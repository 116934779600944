<template>
  <div>
    <h4><i class="fas fa-building"></i> Alle Kassen</h4>
    <div class="dataTables_wrapper">
      <v-data-table
          :headers="headers"
          :items="insurances.items"
          item-key="id"
          class="elevation-1 hdl"
          :loading="isLoading"
          loading-text="Wird geladen"
          :search="search"
          no-data-text="keine Daten vorhanden"
          no-results-text="keine Suchergebnisse"
          :custom-filter="filterOnlyCapsText"
          locale="de-de"
          @click:row="rowClick"
          :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',
        }"
      >

        <template v-slot:top>
          <v-text-field
              v-model="search"
              clearable
              label="Suchen"
              class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.isCareHealthInsurance="{ item }">
          <v-simple-checkbox
              v-model="item.isCareHealthInsurance"
              disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.isNormalInsurance="{ item }">
          <v-simple-checkbox
              v-model="item.isNormalInsurance"
              disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getData"> Neu laden</v-btn>
        </template>


      </v-data-table>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      headers: [
        {text: "ID", value: "ID", align: ' d-none'},
        {text: "Name", value: "InsuranceName"},
        {text: "Debitoren-Nr.", value: "DebitorenNumber", align: ' d-none'}, // ' d-none' hides the column but keeps the search ability
        {text: "PK", value: "isCareHealthInsurance"},
        {text: "KK", value: "isNormalInsurance"}
      ],
      search: "",
    };
  },
  computed: {
    insurances() {
      let insurances = this.$store.state.insurances.all;
      // if (insurances.items != undefined) {
      //   insurances.items.shift();
      // }
      return insurances;
    },
    isLoading() {
      return this.$store.state.insurances.all.loading;
    },
  },
  mounted() {
    this.$root.$on("reload_insurance_list", () => {
      sleep(500).then(() => {
        this.getData();
      });
    });
  },
  created() {
    this.getData();
  },
  destroyed() {
  },
  methods: {
    rowClick: function (item, row) {
      if (!this.clicked) {
        console.log(row)
        this.clicked = true;
        this.$router.push("/insurance/" + item.ID);
        this.$root.$emit("reload_insurance_details");
        this.clicked = false;
      }

    },
    deleteItem(item) {
      if (
          confirm("Möchten Sie die Kasse " + item.InsuranceName + " wirklich löschen?")
      ) {
        this.$store
            .dispatch("insurances/deleteInsuranceById", item.ID)
            .then(() => {
              this.$root.$emit("reload_insurance_list");
            });
      }
    },

    submit() {
      console.log(this.user);
    },
    getData() {
      this.$store.dispatch("insurances/reset");
      this.$store.dispatch("insurances/getAll").then((response) => {
        sleep(1500).then(() => {
          console.log(response);
        });
      });
    },
  },
};

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
</script>

<style>
#myShortTable_length,
#myShortTable_info {
  display: none;
}

#myShortTable > thead > tr > th {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable > thead > tr {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable thead {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody tr {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable tbody td {
  width: 100% !important;
  display: block;
  overflow: hidden;
}

#myShortTable_paginate {
  display: none;
}
</style>