<template>
  <main>
    <v-card-title class="Personal">
      <v-icon class="Personal">mdi-account-multiple</v-icon>
      <h5>PERSONAL</h5>
    </v-card-title>
    <v-card-subtitle> Gesamt</v-card-subtitle>
    <v-container fluid>

      <div style="margin: -10px 5px 10px 5px; padding: 10px;background-color: #ffd3d3; color:black">
        <v-row class="rowTitle">
          <v-col class="customSumSUM"> ∑</v-col>
          <v-col>Im Dienst</v-col>
          <v-col>Urlaub</v-col>
          <v-col>Krank</v-col>
          <v-col>Überstunden</v-col>
        </v-row>
        <v-row class="customSumSUM">
          <v-col>{{ SumsumPersonalAUSAndHN }}</v-col>
          <v-col>{{ sumPersonalAUS }}</v-col>
          <v-col>{{ SumPersonalHN }}</v-col>
          <v-col>{{ SumSickAUSAndHN }}</v-col>
          <v-col>{{ SumOvertimeAUSAndHN }}</v-col>
        </v-row>
      </div>
      <v-col class="mt-4  ">
        <v-row dense>
          <v-col>
            <v-select
                prepend-icon="mdi-account"
                label="Mitarbeiter"
                placeholder="John Doe"
            >
              Mitarbeiterliste
            </v-select>
          </v-col>
          <v-row dense>
            <v-col cols="6">
              <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="computedDateFormattedMomentjs"
                      label="Monat"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    type="month"
                    locale="de"
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-btn color="warning" class="mt-6" outlined small style="color: white" @click="nextMonth">+</v-btn>
            <v-btn color="warning" class="mt-6 ml-2 mr-2" outlined small @click="prevMonth">-</v-btn>
          </v-row>
        </v-row>
        <div style="margin: -10px 5px 10px 5px; padding: 10px; border: 1px solid snow">
          <v-row class="rowTitle">
            <v-col>Urlaubsanspruch</v-col>
            <v-col class="customTitle">Genommen</v-col>
            <v-col>Resturlaub</v-col>
            <v-col>Krank/Fehltage</v-col>
            <v-col>Überstunden</v-col>
          </v-row>
          <v-row>
            <v-col>{{ personalVacationEntitlement }}</v-col>
            <v-col>{{ personalVacationTaken }}</v-col>
            <v-col>{{ PersonalVacationRest }}</v-col>
            <v-col>{{ personalSickDay }}</v-col>
            <v-col>{{ personalOvertime }}</v-col>
          </v-row>
        </div>
      </v-col>


    </v-container>
  </main>
</template>

<script>
import moment from 'moment'

export default {
  name: "DashboardStaff",
  computed: {
    computedDateFormattedMomentjs() {
      moment.locale("de");
      return this.date ? moment(this.date).format('MMMM  YYYY') : '';
    },
    SumsumPersonalAUSAndHN() {
      return this.sumPersonalAUS + this.SumPersonalHN + this.SumSickAUSAndHN;
    },
    PersonalVacationRest() {
      return this.personalVacationEntitlement - this.personalVacationTaken;
    }
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      sumPersonalAUS: 17,
      SumPersonalHN: 19,
      SumSickAUSAndHN: 8,
      SumOvertimeAUSAndHN: 307,
      personalVacationEntitlement: 28,
      personalVacationTaken: 11,
      personalSickDay: 5,
      personalOvertime: 27,

    }
  },
  methods: {
    nextMonth() {
      this.date = moment(this.date).add(1, 'months');

    },
    prevMonth() {
      this.date = moment(this.date).add(-1, 'months');
    },
  }
}
</script>

<style scoped>

.rowTitle {
  font-weight: bold;
}
</style>