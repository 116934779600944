<template>
  <div>
    <div class="dataTables_wrapper">
      <v-data-table
        :headers="headers"
        item-key="ID"
        :items="devicestable"
        class="elevation-1 hdl"
        :loading="isLoading"
        loading-text="Wird geladen"
        :search="search"
        no-data-text="keine Daten vorhanden"
        no-results-text="keine Suchergebnisse"
        locale="de-de"
        :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Einträge pro Seite',                
                }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Suchen"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.Available="{ item }">
          <v-simple-checkbox
            v-model="item.Available"
            disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getData"> Neu laden </v-btn>
        </template>
        <template v-slot:body="{items}">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.ID"
              @click="GotoDeviceDetails()"
            >
              <td>{{item.ID}}</td>
              <td>
                  <v-simple-checkbox
                      v-model="item.Active"
                      disabled
                  ></v-simple-checkbox>
              </td>
              <td>{{item.Description}}</td>
              <td>{{item.PatientID}}</td>
              <td>{{item.Nextmaintainance}}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  created(){
    this.$store.dispatch('inventory/getAllInventoryDevicesTable');
  },
  computed: {
    isLoading() {
      return false;
    },
    devicestable(){
      return this.$store.state.inventory.devicestable.items
    }
  },
  data(){
    return{
      search: '',
      checkbox: true,
      headers:[
          {text: 'ID', value: 'ID'},
          {text: 'Aktiv', value: 'Active'},
          {text: 'Beschreibung', value: 'Description'},
          {text: 'KlientID', value: 'PatientID'},
          {text: 'Nächster Wartungstermin', value: 'Nextmaintainance'},
      ],
    }
  },
  methods:{
    GotoDeviceDetails(){
      this.$router.push('/inventory/device/details');
    }
  }
};
</script>

<style scoped>
  .highlightrow{
    background-color: #006ab5;
  }
</style>
