import {authHeader} from '../helpers';
import {userService} from '../services';
import {store} from '../store'

export const employeeService = {
    getAll,
    getAllTaxclasses,
    getAllMaritalstatuses,
    getAllEmploymenttypes,
    getAllEmployeeActivities,
    getEmployeeOverview,
    getSingleEmployeeByID,
    changeEmployeeOverview,
    uploadFile,
    downloadFile,
    getFiles,
    createEmployeeOverview,
    createEmployeeCalendar,
    getAllEmployeeCalendars,
    changeEmployeeCalender,
    deleteEmployeeCalendar,
    getAllEmployeesTable,
    getWorkTimePerDay,
    createHolidayRange,
    getHolidayForOneEmployee,
    createHolidayOneDay,
    getWorkTimePerDayToCreateNewEvent,
    deleteEmployeeHoliday,
    getAllEmployeeTimeTables,
    createEmployeeTimeTable,
    calculateOvertimeForEachEmployee,
    saveDeparture,
    getAllEmployeeTimeTablesByEmployeeID,
    calculateHolidaysByEmployeeID,
    createEmployeeTimeRecord,
    getEmployeeTimeRecordsByEmployeeID,
    DeleteEmployeeTimeRecordsByID,
    DeleteEmployeeByID,
    DeleteFile,
    saveTimeRecordComment,
    createEmployeeCalendarWithInputScreen,
    getAllCalendarsByEmployeeID,
    getAllFeiertageByYearByBundesland,
    changeEmployeeIDInCalendarTable,
    getAllEmployeesWithRole,
    copyCalendar
};

async function getAllFeiertageByYearByBundesland(year){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/feiertage/get/` + year, requestOptions);
    let data = await handleResponse(response);
    return data;
}


async function getAll(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getAllEmployeesWithRole(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/getAllWithRoles`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

//Wird benutzt
function getAllTaxclasses(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/employee/taxclasses`, requestOptions).then(handleResponse);
}

function getAllMaritalstatuses(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/employee/maritalstatuses`, requestOptions).then(handleResponse);
}

function getAllEmploymenttypes(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/employee/employmenttypes`, requestOptions).then(handleResponse);
}

function getAllEmployeeActivities(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_API_ROUTE + `/employee/activities`, requestOptions).then(handleResponse);
}

async function getEmployeeOverview(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/overview/get/id/` + id, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getSingleEmployeeByID(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/get/id/` + id, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function changeEmployeeOverview(id, employeeoverview){
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(employeeoverview),
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/overview/change/id/` + id, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function uploadFile(id, files){
    const formData = new FormData();
    files.forEach(file => formData.append('file', file))
    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/file/upload/` + id, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function downloadFile(fileID){
    /*let array = filename.split(".")
    let name = array[0]
    let ending = array[1]
    console.log(name)
    console.log(ending)
    console.log(id)*/
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    //let url = process.env.VUE_APP_API_ROUTE + `/employee/file/download/`+ id +`/` +name+ `/` + ending
    //console.log(url)
    //let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/file/download/`+ id +`/` + name + `/` + ending, requestOptions)
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/file/download/` + fileID, requestOptions)
    console.log("test")
    let data = await downloadFileStream(response)
    //let data = await handleResponse(response)
    return data
}

async function getFiles(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/files/id/` + id, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function createEmployeeOverview(employeeoverview){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(employeeoverview),
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/overview/create`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function createEmployeeCalendar(event){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(event),
        headers: authHeader()
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/create`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllEmployeeCalendars(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/get`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function changeEmployeeCalender(event){
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(event),
        headers: authHeader()
    }
    console.log(JSON.stringify(event))
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/change`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function deleteEmployeeCalendar(event){
    const requestOptions = {
        method: 'DELETE',
        body: JSON.stringify(event),
        headers: authHeader()
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/delete`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllEmployeesTable(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/table/get`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getWorkTimePerDay(eventid, employeeid, timestampstart, timestampend){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/calculations/worktimeperday/`+ eventid +`/`+ employeeid +`/` +timestampstart + `/` + timestampend, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function createHolidayRange(holidayrange){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(holidayrange),
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/holiday/createrange`, requestOptions)
    let data =  await handleResponse(response)
    return data
}

async function getHolidayForOneEmployee(employeeid){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/holiday/get/holidayforoneemployee/` + employeeid, requestOptions)
    let data =  await handleResponse(response)
    return data
}

async function createHolidayOneDay(holidayoneday){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(holidayoneday),
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/holiday/createoneday`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getWorkTimePerDayToCreateNewEvent(employeeid, timestampday){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/calculations/worktimeperdaytocreatenewevent/` + employeeid + `/` + timestampday, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function deleteEmployeeHoliday(holidayevent){
    const requestOptions = {
        method: 'DELETE',
        body: JSON.stringify(holidayevent),
        headers: authHeader()
    }
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/holiday/delete`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllEmployeeTimeTables(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/timetable/get`,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function createEmployeeTimeTable(employeetimetable){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employeetimetable)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/timetable/create`,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function calculateOvertimeForEachEmployee(employeeid){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/calculations/overtime/` + employeeid,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function saveDeparture(sendDeparture){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(sendDeparture)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/timetable/departure/save`,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllEmployeeTimeTablesByEmployeeID(employeeID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/timetable/get/employee/id/` + employeeID,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function calculateHolidaysByEmployeeID(employeeID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/holidays/calculate/` + employeeID,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function createEmployeeTimeRecord(employeeTimeRecord){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employeeTimeRecord)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/timerecord/create`,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getEmployeeTimeRecordsByEmployeeID(employeeID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/timerecord/get/employeeid/` + employeeID,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function DeleteEmployeeTimeRecordsByID(timerecordID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employees/timerecord/delete/` + timerecordID,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function DeleteEmployeeByID(employeeID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/delete/id/` + employeeID,requestOptions)
    let data = await handleResponse(response)
    return data
}

async function DeleteFile(DeleteFile){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(DeleteFile)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/file/delete/`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function saveTimeRecordComment(saveTimeRecordCommentDTO){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(saveTimeRecordCommentDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/timerecord/comment`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function createEmployeeCalendarWithInputScreen(createCalendarWithInputScreenDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(createCalendarWithInputScreenDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/inputscreen/create`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function getAllCalendarsByEmployeeID(employeeID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/filter/get/` + employeeID, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function changeEmployeeIDInCalendarTable(changeEmployeeIdInCalendarDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(changeEmployeeIdInCalendarDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/calendar/change/employeeid`, requestOptions)
    let data = await handleResponse(response)
    return data
}

async function copyCalendar(copyCalendarDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(copyCalendarDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/employee/roster/calendar/copy`, requestOptions)
    let data = await handleResponse(response)
    return data
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

/*function handleResponseFile(response){
    return response.blob().then(blob => {
        if(!response.ok){
            if(response.status === 401){
                userService.logout();
                location.reload(true)
            }
            const error = (blob && blob.message) || response.statusText;
            return Promise.reject(error);
        }
        const objectURL = window.URL.createObjectURL(blob);
        return objectURL
    })
}*/

async function downloadFileStream(response){
    console.log("downloadFileStream")
    const contenttype = response.headers.get('Content-Type')
    let splitcontenttypearray = contenttype.split(';')
    console.log("contenttype:" + contenttype)
    let filename = getValueFromSplitContenttypeArray(splitcontenttypearray[0])
    let filesize = getValueFromSplitContenttypeArray(splitcontenttypearray[1])
    store.commit('loading/setTotalBytes', Number(filesize))
    console.log("filename: " + filename)
    const reader = response.body.getReader()
    //let receivedLength = 0
    let chunks = []
    let finished = false
    store.commit('loading/setLoadedBytestoZero')
    while(!finished){
        const {done, value} = await reader.read()
        //console.log("done: " + done)
        if(done){
            break
        }
        chunks.push(value)
        store.commit('loading/setLoadedBytes', value.length)
        //receivedLength += value.length
        //console.log(`Received ${receivedLength}`)
        //console.log(`Content-Disposition ${contentdisposition}`)
        finished = done
    }
    let blob = new Blob(chunks)
    let fileInformation = {
        fileblob: blob,
        filename: filename,
        filesize: filesize
    }
    return fileInformation
}

function getValueFromSplitContenttypeArray(splitcontenttype){
    let array = splitcontenttype.split('=')
    let value = array[1]
    return value
}





