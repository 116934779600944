<template>
  <v-container fluid>
    <h3 class="mb-4">Urlaubs- und Fehlzeiten</h3>
    <v-row>
      <v-col>
        <v-text-field
            label="Urlaubstage"
            v-model="data.Holidays"
            dense
            required
            ref="Holidays"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="genommen"
            v-model="calculationdata.HolidaysTaken"
            dense
            required
            ref="HolidaysTaken"
            disabled
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="geplant"
            v-model="calculationdata.HolidaysPlanned"
            dense
            required
            ref="HolidaysPlanned"
            disabled
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="offen"
            dense
            readonly
            :value="data.Holidays - calculationdata.HolidaysTaken"
            disabled
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-text-field
            label="Krankheitstage"
            v-model="calculationdata.SickDaysTaken"
            dense
            required
            ref="SickDays"
            disabled
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-text-field
            label="Überstunden"
            v-model="calculationdata.Overtime"
            dense
            ref="Overtime"
            disabled
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            label="Überstundenabbau geplant"
            v-model="calculationdata.OvertimePlanned"
            dense
            ref="OvertimePlanned"
            disabled
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            label="Überstunden Übertrag"
            v-model="data.OvertimeTransfer"
            dense
            required
            ref="OvertimeTransfer"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    data: {},
    disableinput: Boolean,
    employeeovertime: String,
    holidaysPlanned: String,
    calculationdata: {}
  },
  created() {
    this.data = {
      Holidays: 0
    },
        this.calculationdata = {
          HolidaysPlanned: 0,
          HolidaysTaken: 0,
          Overtime: 0,
          OvertimePlanned: 0,
          SickDaysTaken: 0
        }
  }
}
</script>