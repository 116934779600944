<template>
  <v-form ref="form">
    <v-col class="densecol mb-0 pb-0">
      <h2>Persönliche Daten</h2>
      <v-row class="mt-2">
        <div class="ml-2">
          <v-checkbox
              v-model="form.personaldata.Interested"
              :label="`Interessent`"
              @change="$emit('toggleInteressent', form.personaldata.Interested)"
          ></v-checkbox>
        </div>
        <div class="ml-2">
          <v-checkbox
              v-model="form.personaldata.Active"
              :label="`Aktiv`"
              @change="$emit('toggleActive', form.personaldata.Active)"
          ></v-checkbox>
        </div>
        <div class="ml-2">
          <v-checkbox
              v-model="form.personaldata.ServiceHousehold"
              :label="`Alltagsunterstützende Dienste`"
              @change="$emit('toggleServiceHouseHold', form.personaldata.ServiceHousehold)"
          ></v-checkbox>
        </div>
        <div class="ml-2">
          <v-checkbox
              v-model="form.personaldata.ServiceEmergencyCall"
              :label="`Hausnotruf`"
              @change="$emit('toggleServiceEmergencyCall', form.personaldata.ServiceEmergencyCall)"
          ></v-checkbox>
        </div>
        <div class="ml-2">
          <v-checkbox
              v-model="form.personaldata.Deceased"
              :label="`Verstorben`"
              @change="$emit('toggleDeceased', form.personaldata.Deceased)"
          ></v-checkbox>
        </div>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-text-field
              v-model="form.personaldata.PATNR"
              label="Klientennummer:"
              placeholder=""
              hide-details="auto"
              readonly
          >
          </v-text-field>
          <v-text-field
              v-model="form.personaldata.EmployeeID"
              label="Mitarbeiternummer:"
              placeholder="3"
              hide-details="auto"
              v-if="false"
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              v-model="form.personaldata.HealthInsuranceNumber"
              label="Versicherungsnummer:"
              placeholder="1234"
              hide-details="auto"
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              v-model="form.personaldata.HealthInsuranceAuthorizationNumbers"
              label="Krankenkassengenehmigungsnummer:"
              placeholder="1234"
              hide-details="auto"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
              v-model="form.personaldata.Title"
              label="Title:"
              :items="titlesPool.titlePool"
              placeholder="Anrede">
            hide-details="auto"
          </v-select>
        </v-col>

        <v-col>
          <v-text-field
              label="Vorname:"
              placeholder="Vorname"
              hide-details="auto"
              v-model="form.personaldata.FirstName"
              :error-messages="firstNameErrors"
              @input="$v.form.personaldata.FirstName.$touch()"
              @blur="$v.form.personaldata.FirstName.$touch()"
              @change="$emit('chipsPatientFirstname',form.personaldata.FirstName)"
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              label="*Nachname:"
              placeholder="Nachname"
              hide-details="auto"
              v-model="form.personaldata.LastName"
              :error-messages="lastNameErrors"
              required
              @input="$v.form.personaldata.LastName.$touch()"
              @blur="$v.form.personaldata.LastName.$touch()"
              @change="$emit('chipsPatientLastname',form.personaldata.LastName)"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
              v-model="form.personaldata.DateOfBirth"
              prepend-icon="mdi-calendar"
              label="Geburtsdatum:"
              placeholder="DD.MM.YYYY"
              hide-details="auto"
              :error-messages="dateErrors"
              @keyup="DateIsValid(form.personaldata.DateOfBirth)"
              @input="$v.form.personaldata.DateOfBirth.$touch()"
              @blur="$v.form.personaldata.DateOfBirth.$touch()"
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-select
              v-model="form.personaldata.CareDegree"
              label="Pflegegrad:"
              :items="caredegrees"
              placeholder="Pflegegrad"
              hide-details="auto"
          >
          </v-select>
        </v-col>

        <v-col>
          <v-text-field
              v-model="form.personaldata.CareDegreeSince"
              prepend-icon="mdi-calendar"
              label="Seit:"
              placeholder="DD.MM.YYYY"
              hide-details="auto"
              :error-messages="careDegreeSinceErrors"
              @input="$v.form.personaldata.CareDegreeSince.$touch()"
              @blur="$v.form.personaldata.CareDegreeSince.$touch()"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
              v-model="form.personaldata.PhoneNumber"
              prepend-icon="fas fa-phone-square"
              label="Telefonnummer:"
              placeholder="1234"
              hide-details="auto"
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              v-model="form.personaldata.AlternativelyPhoneNumber"
              prepend-icon="fas fa-phone-square"
              label="Alternative Telefonnummer:"
              placeholder="1234"
              hide-details="auto"
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              v-model="form.personaldata.MobileNumber"
              prepend-icon="fas fa-mobile-alt"
              label="Mobil:"
              placeholder="1234"
              hide-details="auto"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row style="padding-bottom: 20px">
        <v-col>
          <v-text-field
              v-model="form.personaldata.EMail"
              prepend-icon="fas fa-envelope"
              label="E-Mail:"
              placeholder="e-mail"
              hide-details="auto"
              :error-messages="emailErrors"
              @input="$v.form.personaldata.EMail.$touch()"
              @blur="$v.form.personaldata.EMail.$touch()"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
          v-model="form.personaldata.InsuranceInfo"
            label="Kranken-/Pflegekasse:"
            placeholder="Kranken-/Pflegekasse"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-form>
</template>

<script>
import {email, helpers, required} from 'vuelidate/lib/validators';
import {getUserFromJWT} from '../../helpers'

const alphaNumAndDotAndWhiteSpaceValidator = helpers.regex('alphaNumAndDot', /^[a-zA-Z.\s-,+äüöÄÜÖß]*$/);
const dateValidator = helpers.regex('dateFormat', /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/)


export default {
  props: {
    titlesPool: {},
    patientID: undefined,
  },
  validations: {
    form: {
      personaldata: {
        LastName: {required, alphaNumAndDotAndWhiteSpaceValidator},
        FirstName: {alphaNumAndDotAndWhiteSpaceValidator},
        EMail: {email},
        DateOfBirth: {dateValidator},
        CareDegreeSince: {dateValidator},
      }
    }
  },
  emits: [],
  // mixins: [titleMixin],
  async created() {
    await this.FillInForm()
  },
  data() {
    const defaultForm = Object.freeze({
      personaldata: {
        Title: undefined,
        FirstName: undefined,
        PATNR: undefined,
        LastName: undefined,
        Interested: undefined,
        ServiceHousehold: undefined,
        ServiceEmergencyCall: undefined,
        Deceased: undefined,
        Active: undefined,
        DateOfBirth: undefined,
        EMail: undefined,
        CareDegree: undefined,
        CareDegreeSince: undefined,
        PhoneNumber: undefined,
        AlternativelyPhoneNumber: undefined,
        MobileNumber: undefined,
        HealthInsuranceNumber: undefined,
        HealthInsuranceAuthorizationNumbers: undefined,
        EmployeeID: undefined,
        KlientID: undefined,
        DebitorenNumber: undefined,
        InsuranceInfo: undefined
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
      caredegrees: [0, 1, 2, 3, 4, 5],
      titlePool: this.titlesPool,
      dateIsValid: false,
    };
  },
  computed: {
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.personaldata.LastName.$dirty) return errors
      !this.$v.form.personaldata.LastName.required && errors.push('Nachname ist ein Pflichtfeld')
      !this.$v.form.personaldata.LastName.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Nachname darf keine Zahlen enthalten')
      return errors
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.personaldata.FirstName.$dirty) return errors
      !this.$v.form.personaldata.FirstName.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Vorname darf keine Zahlen enthalten')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.personaldata.EMail.$dirty) return errors
      !this.$v.form.personaldata.EMail.email && errors.push('Ungültige E-Mail Adresse')
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.$v.form.personaldata.DateOfBirth.$dirty) return errors
      !this.$v.form.personaldata.DateOfBirth.dateValidator && errors.push('Datumsformat ungültig')
      if (!this.dateIsValid) {
        errors.push("Datum liegt in der Zukunft")
      }
      return errors;
    },
    careDegreeSinceErrors() {
      const errors = []
      if (!this.$v.form.personaldata.CareDegreeSince.$dirty) return errors
      !this.$v.form.personaldata.CareDegreeSince.dateValidator && errors.push('Datumsformat ungültig')
      return errors;
    },

  },
  methods: {
    DateIsValid(dateToValidate) {
      var date = dateToValidate.toString();
      var splittedDate;

      if (date.includes('-')) {
        splittedDate = date.split('-');
      }
      if (date.includes('.')) {
        splittedDate = date.split('.');
      }
      if (date.includes('/')) {
        splittedDate = date.split('/');
      }
      date = [splittedDate[2], splittedDate[1], splittedDate[0]].join('/');
      var dateObject = new Date(date);
      var currentDate = new Date();
      this.dateIsValid = currentDate > dateObject;
    },
    async SavePersonalData() {


      // SavePersonalDataObject() {
      if (confirm("Möchten Sie den Klienten [" + this.form.personaldata.LastName + ", " + this.form.personaldata.FirstName + "] wirklich speichern?")) {
        //console.log("### calling SavePersonalDataObject() #");
        let user = getUserFromJWT()
        this.form.personaldata.EmployeeID = user.employeeID
        //console.log('SavePersonalDataObject: ' + JSON.stringify(this.form.personaldata));
        await this.$store.dispatch('patientoverview/createPatientPersonalData', this.form.personaldata)

      } else {
        // do nothing
      }
    },
    async ChangePersonalData() {
      if (confirm("Möchten Sie den Klienten [" + this.form.personaldata.LastName + ", " + this.form.personaldata.FirstName + "] wirklich speichern?")) {
        console.log("### calling ChangePersonalDataObject() #");
        let user = getUserFromJWT()
        this.form.personaldata.EmployeeID = user.employeeID
        console.log('ChangePersonalDataObject: ' + JSON.stringify(this.form.personaldata));
        await this.$store.dispatch('patientoverview/changePatientPersonalData', this.form.personaldata)
      } else {
        // do nothing
      }
    },
    ResetForm() {
      //console.log("# calling ResetFrom() of PatientPersonalData #");
      //this.patientObject.item = {}
      this.$refs.form.reset()
      //console.log('PatientPersonalData: ' + JSON.stringify(this.form.personaldata));
    },
    async FillInForm() {
      await this.$store.dispatch('patientoverview/getPatientPersonalDataById', this.patientID)
      this.form.personaldata = this.$store.getters['patientoverview/getPersonalData']
      // alert(this.form.personaldata.FirstName + " " + this.form.personaldata.LastName);
      this.$root.$refs.PatientChips.setFullName(this.form.personaldata.FirstName, this.form.personaldata.LastName);
      this.$root.$refs.PatientChips.setChips(
          this.form.personaldata.Interested,
          this.form.personaldata.ServiceEmergencyCall,
          this.form.personaldata.ServiceHousehold,
          this.form.personaldata.Active,
          this.form.personaldata.Deceased,
      );
      this.$root.$refs.PatientChips.setDebitorenNumber(this.form.personaldata.DebitorenNumber);
      this.$root.$refs.PatientsOverview.setDebitorenNumber(this.form.personaldata.DebitorenNumber);
    }
  },

};
</script>

<style scoped>

.error--text {
  color: red !important;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
}

</style>
