import Vue from 'vue'
import App from './App.vue'
import {router} from './router'
import {store} from './store'
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faVuejs } from '@fortawesome/free-brands-svg-icons'
import { dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Row, Column, Hidden} from 'vue-grid-responsive';
import VueCookies from 'vue-cookies'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css';

dom.watch();
library.add(fas)
library.add(faVuejs)
library.add(faSpinner)
Vue.component('row', Row)
Vue.component( 'column',Column)
Vue.component('hidden', Hidden);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vuelidate);
require('./assets/css/brandmauer.css');

Vue.use(VueCookies)
Vue.use(VueTimepicker)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

