<template>
    <div>
        <v-data-table
                :items="notes"
                :headers="headers"
                :search="search"
                single-select
                item-key="ID"
                isSelected="true"
                :items-per-page="5"
                class="elevation-1 hdl"
                :footer-props="{
                    itemsPerPageAllText: 'Alle',
                    itemsPerPageText: 'Eintr. pro Seite',                
                }"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="Suchen"
                        class="mx-4"
                    >
                    </v-text-field>
                </template>
                <template
                    v-slot:body="{ items}"
                >
                    <tbody>
                    <tr
                        v-for="item in items"
                        :key="item.ID"
                        @click="FillInTextfield(item)"
                    >
                        <td>{{item.Created.slice(0,10)}}</td>
                        <td>{{item.Text.slice(0, 30) + '...'}}</td>
                        <td>
                            <v-btn
                                @click="SendNoteIDtoDeleteNote(item.ID)"    
                            >
                              <v-icon color="primary" small>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
    </div>
</template>
<script>
//import {getUserFromJWT} from '../../helpers'
export default {
    created(){
        this.GetNotesByEmployeeID()
    },
    computed:{
        notes(){
            return this.$store.getters['note/getNotesByEmployee']
        },
    },
    props:{
        referenceEmployeeID: Number,
        referencePatientID: Number,
    },
    data(){
        return{
            search: '',
            headers:[
                {text: 'Erstellt', value: 'Created'},
                {text: 'Text', value: 'Text'},
                {text: 'Löschen'}
            ],
        }
    },
    methods:{
        FillInTextfield(note){
            this.$emit("SendNote", note)
        },
        async GetNotesByEmployeeID(){
            await this.$store.commit('note/setNotesByEmployeeEmpty')
            if(this.referencePatientID == undefined && this.referenceEmployeeID != undefined){
                await this.GetNotesReferencedEmployee()
            }
            if(this.referenceEmployeeID == undefined && this.referencePatientID != undefined){
                console.log("ich bin hier")
                await this.GetNotesReferencedPatient()
            } 
        },
        async GetNotesReferencedEmployee(){
            console.log("referenceEmployeeID")
                let dataEmployee = {
                    //employeeID: getUserFromJWT().employeeID,
                    referenceEmployeeID: this.referenceEmployeeID
                }
                let response = await this.$store.dispatch('note/getNotesReferencedEmployeeByEmployeeID', dataEmployee)
                console.log(response)
        },
        async GetNotesReferencedPatient(){
            console.log("referencePatientID")
                let dataPatient = {
                    //employeeID: getUserFromJWT().employeeID,
                    referencePatientID: this.referencePatientID
                }
                let response = await this.$store.dispatch('note/getNotesReferencedPatientByEmployeeID', dataPatient)
                console.log(response)
        },
        SendNoteIDtoDeleteNote(noteID){
            this.$emit("SendNoteID", noteID)
        }
    }
}
</script>