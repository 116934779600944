<template>
  <v-col>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="InsurancePaymentAddress.Title"
          :items="titles"
          tabindex="1"
          placeholder="Anrede"
          class="form-control"
          :disabled="locked"
          :class="{ 'is-invalid': submitted && !title }"
        >
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Vorname"
          placeholder="Vorname"
          hide-details="auto"
          :rules="rules"
          tabindex="3"
          :disabled="locked"
          v-model="InsurancePaymentAddress.FirstName"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Nachname"
          placeholder="Nachname"
          hide-details="auto"
          :rules="rules"
          tabindex="3"
          :disabled="locked"
          v-model="InsurancePaymentAddress.LastName"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Straße"
          placeholder="Straße"
          hide-details="auto"
          :rules="rules"
          tabindex="3"
          :disabled="locked"
          v-model="InsurancePaymentAddress.Street"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="PLZ"
          placeholder="12345"
          hide-details="auto"
          :rules="plzRules"
          tabindex="3"
          :disabled="locked"
          v-model="InsurancePaymentAddress.PLZ"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Ort"
          placeholder="Ort"
          hide-details="auto"
          :rules="rules"
          tabindex="3"
          :disabled="locked"
          v-model="InsurancePaymentAddress.City"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Telefon"
          placeholder="+49 1234 123456"
          hide-details="auto"
          :rules="phoneRules"
          tabindex="3"
          v-model="InsurancePaymentAddress.Phone"
          :disabled="locked"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="E-Mail"
          placeholder="E-Mail"
          hide-details="auto"
          :rules="mailRules"
          tabindex="3"
          v-model="InsurancePaymentAddress.Email"
          :disabled="locked"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="IBAN"
          placeholder=""
          hide-details="auto"
          :rules="ibanRules"
          tabindex="3"
          v-model="InsurancePaymentAddress.IBAN"
          :disabled="locked"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { validatePLZ }  from "../../helpers/validator"

export default {
  data() {
    return {
      InsurancePaymentAddress: this.insurancePaymentAddress,
      titles: ["Frau", "Herr", "Divers"],
      rules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
          (value && value.length >= 2) || "Mindestens 2 Zeichen eingeben",
      ],
      plzRules: [
        (value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
          (value && validatePLZ(value)) || "Keine gültige Postleitzahl",
      ],
    };
  },
  computed: {
    locked() {
      return this.disabled;
    }
  },
  props: {
    insurancePaymentAddress: {},
     disabled: Boolean
  },
  watch: {
    InsurancePaymentAddress: {
      deep: true,
      handler() {
        this.$emit("insurancePaymentAddress", this.InsurancePaymentAddress);
      },
    },
  },
};
</script>