<template>
  <v-form ref="form" :disabled="formdisabled">
    <div v-if="keyCount === 0" class="mb-4">Keine Schlüssel hinterlegt.</div>
    <div v-else class="mb-5"><b>Hinterlegte Schlüssel:</b></div>
    <div v-for="(find, index) in schluesselArr" v-bind:key="index">
      <v-row>
        <v-chip class="mt-4 mr-4">{{ index }}</v-chip>
        <v-text-field
            clearable
            v-model="find.Place"
            prepend-icon="mdi-folder-key-outline"
            label="Schlüsselort"></v-text-field>
        <v-text-field
            class="ml-4"
            clearable
            v-model="find.Position"
            prepend-icon="mdi-crosshairs-gps"
            label="Schlüsselposition"></v-text-field>
        <v-btn
            class="mt-4 ml-4"
            small color="primary"
            @click="deleteFind(index, find.ID)">
          <v-icon
              left
              style="margin-left: 5px">mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </v-row>
    </div>
    <div>
      <v-btn color="primary" class="mt-4" :disabled="formdisabled" @click="addFind">
        <v-icon left>mdi-key</v-icon>
        {{ btnText }}
      </v-btn>
    </div>
    <v-btn class="mt-4" :disabled="formdisabled" outlined @click="saveKeys">
      <v-icon>mdi-content-save</v-icon>
      Schlüsselorte speichern
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "KeyPlacement",
  props: {
    patientID: undefined,
    formdisabled: Boolean,
  },
  data() {
    return {
      keyCount: 0,
      schluesselArr: [],
      tmpArr: [],
    }
  },
  async mounted() {
    if (this.patientID !== undefined) {
      await this.$store.dispatch('placeforklientkeys/getKeysByPatientID', this.patientID);
      this.loadKeys()
    }
  },
  methods: {
    ResetForm() {
      this.$refs.form.reset()
      this.keyCount = 0
      this.schluesselArr = []
      this.tmpArr = []
    },
    loadKeys() {
      this.tmpArr = this.$store.state.placeforklientkeys.placeforklientkeys.item
      for (let i = 0; i < this.tmpArr.length; i++) {
        this.schluesselArr.push(this.tmpArr[i])
      }
      this.keyCount = this.tmpArr.length;
    },
    async saveKeys() {
      let IdArr = []
      // Befülle IdArr mit vorhandenen Schlüssel ID´s
      for (let i = 0; i < this.tmpArr.length; i++) {
        IdArr.push(this.tmpArr[i].ID)
      }
      // Lösche alle vorhandenen Schlüssel
      for (let i = 0; i < IdArr.length; i++) {
        await this.$store.dispatch("placeforklientkeys/deleteAll", IdArr[i]);
      }
      for (let i = 0; i < this.schluessel.length; i++) {
        let tmpKeyPlace = {
          place: this.schluessel[i].Place,
          position: this.schluessel[i].Position,
          patientID: this.patientID
        };
        await this.$store.dispatch('placeforklientkeys/addKeysByPatientID', tmpKeyPlace);
      }
      alert("Schlüssel gespeichert.")
    },
    addFind: function () {
      this.keyCount++;
      this.schluessel.push({ort: '', position: '', patientId: this.patientID});
    },
    deleteFind: function (index, id) {
      console.log("id to delete: " + id)
      if (confirm("Möchten Sie diesen Schlüssel mit der ID: " + index + " wirklich löschen?")) {
        if (id === undefined) {
          // do nothing
        } else {
          this.$store.dispatch("placeforklientkeys/delete", id);
        }
        this.keyCount--;
        this.schluessel.splice(index, 1);
      } else {
        // do nothing
      }
    }
  },
  computed: {
    schluessel() {
      return this.schluesselArr;
    },
    btnText() {
      if (this.keyCount === 0) {
        return ("Schlüssel hinzufügen")
      }
      return "weiteren Schlüssel hinzufügen";
    }
  }
}
</script>

<style scoped>
</style>