import { settingsService } from '../services';

export const settings = {
    namespaced: true,
    state: {
        holidayConfig: {},
        error: {},
        holidayConfigSettings: {}
    },
    getters:{
        getAllHolidayConfig: state =>{
            return state.holidayConfig
        },
        getHolidayConfigSettings: state =>{
            return state.holidayConfigSettings
        }
    },
    actions:{
        getAllSpecialHolidaysByBundesland({commit}, bundesland){
            return new Promise((resolve, reject)=>{
                settingsService.getAllSpecialHolidaysByBundesland(bundesland).then(
                    payload =>{
                        commit('getSpecialHolidayConfigSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        reject(error)
                    }
                )
            })
        },
        saveSpecialHolidayConfig({commit},specialHolidayConfigDTO){
            return new Promise((resolve, reject)=>{
                settingsService.saveSpecialHolidayConfig(specialHolidayConfigDTO).then(
                    payload =>{
                        resolve(payload)
                    },
                    error =>{
                        commit('getAllFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getSpecialHolidayConfig({commit},){
            return new Promise((resolve, reject)=>{
                settingsService.getSpecialHolidayConfig().then(
                    payload =>{
                        commit('getSpecialHolidayConfigSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getAllFailure', error)
                        reject(error)
                    }
                )
            })
        },
        getSpecialHolidayConfigSettings({commit},){
            return new Promise((resolve, reject)=>{
                settingsService.getSpecialHolidayConfig().then(
                    payload =>{
                        commit('getSpecialHolidayConfigSettingsSuccess', payload)
                        resolve(payload)
                    },
                    error =>{
                        commit('getAllFailure', error)
                        reject(error)
                    }
                )
            })
        }
    },
    mutations: {
        getSpecialHolidayConfigSettingsSuccess(state, payload){
            state.holidayConfigSettings = payload
        },
        getSpecialHolidayConfigSuccess(state, payload){
            state.holidayConfig = payload
        },
        getAllSpecialHolidaysByBundeslandSuccess(state, payload){
            state.specialHolidays = payload
        },
        getAllFailure(state, error) {
            state.error = { error };
        },
        emptyHolidayConfig(state){
            state.holidayConfig = {}
        }
    }
}