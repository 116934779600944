<template>
  <v-row>
    <v-col>
      <h3 class="mb-3">Template Abrechnungen</h3>
      <div v-for="item in templates" :key="item.ID">
        <TemplateInvoiceItem
          :form="item"
          :key="templateinvoiceitemkey"
          :changeTemplate="true"
        />
      </div>
      <v-btn
        color="primary"
        class="ml-2"
        @click="ShowEmptyTemplate()"
      >
        Neues Template
      </v-btn>
      <TemplateInvoiceItem
        v-if="showemptytemplate"
        :form="form.invoicetemplateDTO"
        :changeTemplate="false"
        @ShowNewTemplateList="ShowNewTemplateList"
      />
    </v-col>
  </v-row>
</template>

<script>
import TemplateInvoiceItem from './TemplateInvoiceItem'
export default {
  components: {
    TemplateInvoiceItem
  },
  created(){
    this.$store.dispatch('templates/getAllInvoiceTemplates')
  },
  computed:{
    templates(){
      return this.$store.getters['templates/getTemplateInvoices']
    }
  },
  data() {
    const defaultForm = Object.freeze({
      invoicetemplateDTO: {
        ID: undefined,
        Text: undefined,
        TemplateName: undefined,
        SubjectBeside: false,
        BegintextText: false,
        EndtextText: false,
        EndtextTermsOfPayment: false,
      }
    })
    return {
      defaultForm,
      form: Object.assign({}, defaultForm),
      showemptytemplate: false,
      templateinvoiceitemkey: 0
    };
  },
  methods: {
    ShowEmptyTemplate(){

      this.form.invoicetemplateDTO = {
        ID: undefined,
        Text: undefined,
        TemplateName: undefined,
        SubjectBeside: false,
        BegintextText: false,
        EndtextText: false,
        EndtextTermsOfPayment: false,
      }

      this.showemptytemplate = !this.showemptytemplate
    },
    RenderTemplateInvoiceItem(){
      this.templateinvoiceitemkey++
    },
    ShowNewTemplateList(){
      this.RenderTemplateInvoiceItem()
      this.showemptytemplate = false
    }
  },
}
</script>

<style scoped>

</style>