<template>
  <v-container fluid>
    <h3 class="mb-4">Persönliche Daten</h3>
    <v-row>
      <v-col>
        <v-text-field
            label="Personalnummer *"
            v-model="data.Personalnumber"
            dense
            required
            ref="Personalnumber"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Tätigkeit"
            v-model="data.EmployeeActivity"
            dense
            required
            ref="EmployeeActivity"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            label="Vorname"
            v-model="data.Firstname"
            dense
            required
            ref="Firstname"
            :disabled="disableinput"
            :error-messages="firstNameErrors"
            @input="$v.data.Firstname.$touch()"
            @blur="$v.data.Firstname.$touch()"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Nachname"
            v-model="data.Lastname"
            dense
            required
            ref="Firstname"
            :disabled="disableinput"
            :error-messages="lastNameErrors"
            @input="$v.data.Lastname.$touch()"
            @blur="$v.data.Lastname.$touch()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            label="Geburtstdatum"
            v-model="data.DateOfBirth"
            dense
            prepend-icon="mdi-calendar"
            required
            ref="DateOfBirth"
            placeholder="jjjj-mm-tt"
            :disabled="disableinput"
            :rules="dateRules"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Mitarbeiter seit"
            v-model="data.StartOfWork"
            dense
            prepend-icon="mdi-calendar"
            required
            ref="StartOfWork"
            placeholder="jjjj-mm-tt"
            :disabled="disableinput"
            :rules="dateRules"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Start der Zeiterfassung"
            v-model="data.TimeRecordingSince"
            dense
            required
            prepend-icon="mdi-calendar"
            ref="TimeRecordingSince"
            placeholder="jjjj-mm-tt"
            :disabled="disableinput"
            :rules="dateRules"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
            label="Straße / Hausnr"
            v-model="data.Street"
            dense
            required
            ref="Street"
            :disabled="disableinput"
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
            label="PLZ"
            v-model="data.PLZ"
            dense
            required
            ref="PLZ"
            :disabled="disableinput"
            :error-messages="PLZErrors"
            @input="$v.data.PLZ.$touch()"
            @blur="$v.data.PLZ.$touch()"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Ort"
            v-model="data.City"
            dense
            required
            ref="City"
            :disabled="disableinput"
            :error-messages="CityErrors"
            @input="$v.data.City.$touch()"
            @blur="$v.data.City.$touch()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            label="Telefonnummer"
            v-model="data.PhoneNumber"
            dense
            prepend-icon="mdi-phone"
            required
            :disabled="disableinput"
            ref="PhoneNumber"
            placeholder="+49 1234 12345678"

        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Email"
            v-model="data.Email"
            dense
            prepend-icon="mdi-email"
            required
            :disabled="disableinput"
            ref="Email"
            placeholder="name@domain.de"
            :error-messages="emailErrors"
            @input="$v.data.Email.$touch()"
            @blur="$v.data.Email.$touch()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
            v-model="data.EmployeeColorHex"
            label="Farbe"
            placeholder="Farbe wählen"
            disabled
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <div
            class="square"
            v-bind:style='{backgroundColor: data.EmployeeColorHex}'
        >
        </div>
      </v-col>
      <v-col cols="2">
        <v-btn
            class="ml-2 colorbutton"
            color="primary"
            @click="showColor()"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
          Farbe wählen
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showcolorpicker">
      <v-color-picker
          v-model="colorpicker"
          dot-size="25"
          mode="hex"
          swatches-max-height="100"
          hide-inputs
      ></v-color-picker>
    </v-row>
  </v-container>
</template>
<script>

import {helpers, numeric,minLength, maxLength, email} from 'vuelidate/lib/validators';

const alphaNumAndDotAndWhiteSpaceValidator = helpers.regex('alphaNumAndDot', /^[a-zA-Z.\s-,+äüöÄÖÜß]*$/);

export default {
  props: {
    data: {},
    dropdown: {},
    disableinput: Boolean
  },
  computed: {
    color() {
      if (this.colorpicker == undefined) {
        return 'keine Farbe ausgewählt'
      } else {
        return this.colorpicker.hex
      }
    },
    PLZErrors() {
      const errors = []
      if (!this.$v.data.PLZ.$dirty) return errors
      !this.$v.data.PLZ.numeric && errors.push('Nur Zahlen erlaubt')
      !this.$v.data.PLZ.minLength && errors.push('Maximal 5 Zeichen')
      !this.$v.data.PLZ.maxLength && errors.push('Anzahl der erlaubten Zeichen überschritten')
      return errors
    } ,
    CityErrors() {
      const errors = []
      if (!this.$v.data.City.$dirty) return errors
      !this.$v.data.City.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Keine Zahlen erlaubt')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.data.Email.$dirty) return errors
      !this.$v.data.Email.email && errors.push('Ungültige E-Mail Adresse')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.data.Lastname.$dirty) return errors
      !this.$v.data.Lastname.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Nachname darf keine Zahlen enthalten')
      return errors
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.data.Firstname.$dirty) return errors
      !this.$v.data.Firstname.alphaNumAndDotAndWhiteSpaceValidator && errors.push('Vorname darf keine Zahlen enthalten')
      return errors
    },
  },
  validations: {

      data: {
        PLZ: {
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        City: {alphaNumAndDotAndWhiteSpaceValidator},
        Email: {email},
        Lastname: {alphaNumAndDotAndWhiteSpaceValidator},
        Firstname: {alphaNumAndDotAndWhiteSpaceValidator},
      }

  },
  data()   {
    return {
      date: new Date().toISOString().substr(0, 10),
      DateOfBirth: "",
      modalforstartofwork: false,
      modalfordateofbirth: false,
      showcolorpicker: false,
      StartOfWork: "",
      colorpicker: undefined,
      phoneRules: [
        //(value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
            (this.validatePhoneNumber(value)) || "Keine gültige Telefonnummer",
      ],
      emailRules: [
        //(value) => !!value || "Dieses Feld ist ein Pflichtfeld.",
        (value) =>
            (this.validateEmail(value)) || "Keine gültige E-Mail Adresse",
      ],
      dateRules: [
        /*(value) =>
        (value && this.validateDate(value)) ||
        "kein gültiges Datum",*/
        (value) =>
            this.validateDate(value) || "kein gültiges Datum"
      ]
    }
  },
  methods: {
    storeDateOfBirth(date) {
      this.$refs.dialogdateofbirth.save(date)
      this.date = new Date().toISOString().substr(0, 10)

    },
    storeStartOfWork(date) {
      this.$refs.dialogstartofwork.save(date)
      this.date = new Date().toISOString().substr(0, 10)
    },
    validatePhoneNumber(value) {
      console.log("phonevalue: " + value)
      if (value == "" || value == undefined) {
        return true
      }
      var phoneno = /^\+?([0-9]{2})\)?[ ]?([0-9]{4})[ ]?([0-9]+)$/;
      if (!value.match(phoneno)) {
        console.log("No valid phone");
        return false;
      }
      console.log("valid");
      return true;
    },
    validateEmail(value) {
      if (value == "" || value == undefined) {
        return true
      }
      if (!value.includes("@")) {
        return false;
      }
      if (
          !(
              value.includes(".de") ||
              value.includes(".com") ||
              value.includes(".eu") ||
              value.includes(".ch") ||
              value.includes(".cc") ||
              value.includes(".org") ||
              value.includes(".net") ||
              value.includes(".us") ||
              value.includes(".biz") ||
              value.includes(".co")
          )
      ) {
        return false;
      }
      return true;
    },
    validateDate(value) {
      if (value == "" || value == undefined) {
        return true
      }
      var date = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
      if (!value.match(date)) {
        return false;
      }
      return true;
    },
    showColor() {
      this.showcolorpicker = !this.showcolorpicker
    }
  },
  watch: {
    colorpicker() {
      this.data.EmployeeColorHex = this.colorpicker.hex
    }
  }
}
</script>

<style scoped>
.square {
  height: 30px;
  width: 30px;
  margin-top: 45%;
}

.colorbutton {
  margin-top: 16%
}

.error--text {
  color: red !important;
  font-size: 22px;
  text-transform: uppercase !important;
  font-weight: bold;
  font-style: italic;
}
</style>