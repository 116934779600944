import {
    authHeader
} from '../helpers';

export const templateService = {
    getAll,
    create,
    update,
    deleteById,
    createInvoiceTemplate,
    getAllInvoiceTemplates,
    changeInvoiceTemplate,
    deleteInvoiceTemplateByID,
};

function getAll() {
    console.log(authHeader())
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/templates/`, requestOptions).then(handleResponse);
}

function create(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/template`, requestOptions).then(handleResponse);
}

function update(data) {
    console.log(authHeader())
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/template/change/` + data.ID, requestOptions).then(handleResponse);
}

function deleteById(id) {
    console.log(authHeader())
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/template/delete/` + id, requestOptions).then(handleResponse);
}

async function createInvoiceTemplate(templateDTO){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(templateDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/template/invoice/create`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function getAllInvoiceTemplates(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/template/invoice/get`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function changeInvoiceTemplate(templateDTO){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(templateDTO)
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/template/invoice/change`, requestOptions);
    let data = await handleResponse(response);
    return data;
}

async function deleteInvoiceTemplateByID(templateID){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(process.env.VUE_APP_API_ROUTE + `/template/invoice/delete/` + templateID, requestOptions);
    let data = await handleResponse(response);
    return data;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            alert(response.statusText + ":" + data.message);
            return Promise.reject(error);
        }

        return data;
    });
}