<template>
  <main>
    <v-container fluid>
      <h2><i class="fas fa-users fa-1x"></i> Rechnungslauf: {{ invoiceRuns.item.Name }} </h2>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="NewInvoiceTemplate()">
             Neue Rechnung manuell erzeugen
          </v-btn>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12">
          <h3>Erzeuge Rechnungen</h3>
          <template v-for="invoice in invoiceRuns.item.Invoices">
            <Invoice :invoice="invoice" :key="invoice.ID"></Invoice>
          </template>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="danger"
                @click="DeleteInvoiceRun()"
                :disabled="invoiceRuns.item.Invoices != undefined"
                v-bind="attrs"
                v-on="on"
              >
                Rechnungslauf löschen
              </v-btn>
            </template>
            <span>Lässt sich nur löschen wenn es keine Rechnungen gibt</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import Invoice from "../../components/invoices/Invoice";

export default {
  mounted() {
    this.$root.$refs.start.setMenuActive(true);
  },
  data() {
    return {
      timer: "",
    };
  },
  created() {
    this.reload();
  },
  components: {
    Invoice,
  },
  methods: {
    DeleteInvoiceRun() {
      this.$store
        .dispatch("invoiceRuns/delete", this.id)
        .then(() => {
          this.$router.push("/invoices");
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    NewInvoiceTemplate() {
      let invoiceTemplateDTO = {
        InvoiceRunID: parseInt(this.id),
      };
      this.$store
        .dispatch("invoices/createTemplate", invoiceTemplateDTO)
        .then(() => {
          this.$root.$emit("reload_invoice_details");
        });
      window.location.reload();
    },
    routeTo(url) {
      if (url.startsWith("http")) {
        window.open(url, "_blank");
      } else {
        this.$router.push(url);
      }
    },
    reload() {
      if (this.id != 0 && this.id != undefined) {
        this.$store.dispatch("invoiceRuns/getInvoiceRun", this.id);
      } else {
        this.reload();
      }
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  computed: {
    invoiceRuns() {
      if (this.id != 0) {
        let x = this.$store.state.invoiceRuns.selected;
        console.log(x);
        return x;
      } else {
        let invoiceRun = {
          item: {
            Name: "",
          },
        };
        return invoiceRun;
      }
    },
    id() {
      let tmpID = this.$route.params.id;
      console.log(tmpID);
      return tmpID;
    },
  },
};
</script>

<style scoped>
div.subNav.v-sheet.theme--light.elevation-6
  > div
  > div
  > div.v-slide-group__wrapper
  > div
  > div.v-tab.v-tab--active {
  background-color: #751265 !important;
  color: white !important;
}
div.subNav.v-sheet.theme--light.elevation-6
  > div
  > div
  > div.v-slide-group__wrapper
  > div
  > div.v-tab.v-tab--active
  > a {
  background-color: #751265 !important;
  color: white !important;
}

div.subNav.v-sheet.theme--light.elevation-6
  > div
  > div
  > div.v-slide-group__wrapper
  > div
  > div.v-tab:hover {
  background-color: #951b81 !important;
  color: white !important;
}
div.subNav.v-sheet.theme--light.elevation-6
  > div
  > div
  > div.v-slide-group__wrapper
  > div
  > div.v-tab:hover
  > a {
  background-color: #951b81 !important;
  color: white !important;
}
</style>
